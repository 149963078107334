import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../../../App';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import SituationsService from '../../../../../../api/SituationsService';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import wizardStore from '../../store/wizardStore';
import lessonsStore from '../../../../store/lessonsStore';
import {
    Button,
    Input,
    Label,
    Select,
} from '../../../../../../teacherComponents';
import { Checkbox } from '../../../../../../UI';
import { SelectSituationModal } from '../../../../../TeacherContent/views/SituationsView/components';
import FileUploader, {
    FileTypes,
} from '../../../../../../components/UI/FileUploader/FileUploader';
import { ManageVocabularySearch, ManageWordPackModal } from '../';
import { Chapters } from '../../../../data/constants';
import {
    GENDERS,
    MODALITIES,
} from '../../../../../SituationsAdministration/data/constants';
import { ReactComponent as IconAdd } from '../../../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import { ReactComponent as IconCloud } from '../../../../../../assets/svg/lessons-upload.svg';
import DefaultFile from '../../../../../../assets/webp/situation-cover.webp';
import { WizardViews } from '../../LessonWizard';
import { LanguageLevels } from '../../../../../../data/common';
import cl from './DialogueWizard.module.css';

const DefaultModality = ['Friendly'];

const SelectDialogue = observer(({ onSelect }) => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const { chaptersModalType } = exercisesStore;
    const { currentLesson } = lessonsStore;
    const { difficulty } = currentLesson;

    const [isFav, setIsFav] = useState(false);
    const [vocabulary, setVocabulary] = useState([]);
    const [allSituationsCount, setAllSituationsCount] = useState(null);
    const [favSituationsCount, setFavSituationsCount] = useState(null);

    const [getSituations, isLoading] = useFetching(async () => {
        const { data: categories } =
            await SituationsService.getAvailableCategories({
                language: language.toUpperCase(),
                level: LanguageLevels[difficulty],
                vocabulary,
            });

        const { data } = await ProgressService.getSituations({
            lang: language,
        });

        const ids = data.items.map((s) => s.situationId);

        const allSituations = categories.map((c) => c.situations).flat();
        const favSituations = allSituations.filter((s) => ids.includes(s.id));

        setFavSituationsCount(favSituations?.length || 0);
        setAllSituationsCount(allSituations?.length || 0);
    });

    const handleSelectClick = () => {
        exercisesStore.setChaptersModalType(Chapters.Situation);
    };

    const handleFavClick = () => {
        setIsFav(true);
        setTimeout(() => {
            exercisesStore.setChaptersModalType(Chapters.Situation);
        });
    };

    const handleModalClose = () => {
        setIsFav(false);
        exercisesStore.setChaptersModalType();
    };

    useEffect(() => {
        if (!vocabulary.length) {
            getSituations();
        }
    }, [vocabulary]);

    return (
        <>
            <>
                <Label
                    text={t('lesson_wizard.dialogue_filter_info')}
                    style={{ whiteSpace: 'pre-line' }}
                />
                <ManageVocabularySearch
                    variant={Chapters.Situation}
                    vocabulary={vocabulary}
                    setVocabulary={setVocabulary}
                    onSearch={getSituations}
                    isLoading={isLoading}
                    level={LanguageLevels[difficulty]}
                />
            </>

            <div className={cl.buttonsContainer}>
                <div className={cl.flexContainer}>
                    <Button
                        variant={Chapters.Situation}
                        icon={<IconAdd />}
                        text={
                            typeof favSituationsCount === 'number'
                                ? `${t('teacher_navbar.situations')} (${favSituationsCount})`
                                : t('teacher_navbar.situations')
                        }
                        onClick={handleFavClick}
                        isDisabled={favSituationsCount === 0}
                    />
                    <Button
                        variant={Chapters.Situation}
                        icon={<IconAdd />}
                        text={
                            typeof allSituationsCount === 'number'
                                ? `${t('teacher_onboarding.select_situation')}  (${allSituationsCount})`
                                : t('teacher_onboarding.select_situation')
                        }
                        onClick={handleSelectClick}
                        isDisabled={allSituationsCount === 0}
                    />
                </div>

                <Button
                    className={cl.wideComponent}
                    variant={Chapters.Situation}
                    text={t('exercises.add_new_situation')}
                    icon={<IconStars />}
                    onClick={wizardStore.nextStep}
                />
            </div>

            <SelectSituationModal
                visible={chaptersModalType === Chapters.Situation}
                setVisible={handleModalClose}
                onClick={onSelect}
                // TODO: pass already added dialogues packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isFav}
                vocabulary={vocabulary}
                presetDifficulty={LanguageLevels[difficulty]}
            />
        </>
    );
});

const GenerateDialogue = observer(({ onGenerate }) => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const { currentLesson } = lessonsStore;
    const { difficulty } = currentLesson;
    const { wordPack } = wizardStore;

    const { situationCategoryOptions } = exercisesStore;

    const [addFile, setAddFile] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [maleModalities, setMaleModalities] = useState(DefaultModality);
    const [femaleModalities, setFemaleModalities] = useState(DefaultModality);
    const [requestText, setRequestText] = useState('');
    const [file, setFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const isSendDisabled = () => {
        if (![...maleModalities, ...femaleModalities].length) return true;
        return false;
    };

    const formatSituationsByModalities = () => {
        const userId = authStore.user?.id;
        return [
            ...maleModalities.map((modality) => ({
                title: '',
                categoryId,
                requestText: requestText.toLowerCase(),
                language: language.toUpperCase(),
                gender: GENDERS[0],
                modality,
                level: LanguageLevels[difficulty],
                userId,
            })),
            ...femaleModalities.map((modality) => ({
                title: '',
                categoryId,
                requestText: requestText.toLowerCase(),
                language: language.toUpperCase(),
                gender: GENDERS[1],
                modality,
                level: LanguageLevels[difficulty],
                userId,
            })),
        ];
    };

    const [addSituation, isSituationLoading, error, resetError] = useFetching(
        async () => {
            resetError();
            const situations = formatSituationsByModalities();

            const { data } = await SituationsService.addSituationsBatch({
                situations,
                file,
            });

            if (data && data.length) {
                const info = data[0];
                onGenerate({ id: info.situationId, title: info.title });
            }
        }
    );

    const handleAddImage = () => {
        setAddFile(true);
    };

    const getModality = (modality, gender) => {
        const modalities =
            gender === GENDERS[0] ? maleModalities : femaleModalities;
        const flag = modalities.includes(modality);
        return flag;
    };

    const setModality = (modality, gender) => {
        const setModalities =
            gender === GENDERS[0] ? setMaleModalities : setFemaleModalities;

        setModalities([modality]);
    };

    const setDefaultFile = async () => {
        const fileResponse = await fetch(DefaultFile);
        const blob = await fileResponse.blob();
        setFile(blob);
    };

    const handleSituationGenerate = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                requestText: !requestText,
                categoryId: !categoryId,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            addSituation();
        });
    };

    useEffect(() => {
        setMaleModalities(DefaultModality);
        setFemaleModalities(DefaultModality);
        setRequestText('');
        setDefaultFile();
    }, []);

    useEffect(() => {
        resetError();
    }, [requestText]);

    useEffect(() => {
        setCategoryOptions(situationCategoryOptions);
        setCategoryId(situationCategoryOptions[0]?.value);
    }, [situationCategoryOptions]);

    useEffect(() => {
        setFormErrors({});
    }, [categoryId, requestText]);

    return (
        <>
            <div>
                <Label
                    text={t('situations.request')}
                    isError={formErrors['requestText']}
                />
                <Input
                    value={requestText}
                    variant={
                        formErrors['requestText']
                            ? 'erroredSmall'
                            : 'outlinedSmall'
                    }
                    onChange={setRequestText}
                    placeholder={t('situations.request_example')}
                />
            </div>

            {wordPack.items?.length !== 0 ? (
                <>
                    <div>
                        <Label
                            text={t('lesson_wizard.dialogue_add_word_pack')}
                        />
                        <div className={cl.horizontalContainer}>
                            <p className={cl.currentWordPack}>
                                {wordPack.title}
                            </p>
                            <ManageWordPackModal variant={Chapters.Situation} />
                        </div>
                    </div>
                </>
            ) : (
                <Label
                    text={t('lesson_wizard.include_word_pack_dialogue_info')}
                />
            )}

            {file && (
                <div className={cl.gridContainer}>
                    <div>
                        <Label
                            text={t('exercises.category')}
                            isError={formErrors['categoryId']}
                        />
                        <Select
                            style={{ width: '100%' }}
                            height={180}
                            variant={'grey'}
                            label={t('exercises.category')}
                            value={categoryId}
                            options={categoryOptions}
                            onChange={setCategoryId}
                            ignoreOverflow
                        />
                    </div>

                    <div>
                        <Label
                            text={t('situations.modality')}
                            isError={formErrors['categoryId']}
                        />
                        <div className={cl.horizontalContainer}>
                            {MODALITIES.map((m) => (
                                <Checkbox
                                    color={Chapters.Situation}
                                    key={`female_${m}}`}
                                    style={{ marginBottom: 3, gap: 3 }}
                                    label={t(`situations.${m.toLowerCase()}`)}
                                    value={getModality(m, GENDERS[1])}
                                    onChange={() => setModality(m, GENDERS[1])}
                                />
                            ))}
                        </div>
                    </div>

                    <div
                        className={`${cl.imageCont} ${file ? '' : cl.placeholder}`}
                        onClick={handleAddImage}
                    >
                        {error && (
                            <p className={cl.dialogueError}>
                                {t('situations.conflict_error')}
                            </p>
                        )}

                        <img
                            src={URL.createObjectURL(file)}
                            alt={'situation preview'}
                        />
                        <div className={cl.changeCoverButton}>
                            <IconCloud className={cl.iconCloud} />
                            {t('courses.change_cover')}
                        </div>
                    </div>
                </div>
            )}

            <Button
                style={{ marginTop: 'auto' }}
                variant={Chapters.Situation}
                text={t('exercises.add_new_words')}
                onClick={handleSituationGenerate}
                isLoading={isSituationLoading}
                isDisabled={isSendDisabled()}
            />
            <FileUploader
                type={FileTypes.Image}
                visible={addFile}
                setVisible={setAddFile}
                key="categoryImagePicker"
                onSave={setFile}
                isLessons
            />
        </>
    );
});

const DialogueWizard = ({ onAdd }) => {
    const [view, step] = wizardStore.getCurrentViewAndStep();

    const handleDialogueAdd = ({ id, title }) => {
        onAdd({ chapterType: Chapters.Situation, chapterId: id, title });
        wizardStore.showChaptersList();
    };

    if (!view || view !== WizardViews.Dialogue) return null;

    switch (step) {
        case 'select':
            return <SelectDialogue onSelect={handleDialogueAdd} />;
        case 'generate':
            return <GenerateDialogue onGenerate={handleDialogueAdd} />;

        default:
            return null;
    }
};

export default observer(DialogueWizard);
