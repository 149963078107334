import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../store/interface';
import guestOnboarding from '../../store/guestOnboarding';
import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../TeacherContent/data/constants';
import DemoVideoPreview from '../DemoVideoPreview/DemoVideoPreview';
import DemoDictionaryPreview from '../DemoDictionaryPreview/DemoDictionaryPreview';
import DemoSituationPreview from '../DemoSituationPreview/DemoSituationPreview';
import { Chapters } from '../../../TeacherLessons/data/constants';
import DemoExercisePreview from '../DemoExercisePreview/DemoExercisePreview';
import { Button, Select } from '../../../../teacherComponents';
import SelectionBar from '../SelectionBar/SelectionBar';
import { ReactComponent as IconVideo } from '../../../../assets/svg/icon-video-large.svg';
import { ReactComponent as IconExercises } from '../../../../assets/svg/icon-exercises-large.svg';
import { ReactComponent as IconSituations } from '../../../../assets/svg/icon-situations-large.svg';
import { ReactComponent as IconDictionaries } from '../../../../assets/svg/icon-dictionaries-large.svg';
import { ReactComponent as IconExercises1 } from '../../../../assets/svg/icon-exercises-1.svg';
import { ReactComponent as IconExercises2 } from '../../../../assets/svg/icon-exercises-2.svg';
import { ReactComponent as IconExercises3 } from '../../../../assets/svg/icon-exercises-3.svg';
import { ReactComponent as IconExercises4 } from '../../../../assets/svg/icon-exercises-4.svg';
import { ReactComponent as IconExercises5 } from '../../../../assets/svg/icon-exercises-5.svg';
import { ReactComponent as IconDictionaries1 } from '../../../../assets/svg/icon-dictionaries-1.svg';
import { ReactComponent as IconDictionaries2 } from '../../../../assets/svg/icon-dictionaries-2.svg';
import { ReactComponent as IconDictionaries3 } from '../../../../assets/svg/icon-dictionaries-3.svg';
import { ReactComponent as IconDictionaries4 } from '../../../../assets/svg/icon-dictionaries-4.svg';
import { ReactComponent as IconVideo1 } from '../../../../assets/svg/icon-video-1.svg';
import { ReactComponent as IconVideo2 } from '../../../../assets/svg/icon-video-2.svg';
import { ReactComponent as IconVideo3 } from '../../../../assets/svg/icon-video-3.svg';
import { ReactComponent as IconVideo4 } from '../../../../assets/svg/icon-video-4.svg';
import { ReactComponent as IconVideo5 } from '../../../../assets/svg/icon-video-5.svg';
import { ReactComponent as IconSituations1 } from '../../../../assets/svg/icon-situations-1.svg';
import { ReactComponent as IconSituations2 } from '../../../../assets/svg/icon-situations-2.svg';
import { ReactComponent as IconSituations3 } from '../../../../assets/svg/icon-situations-3.svg';
import { ReactComponent as IconSituations4 } from '../../../../assets/svg/icon-situations-4.svg';
import { ReactComponent as IconSituations5 } from '../../../../assets/svg/icon-situations-5.svg';
import { ReactComponent as FlagEn } from '../../../../assets/svg/flag_en.svg';
import { ReactComponent as FlagEs } from '../../../../assets/svg/flag_es.svg';
import { ReactComponent as FlagPt } from '../../../../assets/svg/flag_pt.svg';
import { ReactComponent as FlagDe } from '../../../../assets/svg/flag_de.svg';
import { ReactComponent as FlagFr } from '../../../../assets/svg/flag_fr.svg';
import { ReactComponent as FlagIt } from '../../../../assets/svg/flag_it.svg';
import { ReactComponent as IconStart } from '../../../../assets/svg/icon-start.svg';
import cl from './TaskCard.module.css';
import InfoWrapper from '../InfoWrapper/InfoWrapper';
import {
    ArrowPositions,
    BubblePositions,
} from '../../../../teacherComponents/Onboarding';

const getImage = (lang) => {
    switch (lang) {
        case 'en':
            return <FlagEn />;
        case 'es':
            return <FlagEs />;
        case 'pt':
            return <FlagPt />;
        case 'de':
            return <FlagDe />;
        case 'fr':
            return <FlagFr />;
        case 'it':
            return <FlagIt />;
        default:
            return <FlagEn />;
    }
};

const getStyleIcon = (type) => {
    switch (type) {
        case Chapters.Exercise:
            return cl.exercisesIcon;
        case Chapters.Dictionary:
            return cl.dictionariesIcon;
        case Chapters.Track:
            return cl.tracksIcon;
        case Chapters.Situation:
            return cl.situationsIcon;
        default:
            return '';
    }
};

const TaskCard = () => {
    const { availableLanguages, interfaceLang, nativeLang } = languagesStore;
    const ref = useRef(null);
    const isInitialRender = useRef(true);

    const [type, setType] = useState(Chapters.Dictionary);
    const [wasChanged, setWasChanged] = useState(false);
    const [isStudentPreview, setStudentPreview] = useState(false);
    const [exerciseType, setExerciseType] = useState(
        GrammarExerciseTypes.Substitution
    );

    const [onboardingFlags, setOnboardingFlags] = useState({
        [Chapters.Dictionary]: false,
        [Chapters.Exercise]: false,
        [Chapters.Situation]: false,
        [Chapters.Track]: false,
    });

    const exerciseTypes = [
        ...Object.values(GrammarExerciseTypes),
        ...Object.values(MediaExerciseTypes),
    ].map((type) => ({
        value: type,
        name: t(`exercises.${type}`),
    }));

    const tasks = {
        [Chapters.Exercise]: {
            title: t('navbar.exercises'),
            icon: <IconExercises />,
            description: [
                {
                    icon: <IconExercises1 />,
                    title: t('teacher_tutorial.task_example_exercises_1_title'),
                    subtitle: t(
                        'teacher_tutorial.task_example_exercises_1_subtitle'
                    ),
                },
                {
                    icon: <IconExercises2 />,
                    title: t('teacher_tutorial.task_example_exercises_2_title'),
                    subtitle: t(
                        'teacher_tutorial.task_example_exercises_2_subtitle'
                    ),
                },
                {
                    icon: <IconExercises3 />,
                    title: t('teacher_tutorial.task_example_exercises_3_title'),
                    subtitle: t(
                        'teacher_tutorial.task_example_exercises_3_subtitle'
                    ),
                },
                {
                    icon: <IconExercises4 />,
                    title: t('teacher_tutorial.task_example_exercises_4_title'),
                    subtitle: t(
                        'teacher_tutorial.task_example_exercises_4_subtitle'
                    ),
                },
                {
                    icon: <IconExercises5 />,
                    title: t('teacher_tutorial.task_example_exercises_5_title'),
                    subtitle: t(
                        'teacher_tutorial.task_example_exercises_5_subtitle'
                    ),
                },
            ],
        },
        [Chapters.Dictionary]: {
            title: t('navbar.dictionaries'),
            icon: <IconDictionaries />,
            description: [
                {
                    icon: <IconDictionaries1 />,
                    title: t(
                        'teacher_tutorial.task_example_dictionaries_1_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_dictionaries_1_subtitle'
                    ),
                },
                {
                    icon: <IconDictionaries2 />,
                    title: t(
                        'teacher_tutorial.task_example_dictionaries_2_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_dictionaries_2_subtitle'
                    ),
                },
                {
                    icon: <IconDictionaries3 />,
                    title: t(
                        'teacher_tutorial.task_example_dictionaries_3_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_dictionaries_3_subtitle'
                    ),
                },
                {
                    icon: <IconDictionaries4 />,
                    title: t(
                        'teacher_tutorial.task_example_dictionaries_4_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_dictionaries_4_subtitle'
                    ),
                },
            ],
        },
        [Chapters.Track]: {
            title: t('navbar.tracks'),
            icon: <IconVideo />,
            description: [
                {
                    icon: <IconVideo1 />,
                    title: t('teacher_tutorial.task_example_tracks_1_title'),
                    subtitle: t(
                        'teacher_tutorial.task_example_tracks_1_subtitle'
                    ),
                },
                {
                    icon: <IconVideo2 />,
                    title: t('teacher_tutorial.task_example_tracks_2_title'),
                    subtitle: t('teacher_tutorial.task_example_tracks_2_title'),
                },
                {
                    icon: <IconVideo3 />,
                    title: t('teacher_tutorial.task_example_tracks_3_title'),
                    subtitle: t('teacher_tutorial.task_example_tracks_3_title'),
                },
                {
                    icon: <IconVideo4 />,
                    title: t('teacher_tutorial.task_example_tracks_4_title'),
                    subtitle: t('teacher_tutorial.task_example_tracks_4_title'),
                },
                {
                    icon: <IconVideo5 />,
                    title: t('teacher_tutorial.task_example_tracks_5_title'),
                    subtitle: t('teacher_tutorial.task_example_tracks_5_title'),
                },
            ],
        },
        [Chapters.Situation]: {
            title: t('navbar.situations'),
            icon: <IconSituations />,
            description: [
                {
                    icon: <IconSituations1 />,
                    title: t(
                        'teacher_tutorial.task_example_situations_1_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_situations_1_subtitle'
                    ),
                },
                {
                    icon: <IconSituations2 />,
                    title: t(
                        'teacher_tutorial.task_example_situations_2_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_situations_2_subtitle'
                    ),
                },
                {
                    icon: <IconSituations3 />,
                    title: t(
                        'teacher_tutorial.task_example_situations_3_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_situations_3_subtitle'
                    ),
                },
                {
                    icon: <IconSituations4 />,
                    title: t(
                        'teacher_tutorial.task_example_situations_4_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_situations_4_subtitle'
                    ),
                },
                {
                    icon: <IconSituations5 />,
                    title: t(
                        'teacher_tutorial.task_example_situations_5_title'
                    ),
                    subtitle: t(
                        'teacher_tutorial.task_example_situations_5_subtitle'
                    ),
                },
            ],
        },
    };

    const renderPreview = () => {
        switch (type) {
            case Chapters.Exercise:
                return <DemoExercisePreview exerciseType={exerciseType} />;
            case Chapters.Dictionary:
                return (
                    <DemoDictionaryPreview
                        exerciseType={exerciseType}
                        isStudent={isStudentPreview}
                    />
                );
            case Chapters.Situation:
                return (
                    <DemoSituationPreview
                        exerciseType={exerciseType}
                        isStudent={isStudentPreview}
                    />
                );
            case Chapters.Track:
                return (
                    <DemoVideoPreview
                        exerciseType={exerciseType}
                        isStudent={isStudentPreview}
                    />
                );
            default:
                return null;
        }
    };

    const scrollIntoView = (element) => {
        const offset = 15;

        const rect = element.getBoundingClientRect();

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo({
            top: scrollTop + rect.top - offset,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else if (ref.current) {
            scrollIntoView(ref.current);
        }
    }, [type]);

    useEffect(() => {
        if (onboardingFlags[Chapters.Dictionary]) return;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    handleCardShow(ref.current);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.7,
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [onboardingFlags[Chapters.Dictionary]]);

    const handleNewTypeSelect = () => {
        if (onboardingFlags[type] || type === Chapters.Dictionary) return;

        guestOnboarding.setCurrentItem(type);
        setOnboardingFlags({
            ...onboardingFlags,
            [type]: true,
        });
    };

    const handleCardShow = (element) => {
        document.body.style.overflow = 'hidden';
        setOnboardingFlags({
            ...onboardingFlags,
            [Chapters.Dictionary]: true,
        });
        setTimeout(() => {
            scrollIntoView(element);
        }, 100);
        setTimeout(() => {
            guestOnboarding.setCurrentItem('navigation');
        }, 700);
    };

    useEffect(() => {
        handleNewTypeSelect();
    }, [type]);

    useEffect(() => {
        languagesStore.setNativeLang(interfaceLang === 'de' ? 'en' : 'de');
    }, [interfaceLang]);

    useEffect(() => {
        if (type !== Chapters.Dictionary) {
            setWasChanged(true);
        }
    }, [type]);

    useEffect(() => {
        if (wasChanged) return;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
                    scrollIntoView(ref.current);
                    setWasChanged(true);
                    setTimeout(() => {
                        scrollIntoView(ref.current);
                    }, 100);
                    setTimeout(() => {
                        guestOnboarding.setCurrentItem('extra_navigation');
                    }, 700);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.5,
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [wasChanged]);

    return (
        <div className={cl.cardContainer} ref={ref}>
            <SelectionBar
                className={cl.selectionBar}
                isTaskExample={true}
                type={type}
                onChange={setType}
            />
            <div className={cl.card}>
                <div className={cl.descriptionBlock}>
                    <div className={cl.description}>
                        {tasks[type].description.map((el, i) => {
                            return (
                                <div className={cl.taskDescription} key={i}>
                                    <div
                                        className={`${cl.taskDescriptionIcon} ${getStyleIcon(type)}`}
                                    >
                                        {el.icon}
                                    </div>
                                    <div
                                        className={cl.taskDescriptionTextBlock}
                                    >
                                        <p className={cl.taskDescriptionTitle}>
                                            {el.title}
                                        </p>
                                        <p
                                            className={
                                                cl.taskDescriptionSubtitle
                                            }
                                        >
                                            {el.subtitle}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {type === Chapters.Exercise ? (
                        <div className={cl.buttonDescription}>
                            <p className={cl.buttonDescriptionTitle}>
                                {t(
                                    'teacher_tutorial.task_example_button_title_1'
                                )}
                            </p>
                            <p className={cl.buttonDescriptionSubtitle}>
                                {t(
                                    'teacher_tutorial.task_example_button_subtitle_1'
                                )}
                            </p>
                            <InfoWrapper
                                onboardingKey={guestOnboarding.isOnboarding(
                                    'exercise_type'
                                )}
                                title={t('teacher_tutorial.exercise_type_info')}
                                subtitle={t(
                                    'teacher_tutorial.exercise_type_info_subtitle'
                                )}
                                delay={500}
                                bubblePosition={{
                                    x: BubblePositions.x.Center,
                                    y: BubblePositions.y.Top,
                                }}
                                arrowPosition={ArrowPositions.Top}
                                showButton
                            >
                                <div className={cl.selectContainer}>
                                    <Select
                                        className={cl.exerciseTypeSelect}
                                        variant={'blue'}
                                        label={t('exercises.type')}
                                        value={exerciseType}
                                        onChange={setExerciseType}
                                        options={exerciseTypes}
                                        height={215}
                                        hideReset
                                        withExerciseIcons
                                    />
                                </div>
                            </InfoWrapper>
                        </div>
                    ) : (
                        <div className={cl.buttonDescription}>
                            {!isStudentPreview ? (
                                <>
                                    <p className={cl.buttonDescriptionTitle}>
                                        {t(
                                            'teacher_tutorial.task_example_button_title_2'
                                        )}
                                    </p>
                                    <p className={cl.buttonDescriptionSubtitle}>
                                        {t(
                                            'teacher_tutorial.task_example_button_subtitle_2'
                                        )}
                                    </p>

                                    <InfoWrapper
                                        onboardingKey={guestOnboarding.isOnboarding(
                                            'student_button'
                                        )}
                                        title={t(
                                            'teacher_tutorial.student_mode_info'
                                        )}
                                        subtitle={t(
                                            'teacher_tutorial.student_mode_info_subtitle'
                                        )}
                                        delay={500}
                                        bubblePosition={{
                                            x: BubblePositions.x.RightBorder,
                                            y: BubblePositions.y.Top,
                                        }}
                                        showButton
                                    >
                                        <div className={cl.buttonsBar}>
                                            <Select
                                                className={cl.select}
                                                variant={'white'}
                                                label={
                                                    availableLanguages.find(
                                                        (l) =>
                                                            l.value ===
                                                            nativeLang
                                                    ).name
                                                }
                                                icon={getImage(nativeLang)}
                                                options={availableLanguages.filter(
                                                    (lObj) =>
                                                        lObj.value !==
                                                        interfaceLang
                                                )}
                                                value={nativeLang}
                                                onChange={
                                                    languagesStore.setNativeLang
                                                }
                                                hideReset
                                            />

                                            <Button
                                                className={cl.button}
                                                variant={type}
                                                text={t(
                                                    'teacher_tutorial.view_as_student'
                                                )}
                                                onClick={() =>
                                                    setStudentPreview(
                                                        !isStudentPreview
                                                    )
                                                }
                                                icon={
                                                    <IconStart
                                                        className={cl.startIcon}
                                                    />
                                                }
                                            />
                                        </div>
                                    </InfoWrapper>
                                </>
                            ) : (
                                <Button
                                    className={cl.teacherButton}
                                    variant={type}
                                    text={t('teacher_tutorial.view_as_teacher')}
                                    onClick={() =>
                                        setStudentPreview(!isStudentPreview)
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
                {renderPreview()}
            </div>
        </div>
    );
};

export default observer(TaskCard);
