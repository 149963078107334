import React from 'react';
import Image from '../../../../assets/webp/teachers-donwload.webp';
import { ReactComponent as WhiteLogo } from '../../../../assets/svg/icon-pony-white.svg';
import Typography from '../Typography/Typography';
import { t } from 'i18next';
import DownloadButtons from '../DownloadButtons/DownloadButtons';
import cl from './DownloadSection.module.css';

const DownloadSection = () => {
    return (
        <div className={cl.imageContainer}>
            <img src={Image} alt="Background" />
            <WhiteLogo className={cl.logo} />
            <Typography
                variant={'h2'}
                className={cl.title}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.download_section_title'
                )}
            />
            <DownloadButtons size={'medium_landing'} variant={'white'} />
        </div>
    );
};

export default DownloadSection;
