import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import exercisesPlayer from '../../../TeacherContent/store/exercisesPlayer';
import { Loader } from '../../../../UI';
import {
    AdvancedQuizPreview,
    ClozePreview,
    FreeFormQuestionsPreview,
    MultichoicePreview,
    QuizPreview,
    SentenceOrderPreview,
    SubstitutionPreview,
    SummaryPreview,
    WordsOrderPreview,
} from '../../../TeacherContent/views/ExercisesView/components/Preview/components';
import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../TeacherContent/data/constants';
import { LanguageLevels, TicksInSecond } from '../../../../data/common';
import {
    ExercisePlayer,
    ExercisePlayerControls,
} from '../../../TeacherContent/views/ExercisesView/components';
import { Button } from '../../../../teacherComponents';
import { getExerciseByLangAndType } from '../../data/exersises';
import cl from './DemoExercisePreview.module.css';
import InfoModal from '../InfoModal/InfoModal';
import guestOnboarding from '../../store/guestOnboarding';
import { Chapters } from '../../../TeacherLessons/data/constants';

const DemoExercise = observer(({ exerciseObj, isLoading }) => {
    const { t } = useTranslation();
    const { showAnswers } = exercisesStore;

    const containerRef = useRef();

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const isExerciseHaveAnswers = () => {
        return ![
            MediaExerciseTypes.FreeFormQuestions,
            GrammarExerciseTypes.StatementsTransformation,
            MediaExerciseTypes.Presentation,
        ].includes(exerciseObj.type);
    };

    const getMediaPreview = () => {
        if (exerciseObj.trackId && exerciseObj.trackInterval) {
            return (
                <div className={cl.videoContainer} key={exerciseObj.trackId}>
                    <ExercisePlayer isPreview={true} />
                    <ExercisePlayerControls mode={'video'} isPreview={true} />
                </div>
            );
        }
        if (exerciseObj.text) {
            return <div className={cl.exerciseText}>{exerciseObj.text}</div>;
        }

        return null;
    };

    const getPreview = () => {
        switch (exerciseObj.type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoicePreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozePreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Summary:
                return (
                    <SummaryPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Presentation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        showInput={true}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showInput={true}
                    />
                );
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        showInput={true}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleShowAnswers = () => {
        exercisesStore.setShowAnswers(!showAnswers);
    };

    const renderButtons = () => {
        const flag = isExerciseHaveAnswers();

        return flag ? (
            <div className={cl.buttons}>
                <Button
                    text={t(
                        showAnswers ? 'buttons.reset' : 'exercises.show_answers'
                    )}
                    variant={showAnswers ? 'dark' : 'grey'}
                    onClick={handleShowAnswers}
                />
            </div>
        ) : null;
    };

    useEffect(() => {
        if (showAnswers) {
            scrollToBottom();
        }
    }, [showAnswers]);

    if (isLoading) return <Loader style={{ margin: 'auto' }} />;

    return (
        <>
            <div className={cl.exerciseContainer} ref={containerRef}>
                <p className={cl.exerciseTitle}>
                    {exerciseObj.title}
                    <span>{LanguageLevels[exerciseObj.difficulty]}</span>
                </p>
                <p className={cl.exerciseDescription}>
                    {exerciseObj.description}
                </p>

                {getMediaPreview()}
                {getPreview()}
            </div>
            <div className={cl.previewButtons}>{renderButtons()}</div>
        </>
    );
});

const DemoExercisePreview = ({ className, exerciseType }) => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const [exerciseObj, setExerciseObj] = useState({});

    const [getTrack, trackLoading] = useFetching(
        async ({ trackId, trackInterval }) => {
            const { data } = await TrackService.getTrack(trackId);
            const rangeInTicks = trackInterval.map((t) => t * TicksInSecond);
            exercisesPlayer.setTrackData(data);
            exercisesPlayer.setMediaRange(rangeInTicks);
            exercisesPlayer.setCurrentTime(rangeInTicks[0]);
        }
    );

    useEffect(() => {
        const getData = async () => {
            const data = getExerciseByLangAndType(language, exerciseType);
            if (data?.trackId && data?.trackInterval) {
                await getTrack({
                    trackId: data.trackId,
                    trackInterval: data.trackInterval,
                });
            } else {
                exercisesPlayer.resetMediaParams();
            }
            setExerciseObj(data);
        };
        getData();
    }, [exerciseType, language]);

    useEffect(() => {
        exercisesStore.setShowAnswers(false);
    }, [exerciseType]);

    return (
        <div className={`${cl.previewContainer} ${className}`}>
            <DemoExercise exerciseObj={exerciseObj} isLoading={trackLoading} />
            <InfoModal
                delay={700}
                onboardingKey={guestOnboarding.isOnboarding(Chapters.Exercise)}
                variant={Chapters.Exercise}
                title={t('teacher_tutorial.exercises_info')}
                subtitle={t('teacher_tutorial.exercises_info_subtitle')}
            />
        </div>
    );
};

export { DemoExercise };

export default observer(DemoExercisePreview);
