import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesPlayer from '../../../../../TeacherContent/store/exercisesPlayer';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import TrackService from '../../../../../../api/TrackService';
import {
    GrammarExerciseTypes,
    LevelOptions,
    MaximumMediaCharactersAmount,
    MediaExerciseTypes,
    MediaTypes,
    QuestionsRange,
    SummaryQuestionsRange,
    TextGenres,
    TextRange,
} from '../../../../../TeacherContent/data/constants';
import {
    Button,
    Input,
    Label,
    Select,
    Tooltip,
    Tree,
} from '../../../../../../teacherComponents';
import { SelectVideoModal } from '../../../../../TeacherContent/components';
import { Checkbox, FilledRange, Loader } from '../../../../../../UI';
import {
    ExerciseIcon,
    ExercisePlayer,
    ExercisePlayerControls,
    SelectExerciseModal,
} from '../../../../../TeacherContent/views/ExercisesView/components';
import { Chapters } from '../../../../data/constants';
import { LanguageLevels, TicksInSecond } from '../../../../../../data/common';
import { GenresByLevel } from '../../../../../TeacherContent/views/ExercisesView/components/GenerateText/GenerateText';
import toHoursAndMinutes from '../../../../../../utils/toHoursAndMinutes';
import { WizardViews } from '../../LessonWizard';
import { ManageWordPackModal } from '../';
import { ReactComponent as IconAdd } from '../../../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import { ReactComponent as IconText } from '../../../../../../assets/svg/lessons-text_alt.svg';
import { ReactComponent as IconVideo } from '../../../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconAbc } from '../../../../../../assets/svg/exercises-abc.svg';
import { ReactComponent as IconArrow } from '../../../../../../assets/svg/lessons-arrow.svg';
import { ReactComponent as IconDialogue } from '../../../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconYouTube } from '../../../../../../assets/svg/youtube-logo.svg';
import cl from './ExerciseWizard.module.css';

export const ExerciseTypes = {
    Grammar: 'grammar',
    Text: 'text',
    Video: 'video',
};

const TextSourceTypes = {
    AI: 'ai',
    Own: 'own',
    Dialogue: 'dialogue',
};

const VideoSourceTypes = {
    Own: 'own',
    Library: 'library',
    YouTube: 'youtube',
};

const DefaultExerciseFormData = {
    difficulty: '',
    grammarTopic: '',
    vocabularyTopic: '',
};

const ExerciseTypeIcon = ({ type }) => {
    switch (type) {
        case ExerciseTypes.Grammar:
            return <IconAbc className={cl.pathIcon} />;
        case ExerciseTypes.Text:
            return <IconText className={cl.strokeIcon} />;
        case ExerciseTypes.Video:
            return <IconVideo className={cl.videoIcon} />;
        default:
            return null;
    }
};

const TextSourceIcon = ({ source }) => {
    switch (source) {
        case TextSourceTypes.AI:
            return <IconStars className={cl.strokeIcon} />;
        case TextSourceTypes.Own:
            return <IconText className={cl.strokeIcon} />;
        case TextSourceTypes.Dialogue:
            return <IconDialogue className={cl.strokeIcon} />;
        default:
            return null;
    }
};

const VideoSourceIcon = ({ source }) => {
    switch (source) {
        case VideoSourceTypes.Own:
            return <IconVideo className={cl.videoIcon} />;
        case VideoSourceTypes.Library:
            return <IconVideo className={cl.videoIcon} />;
        case VideoSourceTypes.YouTube:
            return <IconYouTube className={cl.ytIcon} />;
        default:
            return null;
    }
};

const SelectExercise = observer(({ onSelect }) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const exercisesParamsSource =
        lessonsStore.currentLesson ?? wizardStore.exerciseParams;

    const { difficulty } = exercisesParamsSource;
    const { grammarOptions, lexicalOptions } = wizardStore;

    const [isOwn, setIsOwn] = useState(false);

    const [searchString, setSearchString] = useState('');

    const [allExercisesCount, setAllExercisesCount] = useState(null);
    const [favExercisesCount, setFavExercisesCount] = useState(null);

    const [getExercises, isLoading] = useFetching(async () => {
        const { data: allExercises } = await ExerciseService.getExercises({
            offset: 0,
            limit: 500,
            lang,
            difficulty,
            searchString,
        });
        const { data: favExercises } = await ExerciseService.getSavedExercises({
            offset: 0,
            limit: 500,
            lang,
            difficulty,
            searchString,
        });

        setAllExercisesCount(allExercises.items?.length || 0);
        setFavExercisesCount(favExercises.items?.length || 0);
    });

    const [addExercise, addLoading] = useFetching(async ({ id }) => {
        if (!isOwn) {
            await ExerciseService.cloneExercise({ id });
        }
        const { data } = await ExerciseService.getExercise({
            id,
        });
        onSelect({
            id,
            title: data.title,
        });
    });

    const { chaptersModalType } = exercisesStore;

    const handleSelectClick = () => {
        exercisesStore.setChaptersModalType(Chapters.Exercise);
    };

    const handleOwnClick = () => {
        setIsOwn(true);
        setTimeout(() => {
            exercisesStore.setChaptersModalType(Chapters.Exercise);
        });
    };

    const getAllTopics = () => [
        ...grammarOptions.flatMap((cObj) =>
            cObj.topics.map((tObj) => tObj.topic)
        ),
        ...lexicalOptions.flatMap((cObj) =>
            cObj.topics.map((tObj) => tObj.topic)
        ),
    ];

    useEffect(() => {
        if (!searchString.length) {
            getExercises();
        }
    }, [searchString]);

    return (
        <>
            <Label
                text={t('lesson_wizard.exercises_filter_info')}
                style={{ whiteSpace: 'pre-line' }}
            />
            <div className={cl.searchContainer}>
                <Input
                    placeholder={t('lesson_wizard.search_exercises')}
                    value={searchString}
                    onChange={setSearchString}
                    variant={'outlinedSmall'}
                    hints={getAllTopics()}
                    search
                    showReset
                />
                <Button
                    variant={Chapters.Exercise}
                    text={`${t('lesson_wizard.search_by_words')} (${LanguageLevels[difficulty]})`}
                    onClick={getExercises}
                    isLoading={isLoading}
                />
            </div>

            <div className={cl.buttonsContainer}>
                <div>
                    <div className={cl.flexContainer}>
                        <Button
                            variant={'exercise'}
                            icon={<IconAdd />}
                            text={
                                typeof favExercisesCount === 'number'
                                    ? `${t('teacher_navbar.exercises')} (${favExercisesCount})`
                                    : t('teacher_navbar.exercises')
                            }
                            onClick={handleOwnClick}
                            isDisabled={favExercisesCount === 0}
                        />
                        <Button
                            variant={'exercise'}
                            icon={<IconAdd />}
                            text={
                                typeof allExercisesCount === 'number'
                                    ? `${t('teacher_onboarding.select_exercise')} (${allExercisesCount})`
                                    : t('teacher_onboarding.select_exercise')
                            }
                            onClick={handleSelectClick}
                            isDisabled={allExercisesCount === 0}
                        />
                    </div>
                </div>

                <Button
                    className={cl.wideComponent}
                    variant={'exercise'}
                    text={t('exercises.add_new_exercise')}
                    icon={<IconStars />}
                    onClick={wizardStore.nextStep}
                />
            </div>

            <SelectExerciseModal
                visible={chaptersModalType === Chapters.Exercise}
                setVisible={() => exercisesStore.setChaptersModalType()}
                onClick={addExercise}
                // TODO: pass already added exercises packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isOwn}
                presetDifficulty={LanguageLevels[difficulty]}
                presetSearchString={searchString}
            />
        </>
    );
});

const ExerciseType = observer(() => {
    const { t } = useTranslation();

    const renderExercises = (type) => {
        const exercises =
            type === ExerciseTypes.Grammar
                ? GrammarExerciseTypes
                : MediaExerciseTypes;
        return (
            <span>
                {Object.values(exercises)
                    .map((type) => t(`exercises.${type}`))
                    .join(', ')}
            </span>
        );
    };

    const handleTypeSelect = (type) => {
        wizardStore.setExerciseType(type);
        wizardStore.nextStep();
    };

    return (
        <div className={cl.exerciseTypeContainer}>
            {Object.values(ExerciseTypes).map((type) => (
                <div
                    className={cl.basicCard}
                    key={type}
                    onClick={() => handleTypeSelect(type)}
                >
                    <ExerciseTypeIcon type={type} />
                    <div className={cl.basicCardText}>
                        <p>{t(`lesson_wizard.${type}_exercise`)}</p>
                        {renderExercises(type)}
                    </div>
                </div>
            ))}
        </div>
    );
});

const ExerciseKind = observer(() => {
    const { t } = useTranslation();

    const { exerciseType } = wizardStore;

    const handleKindSelect = (kind) => {
        wizardStore.setExerciseKind(kind);
        wizardStore.nextStep();
    };

    const renderExerciseCard = (kind, mediaType) => {
        return (
            <div
                className={cl.basicCard}
                key={kind}
                onClick={() => handleKindSelect(kind)}
            >
                <ExerciseIcon type={kind} additionalClass={cl.modalIcon} />
                <div className={cl.basicCardText}>
                    <p>{t(`exercises.${kind}`)}</p>
                    <span>
                        {t(
                            `exercises.${kind}${
                                mediaType === MediaTypes.GeneratedText
                                    ? '_text'
                                    : ''
                            }_desc`
                        )}
                    </span>
                </div>
            </div>
        );
    };

    const exercises =
        exerciseType === ExerciseTypes.Grammar
            ? GrammarExerciseTypes
            : MediaExerciseTypes;
    return (
        <div className={cl.exerciseKindContainer}>
            {Object.values(exercises).map((kind) =>
                renderExerciseCard(kind, exerciseType)
            )}
        </div>
    );
});

const TextSource = observer(() => {
    const { t } = useTranslation();

    const { exerciseType, exerciseKind, grammarOptions } = wizardStore;

    const exercisesParamsSource =
        lessonsStore.currentLesson ?? wizardStore.exerciseParams;
    const { grammarTopic } = exercisesParamsSource;

    const currentGrammar = grammarOptions
        .flatMap((o) => o.topics)
        .find((tObj) => tObj.topic === grammarTopic);
    const availableTypes = currentGrammar ? currentGrammar.availableTypes : [];

    const handleSourceSelect = (source) => {
        wizardStore.setTextSource(source);
        wizardStore.nextStep();
    };

    const renderAlert = (source) => {
        if (
            !availableTypes ||
            !availableTypes.length ||
            exerciseType !== ExerciseTypes.Grammar ||
            source !== TextSourceTypes.AI
        )
            return null;

        return availableTypes.some((type) => type === exerciseKind) ? null : (
            <p className={cl.unsupportedAlert}>
                {t('lesson_wizard.unsupporeted_exercise_alert')}
            </p>
        );
    };

    return (
        <div className={cl.exerciseTypeContainer}>
            {Object.values(TextSourceTypes).map((s) => (
                <div
                    key={s}
                    className={`${cl.basicCard} ${s === TextSourceTypes.Dialogue ? cl.disabled : ''}`}
                    onClick={() => handleSourceSelect(s)}
                >
                    <TextSourceIcon
                        source={s}
                        additionalClass={cl.sourceIcon}
                    />
                    <div className={cl.basicCardText}>
                        <p>{t(`lesson_wizard.${s}_text`)}</p>
                        <span>{t(`lesson_wizard.${s}_text_description`)}</span>
                        {renderAlert(s)}
                    </div>
                </div>
            ))}
        </div>
    );
});

const GenerateByText = observer(({ onGenerate }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const {
        textSource,
        exerciseKind,
        exerciseType,
        wordPack,
        grammarOptions,
        lexicalOptions,
        extraStep,
    } = wizardStore;

    const exercisesParamsSource =
        lessonsStore.currentLesson ?? wizardStore.exerciseParams;
    const { difficulty } = exercisesParamsSource;

    const [showText, setShowText] = useState(
        textSource === TextSourceTypes.Own ? true : false
    );
    const [text, setText] = useState(
        textSource === TextSourceTypes.Own ? '' : null
    );

    const [textSentencesAmount, setTextSentencesAmount] = useState(
        TextRange.Default
    );
    const [entitesAmount, setEntitiesAmount] = useState(
        exerciseKind === MediaExerciseTypes.Summary
            ? SummaryQuestionsRange.Default
            : QuestionsRange.Default
    );
    const [gapByWords, setGapByWords] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [exerciseParams, setExerciseParams] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredLexical, setFilteredLexical] = useState(lexicalOptions);

    const [generateExercise, isExerciseLoading] = useFetching(async () => {
        const currentGrammar = grammarOptions
            .flatMap((o) => o.topics)
            .find((tObj) => tObj.topic === exerciseParams.grammarTopic);

        const { items } = wordPack;
        const vocabulary = items ? items.map((wObj) => wObj.word) : [];

        let exerciseText = text;

        if (!exerciseText) {
            const { data: textData } = await ExerciseService.generateText({
                lang,
                difficulty,
                grammarTopic: currentGrammar.prompt,
                grammarRule: currentGrammar?.grammarRule ?? '',
                textTopic: exerciseParams.vocabularyTopic,
                genre: exerciseParams.genre,
                vocabulary,
                sentencesAmount: textSentencesAmount,
                isStatements:
                    exerciseKind ===
                    GrammarExerciseTypes.StatementsTransformation,
            });

            setText(textData.text);
            exerciseText = textData.text;
        }

        wizardStore.setExtraStep(true);

        if (showText) return;

        const { data } = await ExerciseService.createExercise({
            type: exerciseKind,
            prompt: currentGrammar?.prompt ?? exerciseParams.grammarTopic,
            grammarRule: currentGrammar?.grammarRule ?? '',
            grammarTopic: exercisesParamsSource.grammarTopic,
            vocabularyTopic: exercisesParamsSource.vocabularyTopic,
            commonMistakes: currentGrammar?.commonMistakes,
            text: exerciseText,
            sentencesAmount: entitesAmount,
            difficulty,
            lang,
            mediaType: ExerciseTypes.Text,
            vocabulary,
        });
        if (data) {
            onGenerate({ id: data.id, title: data.title });
        }
    });

    const [generateTextExercise, isTextExerciseLoading] = useFetching(
        async () => {
            const currentGrammar = grammarOptions
                .flatMap((o) => o.topics)
                .find((tObj) => tObj.topic === exerciseParams.grammarTopic);

            const { items } = wordPack;
            const vocabulary = items ? items.map((wObj) => wObj.word) : [];

            const { data } = await ExerciseService.createExercise({
                type: exerciseKind,
                prompt: currentGrammar?.prompt ?? exerciseParams.grammarTopic,
                grammarRule: currentGrammar?.grammarRule ?? '',
                grammarTopic: exercisesParamsSource.grammarTopic,
                vocabularyTopic: exercisesParamsSource.vocabularyTopic,
                commonMistakes: currentGrammar?.commonMistakes,
                text,
                sentencesAmount: entitesAmount,
                difficulty,
                lang,
                mediaType: ExerciseTypes.Text,
                vocabulary,
            });
            if (data) {
                onGenerate(data);
            }
        }
    );

    const handleGenerateExercise = (callback) => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                grammarTopic: !exerciseParams.grammarTopic,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            callback();
        });
    };

    const handleTextRangeInput = (e) => {
        setTextSentencesAmount(e.target.value);
    };

    const handleEntitiesRangeInput = (e) => {
        setEntitiesAmount(e.target.value);
    };

    const getGenreOptions = () => {
        const level =
            typeof exerciseParams.difficulty === 'number'
                ? LanguageLevels[exerciseParams.difficulty]
                : LanguageLevels[0];
        return GenresByLevel[level].map((genre) => ({
            value: genre,
            name: t(`exercises.${genre}`),
        }));
    };

    const handleInputChange = (key, value) => {
        setFormErrors({});
        setExerciseParams((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (
            exerciseType === ExerciseTypes.Grammar &&
            !exercisesParamsSource.grammarTopic
        )
            setShowMore(true);
    }, [exercisesParamsSource, exerciseType]);

    useEffect(() => {
        if (!exercisesParamsSource.difficulty) return;
        setExerciseParams({
            grammarTopic: exercisesParamsSource.grammarTopic,
            vocabularyTopic: exercisesParamsSource.vocabularyTopic,
            difficulty: exercisesParamsSource.difficulty,
            genre: TextGenres.Text,
            wordPack: wordPack,
        });
    }, [exercisesParamsSource, wordPack]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));

        setFilteredGrammar(currentGrammar);

        const currentLexical = lexicalOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));
        setFilteredLexical(currentLexical);
    }, [grammarOptions, lexicalOptions, difficulty]);

    useEffect(() => {
        if (!extraStep) {
            setShowText(false);
        }
    }, [extraStep]);

    const rangeParams =
        exerciseKind === MediaExerciseTypes.Summary
            ? SummaryQuestionsRange
            : QuestionsRange;

    if (showText && typeof text === 'string')
        return (
            <>
                <textarea
                    className={cl.textEditor}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={t('lesson_wizard.own_text_placeholder')}
                />
                <Button
                    text={t('exercises.generate_exercise')}
                    variant={Chapters.Exercise}
                    onClick={() => handleGenerateExercise(generateTextExercise)}
                    isLoading={isTextExerciseLoading}
                />
            </>
        );

    return (
        <>
            {showMore ? (
                <div className={cl.moreParamsContainer}>
                    <div className={cl.verticalContainer}>
                        <div>
                            <Label text={t('lesson_wizard.lexical_topic')} />
                            <Tree
                                label={t('lesson_wizard.lexical_topic')}
                                className={cl.wideSelect}
                                variant="grey"
                                value={exerciseParams.vocabularyTopic}
                                onChange={(value) =>
                                    handleInputChange('vocabularyTopic', value)
                                }
                                options={filteredLexical}
                                hideReset
                                ignoreOverflow
                                height={350}
                            />
                        </div>
                        {exerciseType === ExerciseTypes.Grammar && (
                            <div>
                                <Label
                                    isError={formErrors['grammarTopic']}
                                    text={t('lesson_wizard.grammar_topic')}
                                />
                                <Tree
                                    label={t('lesson_wizard.grammar_topic')}
                                    className={cl.wideSelect}
                                    variant="grey"
                                    value={exerciseParams.grammarTopic}
                                    onChange={(value) =>
                                        handleInputChange('grammarTopic', value)
                                    }
                                    options={filteredGrammar}
                                    ignoreOverflow
                                    height={320}
                                />
                            </div>
                        )}
                        {wordPack.items?.length !== 0 && (
                            <div>
                                <Label
                                    text={t(
                                        'lesson_wizard.exercise_add_word_pack'
                                    )}
                                />
                                <div className={cl.horizontalContainer}>
                                    <p className={cl.currentWordPack}>
                                        {wordPack.title}
                                    </p>
                                    <ManageWordPackModal
                                        variant={Chapters.Exercise}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={cl.verticalContainer}>
                        <div>
                            <Label text={t('exercises.genre')} />
                            <Select
                                className={cl.wideSelect}
                                label={t('exercises.genre')}
                                variant={'grey'}
                                value={exerciseParams.genre}
                                options={getGenreOptions()}
                                onChange={(genre) =>
                                    setExerciseParams({
                                        ...exerciseParams,
                                        genre,
                                    })
                                }
                                ignoreOverflow
                            />
                        </div>
                        <div>
                            <Label text={t('exercises.text_sentence_count')} />
                            <FilledRange
                                color={'var(--blue)'}
                                value={textSentencesAmount}
                                min={TextRange.Min}
                                step={TextRange.Step}
                                max={TextRange.Max}
                                onChange={handleTextRangeInput}
                            />
                        </div>
                        {Object.values(MediaExerciseTypes).includes(
                            exerciseKind
                        ) && (
                            <div>
                                <Label
                                    text={t(
                                        exerciseKind ===
                                            MediaExerciseTypes.SentencesOrder
                                            ? 'exercises.sentence_count'
                                            : exerciseKind ===
                                                MediaExerciseTypes.Summary
                                              ? 'exercises.summary_count'
                                              : 'exercises.question_count'
                                    )}
                                />
                                <FilledRange
                                    color={'var(--blue)'}
                                    value={entitesAmount ?? rangeParams.Default}
                                    min={rangeParams.Min}
                                    step={rangeParams.Step}
                                    max={rangeParams.Max}
                                    onChange={handleEntitiesRangeInput}
                                />
                            </div>
                        )}
                        {[
                            GrammarExerciseTypes.Cloze,
                            GrammarExerciseTypes.Multichoice,
                            GrammarExerciseTypes.Substitution,
                        ].includes(exerciseKind) && (
                            <div>
                                <Label
                                    text={t('lesson_wizard.switch_gap_mode')}
                                />
                                <div className={cl.checkboxContainer}>
                                    <Checkbox
                                        color={Chapters.Exercise}
                                        value={gapByWords}
                                        onChange={() =>
                                            setGapByWords(!gapByWords)
                                        }
                                        label={t(
                                            'lesson_wizard.current_word_pack'
                                        )}
                                    />
                                    <Checkbox
                                        color={Chapters.Exercise}
                                        value={!gapByWords}
                                        onChange={() =>
                                            setGapByWords(!gapByWords)
                                        }
                                        label={t('lesson_wizard.grammar_topic')}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className={cl.flexContainer}>
                        <div>
                            <Label text={t('exercises.genre')} />
                            <Select
                                className={cl.wideSelect}
                                label={t('exercises.genre')}
                                variant={'grey'}
                                value={exerciseParams.genre}
                                options={getGenreOptions()}
                                onChange={(genre) =>
                                    setExerciseParams({
                                        ...exerciseParams,
                                        genre,
                                    })
                                }
                                ignoreOverflow
                            />
                        </div>
                        <div>
                            <Label text={t('exercises.text_sentence_count')} />
                            <FilledRange
                                color={'var(--blue)'}
                                value={textSentencesAmount}
                                min={TextRange.Min}
                                step={TextRange.Step}
                                max={TextRange.Max}
                                onChange={handleTextRangeInput}
                            />
                        </div>
                    </div>
                    <div>
                        <Label text={t('lesson_wizard.text_exercise_info')} />
                        <Label
                            text={t(`lesson_wizard.exercise_generate_subtitle`)}
                        />
                    </div>
                </>
            )}
            <Checkbox
                style={{ margin: 'auto 0 12px' }}
                color={Chapters.Exercise}
                variant={'checkmark'}
                value={showText}
                onChange={() => setShowText(!showText)}
                label={t('lesson_wizard.show_text')}
            />

            <div className={cl.flexContainer}>
                <div
                    className={cl.expandButton}
                    onClick={() => setShowMore(!showMore)}
                >
                    {t(
                        showMore
                            ? 'lesson_wizard.show_less'
                            : 'lesson_wizard.show_more'
                    )}
                    <IconArrow />
                </div>
                <Button
                    text={t('exercises.generate_exercise')}
                    variant={Chapters.Exercise}
                    onClick={() => handleGenerateExercise(generateExercise)}
                    isLoading={isExerciseLoading}
                />
            </div>
        </>
    );
});

const VideoSource = observer(() => {
    const { t } = useTranslation();

    const { exerciseKind } = wizardStore;

    const [visible, setVisible] = useState(false);
    const [isOwn, setIsOwn] = useState(false);

    const handleSourceSelect = (source) => {
        setVisible(true);
        if (source === VideoSourceTypes.Own) setIsOwn(true);
    };

    const handleVideoSelect = (video) => {
        wizardStore.setCurrentVideo(video);
        setVisible(false);
        wizardStore.nextStep();
    };

    return (
        <>
            <div className={cl.exerciseTypeContainer}>
                {Object.values(VideoSourceTypes).map((s) => (
                    <div
                        className={`${cl.basicCard} ${s === VideoSourceTypes.YouTube ? cl.disabled : ''}`}
                        key={s}
                        onClick={() =>
                            s !== VideoSourceTypes.YouTube &&
                            handleSourceSelect(s)
                        }
                    >
                        <VideoSourceIcon
                            source={s}
                            additionalClass={cl.sourceIcon}
                        />
                        <div className={cl.basicCardText}>
                            <p>{t(`lesson_wizard.${s}_video`)}</p>
                            <span>
                                {t(`lesson_wizard.${s}_video_description`)}
                            </span>
                        </div>
                        {s === VideoSourceTypes.YouTube && (
                            <Tooltip
                                className={cl.tooltip}
                                variant={'warning'}
                                text={t('lesson_wizard.unsupported_yt_video')}
                                tailPosition={'right'}
                            />
                        )}
                    </div>
                ))}
            </div>

            <SelectVideoModal
                visible={visible}
                setVisible={setVisible}
                onClick={handleVideoSelect}
                isOwn={isOwn}
            />
        </>
    );
});

const GenerateByVideo = observer(({ onGenerate }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const { trackText } = exercisesStore;
    const exercisesParamsSource =
        lessonsStore.currentLesson ?? wizardStore.exerciseParams;
    const { currentVideo, exerciseKind, wordPack, grammarOptions, extraStep } =
        wizardStore;

    const currentGrammar = grammarOptions
        .flatMap((o) => o.topics)
        .find((tObj) => tObj.topic === exercisesParamsSource.grammarTopic);

    const [videoData, setVideoData] = useState({});

    const [entitesAmount, setEntitiesAmount] = useState(
        exerciseKind === MediaExerciseTypes.Summary
            ? SummaryQuestionsRange.Default
            : QuestionsRange.Default
    );

    const [generateExercise, isLoading] = useFetching(async () => {
        const withInterval = ![
            MediaTypes.Text,
            MediaTypes.GeneratedText,
        ].includes(exerciseKind);

        const { items } = wordPack;
        const vocabulary = items ? items.map((wObj) => wObj.word) : [];

        const { data } = await ExerciseService.createExercise({
            type: exerciseKind,
            prompt:
                currentGrammar?.prompt ?? exercisesParamsSource.grammarTopic,
            sentencesAmount: entitesAmount,
            difficulty: exercisesParamsSource.difficulty,
            lang,
            mediaType: ExerciseTypes.Video,
            vocabulary,
            text: exercisesStore.trackText,
            trackId: currentVideo.id,
            grammarTopic: exercisesParamsSource.grammarTopic,
            vocabularyTopic: exercisesParamsSource.vocabularyTopic,
            trackInterval: withInterval
                ? exercisesPlayer.currentRange.map((r) => r / TicksInSecond)
                : null,
        });

        if (data) {
            onGenerate(data);
        }
    });

    const [getVideo, videoLoading] = useFetching(async () => {
        const { data } = await TrackService.getTrackSentences({
            id: currentVideo.id,
            lang,
        });
        const maxDuration = getMax(data.sentences);

        exercisesPlayer.setTrackData({ ...data, duration: maxDuration });
        setVideoData({ ...data, duration: maxDuration });
        exercisesStore.setTrackText(
            data?.sentences.map((s) => s.text).join(' ') ?? ''
        );
    });

    const handleRangeInput = (e) => {
        setEntitiesAmount(e.target.value);
    };

    const getMax = (sentences) => {
        let totalLength = 0;
        let max = exercisesPlayer.trackLength;

        for (const t of sentences) {
            const segmentLength = t.text.length;

            if (totalLength + segmentLength > MaximumMediaCharactersAmount) {
                break;
            }

            totalLength += segmentLength;
            max = t.offset;
        }
        return max / TicksInSecond;
    };

    const getCurrentCharactersAmount = () => {
        if (!trackText) return <>{'0'}</>;
        const amount = trackText.split('').length;
        const className =
            amount > MaximumMediaCharactersAmount ? cl.limit : cl.ok;
        return <span className={className}>{amount}</span>;
    };

    useEffect(() => {
        getVideo();
    }, [currentVideo]);

    useEffect(() => {
        const range = exercisesPlayer.currentRange;
        if (!range?.length) {
            exercisesStore.setTrackText(
                exercisesPlayer.trackSentences?.map((t) => t.text).join(' ')
            );
            return;
        }

        const segment = exercisesPlayer.trackSentences?.filter(
            (t) => t.offset >= range[0] && t.offset <= range[1]
        );
        exercisesStore.setTrackText(segment?.map((t) => t.text).join(' '));
    }, [exercisesPlayer.currentRange]);

    const isRange = () =>
        ![MediaExerciseTypes.Presentation].includes(exerciseKind);

    const rangeParams =
        exerciseKind === MediaExerciseTypes.Summary
            ? SummaryQuestionsRange
            : QuestionsRange;

    if (videoLoading)
        return <Loader className={cl.loader} style={{ margin: 'auto' }} />;

    return (
        <>
            <div
                className={`${cl.labeledContainer} ${extraStep ? cl.hidden : ''}`}
            >
                <div className={cl.videoPreviewContainer}>
                    <Label text={t('lesson_wizard.current_video_info')} />
                    <div className={cl.videoPreview}>
                        <div className={cl.imageContainer}>
                            <img
                                src={videoData.image?.md}
                                alt={videoData.title}
                            />
                        </div>
                        <div className={cl.cardInner}>
                            <p className={cl.cardTitle}>{videoData.title}</p>
                            <div className={cl.videoInfoContainer}>
                                {[
                                    LanguageLevels[videoData.difficulty],
                                    toHoursAndMinutes(videoData.duration),
                                    videoData.lang,
                                ].map((p, i) => (
                                    <p className={cl.cardLabel} key={i}>
                                        {p}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cl.flexContainerBottom}>
                    <div>
                        <Label text={t('lesson_wizard.edit_video_label')} />

                        <Button
                            className={cl.wideSelect}
                            variant={Chapters.Track}
                            text={t('lesson_wizard.edit_video')}
                            onClick={() => wizardStore.setExtraStep(true)}
                        />
                    </div>
                    {isRange() && (
                        <div>
                            <Label
                                text={t(
                                    exerciseKind ===
                                        MediaExerciseTypes.SentencesOrder
                                        ? 'exercises.sentence_count'
                                        : exerciseKind ===
                                            MediaExerciseTypes.Summary
                                          ? 'exercises.summary_count'
                                          : 'exercises.question_count'
                                )}
                            />
                            <FilledRange
                                color={'var(--blue)'}
                                value={entitesAmount ?? rangeParams.Default}
                                min={rangeParams.Min}
                                step={rangeParams.Step}
                                max={rangeParams.Max}
                                onChange={handleRangeInput}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className={`${cl.mediaEditor} ${extraStep ? '' : cl.hidden}`}
                key={currentVideo}
            >
                <div className={cl.mediaEditContainer}>
                    <ExercisePlayer isPreview={false} />
                    <div className={cl.textContainer}>
                        <p>{trackText}</p>
                    </div>
                </div>
                <div className={cl.controlsContainer}>
                    <ExercisePlayerControls mode={'video'} isPreview={false} />
                </div>
            </div>
            <Button
                text={t('exercises.generate_exercise')}
                variant={Chapters.Exercise}
                onClick={generateExercise}
                isLoading={isLoading}
            />
        </>
    );
});

const SelectBasicParams = () => {
    const { t } = useTranslation();
    const { grammarOptions, lexicalOptions } = wizardStore;

    const [formData, setFormData] = useState(DefaultExerciseFormData);
    const [formErrors, setFormErrors] = useState({});

    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredLexical, setFilteredLexical] = useState(lexicalOptions);

    const { difficulty, grammarTopic, vocabularyTopic } = formData;

    const handleNextStep = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                difficulty: typeof formData.difficulty !== 'number',
                vocabularyTopic: !formData.vocabularyTopic,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            const exerciseParams = {
                grammarTopic,
                vocabularyTopic,
                difficulty,
            };

            wizardStore.setExerciseParams(exerciseParams);
            wizardStore.nextStep();
        });
    };

    const handleInputChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setFormErrors({});
    }, [formData]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));

        setFilteredGrammar(currentGrammar);

        const currentLexical = lexicalOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));
        setFilteredLexical(currentLexical);
    }, [grammarOptions, lexicalOptions, difficulty]);

    return (
        <>
            <Label text={t('exercises.select_exercise_params')} />
            <div>
                <Label
                    text={t('users.level')}
                    isError={formErrors['difficulty']}
                />
                <Select
                    className={cl.select}
                    variant={formErrors['difficulty'] ? 'errorSmall' : 'grey'}
                    value={formData.difficulty}
                    options={LevelOptions}
                    onChange={(value) => handleInputChange('difficulty', value)}
                    ignoreOverflow
                    hideReset
                />
            </div>
            <div>
                <Label
                    text={t('exercises.select_lexical_topic')}
                    isError={formErrors['vocabularyTopic']}
                />
                <Tree
                    label={t('lesson_wizard.lexical_topic')}
                    className={cl.select}
                    variant={
                        formErrors['vocabularyTopic'] ? 'errorSmall' : 'grey'
                    }
                    value={formData.vocabularyTopic}
                    onChange={(value) =>
                        handleInputChange('vocabularyTopic', value)
                    }
                    options={filteredLexical}
                    hideReset
                    ignoreOverflow
                    height={240}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <div>
                <Label text={t('exercises.select_grammar_topic')} />
                <Tree
                    label={t('lesson_wizard.grammar_topic')}
                    className={cl.select}
                    variant="grey"
                    value={formData.grammarTopic}
                    onChange={(value) =>
                        handleInputChange('grammarTopic', value)
                    }
                    options={filteredGrammar}
                    ignoreOverflow
                    height={180}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <Button
                style={{ marginTop: 'auto' }}
                text={t('buttons.next')}
                variant={Chapters.Exercise}
                onClick={handleNextStep}
            />
        </>
    );
};

const ExerciseWizard = ({ onAdd }) => {
    const [view, step] = wizardStore.getCurrentViewAndStep();
    const { exerciseType } = wizardStore;
    const { currentLesson } = lessonsStore;

    const handleExerciseAdd = (data) => {
        if (currentLesson) {
            const { id, title } = data;
            onAdd({ chapterType: Chapters.Exercise, chapterId: id, title });
            wizardStore.showChaptersList();
        } else {
            onAdd(data);
        }

        if (currentLesson) wizardStore.showChaptersList();
    };

    if (!view || view !== WizardViews.Exercise) return null;

    switch (step) {
        case 'select':
            return currentLesson ? (
                <SelectExercise onSelect={handleExerciseAdd} />
            ) : (
                <SelectBasicParams />
            );
        case 'type':
            return <ExerciseType />;
        case 'kind':
            return <ExerciseKind />;
        case 'source':
            return exerciseType === ExerciseTypes.Video ? (
                <VideoSource />
            ) : (
                <TextSource />
            );
        case 'generate':
            return exerciseType === ExerciseTypes.Video ? (
                <GenerateByVideo onGenerate={handleExerciseAdd} />
            ) : (
                <GenerateByText onGenerate={handleExerciseAdd} />
            );

        default:
            return null;
    }
};

export default observer(ExerciseWizard);
