export const situations = {
    ru: {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'User Generated',
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
        statements: [
            {
                id: 'dab5b7a7-0053-4650-8cac-0f1fc78bb23d',
                phrase: 'Excuse me, could you help me with some information about the airport procedures?',
                phraseVoice:
                    '/s3/situation-synthesis/8500d8ce-1f4d-482e-83cc-b99c3e070f60.mp3',
                offset: 2,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Excuse me, could you help me with some information about the airport procedures?',
                        sentenceVoice:
                            '/s3/situation-synthesis/906d046f-32fe-442b-a59c-e3712c8fb57d.mp3',
                    },
                ],
                translate:
                    'Извините, не могли бы вы помочь мне с информацией о процедурах в аэропорту?',
            },
            {
                id: 'a26c2632-26ef-425b-aac1-69413fb94df0',
                phrase: "Of course! I'd be happy to help. What do you need to know?",
                phraseVoice:
                    '/s3/situation-synthesis/659a4380-6dd0-47b1-8338-fc6790ec7804.mp3',
                offset: 3,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate: 'Конечно! Я буду рада помочь. Что вам нужно знать?',
            },
            {
                id: '558c8341-10c7-46ce-9a36-6be8fbdc80f3',
                phrase: "Well, I might need some guidance on the check-in process. I haven't flown in a while.",
                phraseVoice:
                    '/s3/situation-synthesis/14816793-f43c-44dd-aa55-c10eec0a545c.mp3',
                offset: 5,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Well, I might need some guidance on the check-in process.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2b8c62a5-d216-4b6c-a924-485fcf0d59f8.mp3',
                    },
                    {
                        text: "I haven't flown in a while.",
                        sentenceVoice:
                            '/s3/situation-synthesis/10a9e42d-9079-4e19-bf53-7eeb28d1194e.mp3',
                    },
                ],
                translate:
                    'Ну, мне, возможно, понадобятся некоторые указания по поводу процесса регистрации. Я давно не летал.',
            },
            {
                id: 'b9f10d31-c914-4389-9932-3efbbddfa396',
                phrase: 'No problem. You can check in online before you arrive at the airport, which could save you some time.',
                phraseVoice:
                    '/s3/situation-synthesis/b561f161-2bc3-4722-a6ff-5216a25ec35d.mp3',
                offset: 6,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Не беда. Вы можете зарегистрироваться на рейс онлайн до прибытия в аэропорт, что поможет вам сэкономить время.',
            },
            {
                id: '87eea3fe-7b73-41d6-8fa9-ba3f77e279e0',
                phrase: "That's good to know. But if I can't check in online, what should I do?",
                phraseVoice:
                    '/s3/situation-synthesis/a20fe6fd-c1ac-4e3a-a0ad-2a499d87a815.mp3',
                offset: 8,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's good to know.",
                        sentenceVoice:
                            '/s3/situation-synthesis/46b707b4-70b3-47e1-9905-ea5e06b871a8.mp3',
                    },
                    {
                        text: "But if I can't check in online, what should I do?",
                        sentenceVoice:
                            '/s3/situation-synthesis/d767c41d-bce0-40be-abc4-be1accae9ebb.mp3',
                    },
                ],
                translate:
                    'Приятно слышать. Но если я не могу зарегистрироваться онлайн, что мне делать?',
            },
            {
                id: '6482a404-f4a5-4d37-a5db-f183f24f0de2',
                phrase: "You must go to the airline's check-in counter. They can assist you with your boarding pass and any luggage you might have.",
                phraseVoice:
                    '/s3/situation-synthesis/b26a5def-00f3-4572-aa2e-4d0375b8fb8d.mp3',
                offset: 9,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вы должны подойти к стойке регистрации авиакомпании. Там вам помогут с посадочным талоном и любым багажом.',
            },
            {
                id: 'd65fbb2c-2737-4cc3-b08a-d46d96e63c3c',
                phrase: 'Speaking of luggage, could you tell me about the baggage policies?',
                phraseVoice:
                    '/s3/situation-synthesis/cdd4a19e-9edd-417f-9781-5bae69478abd.mp3',
                offset: 11,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Speaking of luggage, could you tell me about the baggage policies?',
                        sentenceVoice:
                            '/s3/situation-synthesis/fa7fde62-83af-46ea-aaad-4e1d248f8413.mp3',
                    },
                ],
                translate:
                    'Кстати, о багаже, не могли бы вы рассказать мне о правилах провоза багажа?',
            },
            {
                id: 'df6ae3a7-a92c-4ca9-b50e-6761b97a6311',
                phrase: "Sure! You should check your airline's specific baggage policy, as it can vary. Generally, you might be allowed one carry-on and one personal item.",
                phraseVoice:
                    '/s3/situation-synthesis/2ec664aa-1aeb-491a-aefe-ad25b598ecca.mp3',
                offset: 12,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Конечно! Вам следует ознакомиться с правилами провоза багажа вашей авиакомпании, так как они могут отличаться. Как правило, вам может быть разрешена одна ручная кладь и одна личная вещь.',
            },
            {
                id: 'b7cfcc4a-1114-41f0-b4c6-8e06fb98a587',
                phrase: 'And if I have more luggage, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/694ec3cc-0726-4a8f-be0a-96d90217879e.mp3',
                offset: 14,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I have more luggage, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/82f419d2-bbe1-4aca-95ad-c0ecd81ca430.mp3',
                    },
                ],
                translate:
                    'А если у меня больше багажа, какие у меня есть варианты?',
            },
            {
                id: '17c8728f-0bc8-4845-aab6-2c09a02d4e4f',
                phrase: "You could check additional bags, but there might be extra fees. It's best to weigh your bags at home to avoid surprises.",
                phraseVoice:
                    '/s3/situation-synthesis/14e1868c-0dc3-4b77-b6b3-725d709564d2.mp3',
                offset: 15,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вы можете зарегистрировать дополнительные сумки, но за это придется доплатить. Лучше всего взвесить чемоданы дома, чтобы избежать неожиданностей.',
            },
            {
                id: 'fddae7f1-bfae-493d-8ed8-119f0250af06',
                phrase: "I see. Once I'm checked in, what should I do next?",
                phraseVoice:
                    '/s3/situation-synthesis/0b5c8759-f0a7-43c3-ad16-5b19d5754d9e.mp3',
                offset: 17,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I see.',
                        sentenceVoice:
                            '/s3/situation-synthesis/f8d7576d-1187-4e03-9142-727f11af5d51.mp3',
                    },
                    {
                        text: "Once I'm checked in, what should I do next?",
                        sentenceVoice:
                            '/s3/situation-synthesis/41969f55-1b5b-4d2a-8800-584c0fd01253.mp3',
                    },
                ],
                translate:
                    'Понятно. Когда меня зарегистрируют, что мне делать дальше?',
            },
            {
                id: '017feee8-4d99-4f27-9533-684ba4a5fb17',
                phrase: 'After check-in, you must go through security. Make sure you have your boarding pass and ID ready.',
                phraseVoice:
                    '/s3/situation-synthesis/d137d9f7-2e4d-438b-ae74-210a8018224b.mp3',
                offset: 18,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'После регистрации вы должны пройти через службу безопасности. Убедитесь, что у вас наготове посадочный талон и удостоверение личности.',
            },
            {
                id: 'f40d7aa7-4623-4b82-a61b-702d2a887356',
                phrase: 'Could you explain what I should expect at security?',
                phraseVoice:
                    '/s3/situation-synthesis/1a9976f8-0e5e-412f-8ed1-c47a39a5e1ac.mp3',
                offset: 20,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Could you explain what I should expect at security?',
                        sentenceVoice:
                            '/s3/situation-synthesis/7332dd08-5008-4376-8b08-a53d95c95afb.mp3',
                    },
                ],
                translate:
                    'Не могли бы вы объяснить, чего мне следует ожидать от службы безопасности?',
            },
            {
                id: '577195c5-dc69-4681-b102-c7ee1bfab729',
                phrase: 'Certainly. You might need to remove your shoes, belt, and any metal items. Laptops and liquids must be placed in separate bins.',
                phraseVoice:
                    '/s3/situation-synthesis/1547e8d2-4b74-4593-bb96-d4fbc8fe420f.mp3',
                offset: 21,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Конечно. Возможно, вам придется снять обувь, ремень и любые металлические предметы. Ноутбуки и жидкости должны быть помещены в отдельные контейнеры.',
            },
            {
                id: 'd17e863b-3e9a-4fcf-9ee9-1bfdf3b1cddf',
                phrase: 'That sounds straightforward. Is there anything else I should be aware of?',
                phraseVoice:
                    '/s3/situation-synthesis/a5b57de1-4d93-44f8-86c3-bba4d08c75c4.mp3',
                offset: 23,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'That sounds straightforward.',
                        sentenceVoice:
                            '/s3/situation-synthesis/fe1a5563-25aa-45aa-ae12-ec91052d7a5e.mp3',
                    },
                    {
                        text: 'Is there anything else I should be aware of?',
                        sentenceVoice:
                            '/s3/situation-synthesis/0d606640-a4eb-453b-b59a-4d4ea13493c8.mp3',
                    },
                ],
                translate:
                    'Звучит просто. Есть ли что-то еще, о чем мне следует знать?',
            },
            {
                id: 'f0b750eb-7a19-41cc-b46a-a159061aa269',
                phrase: 'You might want to arrive at the airport at least two hours before your flight for domestic travel, and three hours for international flights.',
                phraseVoice:
                    '/s3/situation-synthesis/8afd9a72-cd04-45c3-b505-8bd93cac1b52.mp3',
                offset: 24,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вам лучше приехать в аэропорт как минимум за два часа до вылета для внутренних рейсов и за три часа для международных.',
            },
            {
                id: '14f90ad6-ac2c-477f-9253-9b95a7fdaa22',
                phrase: "That's helpful. Once I'm through security, what should I do while waiting for my flight?",
                phraseVoice:
                    '/s3/situation-synthesis/42191014-4869-485f-8dfb-08d63c9bd999.mp3',
                offset: 26,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's helpful.",
                        sentenceVoice:
                            '/s3/situation-synthesis/0f27e95c-e386-40bb-9547-fbde8eaea0e3.mp3',
                    },
                    {
                        text: "Once I'm through security, what should I do while waiting for my flight?",
                        sentenceVoice:
                            '/s3/situation-synthesis/6d23d2a0-9963-4049-b4f8-e7d88b4fec44.mp3',
                    },
                ],
                translate:
                    'Это полезно. Что делать в ожидании рейса, когда я пройду контроль?',
            },
            {
                id: 'dc1e3bc2-258c-4683-9485-a78a81b05b29',
                phrase: 'You could explore the airport shops or grab a bite to eat. Just keep an eye on the departure screens for any updates on your flight.',
                phraseVoice:
                    '/s3/situation-synthesis/5cef58f0-9ebd-4f14-9fe7-c76647d34df3.mp3',
                offset: 27,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вы можете заглянуть в магазины аэропорта или перекусить. Следите за табло вылета, чтобы не пропустить информацию о вашем рейсе.',
            },
            {
                id: '65465e60-02a8-40d2-8293-53042edd3d0c',
                phrase: 'If my flight is delayed, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/53c8f359-cadc-43b5-a43e-4940550a28c5.mp3',
                offset: 29,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'If my flight is delayed, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/8fc4dc38-1048-4fa0-830e-634cda3feba1.mp3',
                    },
                ],
                translate:
                    'Если мой рейс задерживается, какие у меня есть варианты?',
            },
            {
                id: '96925634-2765-4a61-9eb6-a594d8041818',
                phrase: 'You should check with your airline. They might offer rebooking options or vouchers, depending on the situation.',
                phraseVoice:
                    '/s3/situation-synthesis/1ab490b7-277d-432e-85f6-7eb0e598b562.mp3',
                offset: 30,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вам следует обратиться в свою авиакомпанию. Они могут предложить варианты перебронирования или ваучеры, в зависимости от ситуации.',
            },
            {
                id: 'c5049575-e75e-4914-bf15-a4ffa15cec57',
                phrase: 'And if I miss my flight, what should I do?',
                phraseVoice:
                    '/s3/situation-synthesis/94396909-0ce4-4089-b6a7-cd0b56cb0daa.mp3',
                offset: 32,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I miss my flight, what should I do?',
                        sentenceVoice:
                            '/s3/situation-synthesis/d4809b1a-400e-4d62-9022-c5192e593df9.mp3',
                    },
                ],
                translate: 'А если я опоздаю на самолет, что мне делать?',
            },
            {
                id: '409fd2ff-53fb-4327-b8a3-7fef7e0373bb',
                phrase: 'You must contact your airline immediately. They could help you find the next available flight.',
                phraseVoice:
                    '/s3/situation-synthesis/c77866b4-f915-4f29-bc8c-856d7d509338.mp3',
                offset: 33,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вы должны немедленно связаться со своей авиакомпанией. Они могут помочь вам найти следующий свободный рейс.',
            },
            {
                id: '0220dc13-2dca-4803-b927-bb6e47636a9f',
                phrase: 'Thank you for all this information. Could you also tell me about customs procedures for international flights?',
                phraseVoice:
                    '/s3/situation-synthesis/a0bf0682-bf12-4813-912c-e117ab0f76d0.mp3',
                offset: 35,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you for all this information.',
                        sentenceVoice:
                            '/s3/situation-synthesis/25dfbeb1-f870-4a2b-8278-f462e2bb2974.mp3',
                    },
                    {
                        text: 'Could you also tell me about customs procedures for international flights?',
                        sentenceVoice:
                            '/s3/situation-synthesis/57bab119-aa02-4c1f-98c4-95131e1c71d6.mp3',
                    },
                ],
                translate:
                    'Спасибо за всю эту информацию. Не могли бы вы также рассказать мне о таможенных процедурах при международных перелетах?',
            },
            {
                id: 'e9e7c7a5-b572-462f-8a73-69ea91d7f98f',
                phrase: "Of course. When you arrive at your destination, you must go through customs. You might need to declare any goods you're bringing into the country.",
                phraseVoice:
                    '/s3/situation-synthesis/fd71aeab-ea73-473c-b8a3-da3389d654f3.mp3',
                offset: 36,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Конечно. Когда вы прибудете в пункт назначения, вы должны пройти таможенный контроль. Возможно, вам придется задекларировать все товары, которые вы ввозите в страну.',
            },
            {
                id: 'ba09a2f0-ddb6-488e-a627-30eb8eddf7b8',
                phrase: 'What kind of items might I need to declare?',
                phraseVoice:
                    '/s3/situation-synthesis/adee4c38-bac6-48b4-b20c-07ca2a620340.mp3',
                offset: 38,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'What kind of items might I need to declare?',
                        sentenceVoice:
                            '/s3/situation-synthesis/6f1960f4-c524-4fa0-ac00-c58036678057.mp3',
                    },
                ],
                translate: 'Какие предметы мне нужно задекларировать?',
            },
            {
                id: 'fbe3a3aa-3848-4509-8d2f-aa32794fd631',
                phrase: "Typically, you must declare items like food, plants, or large amounts of currency. It's best to check the specific regulations of the country you're visiting.",
                phraseVoice:
                    '/s3/situation-synthesis/d4c2133d-3a48-42a0-bc2f-2ebab66c76e4.mp3',
                offset: 39,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Как правило, вы должны задекларировать такие предметы, как продукты питания, растения или крупные суммы валюты. Лучше всего ознакомиться с конкретными правилами страны, которую вы посещаете.',
            },
            {
                id: '4375c6e8-ee45-4f09-bd92-222d09d7f83b',
                phrase: 'I understand. Is there anything else I should prepare for an international trip?',
                phraseVoice:
                    '/s3/situation-synthesis/d2441897-a0a3-4569-bd18-6bc826137271.mp3',
                offset: 41,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I understand.',
                        sentenceVoice:
                            '/s3/situation-synthesis/c7ad48bd-12fa-43c6-b969-53d57ec73669.mp3',
                    },
                    {
                        text: 'Is there anything else I should prepare for an international trip?',
                        sentenceVoice:
                            '/s3/situation-synthesis/51718984-df5b-4b6d-bb6f-ace9f1f6a87b.mp3',
                    },
                ],
                translate:
                    'Я понимаю. Нужно ли еще что-то подготовить для международной поездки?',
            },
            {
                id: '4d097be5-6b8b-4d71-90e6-2bb1f7d954b3',
                phrase: 'You should ensure your passport is valid for at least six months beyond your travel dates. Some countries might also require a visa.',
                phraseVoice:
                    '/s3/situation-synthesis/1627908b-c835-49a5-aa9c-1f45c75852a8.mp3',
                offset: 42,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Убедитесь, что срок действия вашего паспорта составляет не менее шести месяцев после даты поездки. В некоторые страны также может потребоваться виза.',
            },
            {
                id: 'da550395-30a3-461a-9f91-4b566165ef6b',
                phrase: "That's a good point. How can I find out if I need a visa?",
                phraseVoice:
                    '/s3/situation-synthesis/a6c1ef9e-e1e6-44b7-8edf-60cc74d74a57.mp3',
                offset: 44,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's a good point.",
                        sentenceVoice:
                            '/s3/situation-synthesis/53d0f7a8-768c-4e66-9a32-123d5438db30.mp3',
                    },
                    {
                        text: 'How can I find out if I need a visa?',
                        sentenceVoice:
                            '/s3/situation-synthesis/14941153-b56e-400d-b795-0e99e131b9d3.mp3',
                    },
                ],
                translate:
                    'Это хорошая мысль. Как я могу узнать, нужна ли мне виза?',
            },
            {
                id: '6171856e-000a-48dd-90be-a4eacc54725e',
                phrase: "You could check the embassy website of the country you're visiting. They usually have detailed information on visa requirements.",
                phraseVoice:
                    '/s3/situation-synthesis/c457c884-9037-4dc8-aab1-e3708cdd6008.mp3',
                offset: 45,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Вы можете заглянуть на сайт посольства страны, которую вы собираетесь посетить. Там обычно есть подробная информация о визовых требованиях.',
            },
            {
                id: '63e60430-b154-4cae-bc05-4f1c2a7d2b85',
                phrase: 'Thank you so much for your help. I feel much more prepared now.',
                phraseVoice:
                    '/s3/situation-synthesis/0d8f1298-7156-4705-aaac-4472f7344727.mp3',
                offset: 47,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you so much for your help.',
                        sentenceVoice:
                            '/s3/situation-synthesis/b708b800-3911-40e7-8b0e-e011310221f1.mp3',
                    },
                    {
                        text: 'I feel much more prepared now.',
                        sentenceVoice:
                            '/s3/situation-synthesis/e1926edf-35ca-44a6-8522-f6cee01ce888.mp3',
                    },
                ],
                translate:
                    'Большое спасибо за помощь. Теперь я чувствую себя гораздо более подготовленной.',
            },
            {
                id: 'cfc99c7c-a282-479e-b9ca-f29af59dd58d',
                phrase: "You're welcome! Traveling can be a bit overwhelming, but with a little preparation, it can also be a lot of fun.",
                phraseVoice:
                    '/s3/situation-synthesis/5cd82eec-04fc-476e-80bd-668fd772802e.mp3',
                offset: 48,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Пожалуйста! Путешествие может быть немного ошеломляющим, но если немного подготовиться, оно может быть очень веселым.',
            },
            {
                id: '07b1fada-cbfa-4d39-8750-d76939877c7f',
                phrase: 'I agree. One last question',
                phraseVoice:
                    '/s3/situation-synthesis/3f7dab06-3da3-41f6-90a3-5dcce292b738.mp3',
                offset: 50,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I agree.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2daab0de-33ee-43ec-8359-1b068ee4dc82.mp3',
                    },
                    {
                        text: 'One last question',
                        sentenceVoice:
                            '/s3/situation-synthesis/6618564a-26af-47d6-855c-f761e02b59e5.mp3',
                    },
                ],
                translate: 'Я согласен. Последний вопрос',
            },
            {
                id: '2985b5fc-f625-4ebd-a66d-1a416e6c7fec',
                phrase: 'Absolutely! You might find apps like TripIt for organizing your itinerary, or Google Translate for language assistance, very helpful.',
                phraseVoice:
                    '/s3/situation-synthesis/f500d380-34ac-4f93-94e5-f4443949ca59.mp3',
                offset: 51,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Обязательно! Вам могут пригодиться такие приложения, как TripIt для организации маршрута или Google Translate для языковой поддержки.',
            },
            {
                id: '0f7384a3-2935-47f7-ad0c-cea5c8edc1c7',
                phrase: "Those sound great. I'll definitely check them out.",
                phraseVoice:
                    '/s3/situation-synthesis/a1be059d-cfa7-4e3c-8e57-669535e053df.mp3',
                offset: 53,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Those sound great.',
                        sentenceVoice:
                            '/s3/situation-synthesis/943bfbec-72d5-48b3-9e81-955de9c5482e.mp3',
                    },
                    {
                        text: "I'll definitely check them out.",
                        sentenceVoice:
                            '/s3/situation-synthesis/91c97804-6704-4cbb-aaaf-2df78663d41b.mp3',
                    },
                ],
                translate: 'Звучит здорово. Я обязательно проверю их.',
            },
            {
                id: 'e48130d8-f71d-4351-b7cf-afbb43d55e9c',
                phrase: "I'm glad I could help. Have a safe and enjoyable trip!",
                phraseVoice:
                    '/s3/situation-synthesis/74c82984-79e2-49e8-9eab-ca2a7542883c.mp3',
                offset: 54,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Я рад, что смог помочь. Счастливого и приятного пути!',
            },
            {
                id: '41274da6-7b74-49fd-b03f-c2194f491bf6',
                phrase: 'Thank you! I appreciate all your advice.',
                phraseVoice:
                    '/s3/situation-synthesis/be94bf6e-84f0-41e1-951e-fdd76df2089b.mp3',
                offset: 56,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you!',
                        sentenceVoice:
                            '/s3/situation-synthesis/1734d2a5-a3c2-4390-ae2a-55feb16c6e9d.mp3',
                    },
                    {
                        text: 'I appreciate all your advice.',
                        sentenceVoice:
                            '/s3/situation-synthesis/ba3924d4-1123-43af-967a-d2c469706592.mp3',
                    },
                ],
                translate: 'Спасибо! Я ценю все ваши советы.',
            },
            {
                id: 'cd13cf54-02f6-45d6-b6da-4742bc3e083e',
                phrase: 'Anytime! If you have any more questions, feel free to ask. Safe travels!',
                phraseVoice:
                    '/s3/situation-synthesis/bb470f1a-73b9-478f-bb7b-d39fe82df1cb.mp3',
                offset: 57,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'В любое время! Если у вас есть еще вопросы, не стесняйтесь их задавать. Безопасных путешествий!',
            },
        ],
    },
    de: {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'User Generated',
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
        statements: [
            {
                id: 'dab5b7a7-0053-4650-8cac-0f1fc78bb23d',
                phrase: 'Excuse me, could you help me with some information about the airport procedures?',
                phraseVoice:
                    '/s3/situation-synthesis/8500d8ce-1f4d-482e-83cc-b99c3e070f60.mp3',
                offset: 2,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Excuse me, could you help me with some information about the airport procedures?',
                        sentenceVoice:
                            '/s3/situation-synthesis/906d046f-32fe-442b-a59c-e3712c8fb57d.mp3',
                    },
                ],
                translate:
                    'Entschuldigen Sie, könnten Sie mir mit einigen Informationen über die Abläufe am Flughafen helfen?',
            },
            {
                id: 'a26c2632-26ef-425b-aac1-69413fb94df0',
                phrase: "Of course! I'd be happy to help. What do you need to know?",
                phraseVoice:
                    '/s3/situation-synthesis/659a4380-6dd0-47b1-8338-fc6790ec7804.mp3',
                offset: 3,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Ja, natürlich! Ich helfe Ihnen gerne. Was müssen Sie wissen?',
            },
            {
                id: '558c8341-10c7-46ce-9a36-6be8fbdc80f3',
                phrase: "Well, I might need some guidance on the check-in process. I haven't flown in a while.",
                phraseVoice:
                    '/s3/situation-synthesis/14816793-f43c-44dd-aa55-c10eec0a545c.mp3',
                offset: 5,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Well, I might need some guidance on the check-in process.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2b8c62a5-d216-4b6c-a924-485fcf0d59f8.mp3',
                    },
                    {
                        text: "I haven't flown in a while.",
                        sentenceVoice:
                            '/s3/situation-synthesis/10a9e42d-9079-4e19-bf53-7eeb28d1194e.mp3',
                    },
                ],
                translate:
                    'Nun, ich brauche vielleicht eine Anleitung für den Check-in-Prozess. Ich bin schon eine Weile nicht mehr geflogen.',
            },
            {
                id: 'b9f10d31-c914-4389-9932-3efbbddfa396',
                phrase: 'No problem. You can check in online before you arrive at the airport, which could save you some time.',
                phraseVoice:
                    '/s3/situation-synthesis/b561f161-2bc3-4722-a6ff-5216a25ec35d.mp3',
                offset: 6,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Das ist kein Problem. Sie können online einchecken, bevor Sie am Flughafen ankommen, was Ihnen einige Zeit ersparen könnte.',
            },
            {
                id: '87eea3fe-7b73-41d6-8fa9-ba3f77e279e0',
                phrase: "That's good to know. But if I can't check in online, what should I do?",
                phraseVoice:
                    '/s3/situation-synthesis/a20fe6fd-c1ac-4e3a-a0ad-2a499d87a815.mp3',
                offset: 8,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's good to know.",
                        sentenceVoice:
                            '/s3/situation-synthesis/46b707b4-70b3-47e1-9905-ea5e06b871a8.mp3',
                    },
                    {
                        text: "But if I can't check in online, what should I do?",
                        sentenceVoice:
                            '/s3/situation-synthesis/d767c41d-bce0-40be-abc4-be1accae9ebb.mp3',
                    },
                ],
                translate:
                    'Das ist gut zu wissen. Aber was soll ich tun, wenn ich nicht online einchecken kann?',
            },
            {
                id: '6482a404-f4a5-4d37-a5db-f183f24f0de2',
                phrase: "You must go to the airline's check-in counter. They can assist you with your boarding pass and any luggage you might have.",
                phraseVoice:
                    '/s3/situation-synthesis/b26a5def-00f3-4572-aa2e-4d0375b8fb8d.mp3',
                offset: 9,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sie müssen sich zum Check-in-Schalter der Fluggesellschaft begeben. Dort kann man Ihnen mit Ihrer Bordkarte und Ihrem Gepäck helfen.',
            },
            {
                id: 'd65fbb2c-2737-4cc3-b08a-d46d96e63c3c',
                phrase: 'Speaking of luggage, could you tell me about the baggage policies?',
                phraseVoice:
                    '/s3/situation-synthesis/cdd4a19e-9edd-417f-9781-5bae69478abd.mp3',
                offset: 11,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Speaking of luggage, could you tell me about the baggage policies?',
                        sentenceVoice:
                            '/s3/situation-synthesis/fa7fde62-83af-46ea-aaad-4e1d248f8413.mp3',
                    },
                ],
                translate:
                    'Apropos Gepäck, können Sie mir etwas über die Gepäckbestimmungen sagen?',
            },
            {
                id: 'df6ae3a7-a92c-4ca9-b50e-6761b97a6311',
                phrase: "Sure! You should check your airline's specific baggage policy, as it can vary. Generally, you might be allowed one carry-on and one personal item.",
                phraseVoice:
                    '/s3/situation-synthesis/2ec664aa-1aeb-491a-aefe-ad25b598ecca.mp3',
                offset: 12,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sicher! Sie sollten sich über die spezifischen Gepäckrichtlinien Ihrer Fluggesellschaft informieren, da diese variieren können. Im Allgemeinen dürfen Sie ein Handgepäckstück und einen persönlichen Gegenstand mitnehmen.',
            },
            {
                id: 'b7cfcc4a-1114-41f0-b4c6-8e06fb98a587',
                phrase: 'And if I have more luggage, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/694ec3cc-0726-4a8f-be0a-96d90217879e.mp3',
                offset: 14,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I have more luggage, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/82f419d2-bbe1-4aca-95ad-c0ecd81ca430.mp3',
                    },
                ],
                translate:
                    'Und wenn ich mehr Gepäck habe, welche Möglichkeiten habe ich dann?',
            },
            {
                id: '17c8728f-0bc8-4845-aab6-2c09a02d4e4f',
                phrase: "You could check additional bags, but there might be extra fees. It's best to weigh your bags at home to avoid surprises.",
                phraseVoice:
                    '/s3/situation-synthesis/14e1868c-0dc3-4b77-b6b3-725d709564d2.mp3',
                offset: 15,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sie können zusätzliches Gepäck aufgeben, aber es könnten zusätzliche Gebühren anfallen. Am besten wiegen Sie Ihr Gepäck zu Hause, um Überraschungen zu vermeiden.',
            },
            {
                id: 'fddae7f1-bfae-493d-8ed8-119f0250af06',
                phrase: "I see. Once I'm checked in, what should I do next?",
                phraseVoice:
                    '/s3/situation-synthesis/0b5c8759-f0a7-43c3-ad16-5b19d5754d9e.mp3',
                offset: 17,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I see.',
                        sentenceVoice:
                            '/s3/situation-synthesis/f8d7576d-1187-4e03-9142-727f11af5d51.mp3',
                    },
                    {
                        text: "Once I'm checked in, what should I do next?",
                        sentenceVoice:
                            '/s3/situation-synthesis/41969f55-1b5b-4d2a-8800-584c0fd01253.mp3',
                    },
                ],
                translate:
                    'Ich verstehe. Was soll ich als Nächstes tun, sobald ich eingecheckt bin?',
            },
            {
                id: '017feee8-4d99-4f27-9533-684ba4a5fb17',
                phrase: 'After check-in, you must go through security. Make sure you have your boarding pass and ID ready.',
                phraseVoice:
                    '/s3/situation-synthesis/d137d9f7-2e4d-438b-ae74-210a8018224b.mp3',
                offset: 18,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Nach dem Check-in müssen Sie die Sicherheitskontrolle passieren. Stellen Sie sicher, dass Sie Ihre Bordkarte und Ihren Ausweis bereithalten.',
            },
            {
                id: 'f40d7aa7-4623-4b82-a61b-702d2a887356',
                phrase: 'Could you explain what I should expect at security?',
                phraseVoice:
                    '/s3/situation-synthesis/1a9976f8-0e5e-412f-8ed1-c47a39a5e1ac.mp3',
                offset: 20,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Could you explain what I should expect at security?',
                        sentenceVoice:
                            '/s3/situation-synthesis/7332dd08-5008-4376-8b08-a53d95c95afb.mp3',
                    },
                ],
                translate:
                    'Können Sie mir erklären, was ich bei der Sicherheitskontrolle zu erwarten habe?',
            },
            {
                id: '577195c5-dc69-4681-b102-c7ee1bfab729',
                phrase: 'Certainly. You might need to remove your shoes, belt, and any metal items. Laptops and liquids must be placed in separate bins.',
                phraseVoice:
                    '/s3/situation-synthesis/1547e8d2-4b74-4593-bb96-d4fbc8fe420f.mp3',
                offset: 21,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Gewiss. Möglicherweise müssen Sie Ihre Schuhe, Ihren Gürtel und alle Metallgegenstände ausziehen. Laptops und Flüssigkeiten müssen in separaten Behältern aufbewahrt werden.',
            },
            {
                id: 'd17e863b-3e9a-4fcf-9ee9-1bfdf3b1cddf',
                phrase: 'That sounds straightforward. Is there anything else I should be aware of?',
                phraseVoice:
                    '/s3/situation-synthesis/a5b57de1-4d93-44f8-86c3-bba4d08c75c4.mp3',
                offset: 23,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'That sounds straightforward.',
                        sentenceVoice:
                            '/s3/situation-synthesis/fe1a5563-25aa-45aa-ae12-ec91052d7a5e.mp3',
                    },
                    {
                        text: 'Is there anything else I should be aware of?',
                        sentenceVoice:
                            '/s3/situation-synthesis/0d606640-a4eb-453b-b59a-4d4ea13493c8.mp3',
                    },
                ],
                translate:
                    'Das klingt ganz einfach. Gibt es sonst noch etwas, was ich beachten sollte?',
            },
            {
                id: 'f0b750eb-7a19-41cc-b46a-a159061aa269',
                phrase: 'You might want to arrive at the airport at least two hours before your flight for domestic travel, and three hours for international flights.',
                phraseVoice:
                    '/s3/situation-synthesis/8afd9a72-cd04-45c3-b505-8bd93cac1b52.mp3',
                offset: 24,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Bei Inlandsflügen sollten Sie mindestens zwei Stunden, bei internationalen Flügen drei Stunden vor Abflug am Flughafen sein.',
            },
            {
                id: '14f90ad6-ac2c-477f-9253-9b95a7fdaa22',
                phrase: "That's helpful. Once I'm through security, what should I do while waiting for my flight?",
                phraseVoice:
                    '/s3/situation-synthesis/42191014-4869-485f-8dfb-08d63c9bd999.mp3',
                offset: 26,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's helpful.",
                        sentenceVoice:
                            '/s3/situation-synthesis/0f27e95c-e386-40bb-9547-fbde8eaea0e3.mp3',
                    },
                    {
                        text: "Once I'm through security, what should I do while waiting for my flight?",
                        sentenceVoice:
                            '/s3/situation-synthesis/6d23d2a0-9963-4049-b4f8-e7d88b4fec44.mp3',
                    },
                ],
                translate:
                    'Das ist sehr hilfreich. Was soll ich tun, wenn ich die Sicherheitskontrolle passiert habe und auf meinen Flug warte?',
            },
            {
                id: 'dc1e3bc2-258c-4683-9485-a78a81b05b29',
                phrase: 'You could explore the airport shops or grab a bite to eat. Just keep an eye on the departure screens for any updates on your flight.',
                phraseVoice:
                    '/s3/situation-synthesis/5cef58f0-9ebd-4f14-9fe7-c76647d34df3.mp3',
                offset: 27,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sie können in den Geschäften des Flughafens stöbern oder einen Happen essen gehen. Behalten Sie einfach die Abflugbildschirme im Auge, um zu sehen, ob es Neuigkeiten zu Ihrem Flug gibt.',
            },
            {
                id: '65465e60-02a8-40d2-8293-53042edd3d0c',
                phrase: 'If my flight is delayed, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/53c8f359-cadc-43b5-a43e-4940550a28c5.mp3',
                offset: 29,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'If my flight is delayed, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/8fc4dc38-1048-4fa0-830e-634cda3feba1.mp3',
                    },
                ],
                translate:
                    'Welche Möglichkeiten habe ich, wenn mein Flug Verspätung hat?',
            },
            {
                id: '96925634-2765-4a61-9eb6-a594d8041818',
                phrase: 'You should check with your airline. They might offer rebooking options or vouchers, depending on the situation.',
                phraseVoice:
                    '/s3/situation-synthesis/1ab490b7-277d-432e-85f6-7eb0e598b562.mp3',
                offset: 30,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sie sollten sich bei Ihrer Fluggesellschaft erkundigen. Je nach Situation bieten sie möglicherweise Umbuchungsoptionen oder Gutscheine an.',
            },
            {
                id: 'c5049575-e75e-4914-bf15-a4ffa15cec57',
                phrase: 'And if I miss my flight, what should I do?',
                phraseVoice:
                    '/s3/situation-synthesis/94396909-0ce4-4089-b6a7-cd0b56cb0daa.mp3',
                offset: 32,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I miss my flight, what should I do?',
                        sentenceVoice:
                            '/s3/situation-synthesis/d4809b1a-400e-4d62-9022-c5192e593df9.mp3',
                    },
                ],
                translate:
                    'Und wenn ich meinen Flug verpasse, was soll ich dann tun?',
            },
            {
                id: '409fd2ff-53fb-4327-b8a3-7fef7e0373bb',
                phrase: 'You must contact your airline immediately. They could help you find the next available flight.',
                phraseVoice:
                    '/s3/situation-synthesis/c77866b4-f915-4f29-bc8c-856d7d509338.mp3',
                offset: 33,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sie müssen sich sofort mit Ihrer Fluggesellschaft in Verbindung setzen. Sie können Ihnen helfen, den nächsten verfügbaren Flug zu finden.',
            },
            {
                id: '0220dc13-2dca-4803-b927-bb6e47636a9f',
                phrase: 'Thank you for all this information. Could you also tell me about customs procedures for international flights?',
                phraseVoice:
                    '/s3/situation-synthesis/a0bf0682-bf12-4813-912c-e117ab0f76d0.mp3',
                offset: 35,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you for all this information.',
                        sentenceVoice:
                            '/s3/situation-synthesis/25dfbeb1-f870-4a2b-8278-f462e2bb2974.mp3',
                    },
                    {
                        text: 'Could you also tell me about customs procedures for international flights?',
                        sentenceVoice:
                            '/s3/situation-synthesis/57bab119-aa02-4c1f-98c4-95131e1c71d6.mp3',
                    },
                ],
                translate:
                    'Ich danke Ihnen für all diese Informationen. Können Sie mir auch etwas über die Zollverfahren bei internationalen Flügen sagen?',
            },
            {
                id: 'e9e7c7a5-b572-462f-8a73-69ea91d7f98f',
                phrase: "Of course. When you arrive at your destination, you must go through customs. You might need to declare any goods you're bringing into the country.",
                phraseVoice:
                    '/s3/situation-synthesis/fd71aeab-ea73-473c-b8a3-da3389d654f3.mp3',
                offset: 36,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Ja, natürlich. Wenn Sie an Ihrem Zielort ankommen, müssen Sie den Zoll passieren. Möglicherweise müssen Sie alle Waren, die Sie in das Land einführen, deklarieren.',
            },
            {
                id: 'ba09a2f0-ddb6-488e-a627-30eb8eddf7b8',
                phrase: 'What kind of items might I need to declare?',
                phraseVoice:
                    '/s3/situation-synthesis/adee4c38-bac6-48b4-b20c-07ca2a620340.mp3',
                offset: 38,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'What kind of items might I need to declare?',
                        sentenceVoice:
                            '/s3/situation-synthesis/6f1960f4-c524-4fa0-ac00-c58036678057.mp3',
                    },
                ],
                translate:
                    'Welche Art von Gegenständen muss ich möglicherweise anmelden?',
            },
            {
                id: 'fbe3a3aa-3848-4509-8d2f-aa32794fd631',
                phrase: "Typically, you must declare items like food, plants, or large amounts of currency. It's best to check the specific regulations of the country you're visiting.",
                phraseVoice:
                    '/s3/situation-synthesis/d4c2133d-3a48-42a0-bc2f-2ebab66c76e4.mp3',
                offset: 39,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'In der Regel müssen Sie Gegenstände wie Lebensmittel, Pflanzen oder größere Geldbeträge anmelden. Informieren Sie sich am besten über die spezifischen Vorschriften des Landes, das Sie besuchen.',
            },
            {
                id: '4375c6e8-ee45-4f09-bd92-222d09d7f83b',
                phrase: 'I understand. Is there anything else I should prepare for an international trip?',
                phraseVoice:
                    '/s3/situation-synthesis/d2441897-a0a3-4569-bd18-6bc826137271.mp3',
                offset: 41,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I understand.',
                        sentenceVoice:
                            '/s3/situation-synthesis/c7ad48bd-12fa-43c6-b969-53d57ec73669.mp3',
                    },
                    {
                        text: 'Is there anything else I should prepare for an international trip?',
                        sentenceVoice:
                            '/s3/situation-synthesis/51718984-df5b-4b6d-bb6f-ace9f1f6a87b.mp3',
                    },
                ],
                translate:
                    'Ich habe verstanden. Gibt es noch etwas, das ich für eine Auslandsreise vorbereiten sollte?',
            },
            {
                id: '4d097be5-6b8b-4d71-90e6-2bb1f7d954b3',
                phrase: 'You should ensure your passport is valid for at least six months beyond your travel dates. Some countries might also require a visa.',
                phraseVoice:
                    '/s3/situation-synthesis/1627908b-c835-49a5-aa9c-1f45c75852a8.mp3',
                offset: 42,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Stellen Sie sicher, dass Ihr Reisepass noch mindestens sechs Monate über Ihre Reisedaten hinaus gültig ist. Für einige Länder ist möglicherweise auch ein Visum erforderlich.',
            },
            {
                id: 'da550395-30a3-461a-9f91-4b566165ef6b',
                phrase: "That's a good point. How can I find out if I need a visa?",
                phraseVoice:
                    '/s3/situation-synthesis/a6c1ef9e-e1e6-44b7-8edf-60cc74d74a57.mp3',
                offset: 44,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's a good point.",
                        sentenceVoice:
                            '/s3/situation-synthesis/53d0f7a8-768c-4e66-9a32-123d5438db30.mp3',
                    },
                    {
                        text: 'How can I find out if I need a visa?',
                        sentenceVoice:
                            '/s3/situation-synthesis/14941153-b56e-400d-b795-0e99e131b9d3.mp3',
                    },
                ],
                translate:
                    'Das ist ein gutes Argument. Wie kann ich herausfinden, ob ich ein Visum benötige?',
            },
            {
                id: '6171856e-000a-48dd-90be-a4eacc54725e',
                phrase: "You could check the embassy website of the country you're visiting. They usually have detailed information on visa requirements.",
                phraseVoice:
                    '/s3/situation-synthesis/c457c884-9037-4dc8-aab1-e3708cdd6008.mp3',
                offset: 45,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sie können auf der Website der Botschaft des Landes, das Sie besuchen möchten, nachsehen. Dort finden Sie in der Regel detaillierte Informationen zu den Visabestimmungen.',
            },
            {
                id: '63e60430-b154-4cae-bc05-4f1c2a7d2b85',
                phrase: 'Thank you so much for your help. I feel much more prepared now.',
                phraseVoice:
                    '/s3/situation-synthesis/0d8f1298-7156-4705-aaac-4472f7344727.mp3',
                offset: 47,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you so much for your help.',
                        sentenceVoice:
                            '/s3/situation-synthesis/b708b800-3911-40e7-8b0e-e011310221f1.mp3',
                    },
                    {
                        text: 'I feel much more prepared now.',
                        sentenceVoice:
                            '/s3/situation-synthesis/e1926edf-35ca-44a6-8522-f6cee01ce888.mp3',
                    },
                ],
                translate:
                    'Vielen Dank für Ihre Hilfe. Ich fühle mich jetzt viel besser vorbereitet.',
            },
            {
                id: 'cfc99c7c-a282-479e-b9ca-f29af59dd58d',
                phrase: "You're welcome! Traveling can be a bit overwhelming, but with a little preparation, it can also be a lot of fun.",
                phraseVoice:
                    '/s3/situation-synthesis/5cd82eec-04fc-476e-80bd-668fd772802e.mp3',
                offset: 48,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Herzlich willkommen! Reisen kann ein wenig überwältigend sein, aber mit ein wenig Vorbereitung kann es auch viel Spaß machen.',
            },
            {
                id: '07b1fada-cbfa-4d39-8750-d76939877c7f',
                phrase: 'I agree. One last question',
                phraseVoice:
                    '/s3/situation-synthesis/3f7dab06-3da3-41f6-90a3-5dcce292b738.mp3',
                offset: 50,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I agree.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2daab0de-33ee-43ec-8359-1b068ee4dc82.mp3',
                    },
                    {
                        text: 'One last question',
                        sentenceVoice:
                            '/s3/situation-synthesis/6618564a-26af-47d6-855c-f761e02b59e5.mp3',
                    },
                ],
                translate: 'Ich stimme zu. Eine letzte Frage',
            },
            {
                id: '2985b5fc-f625-4ebd-a66d-1a416e6c7fec',
                phrase: 'Absolutely! You might find apps like TripIt for organizing your itinerary, or Google Translate for language assistance, very helpful.',
                phraseVoice:
                    '/s3/situation-synthesis/f500d380-34ac-4f93-94e5-f4443949ca59.mp3',
                offset: 51,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Auf jeden Fall! Apps wie TripIt für die Organisation Ihrer Reiseroute oder Google Translate für die Sprachunterstützung könnten sehr hilfreich sein.',
            },
            {
                id: '0f7384a3-2935-47f7-ad0c-cea5c8edc1c7',
                phrase: "Those sound great. I'll definitely check them out.",
                phraseVoice:
                    '/s3/situation-synthesis/a1be059d-cfa7-4e3c-8e57-669535e053df.mp3',
                offset: 53,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Those sound great.',
                        sentenceVoice:
                            '/s3/situation-synthesis/943bfbec-72d5-48b3-9e81-955de9c5482e.mp3',
                    },
                    {
                        text: "I'll definitely check them out.",
                        sentenceVoice:
                            '/s3/situation-synthesis/91c97804-6704-4cbb-aaaf-2df78663d41b.mp3',
                    },
                ],
                translate:
                    'Das klingt großartig. Ich werde sie mir auf jeden Fall ansehen.',
            },
            {
                id: 'e48130d8-f71d-4351-b7cf-afbb43d55e9c',
                phrase: "I'm glad I could help. Have a safe and enjoyable trip!",
                phraseVoice:
                    '/s3/situation-synthesis/74c82984-79e2-49e8-9eab-ca2a7542883c.mp3',
                offset: 54,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Ich bin froh, dass ich helfen konnte. Ich wünsche Ihnen eine sichere und angenehme Reise!',
            },
            {
                id: '41274da6-7b74-49fd-b03f-c2194f491bf6',
                phrase: 'Thank you! I appreciate all your advice.',
                phraseVoice:
                    '/s3/situation-synthesis/be94bf6e-84f0-41e1-951e-fdd76df2089b.mp3',
                offset: 56,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you!',
                        sentenceVoice:
                            '/s3/situation-synthesis/1734d2a5-a3c2-4390-ae2a-55feb16c6e9d.mp3',
                    },
                    {
                        text: 'I appreciate all your advice.',
                        sentenceVoice:
                            '/s3/situation-synthesis/ba3924d4-1123-43af-967a-d2c469706592.mp3',
                    },
                ],
                translate: 'Ich danke Ihnen! Ich weiß Ihren Rat zu schätzen.',
            },
            {
                id: 'cd13cf54-02f6-45d6-b6da-4742bc3e083e',
                phrase: 'Anytime! If you have any more questions, feel free to ask. Safe travels!',
                phraseVoice:
                    '/s3/situation-synthesis/bb470f1a-73b9-478f-bb7b-d39fe82df1cb.mp3',
                offset: 57,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Jederzeit! Wenn Sie weitere Fragen haben, können Sie diese gerne stellen. Gute Reise!',
            },
        ],
    },
    it: {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'User Generated',
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
        statements: [
            {
                id: 'dab5b7a7-0053-4650-8cac-0f1fc78bb23d',
                phrase: 'Excuse me, could you help me with some information about the airport procedures?',
                phraseVoice:
                    '/s3/situation-synthesis/8500d8ce-1f4d-482e-83cc-b99c3e070f60.mp3',
                offset: 2,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Excuse me, could you help me with some information about the airport procedures?',
                        sentenceVoice:
                            '/s3/situation-synthesis/906d046f-32fe-442b-a59c-e3712c8fb57d.mp3',
                    },
                ],
                translate:
                    'Mi scusi, potrebbe aiutarmi con qualche informazione sulle procedure aeroportuali?',
            },
            {
                id: 'a26c2632-26ef-425b-aac1-69413fb94df0',
                phrase: "Of course! I'd be happy to help. What do you need to know?",
                phraseVoice:
                    '/s3/situation-synthesis/659a4380-6dd0-47b1-8338-fc6790ec7804.mp3',
                offset: 3,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Certo! Sarò felice di aiutarvi. Cosa vi serve sapere?',
            },
            {
                id: '558c8341-10c7-46ce-9a36-6be8fbdc80f3',
                phrase: "Well, I might need some guidance on the check-in process. I haven't flown in a while.",
                phraseVoice:
                    '/s3/situation-synthesis/14816793-f43c-44dd-aa55-c10eec0a545c.mp3',
                offset: 5,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Well, I might need some guidance on the check-in process.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2b8c62a5-d216-4b6c-a924-485fcf0d59f8.mp3',
                    },
                    {
                        text: "I haven't flown in a while.",
                        sentenceVoice:
                            '/s3/situation-synthesis/10a9e42d-9079-4e19-bf53-7eeb28d1194e.mp3',
                    },
                ],
                translate:
                    "Potrei aver bisogno di una guida per il check-in. È da un po' che non volo.",
            },
            {
                id: 'b9f10d31-c914-4389-9932-3efbbddfa396',
                phrase: 'No problem. You can check in online before you arrive at the airport, which could save you some time.',
                phraseVoice:
                    '/s3/situation-synthesis/b561f161-2bc3-4722-a6ff-5216a25ec35d.mp3',
                offset: 6,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Nessun problema. È possibile effettuare il check-in online prima di arrivare in aeroporto, risparmiando così un po' di tempo.",
            },
            {
                id: '87eea3fe-7b73-41d6-8fa9-ba3f77e279e0',
                phrase: "That's good to know. But if I can't check in online, what should I do?",
                phraseVoice:
                    '/s3/situation-synthesis/a20fe6fd-c1ac-4e3a-a0ad-2a499d87a815.mp3',
                offset: 8,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's good to know.",
                        sentenceVoice:
                            '/s3/situation-synthesis/46b707b4-70b3-47e1-9905-ea5e06b871a8.mp3',
                    },
                    {
                        text: "But if I can't check in online, what should I do?",
                        sentenceVoice:
                            '/s3/situation-synthesis/d767c41d-bce0-40be-abc4-be1accae9ebb.mp3',
                    },
                ],
                translate:
                    'Buono a sapersi. Ma se non posso effettuare il check-in online, cosa devo fare?',
            },
            {
                id: '6482a404-f4a5-4d37-a5db-f183f24f0de2',
                phrase: "You must go to the airline's check-in counter. They can assist you with your boarding pass and any luggage you might have.",
                phraseVoice:
                    '/s3/situation-synthesis/b26a5def-00f3-4572-aa2e-4d0375b8fb8d.mp3',
                offset: 9,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Dovete recarvi al banco del check-in della compagnia aerea. Questi potranno assistervi con la carta d'imbarco e con l'eventuale bagaglio.",
            },
            {
                id: 'd65fbb2c-2737-4cc3-b08a-d46d96e63c3c',
                phrase: 'Speaking of luggage, could you tell me about the baggage policies?',
                phraseVoice:
                    '/s3/situation-synthesis/cdd4a19e-9edd-417f-9781-5bae69478abd.mp3',
                offset: 11,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Speaking of luggage, could you tell me about the baggage policies?',
                        sentenceVoice:
                            '/s3/situation-synthesis/fa7fde62-83af-46ea-aaad-4e1d248f8413.mp3',
                    },
                ],
                translate:
                    'A proposito di bagagli, mi può parlare delle politiche sui bagagli?',
            },
            {
                id: 'df6ae3a7-a92c-4ca9-b50e-6761b97a6311',
                phrase: "Sure! You should check your airline's specific baggage policy, as it can vary. Generally, you might be allowed one carry-on and one personal item.",
                phraseVoice:
                    '/s3/situation-synthesis/2ec664aa-1aeb-491a-aefe-ad25b598ecca.mp3',
                offset: 12,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Certo! Dovete verificare la politica specifica della vostra compagnia aerea in materia di bagagli, poiché può variare. In generale, è consentito un bagaglio a mano e un oggetto personale.',
            },
            {
                id: 'b7cfcc4a-1114-41f0-b4c6-8e06fb98a587',
                phrase: 'And if I have more luggage, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/694ec3cc-0726-4a8f-be0a-96d90217879e.mp3',
                offset: 14,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I have more luggage, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/82f419d2-bbe1-4aca-95ad-c0ecd81ca430.mp3',
                    },
                ],
                translate: 'E se ho altri bagagli, quali sono le mie opzioni?',
            },
            {
                id: '17c8728f-0bc8-4845-aab6-2c09a02d4e4f',
                phrase: "You could check additional bags, but there might be extra fees. It's best to weigh your bags at home to avoid surprises.",
                phraseVoice:
                    '/s3/situation-synthesis/14e1868c-0dc3-4b77-b6b3-725d709564d2.mp3',
                offset: 15,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'È possibile imbarcare altri bagagli, ma potrebbero esserci costi aggiuntivi. È meglio pesare i bagagli a casa per evitare sorprese.',
            },
            {
                id: 'fddae7f1-bfae-493d-8ed8-119f0250af06',
                phrase: "I see. Once I'm checked in, what should I do next?",
                phraseVoice:
                    '/s3/situation-synthesis/0b5c8759-f0a7-43c3-ad16-5b19d5754d9e.mp3',
                offset: 17,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I see.',
                        sentenceVoice:
                            '/s3/situation-synthesis/f8d7576d-1187-4e03-9142-727f11af5d51.mp3',
                    },
                    {
                        text: "Once I'm checked in, what should I do next?",
                        sentenceVoice:
                            '/s3/situation-synthesis/41969f55-1b5b-4d2a-8800-584c0fd01253.mp3',
                    },
                ],
                translate:
                    'Capisco. Una volta effettuato il check-in, cosa devo fare?',
            },
            {
                id: '017feee8-4d99-4f27-9533-684ba4a5fb17',
                phrase: 'After check-in, you must go through security. Make sure you have your boarding pass and ID ready.',
                phraseVoice:
                    '/s3/situation-synthesis/d137d9f7-2e4d-438b-ae74-210a8018224b.mp3',
                offset: 18,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Dopo il check-in, è necessario passare i controlli di sicurezza. Assicuratevi di avere a portata di mano la carta d'imbarco e il documento d'identità.",
            },
            {
                id: 'f40d7aa7-4623-4b82-a61b-702d2a887356',
                phrase: 'Could you explain what I should expect at security?',
                phraseVoice:
                    '/s3/situation-synthesis/1a9976f8-0e5e-412f-8ed1-c47a39a5e1ac.mp3',
                offset: 20,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Could you explain what I should expect at security?',
                        sentenceVoice:
                            '/s3/situation-synthesis/7332dd08-5008-4376-8b08-a53d95c95afb.mp3',
                    },
                ],
                translate:
                    'Potrebbe spiegarmi cosa devo aspettarmi alla sicurezza?',
            },
            {
                id: '577195c5-dc69-4681-b102-c7ee1bfab729',
                phrase: 'Certainly. You might need to remove your shoes, belt, and any metal items. Laptops and liquids must be placed in separate bins.',
                phraseVoice:
                    '/s3/situation-synthesis/1547e8d2-4b74-4593-bb96-d4fbc8fe420f.mp3',
                offset: 21,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Certamente. Potrebbe essere necessario togliere le scarpe, la cintura e qualsiasi oggetto metallico. I computer portatili e i liquidi devono essere riposti in contenitori separati.',
            },
            {
                id: 'd17e863b-3e9a-4fcf-9ee9-1bfdf3b1cddf',
                phrase: 'That sounds straightforward. Is there anything else I should be aware of?',
                phraseVoice:
                    '/s3/situation-synthesis/a5b57de1-4d93-44f8-86c3-bba4d08c75c4.mp3',
                offset: 23,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'That sounds straightforward.',
                        sentenceVoice:
                            '/s3/situation-synthesis/fe1a5563-25aa-45aa-ae12-ec91052d7a5e.mp3',
                    },
                    {
                        text: 'Is there anything else I should be aware of?',
                        sentenceVoice:
                            '/s3/situation-synthesis/0d606640-a4eb-453b-b59a-4d4ea13493c8.mp3',
                    },
                ],
                translate:
                    "Sembra una cosa semplice. C'è qualcos'altro di cui dovrei essere a conoscenza?",
            },
            {
                id: 'f0b750eb-7a19-41cc-b46a-a159061aa269',
                phrase: 'You might want to arrive at the airport at least two hours before your flight for domestic travel, and three hours for international flights.',
                phraseVoice:
                    '/s3/situation-synthesis/8afd9a72-cd04-45c3-b505-8bd93cac1b52.mp3',
                offset: 24,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'È consigliabile arrivare in aeroporto almeno due ore prima del volo per i viaggi nazionali e tre ore per i voli internazionali.',
            },
            {
                id: '14f90ad6-ac2c-477f-9253-9b95a7fdaa22',
                phrase: "That's helpful. Once I'm through security, what should I do while waiting for my flight?",
                phraseVoice:
                    '/s3/situation-synthesis/42191014-4869-485f-8dfb-08d63c9bd999.mp3',
                offset: 26,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's helpful.",
                        sentenceVoice:
                            '/s3/situation-synthesis/0f27e95c-e386-40bb-9547-fbde8eaea0e3.mp3',
                    },
                    {
                        text: "Once I'm through security, what should I do while waiting for my flight?",
                        sentenceVoice:
                            '/s3/situation-synthesis/6d23d2a0-9963-4049-b4f8-e7d88b4fec44.mp3',
                    },
                ],
                translate:
                    'È utile. Una volta superati i controlli di sicurezza, cosa devo fare in attesa del volo?',
            },
            {
                id: 'dc1e3bc2-258c-4683-9485-a78a81b05b29',
                phrase: 'You could explore the airport shops or grab a bite to eat. Just keep an eye on the departure screens for any updates on your flight.',
                phraseVoice:
                    '/s3/situation-synthesis/5cef58f0-9ebd-4f14-9fe7-c76647d34df3.mp3',
                offset: 27,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Potreste esplorare i negozi dell'aeroporto o mangiare un boccone. Tenete d'occhio gli schermi delle partenze per eventuali aggiornamenti sul vostro volo.",
            },
            {
                id: '65465e60-02a8-40d2-8293-53042edd3d0c',
                phrase: 'If my flight is delayed, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/53c8f359-cadc-43b5-a43e-4940550a28c5.mp3',
                offset: 29,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'If my flight is delayed, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/8fc4dc38-1048-4fa0-830e-634cda3feba1.mp3',
                    },
                ],
                translate:
                    'Se il mio volo è in ritardo, quali sono le mie opzioni?',
            },
            {
                id: '96925634-2765-4a61-9eb6-a594d8041818',
                phrase: 'You should check with your airline. They might offer rebooking options or vouchers, depending on the situation.',
                phraseVoice:
                    '/s3/situation-synthesis/1ab490b7-277d-432e-85f6-7eb0e598b562.mp3',
                offset: 30,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Dovreste verificare con la vostra compagnia aerea. Potrebbero offrire opzioni di riprenotazione o voucher, a seconda della situazione.',
            },
            {
                id: 'c5049575-e75e-4914-bf15-a4ffa15cec57',
                phrase: 'And if I miss my flight, what should I do?',
                phraseVoice:
                    '/s3/situation-synthesis/94396909-0ce4-4089-b6a7-cd0b56cb0daa.mp3',
                offset: 32,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I miss my flight, what should I do?',
                        sentenceVoice:
                            '/s3/situation-synthesis/d4809b1a-400e-4d62-9022-c5192e593df9.mp3',
                    },
                ],
                translate: 'E se perdo il volo, cosa devo fare?',
            },
            {
                id: '409fd2ff-53fb-4327-b8a3-7fef7e0373bb',
                phrase: 'You must contact your airline immediately. They could help you find the next available flight.',
                phraseVoice:
                    '/s3/situation-synthesis/c77866b4-f915-4f29-bc8c-856d7d509338.mp3',
                offset: 33,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Dovete contattare immediatamente la vostra compagnia aerea. Potrebbero aiutarvi a trovare il prossimo volo disponibile.',
            },
            {
                id: '0220dc13-2dca-4803-b927-bb6e47636a9f',
                phrase: 'Thank you for all this information. Could you also tell me about customs procedures for international flights?',
                phraseVoice:
                    '/s3/situation-synthesis/a0bf0682-bf12-4813-912c-e117ab0f76d0.mp3',
                offset: 35,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you for all this information.',
                        sentenceVoice:
                            '/s3/situation-synthesis/25dfbeb1-f870-4a2b-8278-f462e2bb2974.mp3',
                    },
                    {
                        text: 'Could you also tell me about customs procedures for international flights?',
                        sentenceVoice:
                            '/s3/situation-synthesis/57bab119-aa02-4c1f-98c4-95131e1c71d6.mp3',
                    },
                ],
                translate:
                    'Grazie per tutte queste informazioni. Potreste anche parlarmi delle procedure doganali per i voli internazionali?',
            },
            {
                id: 'e9e7c7a5-b572-462f-8a73-69ea91d7f98f',
                phrase: "Of course. When you arrive at your destination, you must go through customs. You might need to declare any goods you're bringing into the country.",
                phraseVoice:
                    '/s3/situation-synthesis/fd71aeab-ea73-473c-b8a3-da3389d654f3.mp3',
                offset: 36,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Naturalmente. Quando arrivate a destinazione, dovete passare la dogana. Potreste dover dichiarare le merci che state portando nel Paese.',
            },
            {
                id: 'ba09a2f0-ddb6-488e-a627-30eb8eddf7b8',
                phrase: 'What kind of items might I need to declare?',
                phraseVoice:
                    '/s3/situation-synthesis/adee4c38-bac6-48b4-b20c-07ca2a620340.mp3',
                offset: 38,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'What kind of items might I need to declare?',
                        sentenceVoice:
                            '/s3/situation-synthesis/6f1960f4-c524-4fa0-ac00-c58036678057.mp3',
                    },
                ],
                translate: 'Che tipo di articoli devo dichiarare?',
            },
            {
                id: 'fbe3a3aa-3848-4509-8d2f-aa32794fd631',
                phrase: "Typically, you must declare items like food, plants, or large amounts of currency. It's best to check the specific regulations of the country you're visiting.",
                phraseVoice:
                    '/s3/situation-synthesis/d4c2133d-3a48-42a0-bc2f-2ebab66c76e4.mp3',
                offset: 39,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'In genere, è necessario dichiarare articoli come cibo, piante o grandi quantità di valuta. È meglio controllare le norme specifiche del Paese che si sta visitando.',
            },
            {
                id: '4375c6e8-ee45-4f09-bd92-222d09d7f83b',
                phrase: 'I understand. Is there anything else I should prepare for an international trip?',
                phraseVoice:
                    '/s3/situation-synthesis/d2441897-a0a3-4569-bd18-6bc826137271.mp3',
                offset: 41,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I understand.',
                        sentenceVoice:
                            '/s3/situation-synthesis/c7ad48bd-12fa-43c6-b969-53d57ec73669.mp3',
                    },
                    {
                        text: 'Is there anything else I should prepare for an international trip?',
                        sentenceVoice:
                            '/s3/situation-synthesis/51718984-df5b-4b6d-bb6f-ace9f1f6a87b.mp3',
                    },
                ],
                translate:
                    "Capisco. C'è qualcos'altro che dovrei preparare per un viaggio internazionale?",
            },
            {
                id: '4d097be5-6b8b-4d71-90e6-2bb1f7d954b3',
                phrase: 'You should ensure your passport is valid for at least six months beyond your travel dates. Some countries might also require a visa.',
                phraseVoice:
                    '/s3/situation-synthesis/1627908b-c835-49a5-aa9c-1f45c75852a8.mp3',
                offset: 42,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'È necessario assicurarsi che il passaporto sia valido per almeno sei mesi oltre le date del viaggio. Alcuni Paesi potrebbero richiedere un visto.',
            },
            {
                id: 'da550395-30a3-461a-9f91-4b566165ef6b',
                phrase: "That's a good point. How can I find out if I need a visa?",
                phraseVoice:
                    '/s3/situation-synthesis/a6c1ef9e-e1e6-44b7-8edf-60cc74d74a57.mp3',
                offset: 44,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's a good point.",
                        sentenceVoice:
                            '/s3/situation-synthesis/53d0f7a8-768c-4e66-9a32-123d5438db30.mp3',
                    },
                    {
                        text: 'How can I find out if I need a visa?',
                        sentenceVoice:
                            '/s3/situation-synthesis/14941153-b56e-400d-b795-0e99e131b9d3.mp3',
                    },
                ],
                translate:
                    'Questa è una buona osservazione. Come posso sapere se ho bisogno di un visto?',
            },
            {
                id: '6171856e-000a-48dd-90be-a4eacc54725e',
                phrase: "You could check the embassy website of the country you're visiting. They usually have detailed information on visa requirements.",
                phraseVoice:
                    '/s3/situation-synthesis/c457c884-9037-4dc8-aab1-e3708cdd6008.mp3',
                offset: 45,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Potete consultare il sito web dell'ambasciata del Paese che state visitando. Di solito hanno informazioni dettagliate sui requisiti per il visto.",
            },
            {
                id: '63e60430-b154-4cae-bc05-4f1c2a7d2b85',
                phrase: 'Thank you so much for your help. I feel much more prepared now.',
                phraseVoice:
                    '/s3/situation-synthesis/0d8f1298-7156-4705-aaac-4472f7344727.mp3',
                offset: 47,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you so much for your help.',
                        sentenceVoice:
                            '/s3/situation-synthesis/b708b800-3911-40e7-8b0e-e011310221f1.mp3',
                    },
                    {
                        text: 'I feel much more prepared now.',
                        sentenceVoice:
                            '/s3/situation-synthesis/e1926edf-35ca-44a6-8522-f6cee01ce888.mp3',
                    },
                ],
                translate:
                    'Grazie mille per il vostro aiuto. Ora mi sento molto più preparato.',
            },
            {
                id: 'cfc99c7c-a282-479e-b9ca-f29af59dd58d',
                phrase: "You're welcome! Traveling can be a bit overwhelming, but with a little preparation, it can also be a lot of fun.",
                phraseVoice:
                    '/s3/situation-synthesis/5cd82eec-04fc-476e-80bd-668fd772802e.mp3',
                offset: 48,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Non c'è di che! Viaggiare può essere un po' travolgente, ma con un po' di preparazione può anche essere molto divertente.",
            },
            {
                id: '07b1fada-cbfa-4d39-8750-d76939877c7f',
                phrase: 'I agree. One last question',
                phraseVoice:
                    '/s3/situation-synthesis/3f7dab06-3da3-41f6-90a3-5dcce292b738.mp3',
                offset: 50,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I agree.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2daab0de-33ee-43ec-8359-1b068ee4dc82.mp3',
                    },
                    {
                        text: 'One last question',
                        sentenceVoice:
                            '/s3/situation-synthesis/6618564a-26af-47d6-855c-f761e02b59e5.mp3',
                    },
                ],
                translate: "Sono d'accordo. Un'ultima domanda",
            },
            {
                id: '2985b5fc-f625-4ebd-a66d-1a416e6c7fec',
                phrase: 'Absolutely! You might find apps like TripIt for organizing your itinerary, or Google Translate for language assistance, very helpful.',
                phraseVoice:
                    '/s3/situation-synthesis/f500d380-34ac-4f93-94e5-f4443949ca59.mp3',
                offset: 51,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Assolutamente! Potreste trovare molto utili applicazioni come TripIt per organizzare il vostro itinerario o Google Translate per l'assistenza linguistica.",
            },
            {
                id: '0f7384a3-2935-47f7-ad0c-cea5c8edc1c7',
                phrase: "Those sound great. I'll definitely check them out.",
                phraseVoice:
                    '/s3/situation-synthesis/a1be059d-cfa7-4e3c-8e57-669535e053df.mp3',
                offset: 53,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Those sound great.',
                        sentenceVoice:
                            '/s3/situation-synthesis/943bfbec-72d5-48b3-9e81-955de9c5482e.mp3',
                    },
                    {
                        text: "I'll definitely check them out.",
                        sentenceVoice:
                            '/s3/situation-synthesis/91c97804-6704-4cbb-aaaf-2df78663d41b.mp3',
                    },
                ],
                translate: 'Sembrano ottimi. Li controllerò sicuramente.',
            },
            {
                id: 'e48130d8-f71d-4351-b7cf-afbb43d55e9c',
                phrase: "I'm glad I could help. Have a safe and enjoyable trip!",
                phraseVoice:
                    '/s3/situation-synthesis/74c82984-79e2-49e8-9eab-ca2a7542883c.mp3',
                offset: 54,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sono felice di avervi aiutato. Buon viaggio e buon divertimento!',
            },
            {
                id: '41274da6-7b74-49fd-b03f-c2194f491bf6',
                phrase: 'Thank you! I appreciate all your advice.',
                phraseVoice:
                    '/s3/situation-synthesis/be94bf6e-84f0-41e1-951e-fdd76df2089b.mp3',
                offset: 56,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you!',
                        sentenceVoice:
                            '/s3/situation-synthesis/1734d2a5-a3c2-4390-ae2a-55feb16c6e9d.mp3',
                    },
                    {
                        text: 'I appreciate all your advice.',
                        sentenceVoice:
                            '/s3/situation-synthesis/ba3924d4-1123-43af-967a-d2c469706592.mp3',
                    },
                ],
                translate: 'Grazie! Apprezzo tutti i vostri consigli.',
            },
            {
                id: 'cd13cf54-02f6-45d6-b6da-4742bc3e083e',
                phrase: 'Anytime! If you have any more questions, feel free to ask. Safe travels!',
                phraseVoice:
                    '/s3/situation-synthesis/bb470f1a-73b9-478f-bb7b-d39fe82df1cb.mp3',
                offset: 57,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'In qualsiasi momento! Se avete altre domande, non esitate a chiedere. Buon viaggio!',
            },
        ],
    },
    pt: {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'User Generated',
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
        statements: [
            {
                id: 'dab5b7a7-0053-4650-8cac-0f1fc78bb23d',
                phrase: 'Excuse me, could you help me with some information about the airport procedures?',
                phraseVoice:
                    '/s3/situation-synthesis/8500d8ce-1f4d-482e-83cc-b99c3e070f60.mp3',
                offset: 2,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Excuse me, could you help me with some information about the airport procedures?',
                        sentenceVoice:
                            '/s3/situation-synthesis/906d046f-32fe-442b-a59c-e3712c8fb57d.mp3',
                    },
                ],
                translate:
                    'Desculpe, pode ajudar-me com algumas informações sobre os procedimentos no aeroporto?',
            },
            {
                id: 'a26c2632-26ef-425b-aac1-69413fb94df0',
                phrase: "Of course! I'd be happy to help. What do you need to know?",
                phraseVoice:
                    '/s3/situation-synthesis/659a4380-6dd0-47b1-8338-fc6790ec7804.mp3',
                offset: 3,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Claro que sim! Terei todo o gosto em ajudar. O que é que precisa de saber?',
            },
            {
                id: '558c8341-10c7-46ce-9a36-6be8fbdc80f3',
                phrase: "Well, I might need some guidance on the check-in process. I haven't flown in a while.",
                phraseVoice:
                    '/s3/situation-synthesis/14816793-f43c-44dd-aa55-c10eec0a545c.mp3',
                offset: 5,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Well, I might need some guidance on the check-in process.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2b8c62a5-d216-4b6c-a924-485fcf0d59f8.mp3',
                    },
                    {
                        text: "I haven't flown in a while.",
                        sentenceVoice:
                            '/s3/situation-synthesis/10a9e42d-9079-4e19-bf53-7eeb28d1194e.mp3',
                    },
                ],
                translate:
                    'Bem, talvez eu precise de alguma orientação sobre o processo de check-in. Já não ando de avião há algum tempo.',
            },
            {
                id: 'b9f10d31-c914-4389-9932-3efbbddfa396',
                phrase: 'No problem. You can check in online before you arrive at the airport, which could save you some time.',
                phraseVoice:
                    '/s3/situation-synthesis/b561f161-2bc3-4722-a6ff-5216a25ec35d.mp3',
                offset: 6,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Não há problema. Pode fazer o check-in online antes de chegar ao aeroporto, o que pode poupar-lhe algum tempo.',
            },
            {
                id: '87eea3fe-7b73-41d6-8fa9-ba3f77e279e0',
                phrase: "That's good to know. But if I can't check in online, what should I do?",
                phraseVoice:
                    '/s3/situation-synthesis/a20fe6fd-c1ac-4e3a-a0ad-2a499d87a815.mp3',
                offset: 8,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's good to know.",
                        sentenceVoice:
                            '/s3/situation-synthesis/46b707b4-70b3-47e1-9905-ea5e06b871a8.mp3',
                    },
                    {
                        text: "But if I can't check in online, what should I do?",
                        sentenceVoice:
                            '/s3/situation-synthesis/d767c41d-bce0-40be-abc4-be1accae9ebb.mp3',
                    },
                ],
                translate:
                    'É bom saber isso. Mas se não puder fazer o check-in online, o que devo fazer?',
            },
            {
                id: '6482a404-f4a5-4d37-a5db-f183f24f0de2',
                phrase: "You must go to the airline's check-in counter. They can assist you with your boarding pass and any luggage you might have.",
                phraseVoice:
                    '/s3/situation-synthesis/b26a5def-00f3-4572-aa2e-4d0375b8fb8d.mp3',
                offset: 9,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Deve dirigir-se ao balcão de check-in da companhia aérea. O balcão pode ajudá-lo com o seu cartão de embarque e com a sua bagagem.',
            },
            {
                id: 'd65fbb2c-2737-4cc3-b08a-d46d96e63c3c',
                phrase: 'Speaking of luggage, could you tell me about the baggage policies?',
                phraseVoice:
                    '/s3/situation-synthesis/cdd4a19e-9edd-417f-9781-5bae69478abd.mp3',
                offset: 11,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Speaking of luggage, could you tell me about the baggage policies?',
                        sentenceVoice:
                            '/s3/situation-synthesis/fa7fde62-83af-46ea-aaad-4e1d248f8413.mp3',
                    },
                ],
                translate:
                    'Por falar em bagagem, pode falar-me das políticas de bagagem?',
            },
            {
                id: 'df6ae3a7-a92c-4ca9-b50e-6761b97a6311',
                phrase: "Sure! You should check your airline's specific baggage policy, as it can vary. Generally, you might be allowed one carry-on and one personal item.",
                phraseVoice:
                    '/s3/situation-synthesis/2ec664aa-1aeb-491a-aefe-ad25b598ecca.mp3',
                offset: 12,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Claro! Deve verificar a política de bagagem específica da sua companhia aérea, uma vez que esta pode variar. Em geral, pode ser-lhe permitida uma bagagem de mão e um objeto pessoal.',
            },
            {
                id: 'b7cfcc4a-1114-41f0-b4c6-8e06fb98a587',
                phrase: 'And if I have more luggage, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/694ec3cc-0726-4a8f-be0a-96d90217879e.mp3',
                offset: 14,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I have more luggage, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/82f419d2-bbe1-4aca-95ad-c0ecd81ca430.mp3',
                    },
                ],
                translate:
                    'E se eu tiver mais bagagem, quais são as minhas opções?',
            },
            {
                id: '17c8728f-0bc8-4845-aab6-2c09a02d4e4f',
                phrase: "You could check additional bags, but there might be extra fees. It's best to weigh your bags at home to avoid surprises.",
                phraseVoice:
                    '/s3/situation-synthesis/14e1868c-0dc3-4b77-b6b3-725d709564d2.mp3',
                offset: 15,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Poderá despachar bagagem adicional, mas poderá ter de pagar taxas adicionais. É melhor pesar as suas malas em casa para evitar surpresas.',
            },
            {
                id: 'fddae7f1-bfae-493d-8ed8-119f0250af06',
                phrase: "I see. Once I'm checked in, what should I do next?",
                phraseVoice:
                    '/s3/situation-synthesis/0b5c8759-f0a7-43c3-ad16-5b19d5754d9e.mp3',
                offset: 17,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I see.',
                        sentenceVoice:
                            '/s3/situation-synthesis/f8d7576d-1187-4e03-9142-727f11af5d51.mp3',
                    },
                    {
                        text: "Once I'm checked in, what should I do next?",
                        sentenceVoice:
                            '/s3/situation-synthesis/41969f55-1b5b-4d2a-8800-584c0fd01253.mp3',
                    },
                ],
                translate:
                    'Estou a ver. Depois de ter feito o check-in, o que devo fazer a seguir?',
            },
            {
                id: '017feee8-4d99-4f27-9533-684ba4a5fb17',
                phrase: 'After check-in, you must go through security. Make sure you have your boarding pass and ID ready.',
                phraseVoice:
                    '/s3/situation-synthesis/d137d9f7-2e4d-438b-ae74-210a8018224b.mp3',
                offset: 18,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Após o check-in, tem de passar pela segurança. Certifique-se de que tem o seu cartão de embarque e o seu documento de identificação à mão.',
            },
            {
                id: 'f40d7aa7-4623-4b82-a61b-702d2a887356',
                phrase: 'Could you explain what I should expect at security?',
                phraseVoice:
                    '/s3/situation-synthesis/1a9976f8-0e5e-412f-8ed1-c47a39a5e1ac.mp3',
                offset: 20,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Could you explain what I should expect at security?',
                        sentenceVoice:
                            '/s3/situation-synthesis/7332dd08-5008-4376-8b08-a53d95c95afb.mp3',
                    },
                ],
                translate: 'Pode explicar-me o que devo esperar da segurança?',
            },
            {
                id: '577195c5-dc69-4681-b102-c7ee1bfab729',
                phrase: 'Certainly. You might need to remove your shoes, belt, and any metal items. Laptops and liquids must be placed in separate bins.',
                phraseVoice:
                    '/s3/situation-synthesis/1547e8d2-4b74-4593-bb96-d4fbc8fe420f.mp3',
                offset: 21,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Com certeza. Poderá ter de retirar os sapatos, o cinto e quaisquer objectos metálicos. Os computadores portáteis e os líquidos devem ser colocados em caixotes separados.',
            },
            {
                id: 'd17e863b-3e9a-4fcf-9ee9-1bfdf3b1cddf',
                phrase: 'That sounds straightforward. Is there anything else I should be aware of?',
                phraseVoice:
                    '/s3/situation-synthesis/a5b57de1-4d93-44f8-86c3-bba4d08c75c4.mp3',
                offset: 23,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'That sounds straightforward.',
                        sentenceVoice:
                            '/s3/situation-synthesis/fe1a5563-25aa-45aa-ae12-ec91052d7a5e.mp3',
                    },
                    {
                        text: 'Is there anything else I should be aware of?',
                        sentenceVoice:
                            '/s3/situation-synthesis/0d606640-a4eb-453b-b59a-4d4ea13493c8.mp3',
                    },
                ],
                translate:
                    'Isso parece-me simples. Há mais alguma coisa de que eu deva ter conhecimento?',
            },
            {
                id: 'f0b750eb-7a19-41cc-b46a-a159061aa269',
                phrase: 'You might want to arrive at the airport at least two hours before your flight for domestic travel, and three hours for international flights.',
                phraseVoice:
                    '/s3/situation-synthesis/8afd9a72-cd04-45c3-b505-8bd93cac1b52.mp3',
                offset: 24,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'É aconselhável chegar ao aeroporto pelo menos duas horas antes do voo, no caso dos voos domésticos, e três horas no caso dos voos internacionais.',
            },
            {
                id: '14f90ad6-ac2c-477f-9253-9b95a7fdaa22',
                phrase: "That's helpful. Once I'm through security, what should I do while waiting for my flight?",
                phraseVoice:
                    '/s3/situation-synthesis/42191014-4869-485f-8dfb-08d63c9bd999.mp3',
                offset: 26,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's helpful.",
                        sentenceVoice:
                            '/s3/situation-synthesis/0f27e95c-e386-40bb-9547-fbde8eaea0e3.mp3',
                    },
                    {
                        text: "Once I'm through security, what should I do while waiting for my flight?",
                        sentenceVoice:
                            '/s3/situation-synthesis/6d23d2a0-9963-4049-b4f8-e7d88b4fec44.mp3',
                    },
                ],
                translate:
                    'Isso é útil. Depois de passar pela segurança, o que devo fazer enquanto espero pelo meu voo?',
            },
            {
                id: 'dc1e3bc2-258c-4683-9485-a78a81b05b29',
                phrase: 'You could explore the airport shops or grab a bite to eat. Just keep an eye on the departure screens for any updates on your flight.',
                phraseVoice:
                    '/s3/situation-synthesis/5cef58f0-9ebd-4f14-9fe7-c76647d34df3.mp3',
                offset: 27,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Pode explorar as lojas do aeroporto ou comer qualquer coisa. Basta estar atento aos ecrãs de partida para saber se há novidades sobre o seu voo.',
            },
            {
                id: '65465e60-02a8-40d2-8293-53042edd3d0c',
                phrase: 'If my flight is delayed, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/53c8f359-cadc-43b5-a43e-4940550a28c5.mp3',
                offset: 29,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'If my flight is delayed, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/8fc4dc38-1048-4fa0-830e-634cda3feba1.mp3',
                    },
                ],
                translate:
                    'Se o meu voo estiver atrasado, quais são as minhas opções?',
            },
            {
                id: '96925634-2765-4a61-9eb6-a594d8041818',
                phrase: 'You should check with your airline. They might offer rebooking options or vouchers, depending on the situation.',
                phraseVoice:
                    '/s3/situation-synthesis/1ab490b7-277d-432e-85f6-7eb0e598b562.mp3',
                offset: 30,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Deve informar-se junto da sua companhia aérea. Dependendo da situação, a companhia poderá oferecer opções de remarcação ou vales.',
            },
            {
                id: 'c5049575-e75e-4914-bf15-a4ffa15cec57',
                phrase: 'And if I miss my flight, what should I do?',
                phraseVoice:
                    '/s3/situation-synthesis/94396909-0ce4-4089-b6a7-cd0b56cb0daa.mp3',
                offset: 32,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I miss my flight, what should I do?',
                        sentenceVoice:
                            '/s3/situation-synthesis/d4809b1a-400e-4d62-9022-c5192e593df9.mp3',
                    },
                ],
                translate: 'E se perder o meu voo, o que devo fazer?',
            },
            {
                id: '409fd2ff-53fb-4327-b8a3-7fef7e0373bb',
                phrase: 'You must contact your airline immediately. They could help you find the next available flight.',
                phraseVoice:
                    '/s3/situation-synthesis/c77866b4-f915-4f29-bc8c-856d7d509338.mp3',
                offset: 33,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Deve contactar imediatamente a sua companhia aérea. Esta pode ajudá-lo a encontrar o próximo voo disponível.',
            },
            {
                id: '0220dc13-2dca-4803-b927-bb6e47636a9f',
                phrase: 'Thank you for all this information. Could you also tell me about customs procedures for international flights?',
                phraseVoice:
                    '/s3/situation-synthesis/a0bf0682-bf12-4813-912c-e117ab0f76d0.mp3',
                offset: 35,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you for all this information.',
                        sentenceVoice:
                            '/s3/situation-synthesis/25dfbeb1-f870-4a2b-8278-f462e2bb2974.mp3',
                    },
                    {
                        text: 'Could you also tell me about customs procedures for international flights?',
                        sentenceVoice:
                            '/s3/situation-synthesis/57bab119-aa02-4c1f-98c4-95131e1c71d6.mp3',
                    },
                ],
                translate:
                    'Obrigado por toda esta informação. Pode também informar-me sobre os procedimentos aduaneiros nos voos internacionais?',
            },
            {
                id: 'e9e7c7a5-b572-462f-8a73-69ea91d7f98f',
                phrase: "Of course. When you arrive at your destination, you must go through customs. You might need to declare any goods you're bringing into the country.",
                phraseVoice:
                    '/s3/situation-synthesis/fd71aeab-ea73-473c-b8a3-da3389d654f3.mp3',
                offset: 36,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Claro que sim. Quando chega ao seu destino, tem de passar pela alfândega. Poderá ter de declarar os bens que traz para o país.',
            },
            {
                id: 'ba09a2f0-ddb6-488e-a627-30eb8eddf7b8',
                phrase: 'What kind of items might I need to declare?',
                phraseVoice:
                    '/s3/situation-synthesis/adee4c38-bac6-48b4-b20c-07ca2a620340.mp3',
                offset: 38,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'What kind of items might I need to declare?',
                        sentenceVoice:
                            '/s3/situation-synthesis/6f1960f4-c524-4fa0-ac00-c58036678057.mp3',
                    },
                ],
                translate: 'Que tipo de objectos posso ter de declarar?',
            },
            {
                id: 'fbe3a3aa-3848-4509-8d2f-aa32794fd631',
                phrase: "Typically, you must declare items like food, plants, or large amounts of currency. It's best to check the specific regulations of the country you're visiting.",
                phraseVoice:
                    '/s3/situation-synthesis/d4c2133d-3a48-42a0-bc2f-2ebab66c76e4.mp3',
                offset: 39,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Normalmente, é necessário declarar objectos como alimentos, plantas ou grandes quantidades de moeda. É melhor verificar os regulamentos específicos do país que está a visitar.',
            },
            {
                id: '4375c6e8-ee45-4f09-bd92-222d09d7f83b',
                phrase: 'I understand. Is there anything else I should prepare for an international trip?',
                phraseVoice:
                    '/s3/situation-synthesis/d2441897-a0a3-4569-bd18-6bc826137271.mp3',
                offset: 41,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I understand.',
                        sentenceVoice:
                            '/s3/situation-synthesis/c7ad48bd-12fa-43c6-b969-53d57ec73669.mp3',
                    },
                    {
                        text: 'Is there anything else I should prepare for an international trip?',
                        sentenceVoice:
                            '/s3/situation-synthesis/51718984-df5b-4b6d-bb6f-ace9f1f6a87b.mp3',
                    },
                ],
                translate:
                    'Eu compreendo. Há mais alguma coisa que eu deva preparar para uma viagem internacional?',
            },
            {
                id: '4d097be5-6b8b-4d71-90e6-2bb1f7d954b3',
                phrase: 'You should ensure your passport is valid for at least six months beyond your travel dates. Some countries might also require a visa.',
                phraseVoice:
                    '/s3/situation-synthesis/1627908b-c835-49a5-aa9c-1f45c75852a8.mp3',
                offset: 42,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'O passaporte deve ter uma validade de pelo menos seis meses para além da data da viagem. Alguns países podem também exigir um visto.',
            },
            {
                id: 'da550395-30a3-461a-9f91-4b566165ef6b',
                phrase: "That's a good point. How can I find out if I need a visa?",
                phraseVoice:
                    '/s3/situation-synthesis/a6c1ef9e-e1e6-44b7-8edf-60cc74d74a57.mp3',
                offset: 44,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's a good point.",
                        sentenceVoice:
                            '/s3/situation-synthesis/53d0f7a8-768c-4e66-9a32-123d5438db30.mp3',
                    },
                    {
                        text: 'How can I find out if I need a visa?',
                        sentenceVoice:
                            '/s3/situation-synthesis/14941153-b56e-400d-b795-0e99e131b9d3.mp3',
                    },
                ],
                translate:
                    'É um bom ponto de vista. Como posso saber se preciso de um visto?',
            },
            {
                id: '6171856e-000a-48dd-90be-a4eacc54725e',
                phrase: "You could check the embassy website of the country you're visiting. They usually have detailed information on visa requirements.",
                phraseVoice:
                    '/s3/situation-synthesis/c457c884-9037-4dc8-aab1-e3708cdd6008.mp3',
                offset: 45,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Pode consultar o sítio Web da embaixada do país que vai visitar. Normalmente, estas têm informações pormenorizadas sobre os requisitos em matéria de vistos.',
            },
            {
                id: '63e60430-b154-4cae-bc05-4f1c2a7d2b85',
                phrase: 'Thank you so much for your help. I feel much more prepared now.',
                phraseVoice:
                    '/s3/situation-synthesis/0d8f1298-7156-4705-aaac-4472f7344727.mp3',
                offset: 47,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you so much for your help.',
                        sentenceVoice:
                            '/s3/situation-synthesis/b708b800-3911-40e7-8b0e-e011310221f1.mp3',
                    },
                    {
                        text: 'I feel much more prepared now.',
                        sentenceVoice:
                            '/s3/situation-synthesis/e1926edf-35ca-44a6-8522-f6cee01ce888.mp3',
                    },
                ],
                translate:
                    'Muito obrigado pela vossa ajuda. Agora sinto-me muito mais preparado.',
            },
            {
                id: 'cfc99c7c-a282-479e-b9ca-f29af59dd58d',
                phrase: "You're welcome! Traveling can be a bit overwhelming, but with a little preparation, it can also be a lot of fun.",
                phraseVoice:
                    '/s3/situation-synthesis/5cd82eec-04fc-476e-80bd-668fd772802e.mp3',
                offset: 48,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Não tem de quê! Viajar pode ser um pouco complicado, mas com um pouco de preparação, também pode ser muito divertido.',
            },
            {
                id: '07b1fada-cbfa-4d39-8750-d76939877c7f',
                phrase: 'I agree. One last question',
                phraseVoice:
                    '/s3/situation-synthesis/3f7dab06-3da3-41f6-90a3-5dcce292b738.mp3',
                offset: 50,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I agree.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2daab0de-33ee-43ec-8359-1b068ee4dc82.mp3',
                    },
                    {
                        text: 'One last question',
                        sentenceVoice:
                            '/s3/situation-synthesis/6618564a-26af-47d6-855c-f761e02b59e5.mp3',
                    },
                ],
                translate: 'Concordo. Uma última pergunta',
            },
            {
                id: '2985b5fc-f625-4ebd-a66d-1a416e6c7fec',
                phrase: 'Absolutely! You might find apps like TripIt for organizing your itinerary, or Google Translate for language assistance, very helpful.',
                phraseVoice:
                    '/s3/situation-synthesis/f500d380-34ac-4f93-94e5-f4443949ca59.mp3',
                offset: 51,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Sem dúvida! Poderá achar muito úteis aplicações como o TripIt, para organizar o seu itinerário, ou o Google Translate, para assistência linguística.',
            },
            {
                id: '0f7384a3-2935-47f7-ad0c-cea5c8edc1c7',
                phrase: "Those sound great. I'll definitely check them out.",
                phraseVoice:
                    '/s3/situation-synthesis/a1be059d-cfa7-4e3c-8e57-669535e053df.mp3',
                offset: 53,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Those sound great.',
                        sentenceVoice:
                            '/s3/situation-synthesis/943bfbec-72d5-48b3-9e81-955de9c5482e.mp3',
                    },
                    {
                        text: "I'll definitely check them out.",
                        sentenceVoice:
                            '/s3/situation-synthesis/91c97804-6704-4cbb-aaaf-2df78663d41b.mp3',
                    },
                ],
                translate:
                    'Parecem-me óptimos. Vou mesmo dar-lhes uma vista de olhos.',
            },
            {
                id: 'e48130d8-f71d-4351-b7cf-afbb43d55e9c',
                phrase: "I'm glad I could help. Have a safe and enjoyable trip!",
                phraseVoice:
                    '/s3/situation-synthesis/74c82984-79e2-49e8-9eab-ca2a7542883c.mp3',
                offset: 54,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate: 'Fico feliz por ter ajudado. Boa viagem!',
            },
            {
                id: '41274da6-7b74-49fd-b03f-c2194f491bf6',
                phrase: 'Thank you! I appreciate all your advice.',
                phraseVoice:
                    '/s3/situation-synthesis/be94bf6e-84f0-41e1-951e-fdd76df2089b.mp3',
                offset: 56,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you!',
                        sentenceVoice:
                            '/s3/situation-synthesis/1734d2a5-a3c2-4390-ae2a-55feb16c6e9d.mp3',
                    },
                    {
                        text: 'I appreciate all your advice.',
                        sentenceVoice:
                            '/s3/situation-synthesis/ba3924d4-1123-43af-967a-d2c469706592.mp3',
                    },
                ],
                translate: 'Obrigado! Agradeço todos os vossos conselhos.',
            },
            {
                id: 'cd13cf54-02f6-45d6-b6da-4742bc3e083e',
                phrase: 'Anytime! If you have any more questions, feel free to ask. Safe travels!',
                phraseVoice:
                    '/s3/situation-synthesis/bb470f1a-73b9-478f-bb7b-d39fe82df1cb.mp3',
                offset: 57,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'A qualquer altura! Se tiveres mais perguntas, não hesites em perguntar. Boa viagem!',
            },
        ],
    },
    fr: {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'User Generated',
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
        statements: [
            {
                id: 'dab5b7a7-0053-4650-8cac-0f1fc78bb23d',
                phrase: 'Excuse me, could you help me with some information about the airport procedures?',
                phraseVoice:
                    '/s3/situation-synthesis/8500d8ce-1f4d-482e-83cc-b99c3e070f60.mp3',
                offset: 2,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Excuse me, could you help me with some information about the airport procedures?',
                        sentenceVoice:
                            '/s3/situation-synthesis/906d046f-32fe-442b-a59c-e3712c8fb57d.mp3',
                    },
                ],
                translate:
                    "Excusez-moi, pourriez-vous m'aider à obtenir des informations sur les procédures de l'aéroport ?",
            },
            {
                id: 'a26c2632-26ef-425b-aac1-69413fb94df0',
                phrase: "Of course! I'd be happy to help. What do you need to know?",
                phraseVoice:
                    '/s3/situation-synthesis/659a4380-6dd0-47b1-8338-fc6790ec7804.mp3',
                offset: 3,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Bien sûr ! Je serais ravie de vous aider. Qu'avez-vous besoin de savoir ?",
            },
            {
                id: '558c8341-10c7-46ce-9a36-6be8fbdc80f3',
                phrase: "Well, I might need some guidance on the check-in process. I haven't flown in a while.",
                phraseVoice:
                    '/s3/situation-synthesis/14816793-f43c-44dd-aa55-c10eec0a545c.mp3',
                offset: 5,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Well, I might need some guidance on the check-in process.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2b8c62a5-d216-4b6c-a924-485fcf0d59f8.mp3',
                    },
                    {
                        text: "I haven't flown in a while.",
                        sentenceVoice:
                            '/s3/situation-synthesis/10a9e42d-9079-4e19-bf53-7eeb28d1194e.mp3',
                    },
                ],
                translate:
                    "Je pourrais avoir besoin de conseils sur la procédure d'enregistrement. Je n'ai pas pris l'avion depuis longtemps.",
            },
            {
                id: 'b9f10d31-c914-4389-9932-3efbbddfa396',
                phrase: 'No problem. You can check in online before you arrive at the airport, which could save you some time.',
                phraseVoice:
                    '/s3/situation-synthesis/b561f161-2bc3-4722-a6ff-5216a25ec35d.mp3',
                offset: 6,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Pas de problème. Vous pouvez vous enregistrer en ligne avant d'arriver à l'aéroport, ce qui peut vous faire gagner du temps.",
            },
            {
                id: '87eea3fe-7b73-41d6-8fa9-ba3f77e279e0',
                phrase: "That's good to know. But if I can't check in online, what should I do?",
                phraseVoice:
                    '/s3/situation-synthesis/a20fe6fd-c1ac-4e3a-a0ad-2a499d87a815.mp3',
                offset: 8,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's good to know.",
                        sentenceVoice:
                            '/s3/situation-synthesis/46b707b4-70b3-47e1-9905-ea5e06b871a8.mp3',
                    },
                    {
                        text: "But if I can't check in online, what should I do?",
                        sentenceVoice:
                            '/s3/situation-synthesis/d767c41d-bce0-40be-abc4-be1accae9ebb.mp3',
                    },
                ],
                translate:
                    "C'est bon à savoir. Mais si je ne peux pas m'enregistrer en ligne, que dois-je faire ?",
            },
            {
                id: '6482a404-f4a5-4d37-a5db-f183f24f0de2',
                phrase: "You must go to the airline's check-in counter. They can assist you with your boarding pass and any luggage you might have.",
                phraseVoice:
                    '/s3/situation-synthesis/b26a5def-00f3-4572-aa2e-4d0375b8fb8d.mp3',
                offset: 9,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Vous devez vous rendre au comptoir d'enregistrement de la compagnie aérienne. Ils vous aideront à obtenir votre carte d'embarquement et vos éventuels bagages.",
            },
            {
                id: 'd65fbb2c-2737-4cc3-b08a-d46d96e63c3c',
                phrase: 'Speaking of luggage, could you tell me about the baggage policies?',
                phraseVoice:
                    '/s3/situation-synthesis/cdd4a19e-9edd-417f-9781-5bae69478abd.mp3',
                offset: 11,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Speaking of luggage, could you tell me about the baggage policies?',
                        sentenceVoice:
                            '/s3/situation-synthesis/fa7fde62-83af-46ea-aaad-4e1d248f8413.mp3',
                    },
                ],
                translate:
                    "En ce qui concerne les bagages, pourriez-vous m'informer sur la politique en la matière ?",
            },
            {
                id: 'df6ae3a7-a92c-4ca9-b50e-6761b97a6311',
                phrase: "Sure! You should check your airline's specific baggage policy, as it can vary. Generally, you might be allowed one carry-on and one personal item.",
                phraseVoice:
                    '/s3/situation-synthesis/2ec664aa-1aeb-491a-aefe-ad25b598ecca.mp3',
                offset: 12,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Bien sûr ! Vous devez vérifier la politique de votre compagnie aérienne en matière de bagages, car elle peut varier. En général, vous avez droit à un bagage à main et à un article personnel.',
            },
            {
                id: 'b7cfcc4a-1114-41f0-b4c6-8e06fb98a587',
                phrase: 'And if I have more luggage, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/694ec3cc-0726-4a8f-be0a-96d90217879e.mp3',
                offset: 14,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I have more luggage, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/82f419d2-bbe1-4aca-95ad-c0ecd81ca430.mp3',
                    },
                ],
                translate:
                    "Et si j'ai plus de bagages, quelles sont mes options ?",
            },
            {
                id: '17c8728f-0bc8-4845-aab6-2c09a02d4e4f',
                phrase: "You could check additional bags, but there might be extra fees. It's best to weigh your bags at home to avoid surprises.",
                phraseVoice:
                    '/s3/situation-synthesis/14e1868c-0dc3-4b77-b6b3-725d709564d2.mp3',
                offset: 15,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Vous pouvez enregistrer des bagages supplémentaires, mais il peut y avoir des frais supplémentaires. Il est préférable de peser vos bagages à la maison pour éviter les surprises.',
            },
            {
                id: 'fddae7f1-bfae-493d-8ed8-119f0250af06',
                phrase: "I see. Once I'm checked in, what should I do next?",
                phraseVoice:
                    '/s3/situation-synthesis/0b5c8759-f0a7-43c3-ad16-5b19d5754d9e.mp3',
                offset: 17,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I see.',
                        sentenceVoice:
                            '/s3/situation-synthesis/f8d7576d-1187-4e03-9142-727f11af5d51.mp3',
                    },
                    {
                        text: "Once I'm checked in, what should I do next?",
                        sentenceVoice:
                            '/s3/situation-synthesis/41969f55-1b5b-4d2a-8800-584c0fd01253.mp3',
                    },
                ],
                translate:
                    "Je vois. Une fois que j'ai été enregistré, que dois-je faire ?",
            },
            {
                id: '017feee8-4d99-4f27-9533-684ba4a5fb17',
                phrase: 'After check-in, you must go through security. Make sure you have your boarding pass and ID ready.',
                phraseVoice:
                    '/s3/situation-synthesis/d137d9f7-2e4d-438b-ae74-210a8018224b.mp3',
                offset: 18,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Après l'enregistrement, vous devez passer les contrôles de sécurité. Assurez-vous d'avoir votre carte d'embarquement et votre pièce d'identité à portée de main.",
            },
            {
                id: 'f40d7aa7-4623-4b82-a61b-702d2a887356',
                phrase: 'Could you explain what I should expect at security?',
                phraseVoice:
                    '/s3/situation-synthesis/1a9976f8-0e5e-412f-8ed1-c47a39a5e1ac.mp3',
                offset: 20,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Could you explain what I should expect at security?',
                        sentenceVoice:
                            '/s3/situation-synthesis/7332dd08-5008-4376-8b08-a53d95c95afb.mp3',
                    },
                ],
                translate:
                    "Pouvez-vous m'expliquer ce à quoi je dois m'attendre en matière de sécurité ?",
            },
            {
                id: '577195c5-dc69-4681-b102-c7ee1bfab729',
                phrase: 'Certainly. You might need to remove your shoes, belt, and any metal items. Laptops and liquids must be placed in separate bins.',
                phraseVoice:
                    '/s3/situation-synthesis/1547e8d2-4b74-4593-bb96-d4fbc8fe420f.mp3',
                offset: 21,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Il est certain que vous devrez enlever vos chaussures, votre ceinture et tout objet métallique. Vous devrez peut-être retirer vos chaussures, votre ceinture et tout objet métallique. Les ordinateurs portables et les liquides doivent être placés dans des bacs séparés.',
            },
            {
                id: 'd17e863b-3e9a-4fcf-9ee9-1bfdf3b1cddf',
                phrase: 'That sounds straightforward. Is there anything else I should be aware of?',
                phraseVoice:
                    '/s3/situation-synthesis/a5b57de1-4d93-44f8-86c3-bba4d08c75c4.mp3',
                offset: 23,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'That sounds straightforward.',
                        sentenceVoice:
                            '/s3/situation-synthesis/fe1a5563-25aa-45aa-ae12-ec91052d7a5e.mp3',
                    },
                    {
                        text: 'Is there anything else I should be aware of?',
                        sentenceVoice:
                            '/s3/situation-synthesis/0d606640-a4eb-453b-b59a-4d4ea13493c8.mp3',
                    },
                ],
                translate:
                    "Cela semble simple. Y a-t-il d'autres éléments dont je devrais tenir compte ?",
            },
            {
                id: 'f0b750eb-7a19-41cc-b46a-a159061aa269',
                phrase: 'You might want to arrive at the airport at least two hours before your flight for domestic travel, and three hours for international flights.',
                phraseVoice:
                    '/s3/situation-synthesis/8afd9a72-cd04-45c3-b505-8bd93cac1b52.mp3',
                offset: 24,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Il est conseillé d'arriver à l'aéroport au moins deux heures avant le départ pour les vols intérieurs, et trois heures pour les vols internationaux.",
            },
            {
                id: '14f90ad6-ac2c-477f-9253-9b95a7fdaa22',
                phrase: "That's helpful. Once I'm through security, what should I do while waiting for my flight?",
                phraseVoice:
                    '/s3/situation-synthesis/42191014-4869-485f-8dfb-08d63c9bd999.mp3',
                offset: 26,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's helpful.",
                        sentenceVoice:
                            '/s3/situation-synthesis/0f27e95c-e386-40bb-9547-fbde8eaea0e3.mp3',
                    },
                    {
                        text: "Once I'm through security, what should I do while waiting for my flight?",
                        sentenceVoice:
                            '/s3/situation-synthesis/6d23d2a0-9963-4049-b4f8-e7d88b4fec44.mp3',
                    },
                ],
                translate:
                    "C'est utile. Une fois les contrôles de sécurité passés, que dois-je faire en attendant mon vol ?",
            },
            {
                id: 'dc1e3bc2-258c-4683-9485-a78a81b05b29',
                phrase: 'You could explore the airport shops or grab a bite to eat. Just keep an eye on the departure screens for any updates on your flight.',
                phraseVoice:
                    '/s3/situation-synthesis/5cef58f0-9ebd-4f14-9fe7-c76647d34df3.mp3',
                offset: 27,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Vous pouvez explorer les boutiques de l'aéroport ou manger un morceau. Gardez un œil sur les écrans d'affichage des départs pour vous tenir au courant de l'évolution de votre vol.",
            },
            {
                id: '65465e60-02a8-40d2-8293-53042edd3d0c',
                phrase: 'If my flight is delayed, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/53c8f359-cadc-43b5-a43e-4940550a28c5.mp3',
                offset: 29,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'If my flight is delayed, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/8fc4dc38-1048-4fa0-830e-634cda3feba1.mp3',
                    },
                ],
                translate: 'Si mon vol est retardé, quelles sont mes options ?',
            },
            {
                id: '96925634-2765-4a61-9eb6-a594d8041818',
                phrase: 'You should check with your airline. They might offer rebooking options or vouchers, depending on the situation.',
                phraseVoice:
                    '/s3/situation-synthesis/1ab490b7-277d-432e-85f6-7eb0e598b562.mp3',
                offset: 30,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Renseignez-vous auprès de votre compagnie aérienne. Elle peut proposer des options de rebooking ou des bons d'achat, en fonction de la situation.",
            },
            {
                id: 'c5049575-e75e-4914-bf15-a4ffa15cec57',
                phrase: 'And if I miss my flight, what should I do?',
                phraseVoice:
                    '/s3/situation-synthesis/94396909-0ce4-4089-b6a7-cd0b56cb0daa.mp3',
                offset: 32,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I miss my flight, what should I do?',
                        sentenceVoice:
                            '/s3/situation-synthesis/d4809b1a-400e-4d62-9022-c5192e593df9.mp3',
                    },
                ],
                translate: 'Et si je rate mon vol, que dois-je faire ?',
            },
            {
                id: '409fd2ff-53fb-4327-b8a3-7fef7e0373bb',
                phrase: 'You must contact your airline immediately. They could help you find the next available flight.',
                phraseVoice:
                    '/s3/situation-synthesis/c77866b4-f915-4f29-bc8c-856d7d509338.mp3',
                offset: 33,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Vous devez immédiatement contacter votre compagnie aérienne. Elle pourra vous aider à trouver le prochain vol disponible.',
            },
            {
                id: '0220dc13-2dca-4803-b927-bb6e47636a9f',
                phrase: 'Thank you for all this information. Could you also tell me about customs procedures for international flights?',
                phraseVoice:
                    '/s3/situation-synthesis/a0bf0682-bf12-4813-912c-e117ab0f76d0.mp3',
                offset: 35,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you for all this information.',
                        sentenceVoice:
                            '/s3/situation-synthesis/25dfbeb1-f870-4a2b-8278-f462e2bb2974.mp3',
                    },
                    {
                        text: 'Could you also tell me about customs procedures for international flights?',
                        sentenceVoice:
                            '/s3/situation-synthesis/57bab119-aa02-4c1f-98c4-95131e1c71d6.mp3',
                    },
                ],
                translate:
                    'Merci pour toutes ces informations. Pouvez-vous également me renseigner sur les procédures douanières pour les vols internationaux ?',
            },
            {
                id: 'e9e7c7a5-b572-462f-8a73-69ea91d7f98f',
                phrase: "Of course. When you arrive at your destination, you must go through customs. You might need to declare any goods you're bringing into the country.",
                phraseVoice:
                    '/s3/situation-synthesis/fd71aeab-ea73-473c-b8a3-da3389d654f3.mp3',
                offset: 36,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Bien sûr. Lorsque vous arrivez à destination, vous devez passer la douane. Il se peut que vous deviez déclarer les marchandises que vous apportez dans le pays.',
            },
            {
                id: 'ba09a2f0-ddb6-488e-a627-30eb8eddf7b8',
                phrase: 'What kind of items might I need to declare?',
                phraseVoice:
                    '/s3/situation-synthesis/adee4c38-bac6-48b4-b20c-07ca2a620340.mp3',
                offset: 38,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'What kind of items might I need to declare?',
                        sentenceVoice:
                            '/s3/situation-synthesis/6f1960f4-c524-4fa0-ac00-c58036678057.mp3',
                    },
                ],
                translate: 'Quels sont les éléments que je dois déclarer ?',
            },
            {
                id: 'fbe3a3aa-3848-4509-8d2f-aa32794fd631',
                phrase: "Typically, you must declare items like food, plants, or large amounts of currency. It's best to check the specific regulations of the country you're visiting.",
                phraseVoice:
                    '/s3/situation-synthesis/d4c2133d-3a48-42a0-bc2f-2ebab66c76e4.mp3',
                offset: 39,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "En règle générale, vous devez déclarer des articles tels que de la nourriture, des plantes ou de grosses sommes d'argent. Il est préférable de vérifier les réglementations spécifiques du pays que vous visitez.",
            },
            {
                id: '4375c6e8-ee45-4f09-bd92-222d09d7f83b',
                phrase: 'I understand. Is there anything else I should prepare for an international trip?',
                phraseVoice:
                    '/s3/situation-synthesis/d2441897-a0a3-4569-bd18-6bc826137271.mp3',
                offset: 41,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I understand.',
                        sentenceVoice:
                            '/s3/situation-synthesis/c7ad48bd-12fa-43c6-b969-53d57ec73669.mp3',
                    },
                    {
                        text: 'Is there anything else I should prepare for an international trip?',
                        sentenceVoice:
                            '/s3/situation-synthesis/51718984-df5b-4b6d-bb6f-ace9f1f6a87b.mp3',
                    },
                ],
                translate:
                    "Je comprends. Y a-t-il d'autres choses à préparer pour un voyage international ?",
            },
            {
                id: '4d097be5-6b8b-4d71-90e6-2bb1f7d954b3',
                phrase: 'You should ensure your passport is valid for at least six months beyond your travel dates. Some countries might also require a visa.',
                phraseVoice:
                    '/s3/situation-synthesis/1627908b-c835-49a5-aa9c-1f45c75852a8.mp3',
                offset: 42,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Vous devez vous assurer que votre passeport est valable au moins six mois après la date de votre voyage. Certains pays peuvent également exiger un visa.',
            },
            {
                id: 'da550395-30a3-461a-9f91-4b566165ef6b',
                phrase: "That's a good point. How can I find out if I need a visa?",
                phraseVoice:
                    '/s3/situation-synthesis/a6c1ef9e-e1e6-44b7-8edf-60cc74d74a57.mp3',
                offset: 44,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's a good point.",
                        sentenceVoice:
                            '/s3/situation-synthesis/53d0f7a8-768c-4e66-9a32-123d5438db30.mp3',
                    },
                    {
                        text: 'How can I find out if I need a visa?',
                        sentenceVoice:
                            '/s3/situation-synthesis/14941153-b56e-400d-b795-0e99e131b9d3.mp3',
                    },
                ],
                translate:
                    "C'est un bon point. Comment savoir si j'ai besoin d'un visa ?",
            },
            {
                id: '6171856e-000a-48dd-90be-a4eacc54725e',
                phrase: "You could check the embassy website of the country you're visiting. They usually have detailed information on visa requirements.",
                phraseVoice:
                    '/s3/situation-synthesis/c457c884-9037-4dc8-aab1-e3708cdd6008.mp3',
                offset: 45,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Vous pouvez consulter le site web de l'ambassade du pays que vous visitez. Vous y trouverez généralement des informations détaillées sur les conditions d'obtention d'un visa.",
            },
            {
                id: '63e60430-b154-4cae-bc05-4f1c2a7d2b85',
                phrase: 'Thank you so much for your help. I feel much more prepared now.',
                phraseVoice:
                    '/s3/situation-synthesis/0d8f1298-7156-4705-aaac-4472f7344727.mp3',
                offset: 47,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you so much for your help.',
                        sentenceVoice:
                            '/s3/situation-synthesis/b708b800-3911-40e7-8b0e-e011310221f1.mp3',
                    },
                    {
                        text: 'I feel much more prepared now.',
                        sentenceVoice:
                            '/s3/situation-synthesis/e1926edf-35ca-44a6-8522-f6cee01ce888.mp3',
                    },
                ],
                translate:
                    'Merci beaucoup pour votre aide. Je me sens beaucoup mieux préparée maintenant.',
            },
            {
                id: 'cfc99c7c-a282-479e-b9ca-f29af59dd58d',
                phrase: "You're welcome! Traveling can be a bit overwhelming, but with a little preparation, it can also be a lot of fun.",
                phraseVoice:
                    '/s3/situation-synthesis/5cd82eec-04fc-476e-80bd-668fd772802e.mp3',
                offset: 48,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Vous êtes les bienvenus ! Voyager peut être un peu accablant, mais avec un peu de préparation, cela peut aussi être très amusant.',
            },
            {
                id: '07b1fada-cbfa-4d39-8750-d76939877c7f',
                phrase: 'I agree. One last question',
                phraseVoice:
                    '/s3/situation-synthesis/3f7dab06-3da3-41f6-90a3-5dcce292b738.mp3',
                offset: 50,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I agree.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2daab0de-33ee-43ec-8359-1b068ee4dc82.mp3',
                    },
                    {
                        text: 'One last question',
                        sentenceVoice:
                            '/s3/situation-synthesis/6618564a-26af-47d6-855c-f761e02b59e5.mp3',
                    },
                ],
                translate: "Je suis d'accord. Une dernière question",
            },
            {
                id: '2985b5fc-f625-4ebd-a66d-1a416e6c7fec',
                phrase: 'Absolutely! You might find apps like TripIt for organizing your itinerary, or Google Translate for language assistance, very helpful.',
                phraseVoice:
                    '/s3/situation-synthesis/f500d380-34ac-4f93-94e5-f4443949ca59.mp3',
                offset: 51,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Absolument ! Des applications telles que TripIt pour organiser votre itinéraire, ou Google Translate pour l'assistance linguistique, peuvent vous être très utiles.",
            },
            {
                id: '0f7384a3-2935-47f7-ad0c-cea5c8edc1c7',
                phrase: "Those sound great. I'll definitely check them out.",
                phraseVoice:
                    '/s3/situation-synthesis/a1be059d-cfa7-4e3c-8e57-669535e053df.mp3',
                offset: 53,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Those sound great.',
                        sentenceVoice:
                            '/s3/situation-synthesis/943bfbec-72d5-48b3-9e81-955de9c5482e.mp3',
                    },
                    {
                        text: "I'll definitely check them out.",
                        sentenceVoice:
                            '/s3/situation-synthesis/91c97804-6704-4cbb-aaaf-2df78663d41b.mp3',
                    },
                ],
                translate:
                    "Ils ont l'air très bien. Je les vérifierai certainement.",
            },
            {
                id: 'e48130d8-f71d-4351-b7cf-afbb43d55e9c',
                phrase: "I'm glad I could help. Have a safe and enjoyable trip!",
                phraseVoice:
                    '/s3/situation-synthesis/74c82984-79e2-49e8-9eab-ca2a7542883c.mp3',
                offset: 54,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "Je suis heureux d'avoir pu vous aider. Je vous souhaite un bon voyage en toute sécurité !",
            },
            {
                id: '41274da6-7b74-49fd-b03f-c2194f491bf6',
                phrase: 'Thank you! I appreciate all your advice.',
                phraseVoice:
                    '/s3/situation-synthesis/be94bf6e-84f0-41e1-951e-fdd76df2089b.mp3',
                offset: 56,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you!',
                        sentenceVoice:
                            '/s3/situation-synthesis/1734d2a5-a3c2-4390-ae2a-55feb16c6e9d.mp3',
                    },
                    {
                        text: 'I appreciate all your advice.',
                        sentenceVoice:
                            '/s3/situation-synthesis/ba3924d4-1123-43af-967a-d2c469706592.mp3',
                    },
                ],
                translate:
                    "Merci de votre attention ! J'apprécie tous vos conseils.",
            },
            {
                id: 'cd13cf54-02f6-45d6-b6da-4742bc3e083e',
                phrase: 'Anytime! If you have any more questions, feel free to ask. Safe travels!',
                phraseVoice:
                    '/s3/situation-synthesis/bb470f1a-73b9-478f-bb7b-d39fe82df1cb.mp3',
                offset: 57,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    "A tout moment ! Si vous avez d'autres questions, n'hésitez pas à les poser. Bon voyage !",
            },
        ],
    },
    es: {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'User Generated',
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
        statements: [
            {
                id: 'dab5b7a7-0053-4650-8cac-0f1fc78bb23d',
                phrase: 'Excuse me, could you help me with some information about the airport procedures?',
                phraseVoice:
                    '/s3/situation-synthesis/8500d8ce-1f4d-482e-83cc-b99c3e070f60.mp3',
                offset: 2,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Excuse me, could you help me with some information about the airport procedures?',
                        sentenceVoice:
                            '/s3/situation-synthesis/906d046f-32fe-442b-a59c-e3712c8fb57d.mp3',
                    },
                ],
                translate:
                    'Disculpe, ¿podría ayudarme con información sobre los trámites aeroportuarios?',
            },
            {
                id: 'a26c2632-26ef-425b-aac1-69413fb94df0',
                phrase: "Of course! I'd be happy to help. What do you need to know?",
                phraseVoice:
                    '/s3/situation-synthesis/659a4380-6dd0-47b1-8338-fc6790ec7804.mp3',
                offset: 3,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Por supuesto. Estaré encantado de ayudarle. ¿Qué necesita saber?',
            },
            {
                id: '558c8341-10c7-46ce-9a36-6be8fbdc80f3',
                phrase: "Well, I might need some guidance on the check-in process. I haven't flown in a while.",
                phraseVoice:
                    '/s3/situation-synthesis/14816793-f43c-44dd-aa55-c10eec0a545c.mp3',
                offset: 5,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Well, I might need some guidance on the check-in process.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2b8c62a5-d216-4b6c-a924-485fcf0d59f8.mp3',
                    },
                    {
                        text: "I haven't flown in a while.",
                        sentenceVoice:
                            '/s3/situation-synthesis/10a9e42d-9079-4e19-bf53-7eeb28d1194e.mp3',
                    },
                ],
                translate:
                    'Bueno, puede que necesite orientación sobre el proceso de facturación. Hace tiempo que no vuelo.',
            },
            {
                id: 'b9f10d31-c914-4389-9932-3efbbddfa396',
                phrase: 'No problem. You can check in online before you arrive at the airport, which could save you some time.',
                phraseVoice:
                    '/s3/situation-synthesis/b561f161-2bc3-4722-a6ff-5216a25ec35d.mp3',
                offset: 6,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'No hay problema. Puede hacer el check-in online antes de llegar al aeropuerto, lo que le ahorrará tiempo.',
            },
            {
                id: '87eea3fe-7b73-41d6-8fa9-ba3f77e279e0',
                phrase: "That's good to know. But if I can't check in online, what should I do?",
                phraseVoice:
                    '/s3/situation-synthesis/a20fe6fd-c1ac-4e3a-a0ad-2a499d87a815.mp3',
                offset: 8,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's good to know.",
                        sentenceVoice:
                            '/s3/situation-synthesis/46b707b4-70b3-47e1-9905-ea5e06b871a8.mp3',
                    },
                    {
                        text: "But if I can't check in online, what should I do?",
                        sentenceVoice:
                            '/s3/situation-synthesis/d767c41d-bce0-40be-abc4-be1accae9ebb.mp3',
                    },
                ],
                translate:
                    'Es bueno saberlo. Pero si no puedo registrarme en línea, ¿qué debo hacer?',
            },
            {
                id: '6482a404-f4a5-4d37-a5db-f183f24f0de2',
                phrase: "You must go to the airline's check-in counter. They can assist you with your boarding pass and any luggage you might have.",
                phraseVoice:
                    '/s3/situation-synthesis/b26a5def-00f3-4572-aa2e-4d0375b8fb8d.mp3',
                offset: 9,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Debe dirigirse al mostrador de facturación de la compañía aérea. Allí podrán ayudarle con su tarjeta de embarque y el equipaje que lleve.',
            },
            {
                id: 'd65fbb2c-2737-4cc3-b08a-d46d96e63c3c',
                phrase: 'Speaking of luggage, could you tell me about the baggage policies?',
                phraseVoice:
                    '/s3/situation-synthesis/cdd4a19e-9edd-417f-9781-5bae69478abd.mp3',
                offset: 11,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Speaking of luggage, could you tell me about the baggage policies?',
                        sentenceVoice:
                            '/s3/situation-synthesis/fa7fde62-83af-46ea-aaad-4e1d248f8413.mp3',
                    },
                ],
                translate:
                    'Hablando de equipaje, ¿podría informarme sobre las políticas de equipaje?',
            },
            {
                id: 'df6ae3a7-a92c-4ca9-b50e-6761b97a6311',
                phrase: "Sure! You should check your airline's specific baggage policy, as it can vary. Generally, you might be allowed one carry-on and one personal item.",
                phraseVoice:
                    '/s3/situation-synthesis/2ec664aa-1aeb-491a-aefe-ad25b598ecca.mp3',
                offset: 12,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Claro que sí. Debe consultar la política específica de equipaje de su compañía aérea, ya que puede variar. Por lo general, se permite un equipaje de mano y un objeto personal.',
            },
            {
                id: 'b7cfcc4a-1114-41f0-b4c6-8e06fb98a587',
                phrase: 'And if I have more luggage, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/694ec3cc-0726-4a8f-be0a-96d90217879e.mp3',
                offset: 14,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I have more luggage, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/82f419d2-bbe1-4aca-95ad-c0ecd81ca430.mp3',
                    },
                ],
                translate: 'Y si tengo más equipaje, ¿qué opciones tengo?',
            },
            {
                id: '17c8728f-0bc8-4845-aab6-2c09a02d4e4f',
                phrase: "You could check additional bags, but there might be extra fees. It's best to weigh your bags at home to avoid surprises.",
                phraseVoice:
                    '/s3/situation-synthesis/14e1868c-0dc3-4b77-b6b3-725d709564d2.mp3',
                offset: 15,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Puedes facturar más maletas, pero puede haber recargos. Es mejor pesar las maletas en casa para evitar sorpresas.',
            },
            {
                id: 'fddae7f1-bfae-493d-8ed8-119f0250af06',
                phrase: "I see. Once I'm checked in, what should I do next?",
                phraseVoice:
                    '/s3/situation-synthesis/0b5c8759-f0a7-43c3-ad16-5b19d5754d9e.mp3',
                offset: 17,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I see.',
                        sentenceVoice:
                            '/s3/situation-synthesis/f8d7576d-1187-4e03-9142-727f11af5d51.mp3',
                    },
                    {
                        text: "Once I'm checked in, what should I do next?",
                        sentenceVoice:
                            '/s3/situation-synthesis/41969f55-1b5b-4d2a-8800-584c0fd01253.mp3',
                    },
                ],
                translate: 'Ya veo. Una vez registrado, ¿qué debo hacer?',
            },
            {
                id: '017feee8-4d99-4f27-9533-684ba4a5fb17',
                phrase: 'After check-in, you must go through security. Make sure you have your boarding pass and ID ready.',
                phraseVoice:
                    '/s3/situation-synthesis/d137d9f7-2e4d-438b-ae74-210a8018224b.mp3',
                offset: 18,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Después de facturar, deberá pasar por el control de seguridad. Asegúrese de tener a mano la tarjeta de embarque y el DNI.',
            },
            {
                id: 'f40d7aa7-4623-4b82-a61b-702d2a887356',
                phrase: 'Could you explain what I should expect at security?',
                phraseVoice:
                    '/s3/situation-synthesis/1a9976f8-0e5e-412f-8ed1-c47a39a5e1ac.mp3',
                offset: 20,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Could you explain what I should expect at security?',
                        sentenceVoice:
                            '/s3/situation-synthesis/7332dd08-5008-4376-8b08-a53d95c95afb.mp3',
                    },
                ],
                translate: '¿Podría explicarme qué debo esperar en seguridad?',
            },
            {
                id: '577195c5-dc69-4681-b102-c7ee1bfab729',
                phrase: 'Certainly. You might need to remove your shoes, belt, and any metal items. Laptops and liquids must be placed in separate bins.',
                phraseVoice:
                    '/s3/situation-synthesis/1547e8d2-4b74-4593-bb96-d4fbc8fe420f.mp3',
                offset: 21,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Por supuesto. Es posible que tenga que quitarse los zapatos, el cinturón y cualquier objeto metálico. Los ordenadores portátiles y los líquidos deben colocarse en papeleras separadas.',
            },
            {
                id: 'd17e863b-3e9a-4fcf-9ee9-1bfdf3b1cddf',
                phrase: 'That sounds straightforward. Is there anything else I should be aware of?',
                phraseVoice:
                    '/s3/situation-synthesis/a5b57de1-4d93-44f8-86c3-bba4d08c75c4.mp3',
                offset: 23,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'That sounds straightforward.',
                        sentenceVoice:
                            '/s3/situation-synthesis/fe1a5563-25aa-45aa-ae12-ec91052d7a5e.mp3',
                    },
                    {
                        text: 'Is there anything else I should be aware of?',
                        sentenceVoice:
                            '/s3/situation-synthesis/0d606640-a4eb-453b-b59a-4d4ea13493c8.mp3',
                    },
                ],
                translate:
                    'Eso suena sencillo. ¿Hay algo más que deba tener en cuenta?',
            },
            {
                id: 'f0b750eb-7a19-41cc-b46a-a159061aa269',
                phrase: 'You might want to arrive at the airport at least two hours before your flight for domestic travel, and three hours for international flights.',
                phraseVoice:
                    '/s3/situation-synthesis/8afd9a72-cd04-45c3-b505-8bd93cac1b52.mp3',
                offset: 24,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Le conviene llegar al aeropuerto al menos dos horas antes de su vuelo para los viajes nacionales, y tres horas para los vuelos internacionales.',
            },
            {
                id: '14f90ad6-ac2c-477f-9253-9b95a7fdaa22',
                phrase: "That's helpful. Once I'm through security, what should I do while waiting for my flight?",
                phraseVoice:
                    '/s3/situation-synthesis/42191014-4869-485f-8dfb-08d63c9bd999.mp3',
                offset: 26,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's helpful.",
                        sentenceVoice:
                            '/s3/situation-synthesis/0f27e95c-e386-40bb-9547-fbde8eaea0e3.mp3',
                    },
                    {
                        text: "Once I'm through security, what should I do while waiting for my flight?",
                        sentenceVoice:
                            '/s3/situation-synthesis/6d23d2a0-9963-4049-b4f8-e7d88b4fec44.mp3',
                    },
                ],
                translate:
                    'Eso es útil. Una vez pasado el control de seguridad, ¿qué debo hacer mientras espero mi vuelo?',
            },
            {
                id: 'dc1e3bc2-258c-4683-9485-a78a81b05b29',
                phrase: 'You could explore the airport shops or grab a bite to eat. Just keep an eye on the departure screens for any updates on your flight.',
                phraseVoice:
                    '/s3/situation-synthesis/5cef58f0-9ebd-4f14-9fe7-c76647d34df3.mp3',
                offset: 27,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Puede explorar las tiendas del aeropuerto o comer algo. Solo tienes que estar atento a las pantallas de salidas para ver si hay novedades sobre tu vuelo.',
            },
            {
                id: '65465e60-02a8-40d2-8293-53042edd3d0c',
                phrase: 'If my flight is delayed, what are my options?',
                phraseVoice:
                    '/s3/situation-synthesis/53c8f359-cadc-43b5-a43e-4940550a28c5.mp3',
                offset: 29,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'If my flight is delayed, what are my options?',
                        sentenceVoice:
                            '/s3/situation-synthesis/8fc4dc38-1048-4fa0-830e-634cda3feba1.mp3',
                    },
                ],
                translate: 'Si mi vuelo se retrasa, ¿qué opciones tengo?',
            },
            {
                id: '96925634-2765-4a61-9eb6-a594d8041818',
                phrase: 'You should check with your airline. They might offer rebooking options or vouchers, depending on the situation.',
                phraseVoice:
                    '/s3/situation-synthesis/1ab490b7-277d-432e-85f6-7eb0e598b562.mp3',
                offset: 30,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Consulte a su compañía aérea. Es posible que ofrezcan opciones de cambio de reserva o bonos, dependiendo de la situación.',
            },
            {
                id: 'c5049575-e75e-4914-bf15-a4ffa15cec57',
                phrase: 'And if I miss my flight, what should I do?',
                phraseVoice:
                    '/s3/situation-synthesis/94396909-0ce4-4089-b6a7-cd0b56cb0daa.mp3',
                offset: 32,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'And if I miss my flight, what should I do?',
                        sentenceVoice:
                            '/s3/situation-synthesis/d4809b1a-400e-4d62-9022-c5192e593df9.mp3',
                    },
                ],
                translate: 'Y si pierdo el vuelo, ¿qué hago?',
            },
            {
                id: '409fd2ff-53fb-4327-b8a3-7fef7e0373bb',
                phrase: 'You must contact your airline immediately. They could help you find the next available flight.',
                phraseVoice:
                    '/s3/situation-synthesis/c77866b4-f915-4f29-bc8c-856d7d509338.mp3',
                offset: 33,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Debe ponerse en contacto inmediatamente con su compañía aérea. Podrían ayudarte a encontrar el próximo vuelo disponible.',
            },
            {
                id: '0220dc13-2dca-4803-b927-bb6e47636a9f',
                phrase: 'Thank you for all this information. Could you also tell me about customs procedures for international flights?',
                phraseVoice:
                    '/s3/situation-synthesis/a0bf0682-bf12-4813-912c-e117ab0f76d0.mp3',
                offset: 35,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you for all this information.',
                        sentenceVoice:
                            '/s3/situation-synthesis/25dfbeb1-f870-4a2b-8278-f462e2bb2974.mp3',
                    },
                    {
                        text: 'Could you also tell me about customs procedures for international flights?',
                        sentenceVoice:
                            '/s3/situation-synthesis/57bab119-aa02-4c1f-98c4-95131e1c71d6.mp3',
                    },
                ],
                translate:
                    'Gracias por toda esta información. ¿Podría informarme también sobre los trámites aduaneros de los vuelos internacionales?',
            },
            {
                id: 'e9e7c7a5-b572-462f-8a73-69ea91d7f98f',
                phrase: "Of course. When you arrive at your destination, you must go through customs. You might need to declare any goods you're bringing into the country.",
                phraseVoice:
                    '/s3/situation-synthesis/fd71aeab-ea73-473c-b8a3-da3389d654f3.mp3',
                offset: 36,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Por supuesto. Cuando llegues a tu destino, deberás pasar por la aduana. Puede que tengas que declarar las mercancías que introduzcas en el país.',
            },
            {
                id: 'ba09a2f0-ddb6-488e-a627-30eb8eddf7b8',
                phrase: 'What kind of items might I need to declare?',
                phraseVoice:
                    '/s3/situation-synthesis/adee4c38-bac6-48b4-b20c-07ca2a620340.mp3',
                offset: 38,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'What kind of items might I need to declare?',
                        sentenceVoice:
                            '/s3/situation-synthesis/6f1960f4-c524-4fa0-ac00-c58036678057.mp3',
                    },
                ],
                translate: '¿Qué tipo de artículos debo declarar?',
            },
            {
                id: 'fbe3a3aa-3848-4509-8d2f-aa32794fd631',
                phrase: "Typically, you must declare items like food, plants, or large amounts of currency. It's best to check the specific regulations of the country you're visiting.",
                phraseVoice:
                    '/s3/situation-synthesis/d4c2133d-3a48-42a0-bc2f-2ebab66c76e4.mp3',
                offset: 39,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Normalmente, hay que declarar artículos como alimentos, plantas o grandes cantidades de dinero. Lo mejor es consultar la normativa específica del país que visitas.',
            },
            {
                id: '4375c6e8-ee45-4f09-bd92-222d09d7f83b',
                phrase: 'I understand. Is there anything else I should prepare for an international trip?',
                phraseVoice:
                    '/s3/situation-synthesis/d2441897-a0a3-4569-bd18-6bc826137271.mp3',
                offset: 41,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I understand.',
                        sentenceVoice:
                            '/s3/situation-synthesis/c7ad48bd-12fa-43c6-b969-53d57ec73669.mp3',
                    },
                    {
                        text: 'Is there anything else I should prepare for an international trip?',
                        sentenceVoice:
                            '/s3/situation-synthesis/51718984-df5b-4b6d-bb6f-ace9f1f6a87b.mp3',
                    },
                ],
                translate:
                    'Entiendo. ¿Hay algo más que deba preparar para un viaje internacional?',
            },
            {
                id: '4d097be5-6b8b-4d71-90e6-2bb1f7d954b3',
                phrase: 'You should ensure your passport is valid for at least six months beyond your travel dates. Some countries might also require a visa.',
                phraseVoice:
                    '/s3/situation-synthesis/1627908b-c835-49a5-aa9c-1f45c75852a8.mp3',
                offset: 42,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Asegúrese de que su pasaporte es válido al menos seis meses después de la fecha del viaje. Algunos países también pueden exigir visado.',
            },
            {
                id: 'da550395-30a3-461a-9f91-4b566165ef6b',
                phrase: "That's a good point. How can I find out if I need a visa?",
                phraseVoice:
                    '/s3/situation-synthesis/a6c1ef9e-e1e6-44b7-8edf-60cc74d74a57.mp3',
                offset: 44,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: "That's a good point.",
                        sentenceVoice:
                            '/s3/situation-synthesis/53d0f7a8-768c-4e66-9a32-123d5438db30.mp3',
                    },
                    {
                        text: 'How can I find out if I need a visa?',
                        sentenceVoice:
                            '/s3/situation-synthesis/14941153-b56e-400d-b795-0e99e131b9d3.mp3',
                    },
                ],
                translate:
                    'Buena observación. ¿Cómo puedo saber si necesito visado?',
            },
            {
                id: '6171856e-000a-48dd-90be-a4eacc54725e',
                phrase: "You could check the embassy website of the country you're visiting. They usually have detailed information on visa requirements.",
                phraseVoice:
                    '/s3/situation-synthesis/c457c884-9037-4dc8-aab1-e3708cdd6008.mp3',
                offset: 45,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Puedes consultar la página web de la embajada del país que vas a visitar. Suelen tener información detallada sobre los requisitos de visado.',
            },
            {
                id: '63e60430-b154-4cae-bc05-4f1c2a7d2b85',
                phrase: 'Thank you so much for your help. I feel much more prepared now.',
                phraseVoice:
                    '/s3/situation-synthesis/0d8f1298-7156-4705-aaac-4472f7344727.mp3',
                offset: 47,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you so much for your help.',
                        sentenceVoice:
                            '/s3/situation-synthesis/b708b800-3911-40e7-8b0e-e011310221f1.mp3',
                    },
                    {
                        text: 'I feel much more prepared now.',
                        sentenceVoice:
                            '/s3/situation-synthesis/e1926edf-35ca-44a6-8522-f6cee01ce888.mp3',
                    },
                ],
                translate:
                    'Muchas gracias por su ayuda. Ahora me siento mucho más preparado.',
            },
            {
                id: 'cfc99c7c-a282-479e-b9ca-f29af59dd58d',
                phrase: "You're welcome! Traveling can be a bit overwhelming, but with a little preparation, it can also be a lot of fun.",
                phraseVoice:
                    '/s3/situation-synthesis/5cd82eec-04fc-476e-80bd-668fd772802e.mp3',
                offset: 48,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'De nada. Viajar puede ser un poco abrumador, pero con un poco de preparación, también puede ser muy divertido.',
            },
            {
                id: '07b1fada-cbfa-4d39-8750-d76939877c7f',
                phrase: 'I agree. One last question',
                phraseVoice:
                    '/s3/situation-synthesis/3f7dab06-3da3-41f6-90a3-5dcce292b738.mp3',
                offset: 50,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'I agree.',
                        sentenceVoice:
                            '/s3/situation-synthesis/2daab0de-33ee-43ec-8359-1b068ee4dc82.mp3',
                    },
                    {
                        text: 'One last question',
                        sentenceVoice:
                            '/s3/situation-synthesis/6618564a-26af-47d6-855c-f761e02b59e5.mp3',
                    },
                ],
                translate: 'Estoy de acuerdo. Una última pregunta',
            },
            {
                id: '2985b5fc-f625-4ebd-a66d-1a416e6c7fec',
                phrase: 'Absolutely! You might find apps like TripIt for organizing your itinerary, or Google Translate for language assistance, very helpful.',
                phraseVoice:
                    '/s3/situation-synthesis/f500d380-34ac-4f93-94e5-f4443949ca59.mp3',
                offset: 51,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Por supuesto. Puede que aplicaciones como TripIt, para organizar tu itinerario, o Google Translate, para ayudarte con el idioma, te resulten muy útiles.',
            },
            {
                id: '0f7384a3-2935-47f7-ad0c-cea5c8edc1c7',
                phrase: "Those sound great. I'll definitely check them out.",
                phraseVoice:
                    '/s3/situation-synthesis/a1be059d-cfa7-4e3c-8e57-669535e053df.mp3',
                offset: 53,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Those sound great.',
                        sentenceVoice:
                            '/s3/situation-synthesis/943bfbec-72d5-48b3-9e81-955de9c5482e.mp3',
                    },
                    {
                        text: "I'll definitely check them out.",
                        sentenceVoice:
                            '/s3/situation-synthesis/91c97804-6704-4cbb-aaaf-2df78663d41b.mp3',
                    },
                ],
                translate:
                    'Suenan muy bien. Definitivamente les echaré un vistazo.',
            },
            {
                id: 'e48130d8-f71d-4351-b7cf-afbb43d55e9c',
                phrase: "I'm glad I could help. Have a safe and enjoyable trip!",
                phraseVoice:
                    '/s3/situation-synthesis/74c82984-79e2-49e8-9eab-ca2a7542883c.mp3',
                offset: 54,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Me alegro de haber podido ayudar. Que tenga un viaje seguro y agradable.',
            },
            {
                id: '41274da6-7b74-49fd-b03f-c2194f491bf6',
                phrase: 'Thank you! I appreciate all your advice.',
                phraseVoice:
                    '/s3/situation-synthesis/be94bf6e-84f0-41e1-951e-fdd76df2089b.mp3',
                offset: 56,
                actor: 'A',
                voiceId: 'ErXwobaYiN019PkySvjV',
                sentences: [
                    {
                        text: 'Thank you!',
                        sentenceVoice:
                            '/s3/situation-synthesis/1734d2a5-a3c2-4390-ae2a-55feb16c6e9d.mp3',
                    },
                    {
                        text: 'I appreciate all your advice.',
                        sentenceVoice:
                            '/s3/situation-synthesis/ba3924d4-1123-43af-967a-d2c469706592.mp3',
                    },
                ],
                translate: 'Gracias. Agradezco todos sus consejos.',
            },
            {
                id: 'cd13cf54-02f6-45d6-b6da-4742bc3e083e',
                phrase: 'Anytime! If you have any more questions, feel free to ask. Safe travels!',
                phraseVoice:
                    '/s3/situation-synthesis/bb470f1a-73b9-478f-bb7b-d39fe82df1cb.mp3',
                offset: 57,
                actor: 'B',
                voiceId: 'EXAVITQu4vr4xnSDxMaL',
                sentences: null,
                translate:
                    'Cuando quieras. Si tienes más preguntas, no dudes en hacerlas. Buen viaje.',
            },
        ],
    },
};
