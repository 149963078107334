import React, { useRef, useEffect } from 'react';
import cl from './Loader.module.css';

const Loader = ({ className, showOverlay, style, onTimeout }) => {
    const timerRef = useRef();

    useEffect(() => {
        if (!onTimeout) return;
        timerRef.current = setTimeout(() => {
            onTimeout();
        }, 10000);
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <div
            className={`${
                showOverlay ? cl.landingLoaderOverlay : cl.landingLoaderCont
            }`}
            style={style}
        >
            <div className={`${cl.landingLoader} ${className || ''}`}>
                <div className={cl.loader}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    );
};

export default Loader;
