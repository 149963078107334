import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AnimatePresence, motion } from 'framer-motion';
import languagesStore from '../../../../store/interface';
import userWordsStore, {
    WordCardModes,
    WordStatuses,
} from '../../pages/UserWords/store/userWords';
import InfoCard from './InfoCard/InfoCard';
import KeyboardCard from './KeyboardCard/KeyboardCard';
import PronunciationCard from './PronunciationCard/PronunciationCard';
import LettersCard from './LettersCard/LettersCard';
import PhraseCard from './PhraseCard/PhraseCard';
import WriteCard from './WriteCard/WriteCard';
import DictionaryService from '../../../../api/DictionaryService';
import { Loader } from '../../../../UI';
import { ReactComponent as IconArrow } from '../../../../assets/svg/arrow_short.svg';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import cl from './DictionaryCards.module.css';

const cardAnimation = {
    enter: { opacity: 0, transform: 'translate(-50%, -35%)' },
    center: { opacity: 1, transform: 'translate(-50%, -50%)' },
    exitRight: { opacity: 0, transform: 'translate(-40%, -50%) rotate(5deg)' },
    exitLeft: { opacity: 0, transform: 'translate(-60%, -50%) rotate(-5deg)' },
};

const transition = { duration: 0.5, ease: 'easeOut' };

const DictionaryCards = ({
    words,
    isLearned,
    setIsLearned,
    alreadyFetched,
}) => {
    const { t } = useTranslation();

    const { lang, nativeLang } = languagesStore;

    const { activeWords, cardModes, wordsPerPack, currentCardIdx } =
        userWordsStore;

    const finalIteration = cardModes.lastIndexOf(true);

    const [currentPack, setCurrentPack] = useState([]);
    const [isEnd, setIsEnd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showControls, setShowControls] = useState(true);

    const [updateWord] = useFetching(async ({ id, status, lastIteration }) => {
        await ProgressService.updateWord({ id, status, lastIteration });
    });

    const getAllWordsData = async () => {
        setIsLoading(true);
        const active = words.filter((w) => w.status === WordStatuses.Active);
        let wordsDataArr = [];

        if (!alreadyFetched) {
            for (const w of active) {
                const { data: wordData } =
                    await DictionaryService.getWordByText({
                        word: w.word,
                        sourceLang: w.lang,
                        targetLang: nativeLang,
                    });
                if (wordData && wordData.data) {
                    wordsDataArr.push({
                        ...w,
                        ...wordData,
                        ...wordData.data[0],
                        lastIteration: w.lastIteration ? w.lastIteration : 0,
                    });
                }
            }
        } else {
            wordsDataArr = words;
        }

        setIsLoading(false);
        userWordsStore.setActiveWords(wordsDataArr);
    };

    const getCardTitle = (index) => {
        return (
            <p className={cl.cardTitle}>
                {t(`glossary_settings.${Object.values(WordCardModes)[index]}`)}
            </p>
        );
    };

    const getNextCard = (word) => {
        switch (word.lastIteration) {
            case 0:
                return <InfoCard wordObj={word} playAudio />;
            case 1:
                return (
                    <LettersCard
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 2:
                return (
                    <KeyboardCard
                        wordObj={word}
                        mode={'audio'}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 3:
                return (
                    <KeyboardCard
                        wordObj={word}
                        mode={'translation'}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 4:
                return (
                    <PhraseCard
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 5:
                return (
                    <PronunciationCard
                        sourceLang={lang}
                        wordObj={word}
                        isTranslation
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 6:
                return (
                    <PronunciationCard
                        sourceLang={lang}
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 7:
                return (
                    <WriteCard
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );

            default:
                return null;
        }
    };

    const renderNextCard = () => {
        if (!activeWords.length) {
            setIsEnd(true);
            return;
        }

        const word = currentPack[currentCardIdx] || activeWords[0];
        if (word.lastIteration > finalIteration) {
            userWordsStore.setCurrentCardIdx(currentCardIdx + 1);
            return;
        }

        const card = getNextCard(word);

        if (!card) return null;

        return (
            <AnimatePresence mode="wait">
                <motion.div
                    key={`${currentCardIdx}_${word.lastIteration}`}
                    className={cl.cardsCont}
                    variants={cardAnimation}
                    initial="enter"
                    animate="center"
                    exit={exitDirection}
                    transition={transition}
                >
                    {getCardTitle(word.lastIteration)}
                    {card}
                </motion.div>
            </AnimatePresence>
        );
    };

    const updateIteration = (word) => {
        updateWord({ id: word.id, lastIteration: word.lastIteration + 1 });
        userWordsStore.changeWordLevel(word);
    };

    const handleNextCard = (isLearned) => {
        setCardStyle(isLearned);
        setTimeout(() => {
            const word = currentPack[currentCardIdx] || currentPack[0];
            const { id, lastIteration } = word;
            if (isLearned) {
                if (lastIteration >= Object.keys(WordCardModes).length) {
                    updateWord({ id, status: WordStatuses.Learned });
                }
                updateIteration(word);
            }

            if (currentCardIdx + 1 >= currentPack.length) {
                userWordsStore.setCurrentCardIdx(0);
            } else {
                userWordsStore.setCurrentCardIdx(currentCardIdx + 1);
            }
        }, 500);
    };

    const setCardStyle = (isLearned) => {
        setIsLearned(isLearned);
        setTimeout(() => {
            setIsLearned(null);
        }, 500);
    };

    useEffect(() => {
        getAllWordsData();
    }, [words]);

    useEffect(() => {
        setCurrentPack(activeWords.slice(0, wordsPerPack));
    }, [activeWords, wordsPerPack]);

    useEffect(() => {
        if (isEnd) {
            for (const word of currentPack) {
                updateWord({ id: word.id, status: WordStatuses.Learned });
            }
        }
    }, [isEnd]);

    useEffect(() => {
        const currentIteration = cardModes.findIndex((flag) => flag);
        for (const word of currentPack) {
            updateWord({ id: word.id, lastIteration: currentIteration });
        }

        setCurrentPack(
            currentPack.map((w) => ({ ...w, lastIteration: currentIteration }))
        );
    }, [cardModes]);

    useEffect(() => {
        if (isLearned !== null) {
            setShowControls(false);
            setTimeout(() => setShowControls(true), 1500);
        }
    }, [isLearned]);

    const exitDirection =
        isLearned === true
            ? 'exitRight'
            : isLearned === false
              ? 'exitLeft'
              : null;

    if (isLoading || !activeWords.length)
        return (
            <div className={cl.loaderContainer}>
                <Loader />
            </div>
        );

    if (isEnd)
        return (
            <div className={cl.resultsCont}>
                <p className={cl.endTitle}>{t('user_view.pack_end_title')}</p>
                <p className={cl.endSubtitle}>
                    {t('user_view.pack_end_subtitle')}
                </p>
                <p>
                    {t('user_view.learned_words')}
                    {': '}
                    <span className={cl.learnedList}>
                        {currentPack.map((w) => w.word).join(', ')}
                    </span>
                </p>
            </div>
        );

    return (
        <>
            {renderNextCard()}
            {showControls && (
                <div className={cl.cardControls}>
                    <div
                        className={cl.repeatButton}
                        onClick={() => {
                            if (typeof isLearned === 'boolean') return;
                            handleNextCard(false);
                        }}
                    >
                        <div className={cl.buttonInner}>
                            <IconArrow />
                            <p>{t('user_view.repeat')}</p>
                        </div>
                    </div>
                    <div
                        className={cl.continueButton}
                        onClick={() => {
                            if (typeof isLearned === 'boolean') return;
                            handleNextCard(true);
                        }}
                    >
                        <div className={cl.buttonInner}>
                            <p>{t('user_view.i_know')}</p>
                            <IconArrow />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default observer(DictionaryCards);
