import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import guestOnboarding from '../../store/guestOnboarding';
import Modal from '../../../../components/UI/Modal/Modal';
import { Button } from '../../../../teacherComponents';
import cl from './InfoModal.module.css';

const InfoModal = ({
    onboardingKey,
    title,
    subtitle,
    delay,
    style,
    onClose,
    variant,
}) => {
    const { t } = useTranslation();

    const { isDelay } = guestOnboarding;

    const handleButtonClick = () => {
        onClose ? onClose() : guestOnboarding.moveToNextComponent();
    };

    useEffect(() => {
        if (delay && onboardingKey) {
            document.body.style.overflow = 'hidden';
            guestOnboarding.setIsDelay(true);

            setTimeout(() => {
                guestOnboarding.setIsDelay(false);
                // document.body.style.overflow = '';
            }, delay);
        }
    }, [onboardingKey, delay]);

    useEffect(() => {
        if (onboardingKey) {
            document.body.style.overflow = 'hidden';
            return () => (document.body.style.overflow = '');
        }
    }, [onboardingKey]);

    if (!onboardingKey || isDelay) return null;

    return (
        <Modal
            visible={true}
            setVisible={() => {}}
            className={cl.intro}
            style={style}
            addBlur
        >
            <p className={cl.title}>{title}</p>
            <p className={cl.subtitle}>{subtitle}</p>
            <Button
                variant={variant}
                text={t('teacher_onboarding.close_button')}
                onClick={handleButtonClick}
            />
        </Modal>
    );
};

export default observer(InfoModal);
