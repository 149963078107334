import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import lessonsStore from '../../store/lessonsStore';
import onboardingStore from '../../../../store/onboarding';
import teachersStore from '../../../Teachers/store/teachersStore';
import ExerciseService from '../../../../api/ExerciseService';
import { Loader } from '../../../../UI';
import {
    AddChapter,
    CloneLesson,
    FilePreview,
    ModalHeader,
} from './components';
import Player from '../../../Player/Player';
import { CloseButton } from '../../../../teacherComponents';
import { ChapterSection } from '..';
import { Preview } from '../../../TeacherContent/views/ExercisesView/components';
import { CategoryWords } from '../../../TeacherContent/views/DictionaryView/components';
import { Situation } from '../../../TeacherContent/views/SituationsView/components';
import { FileTypes } from '../../../../components/UI/FileUploader/FileUploader';
import { ReactComponent as IconEyes } from '../../../../assets/svg/lessons-eyes.svg';
import { Chapters, StudentExerciseStatuses, Views } from '../../data/constants';
import {
    OnboardingWrapper,
    OnboardingModal,
    OnboardingKeys,
    BubblePositions,
    ArrowPositions,
} from '../../../../teacherComponents/Onboarding';
import { LearnSituation, PonyPlayer } from '../../../../views/User/components';
import { UserWords } from '../../../../views/User';
import cl from './LessonModal.module.css';
import wizardStore from '../LessonWizard/store/wizardStore';
import ProgressService from '../../../../api/ProgressService';

const statusPriority = {
    [StudentExerciseStatuses.AwaitingEvaluation]: 1,
    [StudentExerciseStatuses.Done]: 2,
    [StudentExerciseStatuses.Unfinished]: 3,
};

const LessonModal = () => {
    const { t, i18n } = useTranslation();

    const { view, chaptersModalType, currentStudentChapters } = exercisesStore;
    const { currentLesson, currentChapter, isResetStudent, isStudentPreview } =
        lessonsStore;
    const { currentTeacher } = teachersStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.AwaitLessonModalClose
    );

    const awaitingAddChapter = onboardingStore.isOnboarding(
        OnboardingKeys.AwaitAddChapter
    );

    const isTeacher = view === Views.Teachers && currentTeacher;
    const pdfs = currentLesson?.pdfs ?? [];

    const pictures = currentLesson?.pictures ?? [];

    const [currentWord, setCurrentWord] = useState();
    const [tracks, setTracks] = useState([]);
    const [situations, setSituations] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [dictionaries, setDictionaries] = useState([]);
    const [files, setFiles] = useState([]);
    const [hasFetched, setHasFetched] = useState(false);

    const chapterRefs = useRef({});

    const formatExercises = () => {
        if (!currentStudentChapters.exercises) return exercises;

        return exercises
            .map((e) => ({
                ...e,
                status: currentStudentChapters.exercises.find(
                    (se) => se.id === e.id
                )?.status,
            }))
            .sort(
                (a, b) => statusPriority[a?.status] - statusPriority[b?.status]
            );
    };

    const chaptersList = [
        {
            items: formatExercises(),
            type: Chapters.Exercise,
        },
        {
            items: situations,
            type: Chapters.Situation,
        },

        {
            items: tracks,
            type: Chapters.Track,
        },
        {
            items: dictionaries,
            type: Chapters.Dictionary,
        },
        {
            items: files,
            type: Chapters.File,
        },
    ];

    const [getChaptersData, chaptersLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getLesson({
            id: currentLesson.id,
        });
        const chapters = data.chapters ?? [];
        lessonsStore.setCurrentLesson({ ...currentLesson, chapters });

        setHasFetched(true);

        getCurrentLessonWordPack(data.chapters);
    });

    const getCurrentLessonWordPack = async (chapters) => {
        const wordPack = chapters.find((c) => c.type === Chapters.Dictionary);

        if (!wordPack) return;
        const { id: categoryId, title } = wordPack;
        const { data } = await ProgressService.getCategoryWords({
            categoryId,
            lang: i18n.language,
        });
        if (data && data.items) {
            wizardStore.setWordPack({ title, ...data });
        }
    };

    const [addChapter, addLoading, error, resetError] = useFetching(
        async ({ chapterId, chapterType, title }) => {
            await ExerciseService.addChapterToLesson({
                title,
                lessonId: currentLesson.id,
                chapterId,
                chapterType,
                order: 0,
            });

            const { data } = await ExerciseService.getLesson({
                id: currentLesson.id,
            });

            lessonsStore.updateLesson({
                ...currentLesson,
                chapters: data.chapters,
            });

            const newChapter = data.chapters.find((c) => c.id === chapterId);
            lessonsStore.setCurrentChapter(newChapter);

            if (awaitingAddChapter) {
                onboardingStore.moveToNextComponent();
            }
        }
    );

    const [updateLesson] = useFetching(async ({ id, ...params }) => {
        await ExerciseService.updateLesson({ id, ...params });
    });

    const [deleteLesson] = useFetching(async ({ id }) => {
        try {
            await ExerciseService.removeExercise({
                id,
            });
        } catch (_e) {
            try {
                await ExerciseService.deleteExercise({
                    id,
                });
            } catch (e) {
                console.error(e);
            }
        }
    });

    const handleLessonUpdate = async (params) => {
        const {
            title,
            age,
            difficulty,
            exam,
            vocabularyTopic,
            grammarTopic,
            status,
        } = params;

        await updateLesson({
            id: currentLesson.id,
            title,
            age,
            difficulty,
            exam,
            vocabularyTopic,
            grammarTopic,
            status,
        });
        lessonsStore.updateLesson({
            ...currentLesson,
            title,
            age,
            difficulty,
            exam,
            vocabularyTopic,
            grammarTopic,
            status,
        });
    };

    const handleLessonClose = () => {
        lessonsStore.setCurrentLesson(null);
        lessonsStore.setCurrentChapter({});
        resetChapters();
        setHasFetched(false);
        if (isResetStudent) {
            exercisesStore.setCurrentStudent(null);
            lessonsStore.setCurrentStudentLessons([]);
        }
        lessonsStore.setIsResetStudent(false);
        view === Views.Students && exercisesStore.setCurrentStudent(null);
        if (
            onboardingStore.isOnboarding(OnboardingKeys.AwaitLessonModalClose)
        ) {
            onboardingStore.moveToNextComponent();
        }
    };

    const handleLessonDelete = async () => {
        if (!currentLesson) return;
        lessonsStore.deleteThemeLesson(currentLesson.id);

        await deleteLesson({ id: currentLesson.id });
    };

    const handleFileUpload = (file) => {
        if (file.name.endsWith(FileTypes.Pdf)) {
            updateLesson({ id: currentLesson.id, pdf: file });
            lessonsStore.updateLesson({
                ...currentLesson,
                pdfs: [file.name],
            });
        } else {
            updateLesson({ id: currentLesson.id, picture: file });
            lessonsStore.updateLesson({
                ...currentLesson,
                pictures: [file.name],
            });
        }
    };

    const handleChapterDelete = (deletedChapterId) => {
        const deletedChapter = currentLesson?.chapters.find(
            (c) => c.id === deletedChapterId
        );
        if (deletedChapter && deletedChapter.type === Chapters.Dictionary) {
            wizardStore.resetWordPack();
        }
        const newLesson = {
            ...currentLesson,
            chapters: currentLesson?.chapters.filter(
                (c) => c.id !== deletedChapterId
            ),
        };
        lessonsStore.updateLesson(newLesson);
        if (currentChapter && currentChapter.id === deletedChapterId) {
            lessonsStore.setCurrentChapter({});
        }
    };

    const handleExerciseChange = ({ id, title, difficulty }) =>
        setExercises(
            exercises.map((e) =>
                e.id === id ? { ...e, title, difficulty } : e
            )
        );

    const renderCurrentChapter = () => {
        if (!hasFetched) return null;
        if (chaptersModalType) return null;
        return isStudentPreview ? (
            <div
                className={
                    currentChapter?.type === Chapters.Track
                        ? cl.trackCard
                        : cl.chapterCard
                }
            >
                {getStudentPreview()}
            </div>
        ) : (
            <div className={cl.chapterCard}>{getChapterPreview()}</div>
        );
    };

    const syncWordsCount = ({ id, wordsCount }) => {
        setDictionaries(
            dictionaries.map((d) => (d.id === id ? { ...d, wordsCount } : d))
        );
    };

    const getChapterPreview = () => {
        switch (currentChapter.type) {
            case Chapters.Track:
                return (
                    <Player
                        key={'modalView'}
                        track={{
                            id: currentChapter.id,
                            title: currentChapter?.title,
                        }}
                    />
                );
            case Chapters.Situation: {
                return <Situation situation={currentChapter} />;
            }
            case Chapters.Exercise: {
                return (
                    <Preview
                        exerciseId={currentChapter.id}
                        onChange={handleExerciseChange}
                    />
                );
            }
            case Chapters.Dictionary: {
                return (
                    <CategoryWords
                        category={currentChapter}
                        lang={i18n.language}
                        currentWord={currentWord}
                        setCurrentWord={setCurrentWord}
                        onUpdate={syncWordsCount}
                    />
                );
            }
            case Chapters.File: {
                return <FilePreview file={currentChapter} />;
            }
            default:
                return (
                    <div className={cl.emptyAlert}>
                        <IconEyes />
                        <p className={cl.alertTitle}>
                            {t(`exercises.select_chapter`)}
                        </p>
                    </div>
                );
        }
    };

    const getStudentPreview = () => {
        switch (currentChapter.type) {
            case Chapters.Track:
                return <PonyPlayer trackId={currentChapter.id} />;
            case Chapters.Situation: {
                return <LearnSituation situaiton={currentChapter} isModal />;
            }
            case Chapters.Exercise: {
                return (
                    <Preview
                        exerciseId={currentChapter.id}
                        onChange={handleExerciseChange}
                        hideEdit
                    />
                );
            }
            case Chapters.Dictionary: {
                return <UserWords categoryId={currentChapter.id} />;
            }
            default:
                return (
                    <div className={cl.emptyAlert}>
                        <IconEyes />
                        <p className={cl.alertTitle}>
                            {t(`exercises.select_chapter`)}
                        </p>
                    </div>
                );
        }
    };

    const getModalClassName = () => {
        const classNames = [cl.lessonModal];
        return classNames.join(' ');
    };

    const selectFirstChapter = () => {
        const chapters = currentLesson.chapters ?? [];
        const files = [...pdfs, ...pictures];
        const activeChapter = chapters.length
            ? chapters[0]
            : files.length
              ? {
                    src: files[0],
                    type: Chapters.File,
                    isPdf: files[0].endsWith(FileTypes.Pdf),
                }
              : {};

        lessonsStore.setCurrentChapter(activeChapter);
    };

    const renderChapterButton = () => {
        if (isTeacher || view === Views.Students || wizardStore.isWizardVisible)
            return null;
        return (
            <OnboardingWrapper
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.LessonAddChapter
                )}
                title={t('teacher_onboarding.add_chapter_info')}
                subtitle={t('teacher_onboarding.add_chapter_info_subtitle')}
                delay={1200}
                bubblePosition={{
                    x: BubblePositions.x.Center,
                    y: BubblePositions.y.Top,
                }}
                bubbleAutoWidth
                arrowPosition={ArrowPositions.Top}
            >
                <div style={{ maxHeight: 32 }}>
                    <AddChapter
                        lang={i18n.language}
                        onAdd={addChapter}
                        onFileSelect={handleFileUpload}
                        isLoading={addLoading}
                        isError={error}
                        resetError={resetError}
                        alreadyAdded={{
                            dictionaries,
                            exercises,
                            situations,
                            tracks,
                        }}
                        forceOpen={onboardingStore.isOnboarding(
                            OnboardingKeys.ExercisesContentSelect
                        )}
                    />
                </div>
            </OnboardingWrapper>
        );
    };

    const resetChapters = () => {
        setTracks([]);
        setSituations([]);
        setExercises([]);
        setDictionaries([]);
        setFiles([]);
    };

    useEffect(() => {
        if (!currentLesson) {
            wizardStore.resetWordPack();
            return;
        }
        const chapters = currentLesson.chapters ?? [];

        setFiles([...pdfs, ...pictures]);
        setTracks(chapters.filter((c) => c.type === Chapters.Track));
        setSituations(chapters.filter((c) => c.type === Chapters.Situation));
        setExercises(chapters.filter((c) => c.type === Chapters.Exercise));
        setDictionaries(chapters.filter((c) => c.type === Chapters.Dictionary));

        if (!currentChapter?.id) {
            selectFirstChapter();
        }
    }, [currentLesson]);

    useEffect(() => {
        if (!currentLesson && isResetStudent) {
            exercisesStore.setCurrentStudent(null);
            lessonsStore.setCurrentStudentLessons([]);
            lessonsStore.setIsResetStudent(false);
        }
    }, [currentLesson, isResetStudent]);

    useEffect(() => {
        if (currentLesson?.id) getChaptersData();
    }, [currentLesson?.id]);

    useEffect(() => {
        if (!currentLesson && isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    }, [isOnboarding, currentLesson]);

    useEffect(() => {
        lessonsStore.setIsStudentPreview(false);
    }, [currentLesson]);

    return !currentLesson ? null : (
        <div className={cl.overlay} onClick={handleLessonClose}>
            <div
                className={getModalClassName()}
                onClick={(e) => e.stopPropagation()}
            >
                <ModalHeader
                    lesson={currentLesson}
                    onUpdate={handleLessonUpdate}
                    onDelete={handleLessonDelete}
                />

                <div className={cl.mainContainer}>
                    <div className={cl.aside}>
                        <div className={cl.chaptersList}>
                            {chaptersLoading ? (
                                <Loader
                                    style={{ margin: 'auto', height: '100%' }}
                                />
                            ) : chaptersList.map((c) => c.items).flat()
                                  .length !== 0 ? (
                                <div className={cl.scrollContainer}>
                                    {chaptersList.map((c) => (
                                        <ChapterSection
                                            ref={(el) =>
                                                (chapterRefs.current[c.type] =
                                                    el)
                                            }
                                            key={c.type}
                                            chapters={c.items}
                                            type={c.type}
                                            lessonId={currentLesson.id}
                                            onDelete={handleChapterDelete}
                                        />
                                    ))}
                                </div>
                            ) : hasFetched ? (
                                <div className={cl.emptyAlert}>
                                    <p className={cl.alertTitle}>
                                        {t('exercises.empty_lesson')}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        {isTeacher && (
                            <CloneLesson lessonId={currentLesson.id} />
                        )}
                        {renderChapterButton()}
                    </div>

                    {renderCurrentChapter()}
                </div>
                <div style={{ postion: 'absolute' }}>
                    <CloseButton onClick={handleLessonClose} />
                </div>
            </div>
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.ChapterAdded
                )}
                title={t('teacher_onboarding.chapter_selected_title')}
                subtitle={t('teacher_onboarding.chapter_selected_subtitle')}
                delay={2000}
                style={{ zIndex: 10 }}
            />
        </div>
    );
};

export default observer(LessonModal);
