import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../store/exercisesStore';
import wizardStore from '../../../../../TeacherLessons/components/LessonWizard/store/wizardStore';
import { ReactComponent as IconArrow } from '../../../../../../assets/svg/lessons-arrow.svg';
import { ExerciseWizard } from '../../../../../TeacherLessons/components/LessonWizard/components';
import { WizardViews } from '../../../../../TeacherLessons/components/LessonWizard/LessonWizard';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { CloseButton } from '../../../../../../teacherComponents';
import cl from './GenerateExerciseModal.module.css';

const GenerateExerciseModal = ({ visible, setVisible, onAdd }) => {
    const { t } = useTranslation();
    const { currentViewObj, currentStepIndex, exerciseType, exerciseKind } =
        wizardStore;

    const currentStep = currentViewObj.steps[currentStepIndex];

    const renderTitle = () => {
        if (
            currentViewObj.view === WizardViews.Exercise &&
            ['source', 'generate'].includes(currentStep)
        ) {
            return (
                <p
                    className={cl.shiftedTitle}
                >{`${t(`lesson_wizard.${exerciseType}_exercise`)} / ${t(`exercises.${exerciseKind}`)}: ${t(`lesson_wizard.exercise_${currentStep}`)}`}</p>
            );
        }

        if (
            currentViewObj.view === WizardViews.Exercise &&
            currentStep === 'kind'
        ) {
            return (
                <p className={cl.shiftedTitle}>
                    {t(
                        `lesson_wizard.${exerciseType}_${currentViewObj.view}_${currentStep}_title`
                    )}
                </p>
            );
        }

        return (
            <p className={currentStepIndex === 0 ? cl.title : cl.shiftedTitle}>
                {t(`lesson_wizard.${currentViewObj.view}_${currentStep}_title`)}
            </p>
        );
    };

    const renderBackArrow = () => {
        if (currentStepIndex === 0) return null;
        return (
            <IconArrow
                className={cl.backArrow}
                onClick={wizardStore.prevStep}
            />
        );
    };

    const handleExerciseCreate = (data) => {
        if (onAdd) {
            onAdd({ id: data.id, title: data.title });
            return;
        }
        exercisesStore.setChaptersModalType('');
        exercisesStore.setExercises([data, ...exercisesStore.exercises]);
        setTimeout(() => {
            exercisesStore.setCurrentExercise(data);
        }, 300);
    };

    useEffect(() => {
        if (visible) {
            wizardStore.setCurrentView(WizardViews.Exercise);
        }
    }, [visible]);

    return (
        <Modal
            className={cl.createExercise}
            visible={visible}
            setVisible={setVisible}
        >
            {renderTitle()}
            {renderBackArrow()}
            <div className={cl.modalInner}>
                <ExerciseWizard onAdd={handleExerciseCreate} />
            </div>

            <CloseButton onClick={() => setVisible(false)} />
        </Modal>
    );
};

export default observer(GenerateExerciseModal);
