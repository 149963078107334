import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import lessonsStore from '../../store/lessonsStore';
import wizardStore from './store/wizardStore';
import Modal from '../../../../components/UI/Modal/Modal';
import { Button, CloseButton } from '../../../../teacherComponents';
import {
    AddChapter,
    CreateLesson,
    DialogueWizard,
    ExerciseWizard,
    VideoWizard,
    WordPackWizard,
} from './components';
import { Chapters } from '../../data/constants';
import { Loader } from '../../../../UI';
import { ReactComponent as IconAdd } from '../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import cl from './LessonWizard.module.css';

export const WizardViews = {
    CreateLesson: 'lesson',
    WordPack: Chapters.Dictionary,
    Exercise: Chapters.Exercise,
    Video: Chapters.Track,
    Dialogue: Chapters.Situation,
    AddChapter: 'add_chapter',
};

export const WizardSteps = [
    {
        view: WizardViews.CreateLesson,
        steps: ['create'],
    },
    {
        view: WizardViews.WordPack,
        steps: ['select', 'generate'],
    },
    {
        view: WizardViews.Exercise,
        steps: ['select', 'type', 'kind', 'source', 'generate'],
    },
    {
        view: WizardViews.Video,
        steps: ['select'],
    },
    {
        view: WizardViews.Dialogue,
        steps: ['select', 'generate'],
    },
    {
        view: WizardViews.AddChapter,
        steps: ['select'],
    },
];

const LessonWizard = () => {
    const { t } = useTranslation();
    const {
        currentViewObj,
        currentStepIndex,
        isWizardVisible,
        exerciseType,
        exerciseKind,
    } = wizardStore;
    const { currentLesson } = lessonsStore;

    const currentStep = currentViewObj.steps[currentStepIndex];

    const [addChapter, isLoading] = useFetching(
        async ({ chapterId, chapterType, title }) => {
            await ExerciseService.addChapterToLesson({
                title,
                lessonId: currentLesson.id,
                chapterId,
                chapterType,
                order: 0,
            });

            const { data } = await ExerciseService.getLesson({
                id: currentLesson.id,
            });

            lessonsStore.updateLesson({
                ...currentLesson,
                chapters: data.chapters,
            });

            const newChapter = data.chapters.find((c) => c.id === chapterId);
            lessonsStore.setCurrentChapter(newChapter);
        }
    );

    const renderTitle = () => {
        const isFirst = [
            WizardViews.CreateLesson,
            WizardViews.AddChapter,
        ].includes(currentViewObj.view);

        if (
            currentViewObj.view === WizardViews.Exercise &&
            ['source', 'generate'].includes(currentStep)
        ) {
            return (
                <p
                    className={`${cl.shiftedTitle} ${cl[currentViewObj.view]}`}
                >{`${t(`lesson_wizard.${exerciseType}_exercise`)} / ${t(`exercises.${exerciseKind}`)}: ${t(`lesson_wizard.exercise_${currentStep}`)}`}</p>
            );
        }

        if (
            currentViewObj.view === WizardViews.Exercise &&
            currentStep === 'kind'
        ) {
            return (
                <p className={`${cl.shiftedTitle} ${cl[currentViewObj.view]}`}>
                    {t(
                        `lesson_wizard.${exerciseType}_${currentViewObj.view}_${currentStep}_title`
                    )}
                </p>
            );
        }

        return (
            <p
                className={`${isFirst ? cl.title : cl.shiftedTitle} ${cl[currentViewObj.view]}`}
            >
                {t(`lesson_wizard.${currentViewObj.view}_${currentStep}_title`)}
            </p>
        );
    };

    const renderCurrentStep = () => {
        if (isLoading) return <Loader style={{ margin: 'auto' }} />;
        switch (currentViewObj.view) {
            case WizardViews.CreateLesson:
                return <CreateLesson />;
            case WizardViews.WordPack:
                return <WordPackWizard onAdd={addChapter} />;
            case WizardViews.Video:
                return <VideoWizard onAdd={addChapter} />;
            case WizardViews.Dialogue:
                return <DialogueWizard onAdd={addChapter} />;
            case WizardViews.Exercise:
                return <ExerciseWizard onAdd={addChapter} />;
            case WizardViews.AddChapter:
                return <AddChapter />;
            default:
                return <></>;
        }
    };

    const renderBackArrow = () => {
        if (currentViewObj.view === WizardViews.CreateLesson) return null;
        if (currentViewObj.view === WizardViews.AddChapter) return null;
        return (
            <IconArrow
                className={cl.backArrow}
                onClick={wizardStore.prevStep}
            />
        );
    };

    return (
        <>
            <Button
                text={t('exercises.add_lesson')}
                onClick={() => wizardStore.setWizardVisible(true)}
                variant={'track'}
                icon={<IconAdd className={cl.plusIcon} />}
            />
            <Modal
                className={cl.wizardModal}
                visible={isWizardVisible}
                setVisible={wizardStore.setWizardVisible}
                style={{ zIndex: 20 }}
            >
                {renderBackArrow()}
                {renderTitle()}
                <div className={cl.modalInner}>{renderCurrentStep()}</div>

                <CloseButton
                    onClick={() => wizardStore.setWizardVisible(false)}
                />
            </Modal>
        </>
    );
};

export default observer(LessonWizard);
