import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import ExerciseService from '../../../../../../api/ExerciseService';
import { DefaultWordsParams, WordsRange } from '../../data/constants';
import {
    Button,
    Input,
    Label,
    Range,
    ToggleButton,
} from '../../../../../../teacherComponents';
import { LanguageLevels } from '../../../../../../data/common';
import { GenerateChapterModal } from '../../../../components';
import { Chapters } from '../../../../../TeacherLessons/data/constants';
import cl from './GenerateWordsCategoryModal.module.css';

const GenerateWordsCategoryModal = ({ lang, visible, setVisible, onAdd }) => {
    const { t } = useTranslation();

    const [wordsParams, setWordsParams] = useState(DefaultWordsParams);
    const [formErrors, setFormErrors] = useState({});

    const [generateWords, generaeLoading, generateError, resetGenerateError] =
        useFetching(async () => {
            const { topic, level, title, wordsAmount } = wordsParams;
            const { data } = await ExerciseService.generateWords({
                topic,
                difficulty: LanguageLevels.indexOf(level),
                lang,
                wordsAmount,
                // TODO Add part of speech selector @saratovkin
                partOfSpeech: 'noun',
            });
            const { words } = data;
            createCategory({ words, title, level });
        });

    const [createCategory, createLoading] = useFetching(
        async ({ words, title, level }) => {
            const { data } = await ProgressService.createWordsCategory({
                title,
                lang,
                level: LanguageLevels.indexOf(level),
            });
            setVisible(false);
            onAdd(data, words);
        }
    );

    const setWordsCount = (e) => {
        setWordsParams({ ...wordsParams, wordsAmount: e.target.value });
    };

    const handleWordsGenerate = () => {
        resetErrors();

        setTimeout(() => {
            const errors = {
                topic: !wordsParams.topic,
                title: !wordsParams.title,
                level: !wordsParams.level,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            generateWords();
        });
    };

    const isLoading = createLoading || generaeLoading;

    const resetErrors = () => {
        resetGenerateError();
        setFormErrors({});
    };

    useEffect(() => {
        setWordsParams(DefaultWordsParams);
        setFormErrors({});
    }, [visible]);

    useEffect(() => {
        setFormErrors({});
    }, [wordsParams]);

    return (
        <GenerateChapterModal
            visible={visible}
            setVisible={setVisible}
            title={t('exercises.generate_words_pack')}
            subtitle={t('exercises.generate_words_pack_desc')}
        >
            <div>
                <Label
                    text={t('exercises.words_category_title')}
                    isError={formErrors['title']}
                />
                <Input
                    variant={
                        formErrors['title'] ? 'erroredSmall' : 'outlinedSmall'
                    }
                    placeholder={t(
                        'exercises.words_category_title_placeholder'
                    )}
                    value={wordsParams.title}
                    onChange={(title) =>
                        setWordsParams({ ...wordsParams, title })
                    }
                />
            </div>
            <div>
                <Label
                    text={t('situations.level')}
                    isError={formErrors['level']}
                />
                <ToggleButton
                    style={{ width: 212 }}
                    label={t('users.level')}
                    options={LanguageLevels}
                    value={wordsParams.level}
                    variant={formErrors['level'] ? 'error' : 'outlined'}
                    onChange={(level) =>
                        setWordsParams({ ...wordsParams, level })
                    }
                />
            </div>
            <div>
                <Label
                    text={t('exercises.word_pack_topic')}
                    isError={formErrors['topic']}
                />
                <Input
                    variant={
                        formErrors['topic'] ? 'erroredSmall' : 'outlinedSmall'
                    }
                    placeholder={t('exercises.topic_example')}
                    value={wordsParams.topic}
                    onChange={(topic) =>
                        setWordsParams({ ...wordsParams, topic })
                    }
                />
            </div>

            <div>
                <Label text={t('exercises.words_amount')} />
                <Range
                    color={'var(--words-green)'}
                    style={{ marginTop: 15 }}
                    value={wordsParams.wordsAmount}
                    min={WordsRange.Min}
                    step={WordsRange.Step}
                    max={WordsRange.Max}
                    onChange={setWordsCount}
                />
            </div>
            {generateError && (
                <p className={cl.error}>{t('errors.unexpected_error')}</p>
            )}
            <Button
                text={t('exercises.add_new_words')}
                variant={Chapters.Dictionary}
                onClick={handleWordsGenerate}
                isLoading={isLoading}
            />
        </GenerateChapterModal>
    );
};

export default GenerateWordsCategoryModal;
