import React, { useEffect, useState, createContext } from 'react';
import WebFont from 'webfontloader';
import { BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import languagesStore from './store/interface';
import Header from './components/Header/Header';
import AppRouter from './router/AppRouter';
import AuthStore from './store/auth';
import AppWrapper from './components/UI/AppWrapper/AppWrapper';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { useFetching } from './hooks/useFetching';
import wordsStore from './store/words';
import { Roles } from './data/common';
import './style/App.css';

export const authStore = new AuthStore(languagesStore);
export const AuthContext = createContext({
    authStore,
});

const App = () => {
    const { i18n } = useTranslation();
    const { role } = authStore;

    const [isAuthChecked, setIsAuthChecked] = useState(false);
    const [areFontsLoaded, setAreFontsLoaded] = useState(false);

    const [checkAuth, isLoading, error] = useFetching(async () => {
        await authStore.checkAuth();
        setIsAuthChecked(true);
    });

    const createCustomEvent = () => {
        document.addEventListener('click', (e) => {
            document.dispatchEvent(
                new CustomEvent('closeMenus', {
                    detail: e.target,
                })
            );
        });
    };

    useEffect(() => {
        checkAuth();
        createCustomEvent();
    }, []);

    useEffect(() => {
        WebFont.load({
            google: {
                families: [
                    'Inter:300,400,500,600,700,900',
                    'Montserrat:400,600',
                    'Noto Sans:300,400,500,600,700',
                    'Roboto:300',
                ],
            },
            active: () => {
                setAreFontsLoaded(true);
            },
        });
    }, []);

    useEffect(() => {
        setIsAuthChecked(true);
    }, [error]);

    useEffect(() => {
        wordsStore.init(i18n.language);
        setTimeout(() => wordsStore.startProcessingWords());

        return () => {
            wordsStore.stopProcessingWords();
        };
    }, []);

    if (isLoading || !isAuthChecked || !areFontsLoaded) {
        return <></>;
    }

    return (
        <AuthContext.Provider value={{ authStore }}>
            <BrowserRouter>
                <AppWrapper>
                    <Header />
                    <ErrorBoundary>
                        <AppRouter />
                    </ErrorBoundary>
                </AppWrapper>
            </BrowserRouter>
        </AuthContext.Provider>
    );
};

export default observer(App);
