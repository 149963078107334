import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import { observer } from 'mobx-react-lite';
import TrackService from '../../../../../../api/TrackService';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import { Button, Label } from '../../../../../../teacherComponents';
import { SelectVideoModal } from '../../../../../TeacherContent/components';
import ManageVocabularySearch from '../ManageVocabularySearch/ManageVocabularySearch';
import { LanguageLevels } from '../../../../../../data/common';
import { Chapters } from '../../../../data/constants';
import { ReactComponent as IconAdd } from '../../../../../../assets/svg/lessons-add.svg';
import cl from './VideoWizard.module.css';

const VideoWizard = ({ onAdd }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { chaptersModalType } = exercisesStore;

    const { difficulty } = currentLesson;

    const [isOwn, setIsOwn] = useState(false);

    const [allTracksCount, setAllTracksCount] = useState(null);
    const [favTracksCount, setFavTracksCount] = useState(null);
    const [vocabulary, setVocabulary] = useState([]);

    const handleSelectClick = () => {
        exercisesStore.setChaptersModalType(Chapters.Track);
    };

    const [getTracks, isLoading] = useFetching(async () => {
        const params = {
            offset: 0,
            limit: 50,
            lang,
            difficulty,
            processed: null,
            recognized: true,
            vocabulary,
        };

        const { data: allTracks } = await TrackService.getTracks(params);
        const { data: favTracks } = await TrackService.getFavorites(params);

        setAllTracksCount(allTracks.items?.length || 0);
        setFavTracksCount(favTracks.items?.length || 0);
    });

    const handleOwnClick = () => {
        setIsOwn(true);
        setTimeout(() => {
            exercisesStore.setChaptersModalType(Chapters.Track);
        });
    };

    const addVideo = ({ id, title }) => {
        onAdd({ chapterId: id, title, chapterType: Chapters.Track });
        wizardStore.showChaptersList();
    };

    const handleModalClose = () => {
        setIsOwn(false);
        exercisesStore.setChaptersModalType();
    };

    const getDisplayedValue = (value) => (value >= 50 ? '50+' : value);

    useEffect(() => {
        if (!vocabulary.length) {
            getTracks();
        }
    }, [vocabulary]);

    return (
        <>
            <Label
                text={t('lesson_wizard.videos_filter_info')}
                style={{ whiteSpace: 'pre-line' }}
            />
            <ManageVocabularySearch
                variant={Chapters.Track}
                vocabulary={vocabulary}
                setVocabulary={setVocabulary}
                onSearch={getTracks}
                isLoading={isLoading}
                level={LanguageLevels[difficulty]}
            />
            <div className={cl.buttonsContainer}>
                <div>
                    <div className={cl.flexContainer}>
                        <Button
                            variant={Chapters.Track}
                            icon={<IconAdd />}
                            text={
                                typeof favTracksCount === 'number'
                                    ? `${t('teacher_navbar.tracks')} (${getDisplayedValue(favTracksCount)})`
                                    : t('teacher_navbar.tracks')
                            }
                            onClick={handleOwnClick}
                            isDisabled={favTracksCount === 0}
                        />
                        <Button
                            variant={Chapters.Track}
                            icon={<IconAdd />}
                            text={
                                typeof allTracksCount === 'number'
                                    ? `${t('teacher_onboarding.select_track')} (${getDisplayedValue(allTracksCount)})`
                                    : t('teacher_onboarding.select_track')
                            }
                            onClick={handleSelectClick}
                            isDisabled={allTracksCount === 0}
                        />
                    </div>
                </div>
            </div>
            <SelectVideoModal
                visible={chaptersModalType === Chapters.Track}
                setVisible={handleModalClose}
                onClick={addVideo}
                // TODO: pass already added categoriees packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isOwn}
                presetDifficulty={LanguageLevels[difficulty]}
                vocabulary={vocabulary}
            />
        </>
    );
};

export default observer(VideoWizard);
