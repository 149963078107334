import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, Switch } from '../../../../UI';
import {
    Button,
    CloseButton,
    Range,
    ToggleButton,
} from '../../../../teacherComponents';
import { wordsArray, wordsCategory } from '../../data/words';
import { ReactComponent as IconSound } from '../../../../assets/svg/icon-sound.svg';
import { Chapters } from '../../../TeacherLessons/data/constants';
import userWordsStore, {
    PackSizeOptions,
    PlayBackOptions,
    WordCardModes,
} from '../../../../views/User/pages/UserWords/store/userWords';
import { observer } from 'mobx-react-lite';
import { DictionaryCards } from '../../../../views/User/components';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import Modal from '../../../../components/UI/Modal/Modal';
import { ContentContainer } from '../../../../views/User/components';
import InfoModal from '../InfoModal/InfoModal';
import guestOnboarding from '../../store/guestOnboarding';
import cl from './DemoDictionaryPreview.module.css';
import languagesStore from '../../../../store/interface';

const WordCard = observer(({ wordObj, onClose, className }) => {
    const { t } = useTranslation();

    const [audio] = useState(new Audio());

    const [partsOfSpeech, setPartsOfSpeech] = useState([]);

    const handleAudioClick = (path) => {
        audio.pause();
        audio.src = path;
        setTimeout(() => {
            audio.play();
        });
    };

    const renderFormsBlock = ({ forms }) => {
        if (!forms || forms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.forms')}</p>
                <p className={cl.infoText}>{forms.join(', ')}</p>
            </div>
        );
    };

    const renderSynonymsBlock = ({ synonyms }) => {
        if (!synonyms || synonyms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.synonyms')}</p>
                <p className={cl.infoText}>{synonyms.join(', ')}</p>
            </div>
        );
    };

    useEffect(() => {
        if (wordObj) {
            audio.pause();
            audio.src = wordObj.voicePath ?? '';
        } else {
            audio.src = '';
        }
        setPartsOfSpeech(wordObj?.data?.map((w) => w.partOfSpeech ?? ''));
        if (!wordObj.data) return;
        setPartsOfSpeech(wordObj.data);
    }, [wordObj]);

    if (!wordObj) return null;

    return (
        <div className={`${cl.wordCard} ${className ? className : ''}`}>
            <div className={cl.wordText}>
                {wordObj.language === 'de' && wordObj.article}
                {wordObj?.word && <span>{wordObj.word}</span>}
                {wordObj?.voicePath && (
                    <PrimaryButton
                        onClick={() => handleAudioClick(wordObj.voicePath)}
                        icon={<IconSound className={cl.soundIcon} />}
                        variant={'white'}
                    />
                )}
            </div>

            {partsOfSpeech[0]?.pronunciation && (
                <p
                    className={cl.wordText}
                >{`${partsOfSpeech[0].pronunciation}`}</p>
            )}
            {partsOfSpeech.map((part) => (
                <div className={cl.card} key={part.id}>
                    <p className={cl.partOfSpeech}>{part.partOfSpeech}</p>
                    {renderFormsBlock(part)}
                    {renderSynonymsBlock(part)}
                    {part.examples && part.examples.length !== 0 && (
                        <div className={cl.infoCont}>
                            <p className={cl.infoLabel}>
                                {t('demo_page.phrases')}
                            </p>
                            {part.examples.map((ex) => (
                                <p className={cl.exampleText} key={ex.id}>
                                    <PrimaryButton
                                        onClick={() =>
                                            handleAudioClick(ex.voicePath)
                                        }
                                        icon={
                                            <IconSound
                                                className={cl.soundIcon}
                                            />
                                        }
                                        variant={'white'}
                                    />
                                    {ex.example}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            <CloseButton onClick={onClose} />
        </div>
    );
});

const Settings = observer(() => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const { cardModes, wordsPerPack, playbackSpeed } = userWordsStore;

    const handleSettingToggle = (index) => {
        userWordsStore.toggleCardMode(index);
    };

    const handlePlaybackChange = (e) => {
        userWordsStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <Button
                variant={'grey'}
                className={cl.settingsButton}
                onClick={() => setVisible(true)}
                icon={<IconSettings />}
            />

            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.settingsContainer}>
                    {Object.values(WordCardModes).map((mode, index) => (
                        <div className={cl.switchContainer} key={mode}>
                            <p className={cl.label}>
                                {t(`glossary_settings.${mode}`)}
                            </p>
                            <Switch
                                isOn={cardModes[index]}
                                handleToggle={() => handleSettingToggle(index)}
                                id={mode}
                            />
                        </div>
                    ))}
                </div>
                <p className={cl.toggleLabel}>
                    {t('glossary_settings.words_in_pack')}
                </p>
                <div className={cl.packSizeSelector}>
                    <ToggleButton
                        value={wordsPerPack}
                        onChange={userWordsStore.setWordsPerPack}
                        options={PackSizeOptions}
                        isGrid
                        variant={'transparent'}
                    />
                </div>
                <p> {t('glossary_settings.playback_speed')}</p>
                <Range
                    value={playbackSpeed}
                    min={PlayBackOptions[0]}
                    step={PlayBackOptions[1] - PlayBackOptions[0]}
                    max={PlayBackOptions[PlayBackOptions.length - 1]}
                    color={`var(--purple)`}
                    onChange={handlePlaybackChange}
                />
            </Modal>
        </>
    );
});

const StudentWords = observer(() => {
    const { nativeLang } = languagesStore;
    const [isLearned, setIsLearned] = useState(null);

    const getClassName = () => {
        if (isLearned === true) return cl.continue;
        if (isLearned === false) return cl.repeat;
        return '';
    };

    const currentTranslationWords = wordsArray.map((wObj) => ({
        ...wObj,
        translations: wObj.translations[nativeLang],
        examples: wObj.examples.map((eObj) => ({
            ...eObj,
            translation: eObj.translations[nativeLang],
        })),
    }));

    return (
        <ContentContainer className={`${cl.wordsContainer} ${cl.demo}`}>
            <div className={`${cl.learnWords} ${getClassName()}`}>
                <DictionaryCards
                    words={currentTranslationWords}
                    isLearned={isLearned}
                    setIsLearned={setIsLearned}
                    alreadyFetched={true}
                />
            </div>
        </ContentContainer>
    );
});

const DemoDictionaryPreview = ({ className, isStudent }) => {
    const { nativeLang } = languagesStore;
    const { t } = useTranslation();

    const { title } = wordsCategory;
    const [currentWord, setCurrentWord] = useState(wordsArray[0]);

    return (
        <>
            <div className={`${cl.previewContainer} ${className}`}>
                {isStudent ? (
                    <StudentWords />
                ) : (
                    <div className={cl.category}>
                        <p className={cl.title}>{title}</p>
                        <div className={cl.line} />

                        <div className={cl.wordsList}>
                            {wordsArray.map((w, i) => (
                                <div
                                    className={`${cl.word} ${
                                        currentWord?.word === w.word
                                            ? cl.active
                                            : ''
                                    }`}
                                    key={w.word}
                                    onClick={() => setCurrentWord(w)}
                                >
                                    <span>{`${i + 1}.`}</span>
                                    <p>{w.word}</p>
                                </div>
                            ))}
                        </div>

                        <WordCard
                            wordObj={currentWord}
                            category={wordsCategory}
                            onClose={() => setCurrentWord()}
                            lang={nativeLang}
                        />
                    </div>
                )}
            </div>
            <InfoModal
                delay={700}
                onboardingKey={guestOnboarding.isOnboarding(
                    Chapters.Dictionary
                )}
                variant={Chapters.Dictionary}
                title={t('teacher_tutorial.word_packs_info')}
                subtitle={t('teacher_tutorial.word_packs_info_subtitle')}
            />
        </>
    );
};

export default observer(DemoDictionaryPreview);
