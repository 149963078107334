import React, { useEffect, useCallback, useRef } from 'react';
import cl from './Modal.module.css';
import { CloseButton } from '../../../teacherComponents';

const Modal = ({
    children,
    visible,
    setVisible,
    desc,
    title,
    wrapTitle,
    style,
    className,
    addBlur,
    withCloseButton,
}) => {
    const contentRef = useRef();
    const rootClasses = [cl.modal];
    if (addBlur) rootClasses.push(cl.blur);
    if (visible) rootClasses.push(cl.active);

    const handleClose = useCallback(
        (e) => {
            if (
                e &&
                e.target &&
                contentRef?.current &&
                contentRef.current.contains(e.target)
            )
                return;

            if (setVisible) {
                e?.stopPropagation();
                setVisible(false);
            }
        },
        [setVisible]
    );

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape' && visible) {
                handleClose();
            }
        };

        document.addEventListener('keydown', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [visible, handleClose]);

    useEffect(() => {
        const body = document.querySelector('body');
        if (visible) {
            body.classList.add('blocked');
        } else {
            body.classList.remove('blocked');
        }
        return () => {
            body.classList.remove('blocked');
        };
    }, [visible]);

    return (
        <div
            className={rootClasses.join(' ')}
            onMouseDown={handleClose}
            style={{ zIndex: style?.zIndex || 5 }}
        >
            <div
                className={`${cl.modalContent} ${className ?? ''}`}
                ref={contentRef}
                style={{ ...style }}
            >
                {title && (
                    <p
                        style={{ whiteSpace: wrapTitle ? 'normal' : undefined }}
                        dangerouslySetInnerHTML={{ __html: title }}
                        className={cl.modalTitle}
                    ></p>
                )}
                {desc && (
                    <p style={{ marginBottom: 10, textAlign: 'center' }}>
                        {desc}
                    </p>
                )}
                {visible && children}
                {withCloseButton && (
                    <CloseButton onClick={() => setVisible(false)} />
                )}
            </div>
        </div>
    );
};

export default Modal;
