import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import {
    AgeOptions,
    getExamOptions,
    LevelOptions,
} from '../../../../../TeacherContent/data/constants';
import {
    Button,
    Label,
    Select,
    Tree,
} from '../../../../../../teacherComponents';
import { Chapters, LessonsGroupBy } from '../../../../data/constants';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import cl from './CreateLesson.module.css';
import Modal from '../../../../../../components/UI/Modal/Modal';
import Lesson from '../../../Lesson/Lesson';

const DefaultFormData = {
    age: '',
    difficulty: '',
    exam: '',
    grammarTopic: '',
    vocabularyTopic: '',
};

export const ExerciseTypes = {
    Grammar: 'grammar',
    Text: 'text',
    Video: 'video',
};

const CreateLesson = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { groupBy, lessons } = lessonsStore;
    const { grammarOptions, lexicalOptions } = wizardStore;

    const [formData, setFormData] = useState(DefaultFormData);
    const [formErrors, setFormErrors] = useState({});

    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredLexical, setFilteredLexical] = useState(lexicalOptions);

    const [showAvailableLessons, setShowAvailableLessons] = useState(false);
    const [availableLessons, setAvailableLessons] = useState([]);

    const { difficulty, grammarTopic, vocabularyTopic } = formData;

    const [createLesson, isLoading] = useFetching(async () => {
        const title = getLessonTitle();
        const { data } = await ExerciseService.createLesson({
            ...formData,
            title,
            age: formData.age === '' ? undefined : formData.age,
            lang,
            grammarTopic,
            vocabularyTopic,
            order: 0,
        });
        if (data && data.id) {
            processLesson(data);
            wizardStore.nextStep();
        }
    });

    const [getAvailableLessons, availableLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getLessons({
            offset: 0,
            limit: 100,
            lang,
            grammarTopic,
            vocabularyTopic,
        });
        const availableLessons = data?.items || [];
        const withoutExisting = availableLessons.filter(
            (l1Obj) => !lessons.some((l2Obj) => l2Obj.id === l1Obj.id)
        );
        setAvailableLessons(withoutExisting);
    });

    const [cloneLesson, isCloneLoading] = useFetching(async ({ lessonId }) => {
        const { data } = await ExerciseService.cloneLesson({ id: lessonId });
        if (data && data.id) {
            processLesson(data);
            wizardStore.setWizardVisible(false);
        }
    });

    const getLessonTitle = () => {
        const gObj = grammarTopic
            ? filteredGrammar
                  .flatMap((gObj) => gObj.topics)
                  .find((tObj) => tObj.topic === grammarTopic)
            : null;
        const vObj = filteredLexical
            .flatMap((lObj) => lObj.topics)
            .find((tObj) => tObj.topic === vocabularyTopic);

        return `${vObj?.shortName ?? vocabularyTopic}${gObj ? `: ${gObj.shortName}` : ''}`;
    };

    const processLesson = (lessonObject) => {
        lessonsStore.setCurrentLesson(lessonObject);
        lessonsStore.addLesson(
            groupBy === (LessonsGroupBy.Grammar && grammarTopic)
                ? grammarTopic
                : vocabularyTopic,
            {
                id: lessonObject.id,
                title: `${grammarTopic ? `${grammarTopic}: ` : ''}${vocabularyTopic}`,
                age: formData.age,
                chapters: [],
                difficulty,
                exam: formData.exam,
                grammarTopic: grammarTopic || lessonObject.grammarTopic,
                vocabularyTopic:
                    vocabularyTopic || lessonObject.vocabularyTopic,
                order: 0,
            }
        );
    };

    const handleLessonSelect = (lesson) => {
        cloneLesson({ lessonId: lesson.id });
    };

    const handleLessonCreate = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                difficulty: typeof formData.difficulty !== 'number',
                vocabularyTopic: !formData.vocabularyTopic,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            createLesson();
        });
    };

    const handleInputChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setFormData(DefaultFormData);
    }, []);

    useEffect(() => {
        setFormErrors({});
    }, [formData]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));

        setFilteredGrammar(currentGrammar);

        const currentLexical = lexicalOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));
        setFilteredLexical(currentLexical);
    }, [grammarOptions, lexicalOptions, difficulty]);

    useEffect(() => {
        handleInputChange('grammarTopic', '');
        handleInputChange('vocabularyTopic', '');
    }, [difficulty]);

    useEffect(() => {
        if (grammarTopic || vocabularyTopic) {
            getAvailableLessons();
        }
    }, [difficulty, grammarTopic, vocabularyTopic]);

    return (
        <>
            <Modal
                visible={showAvailableLessons}
                setVisible={setShowAvailableLessons}
                className={cl.lessonsModal}
            >
                {availableLessons.map((lObj) => (
                    <Lesson
                        key={lObj.id}
                        lessonObj={lObj}
                        onSelect={handleLessonSelect}
                    />
                ))}
            </Modal>
            <div className={cl.flexContainer}>
                <div>
                    <Label text={`${t('users.level')} *`} />
                    <Select
                        className={cl.select}
                        variant={
                            formErrors['difficulty'] ? 'errorSmall' : 'grey'
                        }
                        value={formData.difficulty}
                        options={LevelOptions}
                        onChange={(value) =>
                            handleInputChange('difficulty', value)
                        }
                        ignoreOverflow
                        hideReset
                    />
                </div>
                <div>
                    <Label text={t('users.age')} />
                    <Select
                        className={cl.select}
                        variant="grey"
                        value={formData.age}
                        options={AgeOptions}
                        onChange={(value) => handleInputChange('age', value)}
                        ignoreOverflow
                    />
                </div>
                <div>
                    <Label text={t('exercises.exam')} />
                    <Select
                        className={cl.select}
                        variant="grey"
                        value={formData.exam}
                        options={getExamOptions(lang)}
                        onChange={(value) => handleInputChange('exam', value)}
                        ignoreOverflow
                    />
                </div>
                <div>
                    <Label text={t('lesson_wizard.lesson_access_status')} />
                    <Select
                        className={cl.select}
                        variant="grey"
                        value={formData.status}
                        options={[
                            {
                                value: 'public',
                                name: t('lesson_wizard.lesson_status_public'),
                            },
                            {
                                value: 'private',
                                name: t('lesson_wizard.lesson_status_private'),
                            },
                        ]}
                        onChange={(value) => handleInputChange('status', value)}
                        ignoreOverflow
                    />
                </div>
            </div>
            <div>
                <Label text={`${t('lesson_wizard.select_lexical_topic')} *`} />
                <Tree
                    label={t('lesson_wizard.lexical_topic')}
                    className={cl.select}
                    variant={
                        formErrors['vocabularyTopic'] ? 'errorSmall' : 'grey'
                    }
                    value={formData.vocabularyTopic}
                    onChange={(value) =>
                        handleInputChange('vocabularyTopic', value)
                    }
                    options={filteredLexical}
                    hideReset
                    ignoreOverflow
                    height={240}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <div>
                <Label text={t('lesson_wizard.select_grammar_topic')} />
                <Tree
                    label={t('lesson_wizard.grammar_topic')}
                    className={cl.select}
                    variant="grey"
                    value={formData.grammarTopic}
                    onChange={(value) =>
                        handleInputChange('grammarTopic', value)
                    }
                    options={filteredGrammar}
                    ignoreOverflow
                    height={180}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <div className={cl.flexContainer}>
                {availableLessons.length !== 0 && (
                    <Button
                        icon={<IconStars />}
                        variant={Chapters.Track}
                        onClick={() => setShowAvailableLessons(true)}
                        isLoading={availableLoading}
                        text={`${t('lesson_wizard.find_lessons')} (${availableLessons.length})`}
                    />
                )}
                <Button
                    text={t('exercises.add_lesson')}
                    onClick={handleLessonCreate}
                    isLoading={isLoading}
                    variant={Chapters.Track}
                />
            </div>
        </>
    );
};

export default observer(CreateLesson);
