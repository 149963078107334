import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '..';
import TaskCard from '../TaskCard/TaskCard';
import cl from './TaskExample.module.css';

const TaskExample = () => {
    const { t } = useTranslation();

    return (
        <div className={cl.taskExample}>
            <p className={cl.title}>
                {t('teacher_tutorial.task_example_title')}
            </p>
            <Typography
                variant={'h3'}
                className={cl.subtitle}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.task_example_subtitle'
                )}
            />
            <TaskCard className={cl.taskCard} />
        </div>
    );
};

export default TaskExample;
