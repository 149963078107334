import React from 'react';
import { t } from 'i18next';
import Typography from '../Typography/Typography';
import ProfitCard from '../ProfitCard/ProfitCard';
import cl from './ProfitSection.module.css';

const ProfitSection = () => {
    return (
        <div className={cl.profitSectionContainer}>
            <Typography
                variant={'h2'}
                className={cl.title}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.profit_section_title'
                )}
            />
            <Typography
                variant={'h3'}
                className={cl.subtitle}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.profit_section_subtitle'
                )}
            />
            <div className={cl.cardsBlock}>
                <ProfitCard index={0} />
                <ProfitCard index={1} />
                <ProfitCard index={2} />
                <ProfitCard index={3} />
            </div>
        </div>
    );
};

export default ProfitSection;
