import React from 'react';
import { ReactComponent as FlagEn } from '../../../../assets/svg/flag_en.svg';
import { ReactComponent as FlagEs } from '../../../../assets/svg/flag_es.svg';
import { ReactComponent as FlagPt } from '../../../../assets/svg/flag_pt.svg';
import { ReactComponent as FlagDe } from '../../../../assets/svg/flag_de.svg';
import { ReactComponent as FlagFr } from '../../../../assets/svg/flag_fr.svg';
import { ReactComponent as FlagIt } from '../../../../assets/svg/flag_it.svg';
import cl from './LanguageCard.module.css';

const getImage = (lang) => {
    switch (lang) {
        case 'en':
            return FlagEn;
        case 'es':
            return FlagEs;
        case 'pt':
            return FlagPt;
        case 'de':
            return FlagDe;
        case 'fr':
            return FlagFr;
        case 'it':
            return FlagIt;
        default:
            return FlagEn;
    }
};

const getName = (lang) => {
    switch (lang) {
        case 'en':
            return 'English';
        case 'es':
            return 'Española';
        case 'pt':
            return 'Português';
        case 'de':
            return 'Deutsch';
        case 'fr':
            return 'Français';
        case 'it':
            return 'Italiano';
        default:
            return 'English';
    }
};

const LanguageCard = ({ lang }) => {
    const ImageComponent = getImage(lang);
    return (
        <div className={cl.languageCard}>
            <ImageComponent />
            <p>{getName(lang)}</p>
        </div>
    );
};

export default LanguageCard;
