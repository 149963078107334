import { makeAutoObservable } from 'mobx';
import { WizardSteps, WizardViews } from '../LessonWizard';

const DefaultWordPack = { items: [] };

class WizardStore {
    isWizardVisible = false;

    currentViewObj = WizardSteps[0];
    currentStepIndex = 0;
    extraStep = false;

    wordPack = DefaultWordPack;
    excludedWords = new Set();

    exerciseType = '';
    exerciseKind = '';
    textSource = '';

    currentVideo = null;

    grammarOptions = [];
    lexicalOptions = [];

    exerciseParams = {};

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setStepIndex(stepIndex) {
        this.stepIndex = stepIndex;
        this.setExtraStep(false);
    }

    setExerciseType(exerciseType) {
        this.exerciseType = exerciseType;
    }

    setExtraStep(extraStep) {
        this.extraStep = extraStep;
    }

    setExerciseKind(exerciseKind) {
        this.exerciseKind = exerciseKind;
    }

    setWizardVisible(isWizardVisible) {
        this.isWizardVisible = isWizardVisible;
        if (!isWizardVisible) {
            this.setCurrentView(WizardViews.CreateLesson);
        }
    }

    setCurrentView(view) {
        const viewObj = WizardSteps.find((vObj) => vObj.view === view);
        if (viewObj) {
            this.currentViewObj = viewObj;
            this.currentStepIndex = 0;
        }
    }

    setCurrentStepIndex(stepIndex) {
        this.currentStepIndex = stepIndex;
    }

    getCurrentViewAndStep() {
        const currentView = this.currentViewObj.view;
        const currentStep = this.currentViewObj?.steps[this.currentStepIndex];

        return [currentView, currentStep];
    }

    nextStep() {
        if (this.currentStepIndex < this.currentViewObj.steps.length - 1) {
            this.setCurrentStepIndex(this.currentStepIndex + 1);
        } else {
            const nextViewIndex =
                WizardSteps.findIndex(
                    (wObj) => wObj.view === this.currentViewObj.view
                ) + 1;
            if (nextViewIndex < WizardSteps.length) {
                this.setCurrentView(WizardSteps[nextViewIndex].view);
            } else {
                this.setWizardVisible(false);
            }
        }
    }

    prevStep() {
        if (this.extraStep) {
            this.setExtraStep(false);
            return;
        }
        if (this.currentStepIndex > 0) {
            this.setCurrentStepIndex(this.currentStepIndex - 1);
        } else {
            this.showChaptersList();
        }
    }

    setTextSource(textSource) {
        this.textSource = textSource;
    }

    setCurrentVideo(currentVideo) {
        this.currentVideo = currentVideo;
    }

    showChaptersList() {
        this.setWizardVisible(true);
        this.setCurrentView(WizardViews.AddChapter);
        this.setStepIndex(0);
    }

    setWordPack(wordPack) {
        this.wordPack = wordPack;
        this.excludedWords = new Set();
    }

    isWordDisabled(word) {
        return !this.excludedWords.has(word);
    }

    toggleWordInclusion(word) {
        const newExcludedWords = new Set(this.excludedWords);
        if (newExcludedWords.has(word)) {
            newExcludedWords.delete(word);
        } else {
            newExcludedWords.add(word);
        }
        this.excludedWords = newExcludedWords;
    }

    resetWordPack() {
        this.setWordPack(DefaultWordPack);
    }

    setGrammarOptions(grammarOptions) {
        this.grammarOptions = grammarOptions;
    }

    setLexicalOptions(lexicalOptions) {
        this.lexicalOptions = lexicalOptions;
    }

    setExerciseParams(exerciseParams) {
        this.exerciseParams = exerciseParams;
    }
}

const wizardStore = new WizardStore();
export default wizardStore;
