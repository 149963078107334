import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import wordsStore from '../../../../../../store/words';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import ProgressService from '../../../../../../api/ProgressService';
import { WordsRange } from '../../../../../TeacherContent/data/constants';
import {
    Button,
    Input,
    Label,
    Select,
} from '../../../../../../teacherComponents';
import { Checkbox, FilledRange } from '../../../../../../UI';
import { Chapters } from '../../../../data/constants';
import { SelectDictionaryModal } from '../../../../../TeacherContent/views/DictionaryView/components';
import { WizardViews } from '../../LessonWizard';
import { WordStatuses } from '../../../../../TeacherContent/views/DictionaryView/data/constants';
import { ReactComponent as IconAdd } from '../../../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import cl from './WordPackWizard.module.css';
import { LanguageLevels } from '../../../../../../data/common';

const PartsOfSpeech = {
    Any: 'any',
    Noun: 'noun',
    Pronoun: 'pronoun',
    Verb: 'verb',
    Adjective: 'adjective',
    Adverb: 'adverb',
    Preposition: 'preposition',
    Conjunction: 'conjunction',
    Interjection: 'interjection',
};

const SelectWordPack = observer(({ onSelect }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { difficulty } = currentLesson;

    const [isOwn, setIsOwn] = useState(false);
    const [searchString, setSearchString] = useState('');

    const [allPacksCount, setAllPacksCount] = useState(null);
    const [favPacksCount, setFavPacksCount] = useState(null);

    const [addCategory, addCategoryLoading] = useFetching(async (category) => {
        const { id, title } = category;
        if (!isOwn) {
            await ProgressService.cloneWordsCategory({
                id,
                lang,
            });
        }
        wizardStore.setWordPack(category);
        onSelect({ id, title });
    });

    const [getCategories, isLoading] = useFetching(async (category) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setAllExercisesCount(allExercises.items?.length || 0);
        // setFavExercisesCount(favExercises.items?.length || 0);
        setAllPacksCount(0);
        setFavPacksCount(null);
    });

    const { chaptersModalType } = exercisesStore;

    const handleSelectClick = () => {
        exercisesStore.setChaptersModalType(Chapters.Dictionary);
    };

    const handleOwnClick = () => {
        setIsOwn(true);
        setTimeout(() => {
            exercisesStore.setChaptersModalType(Chapters.Dictionary);
        });
    };

    useEffect(() => {
        if (!searchString.length) {
            getCategories();
        }
    }, [searchString]);

    return (
        <>
            <Label
                text={t('lesson_wizard.words_select_subtitle')}
                style={{ whiteSpace: 'pre-line' }}
            />

            <div className={cl.searchContainer}>
                <Input
                    placeholder={t('lesson_wizard.search_exercises')}
                    value={searchString}
                    onChange={setSearchString}
                    variant={'outlinedSmall'}
                    search
                    showReset
                />
                <Button
                    variant={Chapters.Dictionary}
                    text={`${t('lesson_wizard.search_by_words')} (${LanguageLevels[difficulty]})`}
                    onClick={getCategories}
                    isLoading={isLoading}
                />
            </div>

            <div className={cl.buttonsContainer}>
                <div className={cl.flexContainer}>
                    <Button
                        variant={'words'}
                        icon={<IconAdd />}
                        text={
                            typeof favPacksCount === 'number'
                                ? `${t('teacher_navbar.dictionaries')} (${favPacksCount})`
                                : t('teacher_navbar.dictionaries')
                        }
                        onClick={handleOwnClick}
                        isLoading={isOwn && addCategoryLoading}
                        isDisabled={favPacksCount === 0}
                    />
                    <Button
                        variant={'words'}
                        icon={<IconAdd />}
                        text={
                            typeof allPacksCount === 'number'
                                ? `${t('teacher_onboarding.select_words')} (${allPacksCount})`
                                : t('teacher_onboarding.select_words')
                        }
                        onClick={handleSelectClick}
                        isLoading={addCategoryLoading}
                        isDisabled={allPacksCount === 0}
                    />
                </div>

                <Button
                    className={cl.wideComponent}
                    variant={'words'}
                    icon={<IconStars />}
                    text={t('exercises.add_new_words')}
                    onClick={wizardStore.nextStep}
                />
            </div>

            <SelectDictionaryModal
                visible={chaptersModalType === Chapters.Dictionary}
                setVisible={() => exercisesStore.setChaptersModalType()}
                onClick={addCategory}
                // TODO: pass already added word packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isOwn}
            />
        </>
    );
});

const GenerateWordPack = observer(({ onGenerate }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { lexicalOptions } = wizardStore;
    const { difficulty, vocabularyTopic } = currentLesson;

    const currentVocabulary = lexicalOptions
        .flatMap((o) => o.topics)
        .find((tObj) => tObj.topic === vocabularyTopic) || {
        wordPackTitle: t('lesson_wizard.current_word_pack'),
    };

    const [partOfSpeech, setPartOfSpeech] = useState(PartsOfSpeech.Any);
    const [wordsAmount, setWordsAmount] = useState(10);
    const [formErrors, setFormErrors] = useState({});

    const [generateWords, generateLoading, generateError, resetGenerateError] =
        useFetching(async () => {
            const { wordPackTitle } = currentVocabulary;
            const { data } = await ExerciseService.generateWords({
                topic: vocabularyTopic,
                title: wordPackTitle,
                partOfSpeech,
                difficulty,
                lang,
                wordsAmount,
            });
            const { words } = data;
            createCategory({ words, title: wordPackTitle, difficulty });
        });

    const [createCategory, createLoading] = useFetching(
        async ({ words, title, difficulty }) => {
            const { data } = await ProgressService.createWordsCategory({
                title,
                lang,
                level: difficulty,
            });

            wizardStore.setWordPack({
                title: title,
                items: words.map((word) => ({
                    categoryId: data.id,
                    lang,
                    word,
                })),
            });
            handleGeneratedCategoryAdd(data, words);
        }
    );

    const handleGeneratedCategoryAdd = (addedCategory, generatedWords) => {
        const { id, title } = addedCategory;

        onGenerate({ id, title });
        for (const word of generatedWords) {
            wordsStore.addWord({
                word,
                categoryId: id,
                status: WordStatuses.Queued,
            });
        }
    };

    const handleWordsGenerate = () => {
        // resetErrors();

        // setTimeout(() => {
        //     const errors = {
        //         partOfSpeech: !partOfSpeech,
        //     };
        //     setFormErrors(errors);

        //     if (Object.values(errors).some((e) => e)) return;

        generateWords();
        // });
    };

    const isLoading = createLoading || generateLoading;

    const resetErrors = () => {
        resetGenerateError();
        setFormErrors({});
    };

    const handleRangeInput = (e) => {
        setWordsAmount(e.target.value);
    };

    useEffect(() => {
        // setWordsParams(DefaultWordsParams);
        setFormErrors({});
    }, []);

    useEffect(() => {
        setFormErrors({});
    }, [wordsAmount, partOfSpeech]);

    return (
        <>
            <div>
                <Label text={t(`lesson_wizard.words_generate_subtitle`)} />
                <Label text={t('lesson_wizard.generate_pack_info')} />
            </div>
            <div className={cl.flexContainer}>
                <div>
                    <Label
                        text={t('dictionary_administration.part_of_speech')}
                    />
                    <Select
                        className={cl.wideComponent}
                        variant={'grey'}
                        value={partOfSpeech}
                        options={Object.values(PartsOfSpeech).map((value) => ({
                            value,
                            name: t(`parts_of_speech.${value}`),
                        }))}
                        onChange={setPartOfSpeech}
                        height={140}
                    />
                </div>
                <div>
                    <Label text={t('exercises.words_amount')} />
                    <FilledRange
                        color={`var(--words-green)`}
                        value={wordsAmount}
                        min={WordsRange.Min}
                        step={WordsRange.Step}
                        max={WordsRange.Max}
                        onChange={handleRangeInput}
                    />
                </div>
            </div>

            {generateError && (
                <Label isError text={t('errors.unexpected_error')} />
            )}

            <Button
                style={{ marginTop: 'auto' }}
                text={t('exercises.add_new_words')}
                variant={Chapters.Dictionary}
                onClick={handleWordsGenerate}
                isLoading={isLoading}
            />
        </>
    );
});

const WordPackWizard = ({ onAdd }) => {
    const [view, step] = wizardStore.getCurrentViewAndStep();

    const handleWordPackAdd = ({ id, title }) => {
        onAdd({ chapterType: Chapters.Dictionary, chapterId: id, title });
        wizardStore.showChaptersList();
    };

    if (!view || view !== WizardViews.WordPack) return null;

    switch (step) {
        case 'select':
            return <SelectWordPack onSelect={handleWordPackAdd} />;
        case 'generate':
            return <GenerateWordPack onGenerate={handleWordPackAdd} />;

        default:
            return null;
    }
};

export default observer(WordPackWizard);
