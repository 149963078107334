import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import userWordsStore from '../../pages/UserWords/store/userWords';
import { mapChapterData } from '../../../../pages/TeacherContent/helpers/mapChapterData';
import DemoPageLoader from '../../../../components/UI/DemoPageLoader/DemoPageLoader';
import { Chapters } from '../../../../pages/TeacherLessons/data/constants';
import {
    ExerciseIcon,
    ExerciseMediaIcon,
} from '../../../../pages/TeacherContent/views/ExercisesView/components';
import { ReactComponent as IconFolder } from '../../../../assets/svg/lessons-folder.svg';
import { MediaExerciseTypes } from '../../../../pages/TeacherContent/data/constants';
import cl from './StudentChapterCard.module.css';

const StudentChapterCard = ({ chapter, type, isActive, setActive, isDemo }) => {
    const { t } = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);
    const [mappedChapter, setMappedChapter] = useState({});

    const { title, preview, params = [], progress } = mappedChapter;

    const { activeWords } = userWordsStore;

    const handleChapterClick = () => {
        if (!setActive || isActive || chapter.pending) return;
        setActive(chapter);
    };

    const getChapterClassName = () =>
        `${cl.chapterCard} ${isActive ? cl.active : ''}  ${cl[type]} ${chapter.pending ? cl.pending : ''}`;

    const renderWordCount = () => {
        const count =
            chapter?.wordsCount ?? chapter?.words?.length ?? [].length;

        return Number.isNaN(count) ? '' : count;
    };

    const renderProgress = () => {
        switch (type) {
            case Chapters.Track:
                return (
                    <div className={cl.trackProgress}>
                        <div
                            className={cl.progressFill}
                            style={{
                                width: `${100 - progress}%`,
                            }}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    const renderWordProgress = () => {
        if (type !== Chapters.Dictionary) return null;

        const word =
            activeWords.find((w) => w.id === chapter.wordId) || chapter;

        if (!word.levels || !Number.isInteger(word.lastIteration) || isDemo)
            return null;

        return (
            <div className={cl.wordProgress}>
                {word.levels.map((l) => (
                    <span
                        className={l < word.lastIteration ? cl.done : ''}
                        key={l}
                    />
                ))}
            </div>
        );
    };

    const getMediaType = () => {
        if (chapter.trackId && chapter.trackInterval) return 'video';
        if (Object.values(MediaExerciseTypes).includes(chapter.exerciseType))
            return 'text';
        if (chapter.text) return 'text';
        return chapter.exerciseType || chapter.type;
    };

    useEffect(() => {
        setMappedChapter(mapChapterData(chapter, type, t));
    }, [chapter]);

    return (
        <div className={getChapterClassName()} onClick={handleChapterClick}>
            {type !== Chapters.Dictionary && (
                <div className={cl.imageContainer}>
                    {preview && (
                        <>
                            {!isLoaded && (
                                <DemoPageLoader
                                    size={12}
                                    color={'var(--white)'}
                                />
                            )}
                            <img
                                src={preview}
                                alt={title}
                                onLoad={() => setIsLoaded(true)}
                            />
                        </>
                    )}
                    {renderProgress()}
                    {type === Chapters.Dictionary && (
                        <>
                            <div className={cl.folder}>
                                <IconFolder />
                            </div>
                            <p className={cl.count}>{renderWordCount()}</p>
                        </>
                    )}
                    {type === Chapters.Exercise && (
                        <>
                            <ExerciseIcon
                                type={chapter.exerciseType || chapter.type}
                            />
                            <ExerciseMediaIcon type={getMediaType()} />
                        </>
                    )}
                </div>
            )}
            {type === Chapters.Dictionary ? (
                <div className={cl.wordCard}>
                    <div className={cl.titleCont}>
                        {chapter.pending && (
                            <DemoPageLoader
                                className={cl.wordLoader}
                                size={12}
                                color={'var(--black)'}
                            />
                        )}
                        <p className={cl.title}>{title}</p>
                    </div>
                    <p className={cl.translation}>
                        {chapter?.translations?.wordTranslation}
                    </p>
                    {renderWordProgress()}
                </div>
            ) : (
                <div className={cl.cardInner}>
                    <p className={cl.title}>{title}</p>
                    <div className={cl.infoContainer}>
                        {params.map((p, i) => (
                            <p className={cl.label} key={i}>
                                {p}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(StudentChapterCard);
