import React from 'react';
import { ReactComponent as iconVideo } from '../../../../assets/svg/icon-video-mini.svg';
import { ReactComponent as iconExercises } from '../../../../assets/svg/icon-exercises-mini.svg';
import { ReactComponent as iconDialogues } from '../../../../assets/svg/icon-situations-mini.svg';
import { ReactComponent as iconWords } from '../../../../assets/svg/icon-dictionaries-mini.svg';
import cl from './LessonsCard.module.css';
import InfoWrapper from '../InfoWrapper/InfoWrapper';
import guestOnboarding from '../../store/guestOnboarding';
import { useTranslation } from 'react-i18next';
import {
    ArrowPositions,
    BubblePositions,
} from '../../../../teacherComponents/Onboarding';
import { observer } from 'mobx-react-lite';

let getTaskImage = (type) => {
    switch (type) {
        case 'exercises':
            return iconExercises;
        case 'video':
            return iconVideo;
        case 'dialogues':
            return iconDialogues;
        case 'words':
            return iconWords;
        default:
            return '';
    }
};

const LessonsCard = ({
    title,
    track,
    tasks,
    isFocused = false,
    onClick,
    isFirst = false,
}) => {
    const { t } = useTranslation();

    const onboardingKey = guestOnboarding.isOnboarding('lesson_examples');
    const isOnboarding = isFirst && onboardingKey;

    return (
        <div
            className={`${cl.lessonsCard} ${isFocused ? cl.focused : ''}`}
            onClick={!onboardingKey ? onClick : () => {}}
        >
            {isOnboarding ? (
                <InfoWrapper
                    onboardingKey={guestOnboarding.isOnboarding(
                        'lesson_examples'
                    )}
                    title={t('teacher_tutorial.lesson_examples_info')}
                    subtitle={t(
                        'teacher_tutorial.lesson_examples_info_subtitle'
                    )}
                    delay={500}
                    bubblePosition={{
                        x: BubblePositions.x.LeftBorder,
                        y: BubblePositions.y.Top,
                    }}
                    arrowPosition={ArrowPositions.Right}
                    showButton
                >
                    <div className={`${cl.cardContainer} ${cl.onboarding}`}>
                        <div className={cl.titleBlock}>
                            <p>{title}</p>
                        </div>
                        <div className={cl.cardContent}>
                            <div className={cl.cardTrack}>
                                <p>{track.title}</p>
                                <div>
                                    <p>{track.age}</p>∙<p>{track.level}</p>∙
                                    <p>{track.certificate}</p>
                                </div>
                            </div>
                            <div className={cl.cardTasks}>
                                {tasks.map((el, i) => {
                                    const TaskImage = getTaskImage(el.type);
                                    return (
                                        <div className={cl.task} key={i}>
                                            {TaskImage && <TaskImage />}
                                            <p>{el.text}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </InfoWrapper>
            ) : (
                <div className={cl.cardContainer}>
                    <div className={cl.titleBlock}>
                        <p>{title}</p>
                    </div>
                    <div className={cl.cardContent}>
                        <div className={cl.cardTrack}>
                            <p>{track.title}</p>
                            <div>
                                <p>{track.age}</p>∙<p>{track.level}</p>∙
                                <p>{track.certificate}</p>
                            </div>
                        </div>
                        <div className={cl.cardTasks}>
                            {tasks.map((el, i) => {
                                const TaskImage = getTaskImage(el.type);
                                return (
                                    <div className={cl.task} key={i}>
                                        {TaskImage && <TaskImage />}
                                        <p>{el.text}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(LessonsCard);
