import React from 'react';
import { t } from 'i18next';
import { ReactComponent as Check } from '../../../../assets/svg/icon-check.svg';
import { authStore } from '../../../../App';
import { AuthModes } from '../../../../store/auth';
import cl from './CostCard.module.css';

const getCardContent = (type, isTeacher) => {
    let text = {};
    if (isTeacher) {
        text.who = t('teacher_tutorial.costcard_who_teacher');
        if (type === 'free') {
            text.student_count = t('teacher_tutorial.costcard_count_zero');
            text.title = t('teacher_tutorial.costcard_price_free');
            text.functionality = t(
                'teacher_tutorial.costcard_functionality_limited'
            );
            text.functions = [
                t('teacher_tutorial.costcard_function_library'),
                t('teacher_tutorial.costcard_function_gen_exercise_free'),
                t('teacher_tutorial.costcard_function_gen_dialogues_free'),
                t('teacher_tutorial.costcard_function_gen_words'),
            ];
        }
        if (type === 'semi-free') {
            text.student_count = t('teacher_tutorial.costcard_count_two');
            text.title = t('teacher_tutorial.costcard_price_free');
            text.functionality = t(
                'teacher_tutorial.costcard_functionality_full'
            );
            text.functions = [
                t('teacher_tutorial.costcard_function_library'),
                t('teacher_tutorial.costcard_function_gen_exercise'),
                t('teacher_tutorial.costcard_function_gen_dialogues'),
                t('teacher_tutorial.costcard_function_gen_words'),
            ];
        }
        if (type === 'premium') {
            text.student_count = t('teacher_tutorial.costcard_count_premium');
            text.title = t('teacher_tutorial.costcard_price');
            text.functionality = t(
                'teacher_tutorial.costcard_functionality_full'
            );
            text.functions = [
                t('teacher_tutorial.costcard_function_library'),
                t('teacher_tutorial.costcard_function_gen_exercise'),
                t('teacher_tutorial.costcard_function_gen_dialogues'),
                t('teacher_tutorial.costcard_function_gen_words'),
            ];
        }
    } else {
        text.who = t('teacher_tutorial.costcard_who_student');
        if (type === 'free') {
            text.title = t('teacher_tutorial.costcard_price_free');
            text.functionality = t(
                'teacher_tutorial.costcard_functionality_limited'
            );
            text.functions = [
                t('teacher_tutorial.costcard_function_do_exercises'),
                t('teacher_tutorial.costcard_function_watch_video'),
                t('teacher_tutorial.costcard_function_do_dialogues'),
                t('teacher_tutorial.costcard_function_do_dictionaries'),
                t('teacher_tutorial.costcard_function_add_to_dictionaries'),
            ];
        }
        if (type === 'premium') {
            text.title = t('teacher_tutorial.costcard_price');
            text.functionality = t(
                'teacher_tutorial.costcard_functionality_full'
            );
            text.functions = [
                t('teacher_tutorial.costcard_function_translate_video'),
                t(
                    'teacher_tutorial.costcard_function_context_translation_dialogues'
                ),
                t(
                    'teacher_tutorial.costcard_function_add_selected_to_dictionaries_video'
                ),
                t(
                    'teacher_tutorial.costcard_function_context_translation_dialogues'
                ),
                t(
                    'teacher_tutorial.costcard_function_add_selected_to_dictionaries_dialogues'
                ),
            ];
        }
    }
    return text;
};

const CostCard = ({ type, isTeacher }) => {
    const handleSignInButton = () => {
        authStore.setAuthMode(AuthModes.Login);
        authStore.setIsAuth(true);
    };
    let content = getCardContent(type, isTeacher);
    let isPremium = type === 'premium';
    let isFree = type === 'free';
    return (
        <div className={cl.cardContainer}>
            <div className={cl.header}>
                <p className={cl.who}>{content.who}</p>
                {isTeacher && <p>{content.student_count}</p>}
            </div>
            <p className={cl.title}>
                {isPremium ? (
                    <>
                        12 $ <span>/{content.title}</span>
                    </>
                ) : (
                    content.title
                )}
            </p>
            <p className={`${cl.subtitle} ${isFree ? cl.subtitle_free : ''}`}>
                {content.functionality}
            </p>
            <div className={cl.br}></div>
            <div className={cl.list}>
                {content.functions.map((el, i) => (
                    <div key={i} className={cl.list_element}>
                        <Check />
                        <p>{el}</p>
                    </div>
                ))}
            </div>
            <div
                className={`${cl.button} ${isFree ? cl.button_free : ''}`}
                onClick={handleSignInButton}
            >
                {t('teacher_tutorial.costcard_button')}
            </div>
        </div>
    );
};

export default CostCard;
