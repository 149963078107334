import React, { useState } from 'react';
import Typography from '../Typography/Typography';
import { t } from 'i18next';
import SelectionBar from '../SelectionBar/SelectionBar';
import CostCard from '../CostCard/CostCard';
import Image from '../../../../assets/webp/cost_background.webp';
import cl from './CostSection.module.css';

const CostSection = () => {
    const [isTeacher, setTeacher] = useState(true);

    return (
        <div className={cl.costBlock}>
            <Typography
                variant={'h2'}
                className={cl.title}
                dangerouslySetInnerHTML={`${t('teacher_tutorial.cost_title')}:`}
            />
            <Typography
                variant={'h3'}
                className={cl.subtitle}
                dangerouslySetInnerHTML={`${t('teacher_tutorial.cost_subtitle')}`}
            />
            <SelectionBar
                isTeacher={isTeacher}
                onChange={setTeacher}
                isCostSection={true}
            />
            <div className={cl.cardsBlock}>
                <img src={Image} alt="Background" />
                {isTeacher ? (
                    <>
                        <CostCard type={'free'} isTeacher={isTeacher} />
                        <CostCard type={'semi-free'} isTeacher={isTeacher} />
                        <CostCard type={'premium'} isTeacher={isTeacher} />
                    </>
                ) : (
                    <>
                        <CostCard type={'free'} isTeacher={isTeacher} />
                        <CostCard type={'premium'} isTeacher={isTeacher} />
                    </>
                )}
            </div>
        </div>
    );
};

export default CostSection;
