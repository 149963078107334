export const lessons = [
    {
        id: '619083ce-6119-4005-b6e1-303de8f77252',
        title: 'Wardrobe Building: Linking Words',
        description: null,
        lang: 'en',
        pdfs: null,
        pictures: null,
        ownerId: '21cc12d1-44ed-49ad-bc7c-569d863d820b',
        age: 2,
        difficulty: 2,
        grammarTopic:
            'Linking words for contrast, reason, result (however, therefore, so)',
        vocabularyTopic: 'Building a versatile wardrobe and personal style',
        exam: '',
        basic: false,
        createdAt: '2025-01-20T14:09:48.130Z',
        chapters: [
            {
                id: 'dc14ce7e-c5f3-42c2-af1b-959b33fda86b',
                title: 'Summarize the Volvo 740',
                exerciseType: 'summary',
                difficulty: 2,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
                title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
                image: '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
                type: 'situation',
                order: 0,
            },
            {
                id: 'ffca8b2b-cf4f-4e1e-8d78-254014a2a2e5',
                title: 'How to JUGGLE a Soccer Ball for Beginners',
                image: {
                    md: 'https://i.ytimg.com/vi/SxVaMcHqcoU/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: 'How to JUGGLE a Soccer Ball for Beginners',
                },
                description:
                    'Learn to Juggle a Football or Soccer Ball with these tips!\n\nSUBSCRIBE: http://www.youtube.com/channel/UC0Ik25PHaiHCbfGrzu-lBFQ?sub_confirmation=1\n\nSpanish Translation and Subtitling: Fernando - ferssch3@outlook.com\n\nFollow us on Facebook!: https://www.facebook.com/allattack12 \nInstagram!: @all_attack https://instagram.com/all_attack/\nTikTok: https://www.tiktok.com/@officialallattack\n\nBusiness Email: allattackinc@gmail.com\n\nMusic: Candy - The Wildcardz\n\n0:00 Intro\n0:18 Drop to Thigh\n1:06 Drop to Feet\n2:14 Learn the Flick-Up\n3:10 Alternate Feet\n4:01 Juggle with a Partner\n5:12 Don’t Give Up\n5:53 Basketball Hoop Challenge \n6:52 7 Point Challenge',
                podcastTitle: 'AllAttack',
                duration: 482,
                difficulty: 2,
                type: 'track',
                order: 0,
            },
            {
                id: '3680cdef-8108-4f2d-8ec2-ddaa81c9ee72',
                title: 'Building a versatile wardrobe and personal style',
                type: 'words',
                order: 0,
            },
        ],
    },
];
