import React from 'react';
import { t } from 'i18next';
import { ReactComponent as iconPDF } from '../../../../assets/svg/icon-pdf.svg';
import { ReactComponent as iconHyperlinks } from '../../../../assets/svg/icon-hyperlinks.svg';
import { ReactComponent as iconPony } from '../../../../assets/svg/icon-pony.svg';
import cl from './InteractionCard.module.css';
import { Button } from '../../../../teacherComponents';

const getInteractionIcon = (type) => {
    switch (type) {
        case 'PDF':
            return iconPDF;
        case 'hyperlinks':
            return iconHyperlinks;
        case 'pony':
            return iconPony;
        default:
            return '';
    }
};

const getInteractionTitle = (type) => {
    switch (type) {
        case 'PDF':
            return t('teacher_tutorial.title_pdf');
        case 'hyperlinks':
            return t('teacher_tutorial.title_hyperlinks');
        case 'pony':
            return t('teacher_tutorial.title_pony');
        default:
            return '';
    }
};

const getInteractionDescription = (type) => {
    switch (type) {
        case 'PDF':
            return t('teacher_tutorial.description_pdf');
        case 'hyperlinks':
            return t('teacher_tutorial.description_hyperlinks');
        case 'pony':
            return t('teacher_tutorial.description_pony');
        default:
            return '';
    }
};

const getStyle = (type) => {
    switch (type) {
        case 'PDF':
            return cl.pdf;
        case 'hyperlinks':
            return cl.hyperlinks;
        case 'pony':
            return cl.pony;
        default:
            return '';
    }
};

const InteractionCard = ({ type }) => {
    const InteractionIcon = getInteractionIcon(type);
    const style = getStyle(type);

    return (
        <div className={`${cl.card} ${style} ${cl[type]}`}>
            <div className={cl.cardImg}>
                <InteractionIcon />
            </div>
            <p className={cl.title}>{getInteractionTitle(type)}</p>
            <p className={cl.description}>{getInteractionDescription(type)}</p>
            {type !== 'pony' && (
                <Button
                    className={cl.button}
                    variant={type === 'PDF' ? 'bordo' : 'navy'}
                    text={t('teacher_tutorial.work_with_student_button')}
                />
            )}
        </div>
    );
};

export default InteractionCard;
