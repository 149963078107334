import React from 'react';
import { observer } from 'mobx-react-lite';
import player from '../../store/player';
import getTopCenterAndOffset from '../../utils/getTopCenterAndOffset';
import toHoursAndMinutes from '../../utils/toHoursAndMinutes';
import { ReactComponent as IconGoNext } from '../../assets/svg/lessons-forward.svg';
import { ReactComponent as IconGoPrev } from '../../assets/svg/lessons-rewind.svg';
import { ReactComponent as IconPlay } from '../../assets/svg/lessons-play.svg';
import { ReactComponent as IconPause } from '../../assets/svg/lessons-pause.svg';
import { TicksInSecond } from '../../data/common';
import cl from './PlayerControls.module.css';

const PlayerControls = ({ onRewind }) => {
    const percent = (player.currentTime / player.duration) * 100;

    const eventPrevNextSentenceBtn = (e) => {
        if (onRewind) {
            onRewind(e);
            return;
        }

        const dir =
            e.currentTarget.attributes.getNamedItem('data-direction').value;

        const rewindTime = TicksInSecond * 5;
        let newTime =
            dir === 'prev'
                ? player.currentTime - rewindTime
                : player.currentTime + rewindTime;

        if (newTime < 0) newTime = 0;
        if (newTime > player.duration) newTime = player.duration;

        player.handleChangeAudioTime(newTime, true);
    };

    const handleTracklineClick = (e) => {
        const clientX = e.clientX;
        const { left, width } = getTopCenterAndOffset(e.target);

        const clickPercent = ((clientX - left) / width) * 100;

        player.handleChangeAudioTime(
            (player.duration / 100) * clickPercent,
            true
        );
    };

    return (
        <div className={cl.container}>
            <div className={cl.trackLineWrapper}>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(
                        Math.round(player.currentTime / TicksInSecond)
                    )}
                </span>
                <div className={cl.trackLine} onClick={handleTracklineClick}>
                    <div
                        className={cl.trackProgress}
                        style={{ width: `${percent}%` }}
                    />
                    <div
                        className={cl.dragger}
                        style={{ left: `${percent}%` }}
                    />
                </div>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(player.duration / TicksInSecond)}
                </span>
            </div>

            <div className={cl.controlsCont}>
                <button
                    className={cl.controlBtn}
                    data-direction="prev"
                    onClick={eventPrevNextSentenceBtn}
                >
                    <IconGoPrev />
                </button>
                <button
                    className={cl.playBtn}
                    onClick={player.handlePlayPauseClick}
                >
                    {player.isPlaying ? <IconPause /> : <IconPlay />}
                </button>
                <button
                    className={cl.controlBtn}
                    data-direction="next"
                    onClick={eventPrevNextSentenceBtn}
                >
                    <IconGoNext />
                </button>
            </div>
        </div>
    );
};

export default observer(PlayerControls);
