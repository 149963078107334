import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import SituationsService from '../../../../api/SituationsService';
import languagesStore from '../../../../store/interface';
import userDialoguesStore, {
    PlayBackOptions,
} from '../../pages/UserDialogues/store/userDialogues';
import { Loader } from '../../../../UI';
import { Button, Range, ToggleButton } from '../../../../teacherComponents';
import Modal from '../../../../components/UI/Modal/Modal';
import { ExerciseMode, ListenMode, SpeakMode } from './modes';
import {
    GENDERS,
    MODALITIES,
} from '../../../../pages/SituationsAdministration/data/constants';
import { ReactComponent as IconHappy } from '../../../../assets/svg/lessons-happy.svg';
import { ReactComponent as IconNeutral } from '../../../../assets/svg/lessons-neutral.svg';
import { ReactComponent as IconSad } from '../../../../assets/svg/lessons-sad.svg';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import cl from './LearnSituation.module.css';
import ProgressService from '../../../../api/ProgressService';

const ICONS = [
    <IconHappy className={cl.happy} />,
    <IconNeutral className={cl.neutral} />,
    <IconSad className={cl.sad} />,
];

const LearningModes = ['listen', 'exercise', 'speak', 'translation'];

const Settings = observer(({ gender, setGender, modality, setModality }) => {
    const { t } = useTranslation();
    const { playbackSpeed } = userDialoguesStore;
    const [visible, setVisible] = useState(false);

    const handlePlaybackChange = (e) => {
        userDialoguesStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <Button
                variant={'grey'}
                className={cl.settingsButton}
                onClick={() => setVisible(true)}
                icon={<IconSettings />}
            />
            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.horizontalContainer}>
                    <div className={cl.packSizeSelector}>
                        <p className={cl.toggleLabel}>
                            {t('situations.gender')}
                        </p>
                        <ToggleButton
                            value={gender}
                            onChange={setGender}
                            options={GENDERS}
                            isGrid
                            variant={'transparent'}
                        />
                    </div>

                    <div className={cl.packSizeSelector}>
                        <p className={cl.toggleLabel}>
                            {t('situations.modality')}
                        </p>
                        <ToggleButton
                            value={modality}
                            onChange={setModality}
                            options={MODALITIES}
                            isGrid
                            variant={'transparent'}
                            icons={ICONS}
                        />
                    </div>
                </div>

                <p className={cl.rangeLabel}>
                    {t('glossary_settings.playback_speed')}
                </p>
                <Range
                    value={playbackSpeed}
                    min={PlayBackOptions[0]}
                    step={PlayBackOptions[1] - PlayBackOptions[0]}
                    max={PlayBackOptions[PlayBackOptions.length - 1]}
                    color={`var(--purple)`}
                    onChange={handlePlaybackChange}
                />
                <Button
                    variant={'dark'}
                    text={t('buttons.save')}
                    onClick={() => setVisible(false)}
                />
            </Modal>
        </>
    );
});

const LearnSituation = ({ situaiton, isModal }) => {
    const { t, i18n } = useTranslation();

    const { id } = situaiton;
    const { lang, nativeLang } = languagesStore;

    const [progress, setProgress] = useState();
    const [direction, setDirection] = useState(0);
    const [mode, setMode] = useState(LearningModes[0]);
    const [statements, setStatements] = useState([]);
    const [statementIndex, setStatementIndex] = useState(0);
    const [info, setInfo] = useState(situaiton.info ?? []);
    const [gender, setGender] = useState();
    const [modality, setModality] = useState();

    const variants = {
        enter: {
            opacity: 0.5,
            transform:
                direction > 0
                    ? 'translate(-50%, 0%)'
                    : 'translate(-50%, -100%)',
        },

        center: { opacity: 1, transform: 'translate(-50%, -50%)' },
        exit: {
            opacity: 0.5,
            transform:
                direction > 0
                    ? 'translate(-50%, -100%)'
                    : 'translate(-50%, 0%)',
        },
    };

    const startY = useRef(null);
    const isSwiping = useRef(false);

    const [getSituationInfo] = useFetching(async () => {
        const { data } = await SituationsService.getSituationInfo({
            id,
            language: i18n.language.toUpperCase(),
        });
        setInfo(data?.info ?? []);
    });

    const [getSituation, isSituationLoading, isEmpty, resetEmpty] = useFetching(
        async () => {
            const { data } = await SituationsService.getSituation({
                id,
                language: lang.toUpperCase(),
                gender,
                modality,
                targetLanguage: nativeLang.toUpperCase(),
            });

            // const { data: progressData } =
            //     await ProgressService.getSituationProgress({
            //         situationId: id,
            //         language: lang,
            //     });
            // setProgress({
            //     listen: progressData.listeningCompleted,
            //     exercise: progressData.puzzleCompleted,
            //     speak: progressData.speakingCompleted,
            //     translation: progressData.translationCompleted,
            // });

            const { statements } = data;
            setStatements(statements);
            resetEmpty();
        }
    );
    const [sendProgress] = useFetching(async (progress) => {
        const mappedProgress = {
            listeningCompleted: progress.listen,
            puzzleCompleted: progress.exercise,
            speakingCompleted: progress.speak,
            translationCompleted: progress.translation,
        };
        await ProgressService.sendSituationProgress({
            situationId: id,
            language: lang,
            ...mappedProgress,
        });
    });

    const handleMouseDown = (e) => {
        startY.current = e.clientY;
        isSwiping.current = true;
    };

    const handleMouseUp = (e) => {
        if (!isSwiping.current) return;

        const delta = e.clientY - startY.current;
        const threshold = window.innerHeight * 0.15;

        if (delta > threshold) {
            switchStatement(-1);
        } else if (delta < -threshold) {
            switchStatement(1);
        }

        isSwiping.current = false;
        startY.current = null;
    };

    const handleScroll = (e) => {
        const delta = e.deltaY;
        const threshold = window.innerHeight * 0.15;

        if (delta > threshold) {
            switchStatement(1);
        } else if (delta < -threshold) {
            switchStatement(-1);
        }
    };

    const renderMode = () => {
        if (!statements[statementIndex]) return null;
        if (statements[statementIndex].actor === 'B')
            return <ListenMode statement={statements[statementIndex]} />;
        switch (mode) {
            case LearningModes[0]:
                return <ListenMode statement={statements[statementIndex]} />;
            case LearningModes[1]:
                return <ExerciseMode statement={statements[statementIndex]} />;
            case LearningModes[2]:
                return <SpeakMode statement={statements[statementIndex]} />;
            case LearningModes[3]:
                return (
                    <SpeakMode
                        statement={statements[statementIndex]}
                        translationMode
                    />
                );
            default:
                return null;
        }
    };

    const switchStatement = (newDirection) => {
        const nextIndex =
            (statementIndex + newDirection + statements.length) %
            statements.length;

        if (newDirection === 1 && nextIndex === 0) return;
        if (newDirection === -1 && nextIndex === statements.length - 1) return;

        setDirection(newDirection);
        setStatementIndex(nextIndex);
    };
    const renderActorIcon = () => {
        if (!statements[statementIndex]) return null;
        const { actor } = statements[statementIndex];
        return (
            <div className={actor === 'A' ? cl.iconMe : cl.iconThey}>
                <div className={`${cl.equalizer} ${true ? cl.active : ''}`}>
                    <span /> <span /> <span /> <span /> <span />
                </div>
                <span />
                <span>
                    {t(`learn_dialogue.${actor === 'A' ? 'you' : 'opponent'}`)}
                </span>
            </div>
        );
    };

    const getModeStyle = (mode) => {
        if (!progress || !progress[mode]) return {};

        const currentProgress = progress[mode] || 0;
        const fillWidth = currentProgress * 100;

        return {
            background: `linear-gradient(to right, var(--dialogue-progress-purple) ${fillWidth}%, var(--light-purple) ${fillWidth}%)`,
        };
    };

    const handleModeChange = (newMode) => {
        setStatementIndex(0);
        setMode(newMode);
    };

    useEffect(() => {
        if (!gender || !modality) return;
        setMode(LearningModes[0]);
        setStatementIndex(0);
        setProgress();
        getSituation();
    }, [id, gender, nativeLang, modality]);

    useEffect(() => {
        if (!info.length) return;
        setGender(info[0].gender);
        setModality(info[0].modality);
    }, [info]);

    useEffect(() => {
        if (id && !situaiton.info) {
            getSituationInfo();
        }
    }, [situaiton]);

    useEffect(() => {
        if (!statements || statements.length === 0 || statementIndex === null) {
            setProgress({});
        } else {
            setProgress((prevProgress) => ({
                ...prevProgress,
                [mode]:
                    Math.round((statementIndex / statements.length) * 100) /
                    100,
            }));
        }
    }, [mode, statementIndex, statements]);

    if (isSituationLoading || !statements.length)
        return <Loader style={{ margin: 'auto' }} />;

    if (isEmpty)
        return (
            <p className={cl.emptyAlert}>{t('situations.empty_situation')}</p>
        );

    if (!nativeLang || lang === nativeLang)
        return (
            <p className={cl.emptyAlert}>{t('user_view.select_target_lang')}</p>
        );

    return (
        <div className={isModal ? cl.modalContainer : cl.learnContainer}>
            <div className={cl.containerInner}>
                <div className={cl.currentMode}>
                    <div className={cl.modeSelector}>
                        {LearningModes.map((m) => (
                            <Button
                                style={getModeStyle(m)}
                                variant={m === mode ? 'lightPurple' : 'grey'}
                                className={cl.mode}
                                key={m}
                                onClick={() => handleModeChange(m)}
                                text={t(`learn_dialogue.${m}`)}
                            />
                        ))}
                        <Settings
                            gender={gender}
                            setGender={setGender}
                            modality={modality}
                            setModality={setModality}
                        />
                    </div>
                </div>

                <div
                    className={cl.modeContainer}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onWheel={handleScroll}
                >
                    <motion.div
                        className={cl.card}
                        key={statementIndex}
                        custom={direction}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.5 }}
                    >
                        {renderActorIcon()}
                        {renderMode()}
                    </motion.div>

                    <div className={cl.statementControls}>
                        <Button
                            variant={'white'}
                            icon={<IconArrow className={cl.arrowIcon} />}
                            className={cl.arrowButton}
                            onClick={() => switchStatement(-1)}
                        />
                        <Button
                            variant={'white'}
                            icon={<IconArrow className={cl.arrowIcon} />}
                            className={cl.arrowButton}
                            onClick={() => switchStatement(1)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(LearnSituation);
