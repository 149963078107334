import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../../../../../teacherComponents';
import replaceTrailingPunctuation from '../../../../../../utils/replaceTrailingPunctuation';
import shuffleArray from '../../../../../../utils/shuffleArray';
import { ReactComponent as IconQuestion } from '../../../../../../assets/svg/question.svg';
import cl from './ExerciseMode.module.css';
import { URL } from '../../../../../../api/SituationsService';

const ExerciseMode = ({ statement }) => {
    const { sentences, translate } = statement;
    const translatedSentences = translate?.split('. ') ?? '';
    const mappedStatement = {
        examples: sentences.map((s, i) => ({
            example: s.text,
            translation: translatedSentences[i],
            sentenceVoice: s.sentenceVoice,
        })),
    };

    const [currentIdx, setCurrentIdx] = useState(0);
    const [isCorrect, setIsCorrect] = useState(null);
    const [wordsAmount, setWordsAmount] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [options, setOptions] = useState([]);
    const [audio] = useState(new Audio());

    const timeoutRef = useRef();

    const examples = mappedStatement.examples.map((eObj) => eObj.example);
    const translations = mappedStatement.examples.map(
        (eObj) => eObj.translation
    );
    const sentence = replaceTrailingPunctuation(examples[currentIdx] || '');

    const handleWordClick = (wObj, idx) => {
        if (!wObj || isCorrect) return;

        if (idx !== undefined) {
            const updatedAnswers = [...answers];
            updatedAnswers[idx] = null;
            setAnswers(updatedAnswers);
            setOptions([...options, wObj]);
        } else {
            const firstEmptyIndex = answers.findIndex((ans) => !ans);
            if (firstEmptyIndex !== -1) {
                const updatedAnswers = [...answers];
                updatedAnswers[firstEmptyIndex] = wObj;
                setAnswers(updatedAnswers);
                setOptions(options.filter((opt) => opt.idx !== wObj.idx));
            }
        }
    };

    const checkAnswer = () => {
        const flag =
            answers
                .map((wObj) => wObj.word)
                .join(' ')
                .toLowerCase() === sentence.toLowerCase();
        setIsCorrect(flag);
    };

    const handleNextSentence = () => {
        setCurrentIdx(currentIdx + 1);
    };

    const handleHintClick = () => {
        const correctAnswers = sentence.split(' ').map((word, idx) => ({
            word,
            idx,
        }));
        setAnswers(correctAnswers);
        setOptions([]);
    };

    const playAudio = () => {
        audio.pause();
        const voicePath = mappedStatement.examples[currentIdx]?.sentenceVoice;
        audio.src = `${URL}/${voicePath}`;
        audio.play();
    };

    const getSpanStyle = (wordObj) => {
        if (wordObj) return {};
        return {
            minWidth: 35,
        };
    };

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        setCurrentIdx(0);
    }, [statement]);

    useEffect(() => {
        if (!sentence.length) {
            setOptions([]);
            setAnswers([]);
            setWordsAmount(0);
            return;
        }
        const correctAnswers = sentence.split(' ');
        const shuffledOptions = shuffleArray(correctAnswers).map(
            (word, idx) => ({
                word,
                idx,
            })
        );

        setAnswers(Array(correctAnswers.length).fill(null));
        setOptions(shuffledOptions);
        setWordsAmount(correctAnswers.length);
    }, [currentIdx]);

    useEffect(() => {
        if (answers.filter(Boolean).length === wordsAmount) {
            checkAnswer();
        } else {
            setIsCorrect(null);
        }
    }, [answers]);

    useEffect(() => {
        if (isCorrect) {
            playAudio();

            audio.addEventListener('ended', handleNextSentence);

            return () => {
                audio.removeEventListener('ended', handleNextSentence);
            };
        }
    }, [isCorrect]);

    return (
        <motion.div
            className={cl.card}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <div className={cl.translationList}>
                {examples
                    .filter((_e, i) => i < currentIdx)
                    .map((text, idx) => (
                        <p
                            className={`${cl.translationText} ${
                                currentIdx > idx
                                    ? cl.correct
                                    : currentIdx === idx
                                      ? cl.active
                                      : ''
                            }`}
                            key={text}
                        >
                            {text}
                        </p>
                    ))}
            </div>
            <div
                className={`${cl.answerCont} ${
                    isCorrect !== null
                        ? isCorrect
                            ? cl.correct
                            : cl.wrong
                        : ''
                }`}
            >
                {Array(wordsAmount)
                    .fill('')
                    .map((_w, idx) => {
                        const classNames = [cl.word];
                        const wordObj = answers[idx];

                        if (typeof isCorrect === 'boolean') {
                            const correctWord = sentence.split(' ')[idx];
                            const isCorrectWord = wordObj?.word === correctWord;
                            classNames.push(
                                isCorrectWord ? cl.correct : cl.wrong
                            );
                        }

                        return (
                            <span
                                className={classNames.join(' ')}
                                style={getSpanStyle(wordObj)}
                                key={`answer_${idx}`}
                                onClick={() => handleWordClick(wordObj, idx)}
                            >
                                {wordObj?.word || ''}
                            </span>
                        );
                    })}
                {/* {Array(wordsAmount)
                    .fill('')
                    .map((_w, idx) => (
                        <span
                            className={cl.word}
                            style={{
                                minWidth: answers[idx] ? 'unset' : 75,
                            }}
                            key={`answer_${idx}`}
                            onClick={() => handleWordClick(answers[idx])}
                        >
                            {answers[idx]?.word || ''}
                        </span>
                    ))} */}
            </div>
            <div className={cl.translationList}>
                {translations
                    .filter((_e, i) => i >= currentIdx)
                    .map((text, idx) => (
                        <p
                            className={`${cl.translationText}  ${idx === 0 ? cl.active : ''}`}
                            key={text}
                        >
                            {text}
                        </p>
                    ))}
            </div>
            <Button
                className={`${cl.hintButton} ${isCorrect ? cl.hidden : ''}`}
                icon={<IconQuestion />}
                variant={'grey'}
                onClick={handleHintClick}
            />
            {translations[currentIdx] && (
                <div className={cl.optionsCont}>
                    {options.map((wObj, idx) => (
                        <span
                            className={cl.word}
                            key={`option_${idx}`}
                            onClick={() => handleWordClick(wObj)}
                        >
                            {wObj.word}
                        </span>
                    ))}
                </div>
            )}
        </motion.div>
    );
};

export default ExerciseMode;
