import React, { useState, useEffect } from 'react';
import { AdjustableInput } from '../';
import cl from './ClozePreview.module.css';

const ClozePreview = ({ exerciseObj, results, showAnswers }) => {
    const { data } = exerciseObj;

    const { sentences } = data;

    const [words, setWords] = useState([]);
    const [keyboardAnswers, setKeyboardAnswers] = useState([]);

    const renderWord = (w) => {
        if (!w.hidden) return w.word;

        const answerObj = keyboardAnswers.find((a) => a.word.id === w.id);

        return results || showAnswers ? (
            <p
                className={`${cl.answer} ${
                    answerObj.answer === answerObj.word.word
                        ? cl.correct
                        : showAnswers
                          ? ''
                          : cl.wrong
                }`}
            >
                {keyboardAnswers.find((a) => a.word.id === w.id)?.answer}
            </p>
        ) : (
            <AdjustableInput
                isCorrect={w.isCorrect}
                value={answerObj?.answer || ''}
                onChange={(e) => {
                    handleKeyboardInput(w, e.target.value);
                }}
                key={w.id}
            />
        );
    };

    const handleKeyboardInput = (w, input) => {
        const newAnswers = keyboardAnswers.map((a) =>
            a.word.id === w.id ? { ...a, answer: input } : a
        );
        setKeyboardAnswers(newAnswers);
    };

    const displayAnswers = () => {
        setKeyboardAnswers(
            keyboardAnswers.map((a) => ({
                ...a,
                answer: a.word.word,
            }))
        );
    };

    const resetAnswers = () => {
        if (!words.length || results) return;
        setKeyboardAnswers(
            keyboardAnswers.map((a) => ({
                ...a,
                answer: '',
            }))
        );
    };

    useEffect(() => {
        const flatWords = sentences?.map((s) => s.words).flat() ?? [];
        const hiddenWords = flatWords.filter((w) => w?.hidden);

        setWords(flatWords);
        if (results && results.userAnswers) {
            const userAnswers = results.userAnswers.map((a) => a[0] || '');
            setKeyboardAnswers(
                hiddenWords.map((w, i) => ({ word: w, answer: userAnswers[i] }))
            );
        } else {
            setKeyboardAnswers(
                hiddenWords.map((w) => ({ word: w, answer: '' }))
            );
        }
    }, [exerciseObj]);

    useEffect(() => {
        showAnswers ? displayAnswers() : resetAnswers();
    }, [showAnswers]);

    useEffect(() => {
        if (results && results.userAnswers) {
        }
    }, [results]);

    return (
        words.length !== 0 && (
            <div className={cl.exerciseText}>
                {words.map((w) => (
                    <span className={cl.wordContainer} key={w.id}>
                        {renderWord(w)}
                        {w.endChar && <span>{w.endChar}</span>}
                    </span>
                ))}
            </div>
        )
    );
};

export default ClozePreview;
