import React from 'react';
import { observer } from 'mobx-react-lite';
import { FirstSection, Footer } from './components';
import { authStore } from '../../App';
import LessonsExample from './components/LessonsExample/LessonsExample';
import InteractionExample from './components/InteractionExample/InteractionExample';
import DownloadSection from './components/DownloadSection/DownloadSection';
import CostSection from './components/CostSection/CostSection';
import AuthModal from '../../components/AuthModal/AuthModal';
import ProfitSection from './components/ProfitSection/ProfitSection';
import TaskExample from './components/TaskExample/TaskExample';
import cl from './TeachersTutorial.module.css';

const TeachersTutorial = () => {
    const { isAuth } = authStore;

    return (
        <div className={`${cl.teacherLanding} ${isAuth ? cl.blocked : ''}`}>
            <FirstSection />
            <TaskExample />
            <LessonsExample />
            <InteractionExample /> 
            <ProfitSection />
            <CostSection />
            <DownloadSection />
            <Footer />
            <AuthModal />
        </div>
    );
};

export default observer(TeachersTutorial);
