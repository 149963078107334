export const wordsCategory = {
    id: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
    title: 'Airport Vocabulary B1',
};

export const wordsArray = [
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'baggage',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/baggage_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '56c530e4-78b1-47a7-b32b-c4954de665a8',
                partOfSpeech: 'noun',
                pronunciation: '[ˈbæɡɪdʒ]',
                article: null,
                forms: ['baggage'],
                synonyms: ['belongings', 'luggage', 'suitcases'],
                examples: [
                    {
                        id: '08aa4d69-2618-422c-8f7e-c3a9a54c1005',
                        example: 'Is this all your baggage?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_08aa4d69-2618-422c-8f7e-c3a9a54c1005_en.mp3',
                    },
                    {
                        id: '6e4036e2-d243-4f60-9d83-5f6911c3ed42',
                        example: 'Can you help me with my baggage?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_6e4036e2-d243-4f60-9d83-5f6911c3ed42_en.mp3',
                    },
                    {
                        id: 'b76a8676-3ef8-4a43-999c-0867ba685dbc',
                        example:
                            'She was carrying too much baggage for one person.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_b76a8676-3ef8-4a43-999c-0867ba685dbc_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '56c530e4-78b1-47a7-b32b-c4954de665a8',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[ˈbæɡɪdʒ]',
        forms: ['baggage'],
        synonyms: ['belongings', 'luggage', 'suitcases'],
        examples: [
            {
                id: '08aa4d69-2618-422c-8f7e-c3a9a54c1005',
                example: 'Is this all your baggage?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_08aa4d69-2618-422c-8f7e-c3a9a54c1005_en.mp3',
                translations: {
                    ru: 'Это весь ваш багаж?',
                    de: 'Ist das alles Ihr Gepäck?',
                    it: 'È tutto qui il suo bagaglio?',
                    fr: "C'est tout votre bagage ?",
                    pt: 'Esta é toda a tua bagagem?',
                    es: '¿Este es todo tu equipaje?',
                },
            },
            {
                id: '6e4036e2-d243-4f60-9d83-5f6911c3ed42',
                example: 'Can you help me with my baggage?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_6e4036e2-d243-4f60-9d83-5f6911c3ed42_en.mp3',
                translations: {
                    ru: 'Не могли бы вы помочь мне с багажом?',
                    de: 'Können Sie mir mit meinem Gepäck helfen?',
                    it: 'Può aiutarmi con il mio bagaglio?',
                    fr: "Pouvez-vous m'aider avec mes bagages ?",
                    pt: 'Podem ajudar-me com a minha bagagem?',
                    es: '¿Puede ayudarme con mi equipaje?',
                },
            },
            {
                id: 'b76a8676-3ef8-4a43-999c-0867ba685dbc',
                example: 'She was carrying too much baggage for one person.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_b76a8676-3ef8-4a43-999c-0867ba685dbc_en.mp3',
                translations: {
                    ru: 'У нее было слишком много багажа для одного человека.',
                    de: 'Sie trug zu viel Gepäck für eine Person mit sich herum.',
                    it: 'Portava con sé troppi bagagli per una sola persona.',
                    fr: 'Elle portait trop de bagages pour une seule personne.',
                    pt: 'Ela estava a carregar demasiada bagagem para uma só pessoa.',
                    es: 'Llevaba demasiado equipaje para una sola persona.',
                },
            },
        ],
        lastIteration: 0,
        translations: {
            ru: ['вещи', 'багаж', 'чемоданы'],
            de: ['Hab und Gut', 'Gepäck', 'Reisekoffer'],
            it: ['effetti personali', 'bagagli', 'valigie'],
            fr: ['biens', 'bagages', 'valises'],
            pt: ['pertences', 'bagagem', 'malas'],
            es: ['pertenencias', 'equipaje', 'maletas'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'terminal',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/terminal_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '0eb39976-cb74-4bac-86d7-21ba934bd4d4',
                partOfSpeech: 'adjective',
                pronunciation: '[ˈtɜːrmɪnəl]',
                article: null,
                forms: ['terminal', 'terminals'],
                synonyms: ['ultimate', 'last', 'final'],
                examples: [
                    {
                        id: '3c79ebeb-0384-4649-904b-39903481c909',
                        example: 'Is this the terminal station for this train?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_3c79ebeb-0384-4649-904b-39903481c909_en.mp3',
                    },
                    {
                        id: '6b656a28-31a7-45af-b120-998c62263717',
                        example:
                            'The terminal stage of the disease is very painful.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_6b656a28-31a7-45af-b120-998c62263717_en.mp3',
                    },
                    {
                        id: 'd2c3d5af-e18b-4dd3-af3b-77a0a3d70750',
                        example: 'Are there any shops in the airport terminal?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_d2c3d5af-e18b-4dd3-af3b-77a0a3d70750_en.mp3',
                    },
                ],
            },
            {
                id: '9a10c011-9396-4e8e-ab35-6341e498f815',
                partOfSpeech: 'noun',
                pronunciation: '[ˈtɜːrmɪnəl]',
                article: null,
                forms: ['terminal', 'terminals'],
                synonyms: ['port', 'depot', 'station'],
                examples: [
                    {
                        id: '7b168b81-018b-46aa-b4d4-3beffdbb8a78',
                        example: 'The bus terminal was crowded with people.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_7b168b81-018b-46aa-b4d4-3beffdbb8a78_en.mp3',
                    },
                    {
                        id: '9287f378-8726-4d17-abbf-60b8acc3c07e',
                        example:
                            'Are there any restaurants near the airport terminal?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_9287f378-8726-4d17-abbf-60b8acc3c07e_en.mp3',
                    },
                    {
                        id: 'ace8bfb9-fb88-44d1-be09-07dac028993f',
                        example: 'Where is the nearest train terminal?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_ace8bfb9-fb88-44d1-be09-07dac028993f_en.mp3',
                    },
                ],
            },
            {
                id: 'bcfb13ce-22ca-4367-ba68-8ea6813c5733',
                partOfSpeech: 'adverb',
                pronunciation: '[ˈtɜːrmɪnəli]',
                article: null,
                forms: ['terminal', 'terminally'],
                synonyms: ['mortally', 'lethally', 'fatally'],
                examples: [
                    {
                        id: '0c9c8f21-7f48-4c67-94ae-4d219097b9b1',
                        example: 'Is she terminally ill?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_0c9c8f21-7f48-4c67-94ae-4d219097b9b1_en.mp3',
                    },
                    {
                        id: '6d853b19-3d25-4586-86a0-7a3c6cc65499',
                        example: 'The patient was diagnosed as terminally ill.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_6d853b19-3d25-4586-86a0-7a3c6cc65499_en.mp3',
                    },
                    {
                        id: 'ee8481bd-0767-4a7e-94fe-b0e37e98951d',
                        example: 'He was terminally ill with cancer.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_ee8481bd-0767-4a7e-94fe-b0e37e98951d_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '0eb39976-cb74-4bac-86d7-21ba934bd4d4',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'adjective',
        pronunciation: '[ˈtɜːrmɪnəl]',
        forms: ['terminal', 'terminals'],
        synonyms: ['ultimate', 'last', 'final'],
        examples: [
            {
                id: '3c79ebeb-0384-4649-904b-39903481c909',
                example: 'Is this the terminal station for this train?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_3c79ebeb-0384-4649-904b-39903481c909_en.mp3',
                translations: {
                    ru: 'Это конечная станция поезда?',
                    de: 'Ist dies der Endbahnhof für diesen Zug?',
                    it: 'È la stazione terminale di questo treno?',
                    fr: "S'agit-il de la gare terminale de ce train ?",
                    pt: 'Esta é a estação terminal deste comboio?',
                    es: '¿Es ésta la estación terminal de este tren?',
                },
            },
            {
                id: '6b656a28-31a7-45af-b120-998c62263717',
                example: 'The terminal stage of the disease is very painful.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_6b656a28-31a7-45af-b120-998c62263717_en.mp3',
                translations: {
                    ru: 'Терминальная стадия заболевания очень болезненна.',
                    de: 'Das Endstadium der Krankheit ist sehr schmerzhaft.',
                    it: 'Lo stadio terminale della malattia è molto doloroso.',
                    fr: 'La phase terminale de la maladie est très douloureuse.',
                    pt: 'A fase terminal da doença é muito dolorosa.',
                    es: 'La fase terminal de la enfermedad es muy dolorosa.',
                },
            },
            {
                id: 'd2c3d5af-e18b-4dd3-af3b-77a0a3d70750',
                example: 'Are there any shops in the airport terminal?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_d2c3d5af-e18b-4dd3-af3b-77a0a3d70750_en.mp3',
                translations: {
                    ru: 'Есть ли магазины в терминале аэропорта?',
                    de: 'Gibt es im Flughafenterminal irgendwelche Geschäfte?',
                    it: "Ci sono negozi nel terminal dell'aeroporto?",
                    fr: "Y a-t-il des magasins dans le terminal de l'aéroport ?",
                    pt: 'Existem lojas no terminal do aeroporto?',
                    es: '¿Hay tiendas en la terminal del aeropuerto?',
                },
            },
        ],
        lastIteration: 1,
        translations: {
            ru: ['окончательный', 'последний', 'окончательный'],
            de: ['ultimativ', 'zuletzt', 'endgültig'],
            it: ['ultimo', 'ultimo', 'finale'],
            fr: ['ultime', 'dernier', 'final'],
            pt: ['definitivo', 'último', 'final'],
            es: ['lo último en', 'último', 'final'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'departure',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/departure_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: 'e382b5d0-880e-4b81-b498-977c6551400f',
                partOfSpeech: 'noun',
                pronunciation: '[dɪˈpɑːrtʃər]',
                article: null,
                forms: ['departure', 'departures'],
                synonyms: ['farewell', 'exit', 'leaving'],
                examples: [
                    {
                        id: '0d95d259-3fae-436b-bbe0-3732deabbd58',
                        example: 'His sudden departure left us all in shock.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_0d95d259-3fae-436b-bbe0-3732deabbd58_en.mp3',
                    },
                    {
                        id: '327bd80c-39b4-4dc4-911b-374fdb26dacb',
                        example:
                            'How many departures are there from this airport daily?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_327bd80c-39b4-4dc4-911b-374fdb26dacb_en.mp3',
                    },
                    {
                        id: '50541393-6a59-46cf-b7ff-b5bdc0fe450c',
                        example:
                            'The departure of the train was delayed due to bad weather.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_50541393-6a59-46cf-b7ff-b5bdc0fe450c_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: 'e382b5d0-880e-4b81-b498-977c6551400f',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[dɪˈpɑːrtʃər]',
        forms: ['departure', 'departures'],
        synonyms: ['farewell', 'exit', 'leaving'],
        examples: [
            {
                id: '0d95d259-3fae-436b-bbe0-3732deabbd58',
                example: 'His sudden departure left us all in shock.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_0d95d259-3fae-436b-bbe0-3732deabbd58_en.mp3',
                translations: {
                    ru: 'Его внезапный уход поверг всех нас в шок.',
                    de: 'Sein plötzliches Ausscheiden hat uns alle schockiert.',
                    it: 'La sua improvvisa partenza ci ha lasciato tutti sotto shock.',
                    fr: 'Son départ soudain nous a tous laissés sous le choc.',
                    pt: 'A sua partida repentina deixou-nos a todos em estado de choque.',
                    es: 'Su repentina marcha nos dejó a todos conmocionados.',
                },
            },
            {
                id: '327bd80c-39b4-4dc4-911b-374fdb26dacb',
                example:
                    'How many departures are there from this airport daily?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_327bd80c-39b4-4dc4-911b-374fdb26dacb_en.mp3',
                translations: {
                    ru: 'Сколько рейсов ежедневно совершается из этого аэропорта?',
                    de: 'Wie viele Abflüge gibt es täglich von diesem Flughafen?',
                    it: 'Quante partenze ci sono ogni giorno da questo aeroporto?',
                    fr: 'Combien y a-t-il de départs quotidiens de cet aéroport ?',
                    pt: 'Quantas partidas são efectuadas diariamente a partir deste aeroporto?',
                    es: '¿Cuántas salidas diarias hay desde este aeropuerto?',
                },
            },
            {
                id: '50541393-6a59-46cf-b7ff-b5bdc0fe450c',
                example:
                    'The departure of the train was delayed due to bad weather.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_50541393-6a59-46cf-b7ff-b5bdc0fe450c_en.mp3',
                translations: {
                    ru: 'Отправление поезда было отложено из-за плохой погоды.',
                    de: 'Die Abfahrt des Zuges verzögerte sich aufgrund des schlechten Wetters.',
                    it: 'La partenza del treno è stata ritardata a causa del maltempo.',
                    fr: 'Le départ du train a été retardé en raison du mauvais temps.',
                    pt: 'A partida do comboio foi atrasada devido ao mau tempo.',
                    es: 'La salida del tren se retrasó debido al mal tiempo.',
                },
            },
        ],
        lastIteration: 2,
        translations: {
            ru: ['прощание', 'выход', 'покидая'],
            de: ['Abschied nehmen', 'Ausgang', 'verlassen.'],
            it: ['addio', 'uscita', 'lasciando'],
            fr: ['adieu', 'sortie', 'quitter'],
            pt: ['despedida', 'saída', 'saída'],
            es: ['adiós', 'salida', 'dejando'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'arrival',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/arrival_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '8c009010-0122-4737-b356-fac1ff77021d',
                partOfSpeech: 'noun',
                pronunciation: '[əˈraɪvəl]',
                article: null,
                forms: ['arrival', 'arrivals'],
                synonyms: ['entry', 'appearance', 'advent'],
                examples: [
                    {
                        id: '1183ee98-ca20-4bad-b46a-b23a8d90c41d',
                        example:
                            'The arrivals at the airport were delayed due to bad weather.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_1183ee98-ca20-4bad-b46a-b23a8d90c41d_en.mp3',
                    },
                    {
                        id: 'ac013616-1c5b-4519-9d30-45ea93aa8176',
                        example: 'The arrival of the guests was unexpected.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_ac013616-1c5b-4519-9d30-45ea93aa8176_en.mp3',
                    },
                    {
                        id: 'd372c86b-1788-4875-bd4f-c292b3b7849e',
                        example: 'When is your arrival at the airport?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_d372c86b-1788-4875-bd4f-c292b3b7849e_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '8c009010-0122-4737-b356-fac1ff77021d',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[əˈraɪvəl]',
        forms: ['arrival', 'arrivals'],
        synonyms: ['entry', 'appearance', 'advent'],
        examples: [
            {
                id: '1183ee98-ca20-4bad-b46a-b23a8d90c41d',
                example:
                    'The arrivals at the airport were delayed due to bad weather.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_1183ee98-ca20-4bad-b46a-b23a8d90c41d_en.mp3',
                translations: {
                    ru: 'Прибытие в аэропорт было задержано из-за плохой погоды.',
                    de: 'Die Ankunft am Flughafen verzögerte sich aufgrund des schlechten Wetters.',
                    it: "Gli arrivi all'aeroporto sono stati ritardati a causa del maltempo.",
                    fr: "Les arrivées à l'aéroport ont été retardées en raison du mauvais temps.",
                    pt: 'As chegadas ao aeroporto sofreram atrasos devido ao mau tempo.',
                    es: 'Las llegadas al aeropuerto se retrasaron debido al mal tiempo.',
                },
            },
            {
                id: 'ac013616-1c5b-4519-9d30-45ea93aa8176',
                example: 'The arrival of the guests was unexpected.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_ac013616-1c5b-4519-9d30-45ea93aa8176_en.mp3',
                translations: {
                    ru: 'Появление гостей было неожиданным.',
                    de: 'Die Ankunft der Gäste war unerwartet.',
                    it: "L'arrivo degli ospiti è stato inaspettato.",
                    fr: "L'arrivée des invités est inattendue.",
                    pt: 'A chegada dos convidados foi inesperada.',
                    es: 'La llegada de los invitados fue inesperada.',
                },
            },
            {
                id: 'd372c86b-1788-4875-bd4f-c292b3b7849e',
                example: 'When is your arrival at the airport?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_d372c86b-1788-4875-bd4f-c292b3b7849e_en.mp3',
                translations: {
                    ru: 'Когда вы прибудете в аэропорт?',
                    de: 'Wann ist Ihre Ankunft am Flughafen?',
                    it: "Quando è previsto l'arrivo in aeroporto?",
                    fr: "Quand arriverez-vous à l'aéroport ?",
                    pt: 'Quando é a sua chegada ao aeroporto?',
                    es: '¿Cuándo llega al aeropuerto?',
                },
            },
        ],
        lastIteration: 3,
        translations: {
            ru: ['вход', 'внешний вид', 'адвент'],
            de: ['Eintrag', 'Aussehen', 'Advent'],
            it: ['ingresso', 'aspetto', 'avvento'],
            fr: ['entrée', 'apparence', 'avènement'],
            pt: ['entrada', 'aparência', 'advento'],
            es: ['entrada', 'aparición', 'adviento'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'luggage',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/luggage_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '6f3b276f-2453-4417-89ea-05229270b506',
                partOfSpeech: 'noun',
                pronunciation: '[ˈlʌɡɪdʒ]',
                article: null,
                forms: ['luggage'],
                synonyms: ['suitcase', 'baggage', 'belongings'],
                examples: [
                    {
                        id: 'a410f779-33d2-4b43-9d1e-560c8ab43604',
                        example: 'She packed her luggage for the trip.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_a410f779-33d2-4b43-9d1e-560c8ab43604_en.mp3',
                    },
                    {
                        id: 'ad8154c7-d045-44d1-9138-bd8197a5aaa8',
                        example: 'His luggage was lost during the flight.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_ad8154c7-d045-44d1-9138-bd8197a5aaa8_en.mp3',
                    },
                    {
                        id: 'b8183ee5-47f1-4c8d-affb-59591bc732f1',
                        example: 'Do you have any luggage to check in?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_b8183ee5-47f1-4c8d-affb-59591bc732f1_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '6f3b276f-2453-4417-89ea-05229270b506',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[ˈlʌɡɪdʒ]',
        forms: ['luggage'],
        synonyms: ['suitcase', 'baggage', 'belongings'],
        examples: [
            {
                id: 'a410f779-33d2-4b43-9d1e-560c8ab43604',
                example: 'She packed her luggage for the trip.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_a410f779-33d2-4b43-9d1e-560c8ab43604_en.mp3',
                translations: {
                    ru: 'Она собрала свой багаж для поездки.',
                    de: 'Sie packte ihr Gepäck für die Reise.',
                    it: 'Ha preparato i bagagli per il viaggio.',
                    fr: 'Elle prépare ses bagages pour le voyage.',
                    pt: 'Fez as malas para a viagem.',
                    es: 'Hizo las maletas para el viaje.',
                },
            },
            {
                id: 'ad8154c7-d045-44d1-9138-bd8197a5aaa8',
                example: 'His luggage was lost during the flight.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_ad8154c7-d045-44d1-9138-bd8197a5aaa8_en.mp3',
                translations: {
                    ru: 'Его багаж был потерян во время полета.',
                    de: 'Sein Gepäck ist während des Fluges verloren gegangen.',
                    it: 'Il suo bagaglio è stato perso durante il volo.',
                    fr: 'Ses bagages ont été perdus pendant le vol.',
                    pt: 'A sua bagagem perdeu-se durante o voo.',
                    es: 'Su equipaje se perdió durante el vuelo.',
                },
            },
            {
                id: 'b8183ee5-47f1-4c8d-affb-59591bc732f1',
                example: 'Do you have any luggage to check in?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_b8183ee5-47f1-4c8d-affb-59591bc732f1_en.mp3',
                translations: {
                    ru: 'Вам нужно зарегистрировать багаж?',
                    de: 'Haben Sie Gepäck zum Einchecken?',
                    it: 'Ha bagagli da imbarcare?',
                    fr: 'Avez-vous des bagages à enregistrer ?',
                    pt: 'Tem alguma bagagem para registar?',
                    es: '¿Tiene equipaje que facturar?',
                },
            },
        ],
        lastIteration: 4,
        translations: {
            ru: ['Чемодан', 'багаж', 'вещи'],
            de: ['Koffer', 'Reisegepäck', 'Hab und Gut'],
            it: ['valigia', 'bagaglio', 'effetti personali'],
            fr: ['valise', 'bagages', 'biens'],
            pt: ['mala', 'bagagem', 'pertences'],
            es: ['maleta', 'equipaje', 'pertenencias'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'gate',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/gate_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '337a08d6-5d7a-4f97-b00c-dbebf1801ec1',
                partOfSpeech: 'transitive verb',
                pronunciation: '[geɪt]',
                article: null,
                forms: ['gate', 'gated', 'gates', 'gating'],
                synonyms: ['restrict', 'bar', 'block'],
                examples: [
                    {
                        id: '5a543ffa-ea01-4f63-a86c-8faecc09436e',
                        example: 'Why did they gate the community?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_5a543ffa-ea01-4f63-a86c-8faecc09436e_en.mp3',
                    },
                    {
                        id: '8c1aeb1f-b60f-4591-b247-75d4e64d10e5',
                        example:
                            'The company has gated its software to prevent unauthorized access.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_8c1aeb1f-b60f-4591-b247-75d4e64d10e5_en.mp3',
                    },
                    {
                        id: 'b51f46f6-a03e-4962-9da3-451bc2bdb11a',
                        example:
                            'They decided to gate the entrance to the park.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_b51f46f6-a03e-4962-9da3-451bc2bdb11a_en.mp3',
                    },
                ],
            },
            {
                id: 'd082fa26-5f8f-477c-81ac-7c220571453f',
                partOfSpeech: 'noun',
                pronunciation: '[geɪt]',
                article: null,
                forms: ['gate', 'gates'],
                synonyms: ['entrance', 'doorway', 'portal'],
                examples: [
                    {
                        id: '08ad71b9-5789-4c6c-bbcc-789c581b318f',
                        example: 'How many gates does this airport have?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_08ad71b9-5789-4c6c-bbcc-789c581b318f_en.mp3',
                    },
                    {
                        id: '9deadd09-bfad-42f0-a8cb-2fcd375d81ac',
                        example: 'She opened the gates and let the dogs out.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_9deadd09-bfad-42f0-a8cb-2fcd375d81ac_en.mp3',
                    },
                    {
                        id: 'e497f2e8-dcc8-4c56-8ec5-58c2de79f792',
                        example: 'The gate to the garden was locked.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_e497f2e8-dcc8-4c56-8ec5-58c2de79f792_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '337a08d6-5d7a-4f97-b00c-dbebf1801ec1',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'transitive verb',
        pronunciation: '[geɪt]',
        forms: ['gate', 'gated', 'gates', 'gating'],
        synonyms: ['restrict', 'bar', 'block'],
        examples: [
            {
                id: '5a543ffa-ea01-4f63-a86c-8faecc09436e',
                example: 'Why did they gate the community?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_5a543ffa-ea01-4f63-a86c-8faecc09436e_en.mp3',
                translations: {
                    ru: 'Почему они покинули общину?',
                    de: 'Warum haben sie die Gemeinschaft verlassen?',
                    it: 'Perché hanno cancellato la comunità?',
                    fr: 'Pourquoi ont-ils quitté la communauté ?',
                    pt: 'Por que é que eles entraram na comunidade?',
                    es: '¿Por qué entraron en la comunidad?',
                },
            },
            {
                id: '8c1aeb1f-b60f-4591-b247-75d4e64d10e5',
                example:
                    'The company has gated its software to prevent unauthorized access.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_8c1aeb1f-b60f-4591-b247-75d4e64d10e5_en.mp3',
                translations: {
                    ru: 'Компания закрыла свое программное обеспечение для предотвращения несанкционированного доступа.',
                    de: 'Das Unternehmen hat seine Software mit Gates versehen, um unbefugten Zugriff zu verhindern.',
                    it: "L'azienda ha messo sotto controllo il proprio software per impedire l'accesso non autorizzato.",
                    fr: "L'entreprise a verrouillé son logiciel pour empêcher tout accès non autorisé.",
                    pt: 'A empresa bloqueou o seu software para impedir o acesso não autorizado.',
                    es: 'La empresa ha bloqueado su software para impedir el acceso no autorizado.',
                },
            },
            {
                id: 'b51f46f6-a03e-4962-9da3-451bc2bdb11a',
                example: 'They decided to gate the entrance to the park.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_b51f46f6-a03e-4962-9da3-451bc2bdb11a_en.mp3',
                translations: {
                    ru: 'Они решили закрыть вход в парк воротами.',
                    de: 'Sie beschlossen, den Eingang zum Park zu sperren.',
                    it: "Hanno deciso di chiudere l'ingresso del parco con un cancello.",
                    fr: "Ils ont décidé d'ériger une barrière à l'entrée du parc.",
                    pt: 'Decidiram fechar a entrada do parque.',
                    es: 'Decidieron cerrar la entrada al parque.',
                },
            },
        ],
        lastIteration: 5,
        translations: {
            ru: ['ограничить', 'бар', 'блок'],
            de: ['einschränken', 'bar', 'Block'],
            it: ['limitare', 'bar', 'blocco'],
            fr: ['restreindre', 'bar', 'bloc'],
            pt: ['restringir', 'bar', 'bloco'],
            es: ['restrinja', 'bar', 'bloque'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'security',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/security_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: 'df1c09a1-9acf-4f58-bbc0-7449d9aaa091',
                partOfSpeech: 'noun',
                pronunciation: '[sɪˈkjʊrɪti]',
                article: null,
                forms: ['securities', 'security'],
                synonyms: ['protection', 'defense', 'safety'],
                examples: [
                    {
                        id: '093fe4af-1aff-4fdc-bbca-8f065e1c78db',
                        example: 'Are these securities a good investment?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_093fe4af-1aff-4fdc-bbca-8f065e1c78db_en.mp3',
                    },
                    {
                        id: '11d99541-c05c-4253-97bf-049dfb0a5a98',
                        example:
                            'The security of the country is our top priority.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_11d99541-c05c-4253-97bf-049dfb0a5a98_en.mp3',
                    },
                    {
                        id: '56d0c1ca-106b-47fa-a62c-3315d13e14fe',
                        example: 'How can we improve the security of our data?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_56d0c1ca-106b-47fa-a62c-3315d13e14fe_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: 'df1c09a1-9acf-4f58-bbc0-7449d9aaa091',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[sɪˈkjʊrɪti]',
        forms: ['securities', 'security'],
        synonyms: ['protection', 'defense', 'safety'],
        examples: [
            {
                id: '093fe4af-1aff-4fdc-bbca-8f065e1c78db',
                example: 'Are these securities a good investment?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_093fe4af-1aff-4fdc-bbca-8f065e1c78db_en.mp3',
                translations: {
                    ru: 'Являются ли эти ценные бумаги хорошей инвестицией?',
                    de: 'Sind diese Wertpapiere eine gute Investition?',
                    it: 'Questi titoli sono un buon investimento?',
                    fr: 'Ces titres constituent-ils un bon investissement ?',
                    pt: 'Estes títulos são um bom investimento?',
                    es: '¿Son estos valores una buena inversión?',
                },
            },
            {
                id: '11d99541-c05c-4253-97bf-049dfb0a5a98',
                example: 'The security of the country is our top priority.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_11d99541-c05c-4253-97bf-049dfb0a5a98_en.mp3',
                translations: {
                    ru: 'Безопасность страны - наш главный приоритет.',
                    de: 'Die Sicherheit des Landes hat für uns oberste Priorität.',
                    it: 'La sicurezza del Paese è la nostra priorità assoluta.',
                    fr: 'La sécurité du pays est notre priorité absolue.',
                    pt: 'A segurança do país é a nossa principal prioridade.',
                    es: 'La seguridad del país es nuestra máxima prioridad.',
                },
            },
            {
                id: '56d0c1ca-106b-47fa-a62c-3315d13e14fe',
                example: 'How can we improve the security of our data?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_56d0c1ca-106b-47fa-a62c-3315d13e14fe_en.mp3',
                translations: {
                    ru: 'Как мы можем повысить безопасность наших данных?',
                    de: 'Wie können wir die Sicherheit unserer Daten verbessern?',
                    it: 'Come possiamo migliorare la sicurezza dei nostri dati?',
                    fr: 'Comment pouvons-nous améliorer la sécurité de nos données ?',
                    pt: 'Como podemos melhorar a segurança dos nossos dados?',
                    es: '¿Cómo podemos mejorar la seguridad de nuestros datos?',
                },
            },
        ],
        lastIteration: 6,
        translations: {
            ru: ['защита', 'оборона', 'безопасность'],
            de: ['Schutz', 'Verteidigung', 'Sicherheit'],
            it: ['protezione', 'difesa', 'sicurezza'],
            fr: ['protection', 'défense', 'la sécurité'],
            pt: ['proteção', 'defesa', 'segurança'],
            es: ['protección', 'defensa', 'seguridad'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'custom',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/custom_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '0b0cc0ca-d8eb-4870-bc93-df29c0518a5d',
                partOfSpeech: 'noun',
                pronunciation: '[ˈkʌstəm]',
                article: null,
                forms: ['custom', 'customs'],
                synonyms: ['practice', 'tradition', 'convention'],
                examples: [
                    {
                        id: '07c08248-966e-4b9e-bc97-335c0d1fa76a',
                        example: 'What are the customs of this local tribe?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_07c08248-966e-4b9e-bc97-335c0d1fa76a_en.mp3',
                    },
                    {
                        id: '939d0db5-f3cf-4dce-b733-33c4f94f9bc1',
                        example: 'The custom of gift-giving is universal.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_939d0db5-f3cf-4dce-b733-33c4f94f9bc1_en.mp3',
                    },
                    {
                        id: 'd6ec3e58-6fce-477c-9f32-97fcccd0ee28',
                        example:
                            'Customs vary greatly from one country to another.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_d6ec3e58-6fce-477c-9f32-97fcccd0ee28_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '0b0cc0ca-d8eb-4870-bc93-df29c0518a5d',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[ˈkʌstəm]',
        forms: ['custom', 'customs'],
        synonyms: ['practice', 'tradition', 'convention'],
        examples: [
            {
                id: '07c08248-966e-4b9e-bc97-335c0d1fa76a',
                example: 'What are the customs of this local tribe?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_07c08248-966e-4b9e-bc97-335c0d1fa76a_en.mp3',
                translations: {
                    ru: 'Каковы обычаи этого местного племени?',
                    de: 'Was sind die Bräuche dieses lokalen Stammes?',
                    it: 'Quali sono le usanze di questa tribù locale?',
                    fr: 'Quelles sont les coutumes de cette tribu locale ?',
                    pt: 'Quais são os costumes desta tribo local?',
                    es: '¿Cuáles son las costumbres de esta tribu local?',
                },
            },
            {
                id: '939d0db5-f3cf-4dce-b733-33c4f94f9bc1',
                example: 'The custom of gift-giving is universal.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_939d0db5-f3cf-4dce-b733-33c4f94f9bc1_en.mp3',
                translations: {
                    ru: 'Обычай дарить подарки является универсальным.',
                    de: 'Der Brauch des Schenkens ist universell.',
                    it: "L'usanza di fare regali è universale.",
                    fr: 'La coutume du cadeau est universelle.',
                    pt: 'O costume de oferecer presentes é universal.',
                    es: 'La costumbre de hacer regalos es universal.',
                },
            },
            {
                id: 'd6ec3e58-6fce-477c-9f32-97fcccd0ee28',
                example: 'Customs vary greatly from one country to another.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_d6ec3e58-6fce-477c-9f32-97fcccd0ee28_en.mp3',
                translations: {
                    ru: 'Обычаи разных стран сильно отличаются друг от друга.',
                    de: 'Die Sitten und Gebräuche sind von Land zu Land sehr unterschiedlich.',
                    it: "Le abitudini variano molto da un Paese all'altro.",
                    fr: "Les coutumes varient considérablement d'un pays à l'autre.",
                    pt: 'Os costumes variam muito de um país para outro.',
                    es: 'Las costumbres varían mucho de un país a otro.',
                },
            },
        ],
        lastIteration: 7,
        translations: {
            ru: ['практика', 'традиция', 'съезд'],
            de: ['Praxis', 'Tradition', 'Tagung'],
            it: ['pratica', 'tradizione', 'convenzione'],
            fr: ['pratique', 'tradition', 'convention'],
            pt: ['prática', 'tradição', 'convenção'],
            es: ['práctica', 'tradición', 'convención'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'passport',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/passport_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '526af9b8-b528-48ae-b7c8-e26c788e72e7',
                partOfSpeech: 'noun',
                pronunciation: '[ˈpæspɔːrt]',
                article: null,
                forms: ['passport', 'passports'],
                synonyms: ['travel document', 'identification', 'ID'],
                examples: [
                    {
                        id: '00846e59-5955-4170-ab9e-697e26138a63',
                        example:
                            'Is your passport valid for the upcoming journey?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_00846e59-5955-4170-ab9e-697e26138a63_en.mp3',
                    },
                    {
                        id: '2c24de4b-27d8-41e2-a4e3-29080925c1f8',
                        example: 'How many passports do you have?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_2c24de4b-27d8-41e2-a4e3-29080925c1f8_en.mp3',
                    },
                    {
                        id: '60dc52e3-800a-48f8-b1e2-732754c23c1f',
                        example: 'I need to renew my passport before the trip.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_60dc52e3-800a-48f8-b1e2-732754c23c1f_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '526af9b8-b528-48ae-b7c8-e26c788e72e7',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[ˈpæspɔːrt]',
        forms: ['passport', 'passports'],
        synonyms: ['travel document', 'identification', 'ID'],
        examples: [
            {
                id: '00846e59-5955-4170-ab9e-697e26138a63',
                example: 'Is your passport valid for the upcoming journey?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_00846e59-5955-4170-ab9e-697e26138a63_en.mp3',
                translations: {
                    ru: 'Действителен ли ваш паспорт для предстоящего путешествия?',
                    de: 'Ist Ihr Reisepass für die bevorstehende Reise gültig?',
                    it: 'Il passaporto è valido per il viaggio?',
                    fr: 'Votre passeport est-il valable pour le voyage à venir ?',
                    pt: 'O seu passaporte é válido para a viagem que se aproxima?',
                    es: '¿Su pasaporte es válido para el próximo viaje?',
                },
            },
            {
                id: '2c24de4b-27d8-41e2-a4e3-29080925c1f8',
                example: 'How many passports do you have?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_2c24de4b-27d8-41e2-a4e3-29080925c1f8_en.mp3',
                translations: {
                    ru: 'Сколько у вас паспортов?',
                    de: 'Wie viele Pässe haben Sie?',
                    it: 'Quanti passaporti avete?',
                    fr: 'Combien de passeports possédez-vous ?',
                    pt: 'Quantos passaportes tem?',
                    es: '¿Cuántos pasaportes tiene?',
                },
            },
            {
                id: '60dc52e3-800a-48f8-b1e2-732754c23c1f',
                example: 'I need to renew my passport before the trip.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_60dc52e3-800a-48f8-b1e2-732754c23c1f_en.mp3',
                translations: {
                    ru: 'Мне нужно обновить паспорт перед поездкой.',
                    de: 'Ich muss meinen Reisepass vor der Reise erneuern.',
                    it: 'Devo rinnovare il passaporto prima del viaggio.',
                    fr: 'Je dois renouveler mon passeport avant le voyage.',
                    pt: 'Preciso de renovar o meu passaporte antes da viagem.',
                    es: 'Necesito renovar mi pasaporte antes del viaje.',
                },
            },
        ],
        lastIteration: 1,
        translations: {
            ru: ['проездной документ', 'идентификация', 'ID'],
            de: ['Reisedokument', 'Identifizierung', 'ID'],
            it: ['documento di viaggio', 'identificazione', 'ID'],
            fr: ['document de voyage', 'identification', 'ID'],
            pt: ['documento de viagem', 'identificação', 'ID'],
            es: ['documento de viaje', 'identificación', 'ID'],
        },
    },
    {
        categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        word: 'check-in',
        lang: 'en',
        language: 'en',
        voicePath: '/filedump/dictionary-synthesis/check-in_en.mp3',
        article: null,
        processing: false,
        data: [
            {
                id: '0303f53e-9c4a-4d6c-8ede-c94f4c1eebb1',
                partOfSpeech: 'noun',
                pronunciation: '[ˈtʃɛk ɪn]',
                article: null,
                forms: ['check-in', 'check-ins'],
                synonyms: ['arrival', 'sign-in', 'registration'],
                examples: [
                    {
                        id: '44ac17f2-6b82-45de-9141-dcee4b907346',
                        example: 'I will do the check-in for our flight.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_44ac17f2-6b82-45de-9141-dcee4b907346_en.mp3',
                    },
                    {
                        id: 'c3d7b6f2-9f7c-4858-88b7-d35fe282c410',
                        example: 'The hotel has a 24-hour check-in service.',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_c3d7b6f2-9f7c-4858-88b7-d35fe282c410_en.mp3',
                    },
                    {
                        id: 'f60dba02-2921-412b-bc46-5d1c1441991d',
                        example: 'What time is the check-in for our hotel?',
                        voicePath:
                            '/filedump/dictionary-synthesis/example_f60dba02-2921-412b-bc46-5d1c1441991d_en.mp3',
                    },
                ],
            },
        ],
        status: 'active',
        id: '0303f53e-9c4a-4d6c-8ede-c94f4c1eebb1',
        levels: [1, 2, 3, 4, 5, 6, 7],
        partOfSpeech: 'noun',
        pronunciation: '[ˈtʃɛk ɪn]',
        forms: ['check-in', 'check-ins'],
        synonyms: ['arrival', 'sign-in', 'registration'],
        examples: [
            {
                id: '44ac17f2-6b82-45de-9141-dcee4b907346',
                example: 'I will do the check-in for our flight.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_44ac17f2-6b82-45de-9141-dcee4b907346_en.mp3',
                translations: {
                    ru: 'Я займусь регистрацией на наш рейс.',
                    de: 'Ich werde den Check-in für unseren Flug erledigen.',
                    it: 'Mi occuperò del check-in per il nostro volo.',
                    fr: "Je m'occupe de l'enregistrement de notre vol.",
                    pt: 'Eu faço o check-in para o nosso voo.',
                    es: 'Haré el check-in para nuestro vuelo.',
                },
            },
            {
                id: 'c3d7b6f2-9f7c-4858-88b7-d35fe282c410',
                example: 'The hotel has a 24-hour check-in service.',
                voicePath:
                    '/filedump/dictionary-synthesis/example_c3d7b6f2-9f7c-4858-88b7-d35fe282c410_en.mp3',
                translations: {
                    ru: 'В отеле работает круглосуточная служба регистрации.',
                    de: 'Das Hotel bietet einen 24-Stunden-Check-in-Service.',
                    it: "L'hotel dispone di un servizio di check-in 24 ore su 24.",
                    fr: "L'hôtel dispose d'un service d'enregistrement 24 heures sur 24.",
                    pt: 'O hotel dispõe de um serviço de check-in 24 horas por dia.',
                    es: 'El hotel dispone de un servicio de check-in 24 horas.',
                },
            },
            {
                id: 'f60dba02-2921-412b-bc46-5d1c1441991d',
                example: 'What time is the check-in for our hotel?',
                voicePath:
                    '/filedump/dictionary-synthesis/example_f60dba02-2921-412b-bc46-5d1c1441991d_en.mp3',
                translations: {
                    ru: 'В какое время происходит заселение в наш отель?',
                    de: 'Wann ist der Check-in für unser Hotel?',
                    it: 'A che ora è il check-in per il nostro hotel?',
                    fr: "Quelle est l'heure d'arrivée à l'hôtel ?",
                    pt: 'A que horas é o check-in no nosso hotel?',
                    es: '¿A qué hora hay que registrarse en el hotel?',
                },
            },
        ],
        lastIteration: 0,
        translations: {
            ru: ['прибытие', 'регистрация', 'регистрация'],
            de: ['Ankunft', 'Anmeldung', 'Anmeldung'],
            it: ['arrivo', 'firma', 'registrazione'],
            fr: ['arrivée', "s'inscrire", 'enregistrement'],
            pt: ['chegada', 'iniciar sessão', 'registo'],
            es: ['llegada', 'Regístrese en', 'inscripción'],
        },
    },
];
