import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import lessonsStore from '../../../TeacherLessons/store/lessonsStore';
import Player from '../../../Player/Player';
import { Situation } from '../../../SituationsAdministration/components';
import { Preview } from '../../../TeacherContent/views/ExercisesView/components';
import { CategoryWords } from '../../../TeacherContent/views/DictionaryView/components';
import { ChapterSection } from '../../../TeacherLessons/components';
import { CloseButton } from '../../../../teacherComponents';
import { Chapters } from '../../../TeacherLessons/data/constants';
import { ModalHeader } from '../../../TeacherLessons/components/LessonModal/components';
import { DemoExercise } from '../DemoExercisePreview/DemoExercisePreview';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import { TicksInSecond } from '../../../../data/common';
import exercisesPlayer from '../../../TeacherContent/store/exercisesPlayer';
import cl from './DemoLessonModal.module.css';

const DemoLessonModal = ({ lessonObj, onClose }) => {
    const { t, i18n } = useTranslation();

    const { currentChapter } = lessonsStore;

    const [currentWord, setCurrentWord] = useState();
    const [tracks, setTracks] = useState([]);
    const [situations, setSituations] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [dictionaries, setDictionaries] = useState([]);

    const [currentObject, setCurrentObject] = useState({});

    const chapterRefs = useRef({});

    const [getTrack, trackLoading] = useFetching(
        async ({ trackId, trackInterval }) => {
            const { data } = await TrackService.getTrack(trackId);
            const rangeInTicks = trackInterval.map((t) => t * TicksInSecond);
            exercisesPlayer.setTrackData(data);
            exercisesPlayer.setMediaRange(rangeInTicks);
            exercisesPlayer.setCurrentTime(rangeInTicks[0]);
        }
    );

    const chaptersList = [
        {
            items: exercises,
            type: Chapters.Exercise,
        },
        {
            items: situations,
            type: Chapters.Situation,
        },

        {
            items: tracks,
            type: Chapters.Track,
        },
        {
            items: dictionaries,
            type: Chapters.Dictionary,
        },
    ];

    const getChapterPreview = () => {
        switch (currentChapter.type) {
            case Chapters.Track:
                return (
                    <Player
                        key={'modalView'}
                        track={{
                            id: currentChapter.id,
                            title: currentChapter?.title,
                        }}
                    />
                );
            case Chapters.Situation: {
                return <Situation situation={currentChapter} />;
            }
            case Chapters.Exercise: {
                return (
                    <DemoExercise
                        exerciseObj={currentObject}
                        isLoading={trackLoading}
                    />
                );
            }
            case Chapters.Dictionary: {
                return (
                    <CategoryWords
                        category={currentChapter}
                        lang={i18n.language}
                        currentWord={currentWord}
                        setCurrentWord={setCurrentWord}
                    />
                );
            }
            default:
                return (
                    <div className={cl.emptyAlert}>
                        <p className={cl.alertTitle}>
                            {t(`exercises.select_chapter`)}
                        </p>
                    </div>
                );
        }
    };

    const getModalClassName = () => {
        const classNames = [cl.lessonModal];
        return classNames.join(' ');
    };

    const selectFirstChapter = () => {
        const chapters = lessonObj.chapters ?? [];
        const activeChapter = chapters.length ? chapters[0] : {};

        lessonsStore.setCurrentChapter(activeChapter);
    };

    useEffect(() => {
        const chapters = lessonObj.chapters ?? [];

        setTracks(chapters.filter((c) => c.type === Chapters.Track));
        setSituations(chapters.filter((c) => c.type === Chapters.Situation));
        setExercises(chapters.filter((c) => c.type === Chapters.Exercise));
        setDictionaries(chapters.filter((c) => c.type === Chapters.Dictionary));

        if (!currentChapter?.id) {
            selectFirstChapter();
        }
    }, [lessonObj]);

    useEffect(() => {
        if (currentChapter.id && currentChapter.type === Chapters.Exercise) {
            const getData = async () => {
                const data = {
                    id: 'dc14ce7e-c5f3-42c2-af1b-959b33fda86b',
                    title: 'Summarize the Volvo 740',
                    description:
                        'Read the text about the Volvo 740 and choose the summary that best represents the original text.',
                    type: 'summary',
                    lang: 'en',
                    trackId: null,
                    trackInterval: null,
                    text: 'The Volvo 740 is a popular car model from the Swedish company, Volvo. It was first made in the 1980s. The Volvo 740 is known for its strong build and safety features. This car was designed to last a long time and protect its passengers.\n\nThe Volvo 740 has a simple, yet elegant design. It is a four-door sedan, which means it has four seats and a separate trunk for luggage. Some models also came as a station wagon, which has more space for passengers and luggage.\n\nThe engine of the Volvo 740 is powerful. It can reach high speeds and still run smoothly. Despite its power, the Volvo 740 is also known for being fuel-efficient. This means it uses less gas than many other cars.\n\nThe Volvo 740 is not just a car, it is a symbol of safety and reliability. Many people still drive their Volvo 740 today, even though it is an old model. This is a testament to the quality and durability of the Volvo 740.\n\nIn conclusion, the Volvo 740 is a classic car that offers safety, reliability, and efficiency. It is a car that has stood the test of time and continues to be loved by many.',
                    difficulty: 2,
                    createdAt: '2024-06-20T00:32:20.085Z',
                    data: {
                        options: [
                            {
                                text: 'The Volvo 740 is a car model from Volvo, a Swedish company. It was first made in the 1980s and is known for its strong build, safety features, and fuel efficiency. The car has a simple design and comes in both sedan and station wagon models. Despite being an old model, many people still drive their Volvo 740 today due to its quality and durability.',
                                correct: true,
                                id: 'ab198a53-bac8-4ff8-b6bb-0018e0c569e3',
                            },
                            {
                                text: 'The Volvo 740 is a recent car model from Volvo, a Swedish company. It is known for its complex design and high fuel consumption. The car comes in both sedan and station wagon models. Despite being a new model, it is not very popular due to its lack of safety features.',
                                correct: false,
                                id: 'ad0bc4b0-7135-459a-96af-d134d52ed5e6',
                            },
                            {
                                text: 'The Volvo 740 is a car model from a German company. It was first made in the 1990s and is known for its weak build and lack of safety features. The car has a complex design and only comes in a station wagon model. Despite being an old model, it is not very popular today.',
                                correct: false,
                                id: '10e81199-2550-4a5b-a58f-e380d4f0cb42',
                            },
                        ],
                    },
                };
                if (data?.trackId && data?.trackInterval) {
                    await getTrack({
                        trackId: data.trackId,
                        trackInterval: data.trackInterval,
                    });
                } else {
                    exercisesPlayer.resetMediaParams();
                }
                setCurrentObject(data);
            };
            getData();
        }
    }, [currentChapter]);

    return (
        <div className={cl.overlay} onClick={onClose}>
            <div
                className={getModalClassName()}
                onClick={(e) => e.stopPropagation()}
            >
                <ModalHeader lesson={lessonObj} />
                <div className={cl.mainContainer}>
                    <div className={cl.aside}>
                        <div className={cl.chaptersList}>
                            <div className={cl.scrollContainer}>
                                {chaptersList.map((c) => (
                                    <ChapterSection
                                        ref={(el) =>
                                            (chapterRefs.current[c.type] = el)
                                        }
                                        key={c.type}
                                        chapters={c.items}
                                        type={c.type}
                                        lessonId={lessonObj.id}
                                        onDelete={() => {}}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={cl.chapterCard}>{getChapterPreview()}</div>
                </div>
                <div style={{ postion: 'absolute' }}>
                    <CloseButton onClick={onClose} />
                </div>
            </div>
        </div>
    );
};

export default observer(DemoLessonModal);
