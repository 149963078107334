import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import guestOnboarding from '../../store/guestOnboarding';
import Player from '../../../Player/Player';
import { PonyPlayer } from '../../../../views/User/components';
import InfoModal from '../InfoModal/InfoModal';
import { Chapters } from '../../../TeacherLessons/data/constants';
import { tracks } from '../../data/tracks';
import cl from './DemoVideoPreview.module.css';

const DemoVideoPreview = ({ className, isStudent }) => {
    const { t } = useTranslation();

    const track = tracks[0];

    const onboardingKey = guestOnboarding.isOnboarding(Chapters.Track);

    return (
        <div className={`${cl.previewContainer} ${className}`}>
            {!onboardingKey &&
                (isStudent ? (
                    <PonyPlayer trackId={track.id} />
                ) : (
                    <Player track={track} isGuest />
                ))}
            <InfoModal
                delay={700}
                onboardingKey={onboardingKey}
                variant={Chapters.Track}
                title={t('teacher_tutorial.videos_info')}
                subtitle={t('teacher_tutorial.videos_info_subtitle')}
            />
        </div>
    );
};

export default observer(DemoVideoPreview);
