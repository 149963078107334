import React from 'react';
import { observer } from 'mobx-react-lite';
import player from '../../../../store/player';
import { Sentence } from '..';
import cl from './TextDisplay.module.css';

const TextDisplay = ({ textRef }) => {
    const { sentences } = player;

    return (
        <>
            <div ref={textRef} className={cl.textDisplay}>
                {sentences.map((s) => (
                    <Sentence key={s.key || 'last'} sentence={s} />
                ))}
            </div>
        </>
    );
};

export default observer(TextDisplay);
