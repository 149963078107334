import axios from 'axios';
export const URL = 'v1/situations';

export default class SituationsService {
    static async addCategory({ title, file }) {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('file', file);
        const response = await axios.post(`${URL}/category`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    }

    static async addSituation({
        categoryId,
        requestText,
        language,
        gender,
        modality,
        languageLevel,
    }) {
        const response = await axios.post(`${URL}/situation`, {
            title: '',
            categoryId,
            requestText,
            language,
            gender,
            modality,
            languageLevel,
        });
        return response;
    }

    static async addSituationsBatch({ situations, file }) {
        const formData = new FormData();
        formData.append('situations', JSON.stringify(situations));
        formData.append('file', file);
        const response = await axios.post(
            `${URL}/admin/situation/batch`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response;
    }

    static async getAvailableCategories({
        language,
        level,
        interfaceLanguage,
        vocabulary,
    }) {
        const params = new URLSearchParams({
            language: language,
            includeUserGenerated: true,
        });

        if (level) {
            params.append('level', level);
        }

        if (interfaceLanguage) {
            params.append('interfaceLanguage', interfaceLanguage);
        }

        if (vocabulary && vocabulary.length) {
            params.append('vocabulary', vocabulary);
        }

        const categories = await axios.get(
            `${URL}/category/with-situations/?${params.toString()}`
        );
        return categories;
    }

    static async getCategories({ language }) {
        const categories = await axios.get(
            `${URL}/category/list?language=${language}&includeUserGenerated=true`
        );
        return categories;
    }

    static async getQueue() {
        const queue = await axios.get(`${URL}/category/queue/`);
        return queue;
    }

    static async getCategoryQueue({ id }) {
        const queue = await axios.get(`${URL}/category/${id}/queue/`);
        return queue;
    }

    static async deleteSituationFromQueue({ id }) {
        const res = await axios.delete(`${URL}/situation/queue/${id}`);
        return res;
    }

    static async getSituation({
        id,
        language,
        gender,
        modality,
        targetLanguage,
    }) {
        const queryParams = new URLSearchParams({
            language,
            targetLanguage,
            gender,
            modality,
        });

        const situation = await axios.get(
            `${URL}/situation/${id}?${queryParams.toString()}`
        );
        return situation;
    }

    static async getSituationInfo({ id, language }) {
        const situation = await axios.get(
            `${URL}/situation/info/${id}?language=${language}`
        );
        return situation;
    }

    static async regenerateSituation({ situationInfoId }) {
        try {
            const response = await axios.post(
                `${URL}/admin/situation/regenerate-situation`,
                {
                    situationInfoId,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async generateSituationBy({
        id,
        language,
        gender,
        modality,
        level,
    }) {
        try {
            const body = {
                situationId: id,
                language,
                gender,
                modality,
            };
            if (level) {
                body.level = level;
            }
            const response = await axios.post(
                `${URL}/admin/situation/generate-situation-by`,
                body
            );

            return response;
        } catch (error) {
            return error.response;
        }
    }

    static async updateLevels({ id, levels, language }) {
        const response = await axios.patch(
            `${URL}/admin/situation/update-levels/${id}`,
            {
                levels,
                language,
            }
        );
        return response;
    }

    static async updateStatement({ id, phrase, forceUpdate, voiceId }) {
        const body = { phrase, forceUpdate };
        if (voiceId) body.voiceId = voiceId;

        const response = await axios.patch(
            `${URL}/admin/situation/update-statement/${id}`,
            body
        );
        return response;
    }

    static async deleteStatement({ id }) {
        const response = await axios.delete(
            `${URL}/admin/situation/delete-statement/${id}`
        );
        return response;
    }

    static async updateCategoryTitle({ id, title, language }) {
        const response = await axios.patch(
            `${URL}/category/${id}/title-translation`,
            {
                title,
                language,
            }
        );
        return response;
    }

    static async updateSituationCategory({ id, categoryId }) {
        const response = await axios.patch(
            `${URL}/admin/situation/${id}/category`,
            {
                categoryId,
            }
        );
        return response;
    }

    static async updateSituationProcessed({ id, processed, language }) {
        const response = await axios.patch(
            `${URL}/admin/situation/update-processed/${id}`,
            {
                processed,
                language,
            }
        );
        return response;
    }

    static async getHealthCheck() {
        try {
            const response = await axios.get(`${URL}/healthcheck`);

            return response;
        } catch (e) {
            return null;
        }
    }
}
