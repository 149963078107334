import axios from 'axios';
import { DEVICE_ID } from '../utils/getDeviceId';

const URL = 'v1/translation';

const headers = {
    'x-actor-device': DEVICE_ID,
};

export default class TranslationService {
    static async translateSentence({ text, sourceLang, targetLang }) {
        try {
            const res = await axios.post(
                `${URL}/translate-sentence`,
                { text, sourceLang, targetLang },
                {}
            );
            return res;
        } catch (e) {
            return { data: { targetText: text } };
        }
    }

    static async translateTextArray({ sourceArr, targetLang, sourceLang }) {
        try {
            const res = await axios.post(
                `${URL}/translate-text-array`,
                { sourceArr, sourceLang, targetLang },
                {}
            );
            return res;
        } catch (e) {
            return { data: { sourceArr, targetArr: sourceArr } };
        }
    }

    static async getHealthCheck() {
        try {
            const response = await axios.get(`${URL}/healthcheck`, { headers });

            return response;
        } catch (e) {
            return null;
        }
    }
}
