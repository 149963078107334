import React, { useEffect, useRef, useState } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import lessonsStore from '../../store/lessonsStore';
import teachersStore from '../../../Teachers/store/teachersStore';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import onboardingStore from '../../../../store/onboarding';
import { MicroButton } from '../../../../teacherComponents';
import { Views } from '../../data/constants';
import { PrimaryInput } from '../../../../UI';
import { DeleteConfirmation, Lesson } from '..';
import {
    OnboardingWrapper,
    OnboardingKeys,
    BubblePositions,
    ArrowPositions,
} from '../../../../teacherComponents/Onboarding';
import cl from './Theme.module.css';
import { ReactComponent as IconBin } from '../../../../assets/svg/lessons-bin.svg';

const Theme = ({ themeObj, isLast }) => {
    const { t } = useTranslation();

    const lessonsRef = useRef();

    const { id, title, lessons = [] } = themeObj;
    const { gridSize } = lessonsStore;
    const { currentTeacher } = teachersStore;
    const isTeacher = exercisesStore.view === Views.Teachers && currentTeacher;

    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [newTitle, setNewTitle] = useState(title);

    const [updateLesson] = useFetching(async ({ id, topic }) => {
        await ExerciseService.updateLesson({ id, topic });
    });

    const [deleteAllLessons] = useFetching(async () => {
        for (const lesson of lessons) {
            await ExerciseService.deleteLesson({ id: lesson.id });
        }
        lessonsStore.deleteTheme(themeObj.id);
    });

    const handleTitleUpdate = () => {
        setIsEdit(false);
        if (!newTitle) return;
        lessonsStore.updateTheme({ ...themeObj, title: newTitle });
        lessons.forEach((l) => updateLesson({ id: l.id, topic: newTitle }));
    };

    const handleThemeDelete = () => {
        setIsDelete(true);
    };

    return (
        <Droppable droppableId={id}>
            {(droppableProvided) => (
                <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={`${cl.theme} ${isTeacher ? cl.teacher : ''}`}
                    style={{
                        minWidth: `calc(${100 / gridSize}% - 9px)`,
                        maxWidth: `calc(${100 / gridSize}% - 9px)`,
                    }}
                >
                    {isEdit ? (
                        <PrimaryInput
                            value={newTitle}
                            onChange={setNewTitle}
                            onBlur={handleTitleUpdate}
                            autoFocus={true}
                        />
                    ) : (
                        <>
                            <p className={cl.title} title={title}>
                                {title}
                            </p>

                            <div className={cl.actions}>
                                <MicroButton
                                    icon={<IconBin />}
                                    variant={'red'}
                                    size={'small'}
                                    onClick={handleThemeDelete}
                                />
                            </div>
                        </>
                    )}

                    <div
                        className={`${cl.lessons} ${isEdit ? cl.locked : ''}`}
                        ref={lessonsRef}
                    >
                        {lessons.map((lesson, index) => (
                            <OnboardingWrapper
                                key={lesson.id}
                                onboardingKey={
                                    isLast
                                        ? onboardingStore.isOnboarding(
                                              OnboardingKeys.SelectLesson
                                          )
                                        : null
                                }
                                title={t(
                                    'teacher_onboarding.open_lesson_modal'
                                )}
                                delay={500}
                                bubblePosition={{
                                    x: BubblePositions.x.Center,
                                    y: BubblePositions.y.Bottom,
                                }}
                                arrowPosition={ArrowPositions.Bottom}
                                bubbleAutoWidth
                            >
                                <div>
                                    <Lesson
                                        lessonObj={lesson}
                                        index={index}
                                        themeId={id}
                                        isOnboarding={
                                            onboardingStore.isOnboarding(
                                                OnboardingKeys.SelectLesson
                                            ) && isLast
                                        }
                                    />
                                </div>
                            </OnboardingWrapper>
                        ))}

                        {droppableProvided.placeholder}
                    </div>
                    <DeleteConfirmation
                        title={t(`exercises.delete_theme_confirmation_title`)}
                        visible={isDelete}
                        setVisible={setIsDelete}
                        onDelete={deleteAllLessons}
                    />
                </div>
            )}
        </Droppable>
    );
};

export default observer(Theme);
