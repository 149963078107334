import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import player from '../../../../store/player';
import cl from './Sentence.module.css';

const Sentence = ({ sentence }) => {
    const { active, words } = sentence;

    const [spans, setSpans] = useState([]);

    useEffect(() => {
        if (active === true) {
            player.setCurrentSentenceObj(sentence);
        }
    }, [active]);

    useEffect(() => {
        if (!words || !words.length) return;
        const arr = [];
        words.forEach((w) => {
            if (!w.text) {
                return;
            }
            const wBySpaces = w.text.split(' ');
            wBySpaces.forEach((sW) => {
                arr.push({
                    offset: w.offset,
                    duration: w.duration,
                    text: sW,
                    speaker: w.speaker,
                    active: w.active,
                });
            });
        });
        setSpans(arr);
    }, [player.currentTime]);

    return (
        <div className={`${cl.sentence} ${active ? cl.active : ''}`}>
            {spans.map((word, i) => {
                const { text, offset } = word;
                const classNames = [cl.span];
                if (word.active === true && active === true) {
                    classNames.push(cl.active);
                }
                return (
                    <span
                        key={`${text}_${offset}_${i}`}
                        className={classNames.join(' ')}
                    >
                        {word.text}
                    </span>
                );
            })}
        </div>
    );
};

export default observer(Sentence);
