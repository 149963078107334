import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography/Typography';
import LessonsCard from '../LessonsCard/LessonsCard';
import { lessons } from '../../data/lessons';
import DemoLessonModal from '../DemoLessonModal/DemoLessonModal';
import cl from './LessonsExample.module.css';
import guestOnboarding from '../../store/guestOnboarding';
import InfoModal from '../InfoModal/InfoModal';
import InfoWrapper from '../InfoWrapper/InfoWrapper';
import {
    ArrowPositions,
    BubblePositions,
} from '../../../../teacherComponents/Onboarding';

const tasksExample = [
    {
        title: 'Горящие приожки',
        track: {
            title: 'Career',
            age: '18+',
            level: 'C1',
            certificate: 'IELTS',
        },
        tasks: [
            {
                type: 'video',
                text: 'Killer Red Fox – Ep. 5 | National Geographic Presents: IMPACT With Gal Gadot',
            },
            {
                type: 'video',
                text: 'BRICS Foreign Ministers Meet In Cape Town This Afternoon',
            },
            {
                type: 'exercises',
                text: 'Past Simple...',
            },
            {
                type: 'exercises',
                text: 'Have/Has in English',
            },
            {
                type: 'dialogues',
                text: 'Asking for Directions to Find a Particular Product in a Store',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
        ],
    },
    {
        title: 'Past Simple',
        track: {
            title: 'Career',
            age: '18+',
            level: 'C1',
            certificate: 'IELTS',
        },
        tasks: [
            {
                type: 'video',
                text: 'Killer Red Fox – Ep. 5 | National Geographic Presents: IMPACT With Gal Gadot',
            },
            {
                type: 'exercises',
                text: 'Past Simple...',
            },
            {
                type: 'exercises',
                text: 'Have/Has in English',
            },
            {
                type: 'dialogues',
                text: 'Asking for Directions to Find a Particular Product in a Store',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
        ],
    },
    {
        title: 'Горящие приожки',
        track: {
            title: 'Career',
            age: '18+',
            level: 'C1',
            certificate: 'IELTS',
        },
        tasks: [
            {
                type: 'video',
                text: 'Killer Red Fox – Ep. 5 | National Geographic Presents: IMPACT With Gal Gadot',
            },
            {
                type: 'video',
                text: 'BRICS Foreign Ministers Meet In Cape Town This Afternoon',
            },
            {
                type: 'exercises',
                text: 'Past Simple...',
            },
            {
                type: 'exercises',
                text: 'Have/Has in English',
            },
            {
                type: 'dialogues',
                text: 'Asking for Directions to Find a Particular Product in a Store',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
        ],
    },
    {
        title: 'Past Simple',
        track: {
            title: 'Career',
            age: '18+',
            level: 'C1',
            certificate: 'IELTS',
        },
        tasks: [
            {
                type: 'video',
                text: 'Killer Red Fox – Ep. 5 | National Geographic Presents: IMPACT With Gal Gadot',
            },
            {
                type: 'video',
                text: 'BRICS Foreign Ministers Meet In Cape Town This Afternoon',
            },
            {
                type: 'exercises',
                text: 'Past Simple...',
            },
            {
                type: 'exercises',
                text: 'Have/Has in English',
            },
            {
                type: 'dialogues',
                text: 'Asking for Directions to Find a Particular Product in a Store',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
            {
                type: 'words',
                text: 'Religion — vocabulary',
            },
            {
                type: 'words',
                text: 'Job Search Advice ',
            },
        ],
    },
];

const LessonsExample = () => {
    const ref = useRef();
    const [wasOnboarded, setWasOnboarded] = useState(false);
    const [currentLesson, setCurrentLesson] = useState();

    const handleLessonClick = (el) => {
        setCurrentLesson(lessons[0]);
    };

    const handleCardShow = (element) => {
        if (wasOnboarded) return;
        document.body.style.overflow = 'hidden';
        setWasOnboarded(true);
        setTimeout(() => {
            scrollIntoView(element);
        }, 100);
        setTimeout(() => {
            guestOnboarding.setCurrentItem('lesson_examples');
        }, 700);
    };

    const scrollIntoView = (element) => {
        const offset = 50;

        const rect = element.getBoundingClientRect();

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo({
            top: scrollTop + rect.top - offset,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (wasOnboarded) return;
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    handleCardShow(ref.current);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.7,
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [wasOnboarded]);

    return (
        <div className={cl.lessonsExampleContainer} ref={ref}>
            <Typography
                variant={'h2'}
                className={cl.title}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.lessons_example_title'
                )}
            />
            <Typography
                variant={'h3'}
                className={cl.subTitle}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.lessons_example_subtitle'
                )}
            />
            <div className={cl.cardsBlock}>
                {tasksExample.map((el, i) => (
                    <LessonsCard
                        key={i}
                        title={el.title}
                        track={el.track}
                        tasks={el.tasks}
                        onClick={() => handleLessonClick(el)}
                        isFirst={i === 0}
                        isFocused={i === 0}
                    />
                ))}
            </div>
            {currentLesson && (
                <DemoLessonModal
                    lessonObj={currentLesson}
                    onClose={() => setCurrentLesson()}
                />
            )}
        </div>
    );
};

export default observer(LessonsExample);
