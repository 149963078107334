import React, { useState } from 'react';
import { ReactComponent as IconSearch } from '../../assets/svg/lessons-search.svg';
import DemoPageLoader from '../../components/UI/DemoPageLoader/DemoPageLoader';
import { ReactComponent as IconClose } from '../../assets/svg/lessons-cross_white.svg';
import Button from '../Button/Button';
import cl from './Input.module.css';

const Input = ({
    placeholder,
    value,
    onChange,
    onEnterKey,
    onBlur,
    onFocus,
    style,
    type,
    color,
    disabled,
    autoComplete,
    autoFocus,
    search,
    variant,
    isLoading,
    showReset,
    hints = [],
}) => {
    const [showHints, setShowHints] = useState(false);
    const filteredHints = hints.filter((hint) =>
        hint.toLowerCase().includes(value.toLowerCase())
    );

    const classNames = [cl.input];
    if (color) {
        color = Array.isArray(color) ? color : color.split(' ');
        color.forEach((el) => {
            classNames.push(cl[el]);
        });
    }
    if (variant) classNames.push(cl[variant]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            if (onEnterKey) {
                onEnterKey(e);
            }
        }
    };

    const handleChange = (e) => {
        onChange(e.target.value, e);
        setShowHints(true);
    };

    const handleResetButton = () => {
        onChange('');
        setShowHints(false);
    };

    const handleHintClick = (e, hint) => {
        e.stopPropagation();
        onChange(hint);
        setShowHints(false);
    };

    const handleBlur = () => {
        onBlur && onBlur();
        setTimeout(() => {
            setShowHints(false);
        }, 100);
    };

    const handleFocus = () => {
        setShowHints(true);
        onFocus && onFocus();
    };

    return (
        <div className={cl.container}>
            {isLoading ? (
                <DemoPageLoader size={20} color="var(--border-light-gray)" />
            ) : (
                <>
                    <input
                        autoComplete={autoComplete}
                        disabled={disabled}
                        style={style}
                        placeholder={placeholder}
                        value={value}
                        type={type || 'text'}
                        className={classNames.join(' ')}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        autoFocus={autoFocus}
                    />
                    {value && showHints && filteredHints.length > 0 && (
                        <div className={cl.hintsList}>
                            {filteredHints.map((hint, index) => (
                                <div
                                    key={index}
                                    className={cl.hintItem}
                                    onClick={(e) => handleHintClick(e, hint)}
                                >
                                    {hint}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            {search && (
                <div className={cl.icon}>
                    <IconSearch />
                </div>
            )}
            {showReset && value && (
                <div className={cl.resetButton}>
                    <Button
                        variant={'dark'}
                        size={'tiny'}
                        icon={<IconClose style={{ width: 22 }} />}
                        onClick={handleResetButton}
                    />
                </div>
            )}
        </div>
    );
};

export default Input;
