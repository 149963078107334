import React from 'react';
import { ReactComponent as IconClose } from '../../assets/svg/close.svg';
import cl from './Chip.module.css';

const Chip = ({ text, onDelete }) => {
    return (
        <div className={cl.chip}>
            <span>{text}</span>
            {onDelete && (
                <IconClose className={cl.iconClose} onClick={onDelete} />
            )}
        </div>
    );
};

export default Chip;
