import { makeAutoObservable } from 'mobx';
import { Chapters } from '../../TeacherLessons/data/constants';

const OnboardingRoutes = [
    'await',
    'navigation',
    Chapters.Dictionary,
    'student_button',
    Chapters.Exercise,
    'exercise_type',
    Chapters.Situation,
    Chapters.Track,
    'end',
    'lesson_examples',
    'end',
    'extra_navigation',
    'end',
];

class GuestOnboardingStore {
    currentItem = 'await';

    isDelay = false;
    temporaryStudent = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setCurrentItem(item) {
        this.currentItem = item;
    }

    moveToNextComponent() {
        const currentRouteIndex = OnboardingRoutes.indexOf(this.currentItem);

        if (currentRouteIndex + 1 < OnboardingRoutes.length) {
            const nextRoute = OnboardingRoutes[currentRouteIndex + 1];
            this.setCurrentItem(nextRoute);
        }
    }

    isOnboarding(routeKey) {
        return this.currentItem === routeKey;
    }

    setIsDelay(flag) {
        this.isDelay = flag;
    }
}

const guestOnboarding = new GuestOnboardingStore();

export default guestOnboarding;
