import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import player from '../../../../store/player';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import { PlayerControls } from '..';
import { Roles, TicksInSecond } from '../../../../data/common';
import cl from './Player.module.css';

const YTPlayer = require('yt-player');

const Player = ({ trackId, currentIndex, setCurrentIndex }) => {
    const { i18n } = useTranslation();
    const { role } = authStore;
    const [isCursorIdle, setIsCursorIdle] = useState(false);
    const [progress, setProgress] = useState(null);

    const playerRef = useRef(null);
    const controlsRef = useRef();
    const fetchedProgressRef = useRef(0);

    const { currentTime, duration, externalId } = player;

    const [getProgress] = useFetching(async () => {
        if (role !== Roles.User) return;
        const { data } = await ProgressService.getTrackProgress({ trackId });
        fetchedProgressRef.current = data.progress || 0;
        setProgress(data.progress || 0);
    });

    const [sendProgress] = useFetching(async (newProgress) => {
        if (role !== Roles.User) return;
        await ProgressService.sendTrackProgress({
            trackId,
            progress: newProgress,
            language: i18n.language,
            rating: null,
        });
    });

    const onPlayerReady = () => {
        playerRef.current.load(externalId, false, currentTime / TicksInSecond);

        playerRef.current.on('paused', () => player.setIsPlaying(false));
        playerRef.current.on('cued', () => {
            player.setIsLoaded(true);
            player.handlePlayPauseClick(true);
        });
        playerRef.current.on('ended', () => player.setIsPlaying(false));
        playerRef.current.on('playing', () => player.setIsPlaying(true));

        playerRef.current.off('timeupdate', handleOnProgress);
        playerRef.current.on('timeupdate', handleOnProgress);

        playerRef.current.on('error', (err) => console.error(err));
        playerRef.current.seek(currentTime / TicksInSecond);
        player.setPlayerRef(playerRef.current);
        player.setIsPlaying(false);
    };

    const handleOnProgress = (time) => {
        if (time === 0) return;

        if (player.maxTime && time * TicksInSecond > player.maxTime) {
            player.handleChangeAudioTime(player.maxTime, true);
            player.playerRef.pause();
            return false;
        } else {
            player.handleChangeAudioTime(time);
        }
    };

    const handleVideoClick = (e) => {
        if (controlsRef.current && controlsRef.current.contains(e.target))
            return;
        player.handlePlayPauseClick();
    };

    useEffect(() => {
        if (externalId) {
            playerRef.current = new YTPlayer('#YTPlayer', {
                captions: false,
                controls: false,
                modestBranding: true,
                timeupdateFrequency: 100,
                playsInline: true,
                fullscreen: false,
                annotations: false,
                rel: 0,
                autoplay: true,
                related: false,
                disablekb: 0,
                iv_load_policy: 3,
                showinfo: 0,
                enablejsapi: 1,
            });
            onPlayerReady();

            return () => {
                playerRef.current?.destroy();
            };
        }
    }, [externalId]);

    useEffect(() => {
        const currentProgress = Math.floor((currentTime / duration) * 100);

        if (currentProgress > fetchedProgressRef.current) {
            setProgress(currentProgress);
        }
    }, [currentTime, duration]);

    useEffect(() => {
        if (progress && progress > fetchedProgressRef.current) {
            fetchedProgressRef.current = progress;
            sendProgress(progress);
        }
    }, [progress, fetchedProgressRef]);

    useEffect(() => {
        getProgress();
        return () => {
            fetchedProgressRef.current = 0;
        };
    }, [trackId]);

    useEffect(() => {
        let timeoutId;

        const handleMouseMove = () => {
            setIsCursorIdle(false);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setIsCursorIdle(true), 3000);
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div
            className={`${cl.wrapper} ${isCursorIdle ? cl.idle : ''}`}
            onClick={handleVideoClick}
        >
            {externalId ? <div className={cl.player} id="YTPlayer" /> : null}
            <div className={cl.controls} ref={controlsRef}>
                <PlayerControls
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            </div>
        </div>
    );
};

export default observer(Player);
