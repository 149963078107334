import React from 'react';
import Typography from '../Typography/Typography';
import { t } from 'i18next';
import InteractionCard from '../InteractionCard/InteractionCard';
import cl from './InteractionExample.module.css';

const InteractionExample = () => {
    return (
        <div className={cl.interactionBlock}>
            <Typography
                variant={'h2'}
                className={cl.mainTitle}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.interaction_example_title'
                )}
            />
            <Typography
                variant={'h3'}
                className={cl.subtitle}
                dangerouslySetInnerHTML={t(
                    'teacher_tutorial.interaction_example_subtitle'
                )}
            />
            <div className={cl.cardBlock}>
                <InteractionCard type={'PDF'}></InteractionCard>
                <InteractionCard type={'hyperlinks'}></InteractionCard>
                <InteractionCard type={'pony'}></InteractionCard>
            </div>
        </div>
    );
};

export default InteractionExample;
