import axios from 'axios';
import { DEVICE_ID } from '../utils/getDeviceId';

const URL = 'v1/tracks';

const headers = {
    'x-actor-device': DEVICE_ID,
};

export default class TrackService {
    static async getTracks({
        offset,
        limit,
        searchString,
        lang,
        difficulty,
        hasErrors,
        recognized,
        processed,
        podcastId,
        sortColumn,
        sortOrder,
        categories,
        vocabulary,
    }) {
        const params = new URLSearchParams({
            offset,
            limit,
        });

        const optional = {
            searchString,
            difficulty,
            hasErrors,
            recognized,
            processed,
            podcastId,
            sortColumn,
            sortOrder,
            lang,
        };

        if (categories && categories.length) {
            params.append('categories', categories);
        }

        if (vocabulary && vocabulary.length) {
            params.append('vocabulary', vocabulary);
        }

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const tracks = await axios.get(`${URL}/tracks?${params.toString()}`);
        return tracks;
    }

    static async getHistory({
        offset,
        limit,
        searchString,
        lang,
        difficulty,
        podcastId,
        userId,
    }) {
        const params = new URLSearchParams({
            offset,
            limit,
        });

        const optional = {
            searchString,
            difficulty,
            podcastId,
            lang,
            userId,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const tracks = await axios.get(
            `${URL}/tracks/history?${params.toString()}`
        );
        return tracks;
    }

    static async getFavorites({
        offset,
        limit,
        searchString,
        categories,
        lang,
        difficulty,
        podcastId,
        userId,
        unassigned,
        vocabulary,
    }) {
        const params = new URLSearchParams({
            offset,
            limit,
        });

        const optional = {
            searchString,
            difficulty,
            podcastId,
            lang,
            userId,
        };

        if (categories && categories.length) {
            params.append('categories', categories);
        }

        if (vocabulary && vocabulary.length) {
            params.append('vocabulary', vocabulary);
        }

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        if (unassigned) {
            params.append('unassigned', true);
        }

        const tracks = await axios.get(
            `${URL}/tracks/favorites?${params.toString()}`
        );
        return tracks;
    }

    static async getBlacklist({ offset, limit, searchString }) {
        const params = new URLSearchParams({
            offset,
            limit,
        });
        if (searchString) {
            params.append('searchString', searchString);
        }
        const tracks = await axios.get(
            `${URL}/tracks/blacklist?${params.toString()}`
        );
        return tracks;
    }

    static async getTrack(id) {
        const track = await axios.get(`${URL}/tracks/${id}`);
        return track;
    }

    static async addTrackByUrl({ url, lang }) {
        const response = await axios.post(`${URL}/tracks/add-by-url`, {
            url,
            lang,
        });
        return response;
    }

    static async deleteTrack(id) {
        const response = await axios.delete(`${URL}/tracks/${id}`);
        return response;
    }

    static async blacklistTrack({ id, reason }) {
        const response = await axios.post(`${URL}/tracks/blacklist/${id}`, {
            reason,
        });
        return response;
    }

    static async unblacklistTrack({ id }) {
        const response = await axios.delete(`${URL}/tracks/blacklist/${id}`);
        return response;
    }

    static async getTrackSentences({ id, lang }) {
        const track = await axios.get(
            `${URL}/tracks/sentences/${id}?lang=${lang}`
        );
        return track;
    }

    static async getHealthCheck() {
        try {
            const response = await axios.get(`${URL}/healthcheck`, { headers });

            return response;
        } catch (e) {
            return null;
        }
    }

    static async updateTrack({ id, ...params }) {
        try {
            const response = await axios.patch(
                `${URL}/tracks/${id}`,
                { ...params },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async refresh() {
        const response = await axios.patch(`${URL}/tracks/refresh`, {});
        return response;
    }

    static async addCategory({ id, categoryId }) {
        try {
            const response = await axios.post(
                `${URL}/tracks/${id}/category`,
                { categoryId },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async deleteCategory({ id, categoryId }) {
        try {
            const response = await axios.delete(
                `${URL}/tracks/${id}/category`,
                {
                    data: { categoryId },
                    headers: headers,
                }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async updateTrackPriority({ id, preset, level, rating }) {
        try {
            const response = await axios.post(
                `${URL}/tracks/preset/${id}`,
                { preset, level, rating },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async getPriorityTracks({ lang, difficulty }) {
        const params = new URLSearchParams();

        const optional = { lang, difficulty };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const url = `${URL}/tracks/preset-tracks?${params.toString()}`;

        try {
            const response = await axios.get(url, {
                headers: headers,
            });

            return response;
        } catch (e) {
            return null;
        }
    }
}
