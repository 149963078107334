import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import wizardStore from '../../store/wizardStore';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CloseButton,
    Label,
} from '../../../../../../teacherComponents';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { Checkbox } from '../../../../../../UI';
import cl from './ManageWordPackModal.module.css';

const ManageWordPackModal = ({ variant, isDisabled }) => {
    const { t } = useTranslation();

    const { wordPack } = wizardStore;
    const [visible, setVisible] = useState(false);

    const words = wordPack?.items || wordPack?.words || [];

    return (
        <div style={{ position: 'relative' }}>
            <Button
                text={t('lesson_wizard.manage_word_pack')}
                onClick={() => setVisible(true)}
                variant={variant}
                isDisabled={isDisabled}
            />
            <Modal
                visible={visible}
                setVisible={setVisible}
                className={cl.manageWordsModal}
                style={{ zIndex: 11 }}
            >
                <p className={cl.title}>
                    {t('lesson_wizard.manage_word_pack')}
                </p>
                <Label text={t('lesson_wizard.select_unselect_words')} />
                <div className={cl.wordsList}>
                    {words.map((w) => (
                        <div className={cl.wordCard} key={w.word}>
                            <Checkbox
                                label={w.word}
                                variant={'checkmark'}
                                value={wizardStore.isWordDisabled(w.word)}
                                color={variant}
                                onChange={() =>
                                    wizardStore.toggleWordInclusion(w.word)
                                }
                            />
                        </div>
                    ))}
                </div>
                <CloseButton onClick={() => setVisible(false)} />
            </Modal>
        </div>
    );
};

export default observer(ManageWordPackModal);
