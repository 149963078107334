import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import lessonsStore from '../../../../store/lessonsStore';
import onboardingStore from '../../../../../../store/onboarding';
import { Chapters } from '../../../../data/constants';
import { OnboardingKeys } from '../../../../../../data/onboardingKeys';
import { ReactComponent as IconDictionary } from '../../../../../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconExercise } from '../../../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconTrack } from '../../../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconFile } from '../../../../../../assets/svg/lessons-file.svg';
import cl from './AddChapter.module.css';
import wizardStore from '../../store/wizardStore';

const ChapterOptions = [
    {
        type: Chapters.Exercise,
        onboardingKey: OnboardingKeys.ExercisesContentSelect,
    },
    {
        type: Chapters.Situation,
        onboardingKey: OnboardingKeys.SituationsContentSelect,
    },
    {
        type: Chapters.Track,
        onboardingKey: OnboardingKeys.VideosContentSelect,
    },
    {
        type: Chapters.Dictionary,
        onboardingKey: OnboardingKeys.DictionaryContentSelect,
    },
];

const AddChapter = () => {
    const { t } = useTranslation();
    const { currentLesson } = lessonsStore;
    const addedChapters = currentLesson?.chapters || [];

    const handleTypeSelect = (type, event) => {
        event.stopPropagation();
        if (isDisabled(type)) return;
        wizardStore.setCurrentView(type);
        onboardingStore.moveToNextComponent();
    };

    const renderIcon = (type) => {
        switch (type) {
            case Chapters.Track:
                return <IconTrack />;
            case Chapters.Situation:
                return <IconSituation />;
            case Chapters.Exercise:
                return <IconExercise />;
            case Chapters.Dictionary:
                return <IconDictionary />;
            case Chapters.File:
                return <IconFile />;
            default:
                return null;
        }
    };

    const isDisabled = (chapter) => {
        if (chapter !== Chapters.Dictionary) return false;
        return addedChapters.some(
            (chapter) => chapter.type === Chapters.Dictionary
        );
    };

    const getChapterButtonClassName = (chapter) => {
        const classNames = [cl.optionContainer, cl[chapter]];
        if (isDisabled(chapter)) classNames.push(cl.disabled);
        return classNames.join(' ');
    };

    return (
        <>
            {ChapterOptions.map((o) => (
                <div
                    key={o.type}
                    className={getChapterButtonClassName(o.type)}
                    onClick={(e) => {
                        handleTypeSelect(o.type, e);
                    }}
                >
                    <div className={cl.iconContainer}>{renderIcon(o.type)}</div>
                    <div className={cl.optionText}>
                        <p className={cl.title}>
                            {t(`exercises.add_${o.type}`)}
                        </p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default observer(AddChapter);
