export default function toHoursAndMinutes(totalSeconds) {
    if (
        totalSeconds === undefined ||
        Number.isNaN(totalSeconds) ||
        totalSeconds < 0
    )
        return '--:--';

    const totalMinutes = Math.floor(totalSeconds / 60);
    let seconds = Math.round(totalSeconds % 60);
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return totalSeconds;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    if (hours === '00') {
        return `${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
}
