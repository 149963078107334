import React from 'react';
import LanguageCard from '../LanguageCard/LanguageCard';
import { t } from 'i18next';
import cl from './LanguagesBar.module.css';

const langArr = ['en', 'es', 'pt', 'de', 'fr', 'it'];

const LanguagesBar = () => {
    return (
        <div className={cl.languagesBar}>
            <p>{t(`teacher_tutorial.language_of_teaching`)}:</p>
            <div className={cl.languagesSection}>
                {langArr.map((el) => (
                    <LanguageCard key={el} lang={el} />
                ))}
            </div>
        </div>
    );
};

export default LanguagesBar;
