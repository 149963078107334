import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../../../hooks/useFetching';
import UserService from '../../../../../../api/UserService';
import { authStore } from '../../../../../../App';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import lessonsStore from '../../../../store/lessonsStore';
import languagesStore from '../../../../../../store/interface';
import teachersStore from '../../../../../Teachers/store/teachersStore';
import wizardStore from '../../../LessonWizard/store/wizardStore';
import {
    Select,
    Button,
    Label,
    Tree,
    Input,
    CloseButton,
} from '../../../../../../teacherComponents';
import {
    Ages,
    AgeOptions,
    LevelOptions,
    getExamOptions,
} from '../../../../../TeacherContent/data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import { Views } from '../../../../data/constants';
import { ReactComponent as IconEdit } from '../../../../../../assets/svg/lessons-edit.svg';
import { ReactComponent as IconEye } from '../../../../../../assets/svg/icon-eye.svg';
import Modal from '../../../../../../components/UI/Modal/Modal';
import cl from './ModalHeader.module.css';

const ModalHeader = ({ lesson, onUpdate }) => {
    const { t } = useTranslation();

    const { user } = authStore;
    const { currentTeacher } = teachersStore;
    const { lang, languages, nativeLang } = languagesStore;
    const { isStudentPreview } = lessonsStore;

    const isTeacher = exercisesStore.view === Views.Teachers && currentTeacher;

    const { grammarOptions, lexicalOptions } = wizardStore;

    const [lessonData, setLessonData] = useState({});
    const [lessonErrors, setLessonErrors] = useState({});

    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredLexical, setFilteredLexical] = useState(lexicalOptions);
    const [isEdit, setIsEdit] = useState(false);

    const { difficulty } = lessonData;

    const [updateUser] = useFetching(async (nativeLang) => {
        const { id } = user;
        await UserService.updateUser({ id, lang, nativeLang });

        authStore.setUser({ ...user, lang, nativeLang });
    });

    const handleLessonUpdate = () => {
        setLessonErrors({});
        setTimeout(() => {
            const errors = {
                difficulty: typeof lessonData.difficulty !== 'number',
                vocabularyTopic: !lessonData.vocabularyTopic,
                title: !lessonData.title,
            };
            setLessonErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            setIsEdit(false);
            onUpdate(lessonData);
        });
    };

    const handleInputChange = (key, value) => {
        setLessonData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setIsEdit(false);
    }, []);

    useEffect(() => {
        setLessonData({
            title: lesson?.title ?? '',
            age: lesson?.age ?? '',
            difficulty: lesson?.difficulty ?? '',
            vocabularyTopic: lesson?.vocabularyTopic ?? '',
            grammarTopic: lesson?.grammarTopic ?? '',
            exam: lesson?.exam ?? '',
            status: lesson?.status ?? '',
        });
    }, [lesson]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));

        setFilteredGrammar(currentGrammar);

        const currentLexical = lexicalOptions
            .map((g) => ({
                ...g,
                topics: g.topics
                    .filter((tObj) => tObj.difficulty === difficulty)
                    .sort((a, b) => a.topic.localeCompare(b.topic)),
            }))
            .filter((g) => g.topics?.length)
            .sort((a, b) => a.chapter.localeCompare(b.chapter));
        setFilteredLexical(currentLexical);
    }, [grammarOptions, lexicalOptions, difficulty]);

    useEffect(() => {
        setLessonErrors({});
    }, [lessonData.title]);

    return (
        <div className={cl.lessonHeader}>
            <div className={cl.lessonInfo}>
                <p className={cl.title}>{lesson.title}</p>

                {typeof lesson.age === 'number' && (
                    <p className={cl.lessonInfoText}>{Ages[lesson.age]}</p>
                )}
                {typeof lesson.difficulty === 'number' && (
                    <p className={cl.lessonInfoText}>
                        {LanguageLevels[lesson.difficulty]}
                    </p>
                )}
                {lesson.exam && (
                    <p className={cl.lessonInfoText}>{lesson.exam}</p>
                )}

                {!isTeacher && onUpdate && (
                    <IconEdit
                        onClick={() => setIsEdit(true)}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <Button
                    className={cl.switchModeButton}
                    variant={isStudentPreview ? 'dark' : 'white'}
                    onClick={() =>
                        lessonsStore.setIsStudentPreview(!isStudentPreview)
                    }
                    text={t(
                        isStudentPreview
                            ? 'exercises.student_preview_end'
                            : 'exercises.student_preview_mode'
                    )}
                    icon={isStudentPreview ? null : <IconEye />}
                />
                {isStudentPreview && (
                    <Select
                        className={cl.targetLangSelect}
                        label={t('dictionary_administration.target_lang')}
                        value={nativeLang}
                        options={languages.filter((l) => l.value !== lang)}
                        onChange={updateUser}
                        variant={'white'}
                    />
                )}
            </div>
            <Modal
                className={cl.editModal}
                visible={isEdit}
                setVisible={setIsEdit}
            >
                <p className={cl.modalTitle}>{t('exercises.edit_lesson')}</p>
                <div>
                    <Label
                        isError={lessonErrors['title']}
                        text={t('exercises.lesson_title')}
                    />
                    <Input
                        placeholder={t('exercises.lesson_title')}
                        variant={
                            lessonErrors['title'] ? 'erroredSmall' : 'filled'
                        }
                        value={lessonData.title}
                        onChange={(value) => handleInputChange('title', value)}
                    />
                </div>
                <div className={cl.flexContainer}>
                    <div>
                        <Label text={t('users.level')} />
                        <Select
                            className={cl.select}
                            variant={
                                lessonErrors['difficulty']
                                    ? 'errorSmall'
                                    : 'grey'
                            }
                            value={lessonData.difficulty}
                            options={LevelOptions}
                            onChange={(value) =>
                                handleInputChange('difficulty', value)
                            }
                            ignoreOverflow
                            hideReset
                        />
                    </div>
                    <div>
                        <Label text={t('users.age')} />
                        <Select
                            className={cl.select}
                            variant="grey"
                            value={lessonData.age}
                            options={AgeOptions}
                            onChange={(value) =>
                                handleInputChange('age', value)
                            }
                            ignoreOverflow
                        />
                    </div>
                    <div>
                        <Label text={t('exercises.exam')} />
                        <Select
                            className={cl.select}
                            variant="grey"
                            value={lessonData.exam}
                            options={getExamOptions(lang)}
                            onChange={(value) =>
                                handleInputChange('exam', value)
                            }
                            ignoreOverflow
                        />
                    </div>
                    <div>
                        <Label text={t('lesson_wizard.lesson_access_status')} />
                        <Select
                            className={cl.select}
                            variant="grey"
                            value={lessonData.status}
                            options={[
                                {
                                    value: 'public',
                                    name: t(
                                        'lesson_wizard.lesson_status_public'
                                    ),
                                },
                                {
                                    value: 'private',
                                    name: t(
                                        'lesson_wizard.lesson_status_private'
                                    ),
                                },
                            ]}
                            onChange={(value) =>
                                handleInputChange('status', value)
                            }
                            ignoreOverflow
                        />
                    </div>
                </div>
                <div>
                    <Label text={t('lesson_wizard.select_lexical_topic')} />
                    <Tree
                        label={t('lesson_wizard.lexical_topic')}
                        className={cl.select}
                        variant={
                            lessonErrors['vocabularyTopic']
                                ? 'errorSmall'
                                : 'grey'
                        }
                        value={lessonData.vocabularyTopic}
                        onChange={(value) =>
                            handleInputChange('vocabularyTopic', value)
                        }
                        options={filteredLexical}
                        hideReset
                        ignoreOverflow
                        height={240}
                        disabled={lessonData.difficulty === ''}
                        tooltip={t('lesson_wizard.grammar_topic_select_level')}
                    />
                </div>
                <div>
                    <Label text={t('lesson_wizard.select_grammar_topic')} />
                    <Tree
                        label={t('lesson_wizard.grammar_topic')}
                        className={cl.select}
                        variant="grey"
                        value={lessonData.grammarTopic}
                        onChange={(value) =>
                            handleInputChange('grammarTopic', value)
                        }
                        options={filteredGrammar}
                        ignoreOverflow
                        height={180}
                        disabled={lessonData.difficulty === ''}
                        tooltip={t('lesson_wizard.grammar_topic_select_level')}
                    />
                </div>
                <Button
                    style={{ marginTop: 'auto' }}
                    text={t('buttons.save')}
                    onClick={handleLessonUpdate}
                    variant={'purple'}
                />
                <CloseButton onClick={() => setIsEdit(false)} />
            </Modal>
        </div>
    );
};

export default observer(ModalHeader);
