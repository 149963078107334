import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import onboardingStore from '../../../../store/onboarding';
import DownloadButtons from '../DownloadButtons/DownloadButtons';
import { TEACHER_NAVIGATION } from '../../../../components/Header/Header';
import { ReactComponent as TeacherLogo } from '../../../../assets/svg/lessons-logo.svg';
import { ReactComponent as TeacherLogoText } from '../../../../assets/svg/lessons-logo-text.svg';

import { OnboardingKeys } from '../../../../data/onboardingKeys';
import { Roles } from '../../../../data/common';
import cl from './Footer.module.css';

const Footer = () => {
    const { t } = useTranslation();

    const isOnboarding = onboardingStore.isOnboarding(OnboardingKeys.Start);
    const isGuest = authStore.role === Roles.Guest;

    const hideNavigation = isOnboarding || isGuest;

    return (
        <div className={cl.footer}>
            <div className={cl.navigationBlock}>
                <div className={cl.logo}>
                    <TeacherLogo className={cl.teacherLogo} />
                    <TeacherLogoText />
                </div>
                <div
                    className={`${cl.navigation} ${hideNavigation ? '' : cl.hidden}`}
                >
                    {TEACHER_NAVIGATION.map((l, i) => {
                        if (i === 0) {
                            return (
                                <Fragment key={l.to}>
                                    <p className={cl.greyText}>
                                        {t('footer.navigation')}
                                    </p>
                                    <NavLink
                                        key={l.to}
                                        className={cl.link}
                                        to={l.to}
                                    >
                                        {t(l.text)}
                                    </NavLink>
                                </Fragment>
                            );
                        } else if (i === 3) {
                            return (
                                <Fragment key={l.to}>
                                    <p className={cl.greyText}>
                                        {t('footer.content')}
                                    </p>
                                    <NavLink
                                        key={l.to}
                                        className={cl.link}
                                        to={l.to}
                                    >
                                        {t(l.text)}
                                    </NavLink>
                                </Fragment>
                            );
                        } else
                            return (
                                <NavLink
                                    key={l.to}
                                    className={cl.link}
                                    to={l.to}
                                >
                                    {t(l.text)}
                                </NavLink>
                            );
                    })}
                </div>
                <div className={cl.buttonBar}>
                    <p className={cl.greyText}>{t('footer.download')}</p>
                    <DownloadButtons
                        size={'medium'}
                        variant={'grey'}
                        style={{ flexDirection: 'column', marginTop: '12px' }}
                    />
                </div>
            </div>
            <div className={cl.greyBlock}>
                <p className={cl.link}>©Pony, 2024</p>
                <p className={cl.link}>{t('landing.commercial_terms')}</p>
                <p className={cl.link}>{t('landing.contact_us')}</p>
            </div>
        </div>
    );
};

export default Footer;
