import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Typography } from '..';
import { ReactComponent as Background } from '../../../../assets/svg/teacher_tutorial_background.svg';
import LanguagesBar from '../LanguagesBar/LanguagesBar';
import cl from './FirstSection.module.css';

const FirstSection = () => {
    const { t } = useTranslation();

    return (
        <div className={cl.firstSection}>
            <Background className={cl.backgroundImage} />
            <div className={cl.sectionInner}>
                <Typography
                    variant={'h1'}
                    className={cl.mainTitle}
                    dangerouslySetInnerHTML={t('teacher_tutorial.title')}
                />
                <div className={cl.subTitleSection}>
                    <Typography className={cl.subTitle} color="#1C1C1EB2">
                        {t(`teacher_tutorial.sub_title`)}
                    </Typography>
                    <div className={cl.subTitleSelection}>
                        <p>{t(`teacher_tutorial.from`)}</p>
                        <p className={cl.selected}>A2</p>
                        <p>{t(`teacher_tutorial.to`)}</p>
                        <p className={cl.selected}>C1</p>
                    </div>
                </div>
            </div>
            <LanguagesBar />
        </div>
    );
};

export default observer(FirstSection);
